import React from 'react'
import './ProfileCompletion.css'
function ProfileCompletion({currenPage,totalPage,title}) {
  const percentage = (currenPage/totalPage) * 100 ?? 10;
  return (
    <div className='ProfileCompletionParentContainer'>
    <div className='ProfileCompletionContainer'>
        <div className='Profile-Text'>{title ?? "Tell us a bit about yourself"}</div>
        <div className='completionPercentage'>
            <div className="completion-progress" style={{ width: `${percentage}%` }} />
        </div>
    </div>
    </div>
  )
}

export default ProfileCompletion