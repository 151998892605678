
export function formatDateByTimeStamp(timestamp) {
    try {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        if (isNaN(timestamp)) {
            return "Invalid timestamp";
        }
        const date = new Date(parseInt(timestamp));
        if (isNaN(date.getTime())) {
            return "Invalid date";
        }
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month}, ${day} ${year}`;
    } catch {
        return "Invalid date";
    }
}