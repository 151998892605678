import React, { useEffect } from "react";

import { connect, useDispatch } from "react-redux";

import { LoadingOutlined } from "@ant-design/icons";

import {
  isModalVisibleAuth,
  linkedInTokenFail,
  linkedInTokenInit,
} from "../../../redux/actions";

import { useHistory, useLocation } from "react-router-dom";
import { getCookie, setCookie } from "../../../shared/helpers";

import Loader from "../../../components/Loader/Loader";
import { alertNotification } from "../../../components/notifications/alertNotification";

const LinkedInCheck = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation().pathname;

  useEffect(() => {
    const currURL = window.location.href;
    const talentToken = currURL.split("=")[1]?.toString()?.split("&");
    if (talentToken) {
      props.sendLinkedToken(talentToken[0]);
    } else {
      history.push("/");
    }
  }, []);

  // So save talentToken directly in saga through setCookie which works
  useEffect(() => {
    const talentToken = props.user.authToken;
    if (talentToken) {
      history.push("/auth");
    }
  }, [props.user.authToken]);

  useEffect(() => {
    // checking if the user exist from different social client, redirects to login if true
    if (props.error.existingUser) {
      alertNotification("error", props.error.message);
      history.push("/");
      dispatch(linkedInTokenFail({}));
      if (window.innerWidth > 1000&&false) {
        return setTimeout(() => dispatch(isModalVisibleAuth(true)), 2000);
      } else {
        return history.push("/login");
      }
    }
  }, [props.error]);

  return (
    <React.Fragment>
      <Loader height="40vh" />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    notLogged: state.authReducer.notLogged,
    error: state.authReducer.error,
    // userProfile: state.profileReducer.user.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendLinkedToken: (talentToken) => {
      dispatch(linkedInTokenInit(talentToken)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedInCheck);
