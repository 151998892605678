import React, { useState, useEffect } from "react";

import "./SkillListingDetailPage.css";

import TalentProfile from "../../Profile/components/TalentProfile";
import SkillListingStatus from "./components/SkillListingStatus";
import SkillListingTabs from "./components/SkillListingTabs";
import WorkHistorySkillListing from "./components/WorkHistorySkillListing";
import Credentialing from "../../Credentialing/components/StepTwo";
import EducationHistorySkillListing from "./components/EducationHistorySkillListing";
import TabSkillListing from "./components/TabSkillListing";

import ResumeBox3d from "./components/_3Dresume_skillListing";

import {
  fetchOneSkillListing,
  fetchUserSkillListing,
  getTalentInfo,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ModalEdit from "../ModalEdit";
import { Button, Dropdown, Menu, Space, Tabs } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { IoIosArrowDown } from "react-icons/io";
import CertsAwardsSkillListing from "./components/CertsAwardsSkillListing";
import { useUpdateLogger } from "../../../shared/useUpdateLogger";
import BidHistorySkillListing from "./components/BidHistorySkillListing/BidHistorySkillListing";

const SkillListingDetailPage = () => {
  const dispatch = useDispatch();
  const { skillId } = useParams();
  const SkillListing  = useLocation()?.state?.SkillListing;

  const [tab, setTab] = useState(1);
  const [dropdown, setDropDown] = useState("Skill Listing");
  const [midSection, setMidSection] = useState();
  const [editModalState, setEditModalState] = useState(false);
  const [oneSkillListing, setOneSkillListing] = useState();
  const [docFileView, setDocFileView] = useState(false);

  const [dimensions, setDimensions] = useState(window.innerWidth);

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
    if (SkillListing?._id) {
      localStorage.setItem("skill_id", SkillListing?._id);
    }

    return () => localStorage.removeItem("skill_id");
  }, []);

  const skill_id = localStorage.getItem("skill_id");

  const handleUpdateSkillListing = (state) => {
    setEditModalState(state);
  };

  const authToken = useSelector((state) => state.authReducer.user.authToken);
  const ProfessionCode = useSelector(
    (state) => state.profileReducer.user.profile.ProfessionCode
  );
  const singleSkillListing = useSelector(
    (state) => state.skillListingReducer?.singleSkillListing
  );

  // const res = useSelector((state) => state.profileReducer.user.profile);

  // useUpdateLogger("skill_id:res", skill_id);

  useEffect(() => {
    dispatch(fetchUserSkillListing("Published", 1, 9));
    dispatch(fetchOneSkillListing(skillId));

    return () => setOneSkillListing({});
  }, []);

  useEffect(() => {
    if (singleSkillListing[0]?.Created) {
      setOneSkillListing(singleSkillListing[0]);
    }
  }, [singleSkillListing]);

  useEffect(() => {
    dispatch(getTalentInfo(authToken));
  }, [authToken]);

  useEffect(() => {
    if (tab === 0 && oneSkillListing?.Bids?.length) {
      setDropDown("Bid History");
      setMidSection(
        <BidHistorySkillListing skillId={oneSkillListing?.SkillListingId} />
      );
    } else if (tab === 1) {
      setDropDown("Skill Listing");
      setMidSection(
        <TabSkillListing
          ProfessionCode={ProfessionCode}
          SkillListing={oneSkillListing}
        />
      );
    } else if (tab === 2) {
      setDropDown("Work History");
      setMidSection(<WorkHistorySkillListing dimensions={dimensions} />);
    } else if (tab === 3) {
      setDropDown("Credentialing");
      setMidSection(
        <Credentialing
          skillListingDetailPage={true}
          docFileView={docFileView}
          setDocFileView={setDocFileView}
        />
      );
    } else if (tab === 4) {
      setDropDown("Education History");
      setMidSection(<EducationHistorySkillListing dimensions={dimensions} />);
    } else if (tab === 5) {
      setDropDown("Certifications & Awards");
      setMidSection(<CertsAwardsSkillListing dimensions={dimensions} />);
    }
  }, [tab, oneSkillListing, docFileView]);

  const filterMenu = (
    <Menu>
      {oneSkillListing?.Bids?.length ? (
        <Menu.Item className={tab === 0 && "DropDown-select-Bid-history"} onClick={() => setTab(0)}>
          <span className="tabsDropdownSkillListing">Bid history</span>
        </Menu.Item>
      ) : null}
      <Menu.Item className={tab === 1 && "DropDown-select-Bid-history"} onClick={() => setTab(1)}>
        <span className="tabsDropdownSkillListing">Skill listing</span>
      </Menu.Item>
      <Menu.Item className={tab === 2 && "DropDown-select-Bid-history"} onClick={() => setTab(2)}>
        <span className="tabsDropdownSkillListing">Work history</span>
      </Menu.Item>
      {ProfessionCode === "HC" && (
        <Menu.Item className={tab === 3 && "DropDown-select-Bid-history"} onClick={() => setTab(3)}>
          <span className="tabsDropdownSkillListing">Credentialing</span>
        </Menu.Item>
      )}
      <Menu.Item className={tab === 4 && "DropDown-select-Bid-history"} onClick={() => setTab(4)}>
        <span className="tabsDropdownSkillListing">Education History</span>
      </Menu.Item>
      {ProfessionCode === "IT" && (
        <Menu.Item className={tab === 5 && "DropDown-select-Bid-history"} onClick={() => setTab(5)}>
          <span className="tabsDropdownSkillListing">
            Certifications & Awards
          </span>
        </Menu.Item>
      )}
    </Menu>
  );

  // useEffect(() => {
  //   
  //   
  // }, [SkillListing?.SkillListingId, skill_id]);

  return (
    <div className="skillListingDetailPage">
      <section className="first-section-sl-detail-page">
        <TalentProfile skillListingDetailPage={true} />
        {dimensions > 768 && (
          <>
            <SkillListingTabs
              tab={tab}
              setTab={setTab}
              ProfessionCode={ProfessionCode}
              bids={oneSkillListing?.Bids?.length}
            />
            <ResumeBox3d />
          </>
        )}
      </section>
      {dimensions < 768 && (
        <section className="section-dropdown-skill">
          <ResumeBox3d />
          <Dropdown
            trigger="click"
            overlay={filterMenu}
            // overlayClassName="dropdownClassRB"
            placement="bottom"
          >
            <div className="dropdowntab-skill">
              {dropdown} <IoIosArrowDown />
            </div>
          </Dropdown>
        </section>
      )}
      <section className="second-section-sl-detail-page">
        {dimensions > 768 && (
          <div className="title-edit">
            <div className="midSection-title">
              {oneSkillListing?.DisplayTitle
                ? oneSkillListing?.DisplayTitle
                : oneSkillListing?.Title}
            </div>
            <div
              className="midSection-edit clickable"
              onClick={() => setEditModalState(true)}
            >
              Edit
            </div>
            <ModalEdit
              editModalState={editModalState}
              setEditModalState={setEditModalState}
              handleUpdateSkillListing={handleUpdateSkillListing}
              id={oneSkillListing?._id || SkillListing?._id || skill_id}
              idSkill={skillId}
            />
          </div>
        )}
        {midSection}
      </section>
      <section className="third-section-sl-detail-page">
        {/* <SkillListingStatus /> */}
        {ProfessionCode == "HC" && (
          <div
            className="reliasTestingSkill clickable"
            onClick={() =>
              window.open(
                "https://app.healthcarestaffinghire.com/?redirectUrl=/admin/users",
                "_blank"
              )
            }
          >
            Complete your Skill Checklist
          </div>
        )}
      </section>
    </div>
  );
};

export default SkillListingDetailPage;
