import { types } from "./action-types";
export const isModalVisibleAuth = (params) => ({
  type: types.IS_MODAL_VISIBLE,
  payload: params,
});
export const isModalVisibleAuthResetPassword = (params) => ({
  type: types.IS_MODAL_VISIBLE_RESETPASSWORD,
  payload: params,
});
export const signUpLoginUpdate = (params) => ({
  type: types.SIGNUP_LOGIN_UPDATE,
  payload: params,
});
export const Modalclosefunction = (params) => ({
  type: types.MODAL_CLOSE_FUNCTION,
  payload: params,
});
export const checkEmailFlow = (params) => ({
  type: types.EMAIL_FLOW_UPDATE,
  payload: params,
});
export const fetchData = (params) => ({
  type: types.SEND_REQUEST_INIT,
  payload: params,
});
export const fetchDataSuccess = (response) => ({
  type: types.SEND_REQUEST_SUCCESS,
  payload: response,
});
export const fetchDataFailure = (error) => ({
  type: types.SEND_REQUEST_FAILURE,
  payload: {},
  error: error,
});
export const updateUserInfo = (params) => ({
  type: types.UPDATE_USER_INFO_INIT,
  payload: params,
});
export const uploadDocument = (params) => ({
  type: types.UPLOAD_DOCUMENT_INIT,
  params,
});
export const uploadDocSuccess = (response) => ({
  type: types.UPLOAD_DOCUMENT_SUCCESS,
  payload: response,
});
export const uploadDocFailure = (error) => ({
  type: types.UPLOAD_DOCUMENT_FAILURE,
  payload: {},
  error: error,
});
export const removeDocument = (params) => ({
  type: types.REMOVE_DOCUMENT_INIT,
  params,
});
export const removeDocumentSuccess = (params) => ({
  type: types.REMOVE_DOCUMENT_SUCCESS,
  params,
});
export const removeDocumentFailure = (params) => ({
  type: types.REMOVE_DOCUMENT_FAIL,
  params,
});
export const fetchUserProfile = (payload) => ({
  type: types.FETCH_PROFILE_INIT,
  payload,
});
export const fetchUserProfileSuccess = (response) => ({
  type: types.FETCH_PROFILE_REQUEST_SUCCESS,
  payload: response,
});
export const fetchUserProfileFailure = (error) => ({
  type: types.FETCH_PROFILE_REQUEST_FAILURE,
  payload: {},
  error: error,
});
export const talentProfileApidata = (params) => ({
  type: types.TALENT_PROFILE_API_DATA,
  payload: params,
});
export const updateProfile = (params) => ({
  type: types.UPDATE_PROFILE_INIT,
  params,
});
export const updateRecentSearch = (params) => ({
  type: types.UPDATE_RECENTSEARCH_INIT,
  params,
});
export const updateProfileSuccess = (response) => ({
  type: types.UPDATE_PROFILE_SUCCESS,
  payload: response,
});
export const updateProfileFailure = (error) => ({
  type: types.UPDATE_PROFILE_FAILURE,
  payload: {},
  error: error,
});

export const updateSignupProfile = (params) => ({
  type: types.UPDATE_SIGNUP_PROFILE_INIT,
  params,
});
export const updateSignupProfileSuccess = (response) => ({
  type: types.UPDATE_SIGNUP_PROFILE_SUCCESS,
  payload: response,
});
export const updateSignupProfileFailure = () => ({
  type: types.UPDATE_SIGNUP_PROFILE_FAILURE,
});

export const updateSocialProfileLink = (params) => ({
  type: types.UPDATE_SOCIAL_PROFILE_LINK_INIT,
  params,
});

export const uploadProfilePic = (params) => ({
  type: types.UPLOAD_PROFILE_PIC_INIT,
  params,
});
export const uploadProfilePicSuccess = (response) => ({
  type: types.UPLOAD_PROFILE_PIC_SUCCESS,
  payload: response,
});
export const uploadProfilePicFailure = (error) => ({
  type: types.UPLOAD_PROFILE_PIC_FAILURE,
  payload: {},
  error: error,
});
export const uploadResume = (params) => ({
  type: types.UPLOAD_RESUME_INIT,
  params,
});
export const uploadResumeSuccess = (response) => ({
  type: types.UPLOAD_RESUME_SUCCESS,
  payload: response,
});
export const uploadResumeFailure = (error) => ({
  type: types.UPLOAD_RESUME_FAILURE,
  payload: {},
  error: error,
});
// linkedIn login
export const fetchLinkedInToken = (params) => ({
  type: types.LINKEDIN_TOKEN_FETCH,
  payload: params,
});
export const linkedInTokenInit = (params) => ({
  type: types.LINKEDIN_TOKEN_INIT,
  payload: params,
});
export const linkedInTokenFail = (params) => ({
  type: types.LINKEDIN_TOKEN_FAIL,
  payload: params,
});
// Search Gigs
export const SearchResponseRequested = (params) => ({
  type: types.SEARCH_GIGS_INIT,
  payload: params,
});
export const SearchResponse = (params) => ({
  type: types.SEARCH_GIGS_SUCCESS,
  payload: params,
});
export const SearchValue = (params) => ({
  type: types.SEARCH_VALUE,
  payload: params,
});
// export const SearchResponseSuccess = (response) => ({
//   type: types.SEARCH_GIGS_SUCCESS,
//   payload: response,
// });
// export const SearchResponseFailure = (error) => ({
//   type: types.SEARCH_GIGS_FAILURE,
//   payload: {},
//   error: error,
// });
// Fetch existing user
export const fetchExistingtUser = (params) => ({
  type: types.CHECK_EXISTING_USER_INIT,
  params,
});
export const fetchExistingUserSuccess = (response) => ({
  type: types.CHECK_EXISTING_USER_SUCCESS,
  payload: response,
});
export const fetchExistingUserFailure = (error) => ({
  type: types.CHECK_EXISTING_USER_FAILURE,
  payload: { valid: false, msg: "User doesn't exist" },
  error: error,
});
// export const fetchLinkedInTokenFail = (error) => ({
//   type: types.LINKEDIN_TOKEN_FAIL,
//   payload: {},
//   error: error,
// });

export const saveOneSkillListingId = (payload) => ({
  type: types.FETCH_ONE_SKILLLISTING_ID,
  payload,
});

export const getRating = (payload) => ({
  type: types.GET_RATING_REQUESTED,
  payload,
});

export const removeOneSkillListingId = () => ({
  type: types.REMOVE_ONE_SKILLLISTING_ID,
});

export const fetchSearchSkill = (params) => ({
  type: types.FETCH_SKILLS_INIT,
  params,
});
export const fetchSearchSkillSuccess = (response) => ({
  type: types.FETCH_SKILLS_SUCCESS,
  payload: response,
});
export const createSkillListing = (params) => ({
  type: types.CREATE_SKILL_LISTING_INIT,
  params,
});
export const updateSkillListing = (params) => ({
  type: types.UPDATE_SKILL_LISTING_INIT,
  params,
});
export const deleteSkillListing = (params) => ({
  type: types.DELETE_SKILL_LISTING,
  params,
});
export const updateSkillListingSuccess = (response) => ({
  type: types.UPDATE_SKILL_LISTING_SUCCESS,
  payload: response,
});
export const saveSearchSkill = (params) => ({
  type: types.UPDATE_SKILL_LISTING_SAVE,
  stage: "step4",
  payload: params,
});
// CredentialingForm
export const formSubmitStepOne = (e) => {
  if (e.target.name === "resume") {
    return {
      type: types.STEP_ONE,
      stage: "step1",
      payload: e.target.files[0],
      key: e.target.name,
    };
  } else {
    return {
      type: types.STEP_ONE,
      stage: "step1",
      payload: e.target.value,
      key: e.target.name,
    };
  }
};
export const formSubmitSkillListingOne = (e) => ({
  type: types.SKILLISTING,
  stage: "step3",
  payload: e.target.value,
  key: e.target.name,
});
export const formSubmitSkillListingTwo = (e) => ({
  type: types.SKILLISTING,
  stage: "step4",
  payload: e.target.value,
  key: e.target.name,
});

export const fetchOneSkillListing = (payload) => ({
  type: types.FETCH_ONE_SKILLLISTING_INIT,
  payload,
});
export const fetchOneSkillListingSuccess = (payload) => ({
  type: types.FETCH_ONE_SKILLLISTING_SUCCESS,
  payload,
});
export const fetchOneSkillListingFailure = (error) => ({
  type: types.FETCH_ONE_SKILLLISTING_FAILURE,
  error,
});

// get location - save location
export const fetchKeySearchLocation = (params) => ({
  type: types.FETCH_SEARCH_LOCATION_INIT,
  payload: params,
});
export const saveSearchLocationStepOne = (params) => ({
  type: types.FETCH_LOCATION_SAVE,
  stage: "step1",
  payload: params,
});
export const saveExperienceStepOne = (params) => ({
  type: types.FETCH_EXPERIENCE_SAVE,
  stage: "step1",
  payload: params,
});
export const fetchLocationFailure = (error) => ({
  type: types.FETCH_SEARCH_LOCATION_FAILURE,
  payload: {},
  error: error,
});
// get location - save location
export const fetchKeySearchCountry = (params) => ({
  type: types.FETCH_SEARCH_COUNTRY_INIT,
  payload: params,
});
export const fetchCountryFailure = (error) => ({
  type: types.FETCH_SEARCH_COUNTRY_FAILURE,
  payload: {},
  error: error,
});
export const fetchKeySearchRegion = (params) => ({
  type: types.FETCH_SEARCH_REGION_INIT,
  payload: params,
});
export const fetchRegionFailure = (error) => ({
  type: types.FETCH_SEARCH_REGION_FAILURE,
  payload: {},
  error: error,
});
export const fetchKeyExperinceLevels = (params) => ({
  type: types.FETCH_EXPERINCE_LEVEL_INIT,
  payload: params,
});
export const fetchExperinceLevelsFailure = (error) => ({
  type: types.FETCH_EXPERINCE_LEVEL_FAILURE,
  payload: {},
  error: error,
});

export const fetchKeySearchCity = (params) => ({
  type: types.FETCH_SEARCH_CITY_INIT,
  payload: params,
});
export const fetchCityFailure = (error) => ({
  type: types.FETCH_SEARCH_CITY_FAILURE,
  payload: {},
  error: error,
});

export const saveSearchLocation = (params) => ({
  type: types.FETCH_SEARCH_LOCATION_SAVE,
  stage: "step4",
  payload: params,
  key: "desiredLocation",
});

export const saveSearchCountry = (params) => ({
  type: types.FETCH_SEARCH_LOCATION_SAVE,
  stage: "step4",
  payload: params,
  key: "desiredCountry",
});
export const fetchRoleDiscipline = (params) => ({
  type: types.FETCH_ROLE_DISCIPLINE_INIT,
  payload: params,
});
export const fetchRoleDisciplineSuccess = (params) => ({
  type: types.FETCH_ROLE_DISCIPLINE_SUCCESS,
  payload: params,
});
// get Discipline - save Discipline
export const fetchKeySearchDiscipline = (params) => ({
  type: types.FETCH_SEARCH_DISCIPLINE_INIT,
  payload: params,
});
export const saveSearchDiscipline = (params) => ({
  type: types.SEARCH_DISCIPLINE_SAVE,
  stage: "step3",
  payload: params,
});

export const saveDisplayTitle = (params) => ({
  type: types.DISPLAY_TITLE_SAVE,
  stage: "step3",
  payload: params,
});

// Fetching User SkillListing
export const setPubfilterSkillListing = (params) => ({
  type: types.SET_PUBFILTER_SKILLISTING,
  payload: params,
});
export const setPageSkillListing = (params) => ({
  type: types.SET_PAGE_SKILLISTING,
  payload: params,
});
export const fetchUserSkillListing = (...params) => ({
  type: types.FETCH_USER_SKILLISTING_INIT,
  payload: params,
});
export const fetchUserSkillListingSuccess = (params) => ({
  type: types.FETCH_USER_SKILLISTING_SUCCESS,
  payload: params,
  error: {},
});
export const fetchUserSkillListingSuccessId = (params) => ({
  type: types.FETCH_USER_SKILLISTING_SUCCESS_ID,
  payload: params,
  error: {},
});
export const gotSkillListingFailure = (error) => ({
  type: types.SKILLLISTING_FAILURE,
  payload: {},
  error: error,
});
// fetch profile experience
export const fetchProfileExperience = () => ({
  type: types.FETCH_PROFILE_EXPERIENCE_INIT,
});
export const fetchProfileExperienceSuccess = (payload) => ({
  type: types.FETCH_PROFILE_EXPERIENCE_SUCCESS,
  payload: payload,
});
//BIDS
//PROPOSALS
export const fetchProposals = (id, limit, skip, status) => {
  return {
    type: types.FETCH_PROPOSALS_REQUESTED,
    payload: { id, limit, skip, status },
  };
};
export const createBid = (
  GigId,
  ProposalId,
  JobOrderId,
  ProfileId,
  Message,
  Created,
  Modified,
  InterviewId,
  BudgetType,
  ProposalStatus,
  Rate,
  IdealTerms,
  Shortlisted,
  AcceptedDate,
  SGFees,
  DeclineReasonId,
  DeclineReason,
  ViewedAt,
  ActualRate,
  Currency
) => {
  return {
    type: types.CREATE_BID_REQUESTED,
    payload: {
      GigId,
      ProposalId,
      JobOrderId,
      ProfileId,
      Message,
      Created,
      Modified,
      InterviewId,
      BudgetType,
      ProposalStatus,
      Rate,
      IdealTerms,
      Shortlisted,
      AcceptedDate,
      SGFees,
      DeclineReasonId,
      DeclineReason,
      ViewedAt,
      ActualRate,
      Currency,
    },
  };
};
export const createBidReset = () => {
  return {
    type: types.CREATE_BID_RESET,
  };
};
export const updateBid = (parms) => {
  // 
  return {
    type: types.UPDATE_BID_REQUESTED,
    payload: parms,
  };
};

export const updateGig = (parms) => {
  // 
  return {
    type: types.UPDATE_GIG_REQUESTED,
    payload: parms,
  };
};

export const updateBidReset = () => {
  return {
    type: types.UPDATE_BID_RESET,
  };
};
export const retractBid = (
  ProposalStatus,
  id,
  SenderLabels,
  RecipientLabels,
  Message
) => {
  return {
    type: types.RETRACT_BID_REQUESTED,
    payload: {
      ProposalStatus,
      id,
      SenderLabels,
      RecipientLabels,
      Message,
    },
  };
};
export const retractBidReset = () => {
  return {
    type: types.RETRACT_BID_RESET,
  };
};
//fetchBidData
export const fetchBidData = (_id) => {
  return {
    type: types.FETCH_BIDDATA_REQUESTED,
    payload: {
      _id,
    },
  };
};
//fetchBidData
export const ResetBidData = (_id) => {
  return {
    type: types.FETCH_BIDDATA_RESET,
  };
};
// MESSAGES
// possible types of messages: 'inbox' / 'sentItems'
export const fetchMessages = (messageType, results, skip) => {
  return {
    type: types.FETCH_MESSAGES_REQUESTED,
    payload: {
      type: messageType,
      results,
      skip,
    },
  };
};

export const addMessageToFavourite = (params) => {
  
  return {
    type: types.ADD_MESSAGE_TO_FAVOURITE_REQUESTED,
    payload: params,
  };
};

export const removeMessageFromFavourite = (params) => {
  
  return {
    type: types.REMOVE_MESSAGE_TO_FAVOURITE_REQUESTED,
    payload: params,
  };
};
export const sendMessage = (
  conversationId,
  name,
  recipientId,
  message,
  ProfilePic,
  fileUploadResponse
) => {
  return {
    type: types.SEND_MESSAGE_REQUESTED,
    payload: {
      conversationId,
      name,
      recipientId,
      message,
      ProfilePic,
      fileUploadResponse,
    },
  };
};
// export const sendMessage_Attachment = () => {
//   return {
//     type: types.SEND_MESSAGE_ATTACHMENT_REQUESTED,
//   };
// };
//CONVERSATION
export const fetchChatHistory = (id) => {
  return {
    type: types.FETCH_CHAT_REQUESTED,
    payload: id,
  };
};

export const fetchChatsHistory = (id, bid) => {
  return {
    type: types.FETCH_CHAT_REQUESTED,
    payload: {
      id,
      bid
    },
  };
};

export const updateUserOnlineStatus = (userId, IsUserOnline) => ({
  type: types.UPDATE_USER_ONLINE_STATUS,
  payload: { userId, IsUserOnline },
});

export const addUserOnlineStatus = (users) => ({
  type: types.ADD_USERS_ONLINE_STATUS,
  payload: users,
});

export const FavAdded = (index) => {
  
  return {
    type: types.FAVOURITE_SUCCESS,
    payload: {
      index,
    },
  };
};

export const FavRemoved = (index) => {
  return {
    type: types.REMOVE_FAV_SUCCESS,
    payload: {
      index,
    },
  };
};

export function receivedMessage(messageObject) {
  // 
  return {
    type: types.MESSAGE_RECEIVED,
    payload: {
      message: messageObject,
    },
  };
}
export function receivedMessages(messageObject, sourceType) {
  // 
  return {
    type: types.MESSAGES_RECEIVED,
    payload: {
      message: messageObject,
      sourceType,
    },
  };
}
export const updateReceivedBids = (
  id,
  BidStatusName,
  Message,
  SenderLabels,
  RecipientLabels
) => {
  return {
    type: types.FETCH_UPDATEBIDS_REQUESTED,
    payload: {
      id,
      BidStatusName,
      Message,
      SenderLabels,
      RecipientLabels,
    },
  };
};
export const updateReceivedBidsTalent = (
  id,
  BidStatusName,
  Message,
  SenderLabels,
  RecipientLabels
) => {
  return {
    type: types.FETCH_UPDATEBIDS_REQUESTED,
    payload: {
      id,
      BidStatusName,
      Message,
      SenderLabels,
      RecipientLabels,
    },
  };
};
// Delete Resume
export const deleteResume = () => ({
  type: types.DELETE_RESUME_INIT,
});
export const deleteResumeSuccess = (params) => ({
  type: types.DELETE_RESUME_SUCCESS,
  payload: params,
});
export const deleteResumeFailure = () => ({
  type: types.DELETE_RESUME_FAILURE,
});
//Get data for the graph
export const getGraph = (type, StartTime, enddate) => {
  return {
    type: types.GET_GRAPH_DATA_REQUESTED,
    payload: {
      type,
      StartTime,
      enddate,
    },
  };
};
export const createTimeSheetCorrection = (
  TimeSheetId,
  Date,
  StartTime,
  EndTime,
  Reason,
  Status
) => {
  // 
  return {
    type: types.CREATE_TIMESHEET_CORRECTION_REQUESTED,
    payload: { TimeSheetId, Date, StartTime, EndTime, Reason, Status },
  };
};
export const createTimesheet = (Status, Date, SpentTime, OverTime) => {
  // 
  return {
    type: types.CREATE_TIMESHEET_DATA_REQUESTED,
    payload: {
      Status,
      Date,
      SpentTime,
      OverTime,
    },
  };
};
export const updateTimesheetItem = (Status) => {
  // 
  return {
    type: types.UPDATE_TIMESHEET_DATA_REQUESTED,
    payload: {
      Status,
    },
  };
};
export const fetchTimeSheetList = (limit, skip, type, start, end) => {
  // // 
  // // 
  return {
    type: types.FETCH_TIMESHEETLIST_REQUESTED,
    payload: { limit, skip, type, start, end },
  };
};
export const userAssignment = () => {
  return {
    type: types.USERASSIGNMENT_REQUESTED,
    payload: {},
  };
};
//Fetching timesheet start timer
export const setTimerTimeSheet = (params) => ({
  type: types.SET_TIMER_TIMESHEET,
  payload: params,
});
export const getExistingListItem = () => {
  return {
    type: types.GET_EXISTING_LIST_ITEM_REQUESTED,
    payload: {},
  };
};
// fetch search suggestions
export const fetchSearchSuggestions = (params) => ({
  type: types.FETCH_SEARCH_SUGGESTIONS_INIT,
  payload: params,
});
export const fetchSearchSuggestionsFailure = (error) => ({
  type: types.FETCH_SEARCH_SUGGESTIONS_FAILURE,
  payload: {},
  error: error,
});
// SEARCH FILTERS]
export const UpdateSearchFilter = (key, payload) => ({
  type: types.SEARCH_FILTER_UPDATE,
  payload,
  key,
});
// DashBoardInbox
export const fetchInboxData = (limit, skip, type, bidType, bidStatus) => {
  // 
  return {
    type: types.FETCH_INBOX_DATA_REQUEST,
    payload: { limit, skip, type, bidType, bidStatus },
  };
};
export const fetchInboxDataSuccess = (payload) => ({
  type: types.FETCH_INBOX_DATA_SUCCESS,
  payload,
});
// Talent Info in Dashboard
export const getTalentInfo = (params) => ({
  type: types.GET_TALENT_INFO_REQUEST,
  payload: params,
});
//Offer action
export const acceptOffer = (params) => ({
  type: types.FETCH_OFFER_REQUESTED,
  payload: params,
});
// Job details on dasboard
export const fetchJobDetailsProfile = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_REQUESTED,
  payload,
});
export const fetchJobDetailsProfileSuccess = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_SUCCESS,
  payload,
});
export const fetchJobDetailsProfileFailure = () => ({
  type: types.JOB_DETAILS_PROFILE_FAILURE,
});
export const jobDetailsSelecter = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_SELECTER,
  payload,
});
// Job details on dasboard
export const fetchJobDetailsProfileCompleted = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_COMPLETED_REQUESTED,
  payload,
});
export const fetchJobDetailsProfileCompletedSuccess = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_COMPLETED_SUCCESS,
  payload,
});
export const fetchJobDetailsProfileCompletedFailure = () => ({
  type: types.JOB_DETAILS_PROFILE_COMPLETED_FAILURE,
});
// Job details on dasboard Onbilling
export const fetchJobDetailsProfileOnbilling = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_ON_BILLING_REQUESTED,
  payload,
});
export const fetchJobDetailsProfileOnbillingSuccess = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_ON_BILLING_SUCCESS,
  payload,
});
export const fetchJobDetailsProfileOnbillingFailure = () => ({
  type: types.JOB_DETAILS_PROFILE_ON_BILLING_FAILURE,
});
// Job details on dasboard canceled
export const fetchJobDetailsProfileCanceled = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_CANCELED_REQUESTED,
  payload,
});
export const fetchJobDetailsProfileCanceledSuccess = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_CANCELED_SUCCESS,
  payload,
});
export const fetchJobDetailsProfileCanceledFailure = () => ({
  type: types.JOB_DETAILS_PROFILE_CANCELED_FAILURE,
});
// Job details on terminated
export const fetchJobDetailsProfileTerminated = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_TERMINATED_REQUESTED,
  payload,
});
export const fetchJobDetailsProfileTerminatedSuccess = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_TERMINATED_SUCCESS,
  payload,
});
export const fetchJobDetailsProfileTerminatedFailure = () => ({
  type: types.JOB_DETAILS_PROFILE_TERMINATED_FAILURE,
});
// Job details on dasboard
export const getTalentInfoSuccess = (response) => ({
  type: types.GET_TALENT_INFO_SUCCESS,
  payload: response,
});
export const getTalentInfoFailure = (error) => ({
  type: types.GET_TALENT_INFO_FAILURE,
  payload: {},
  error: error,
});
export const getAnalyticInfo = (params) => ({
  type: types.GET_ANALYTIC_INFO_REQUEST,
  payload: params,
});
export const getAnalyticInfoSuccess = (response) => ({
  type: types.GET_ANALYTIC_INFO_SUCCESS,
  payload: response,
});
export const getAnalyticInfoFailure = (error) => ({
  type: types.GET_ANALYTIC_INFO_FAILURE,
  payload: {},
  error: error,
});
export const getFavoritesInfo = (payload) => {
  return {
    type: types.GET_FAVORITES_INFO_REQUEST,
    payload
  };
};
export const getFavoritesInfoSuccess = (response) => ({
  type: types.GET_FAVORITES_INFO_SUCCESS,
  payload: response,
});
export const getFavoritesInfoFailure = (error) => ({
  type: types.GET_FAVORITES_INFO_FAILURE,
  payload: {},
  error: error,
});

export const getNewFavoritesInfo = (payload) => {
  return {
    type: types.GET_NEW_FAVORITES_INFO_REQUEST,
    payload,
  };
};
export const getNewFavoritesInfoSuccess = (response) => ({
  type: types.GET_NEW_FAVORITES_INFO_SUCCESS,
  payload: response,
});
export const getNewFavoritesInfoFailure = (error) => ({
  type: types.GET_NEW_FAVORITES_INFO_FAILURE,
  payload: {},
  error: error,
});

// export const favoritefunctiontrigger = (payload) => ({
//   type: types.FAVORITE_FUNCTION_DETAIL,
//   payload,
// });
// Remove gigcard from favorite section
export const removeFavoriteGigCard = (...payload) => {
  // 
  return {
    type: types.REMOVE_FAVORITE_GIG_CARD,
    payload,
  };
};
export const removeFavoriteGigCardSuccess = (payload) => ({
  type: types.REMOVE_FAVORITE_GIG_CARD_SUCCESS,
  payload,
});
export const removeFavoriteGigCardFailure = () => ({
  type: types.REMOVE_FAVORITE_GIG_CARD_FAILURE,
});
export const removeFavoriteGigCardReducer = (payload) => ({
  type: types.REMOVE_FAVORITE_GIG_CARD_REDUCER,
  payload,
});
// Adding gigcard into favorite section
export const postFavoriteGigCard = (...payload) => ({
  type: types.FAVORITE_GIG_CARD,
  payload,
});
export const postFavoriteGigCardSuccess = (payload) => ({
  type: types.FAVORITE_GIG_CARD_SUCCESS,
  payload,
});
export const postFavoriteGigCardFailure = () => ({
  type: types.FAVORITE_GIG_CARD_FAILURE,
});
export const favoriteBidUpdate = (payload) => ({
  type: types.FAVORITE_BID_UPDATE,
  payload,
});
// profile Education History ////////////////////////////////////////////////////
export const fetchCollegeMasterEducationHistory = (payload) => ({
  type: types.FETCH_COLLEGE_MASTER_INIT,
  payload,
});
export const fetchCollegeMasterEducationHistorySuccess = (payload) => ({
  type: types.FETCH_COLLEGE_MASTER_SUCCESS,
  payload,
});
export const fetchCollegeMasterEducationHistoryFailure = (error) => ({
  type: types.FETCH_COLLEGE_MASTER_FAILURE,
  error,
});
export const fetchDegreeMasterEducationHistory = (payload) => ({
  type: types.FETCH_DEGREE_MASTER_INIT,
  payload,
});
export const fetchDegreeMasterEducationHistorySuccess = (payload) => ({
  type: types.FETCH_DEGREE_MASTER_SUCCESS,
  payload,
});
export const fetchDegreeMasterEducationHistoryFailure = (error) => ({
  type: types.FETCH_DEGREE_MASTER_FAILURE,
  error,
});
export const fetchCourseMasterEducationHistory = (payload) => ({
  type: types.FETCH_COURSE_MASTER_INIT,
  payload,
});
export const fetchCourseMasterEducationHistorySuccess = (payload) => ({
  type: types.FETCH_COURSE_MASTER_SUCCESS,
  payload,
});
export const fetchCourseMasterEducationHistoryFailure = (error) => ({
  type: types.FETCH_COURSE_MASTER_FAILURE,
  error,
});
export const postEducationHistory = (payload) => ({
  type: types.POST_EDUCATION_HISTORY_INIT,
  payload,
});
export const postEducationHistorySuccess = (payload) => ({
  type: types.POST_EDUCATION_HISTORY_SUCCESS,
  payload,
});
export const postEducationHistoryFailure = (error) => ({
  type: types.POST_EDUCATION_HISTORY_FAILURE,
  error,
});
export const updateEducationHistory = (...payload) => ({
  type: types.UPDATE_EDUCATION_HISTORY_INIT,
  payload,
});
export const updateEducationHistorySuccess = (payload) => ({
  type: types.UPDATE_EDUCATION_HISTORY_SUCCESS,
  payload,
});
export const updateEducationHistoryFailure = (error) => ({
  type: types.UPDATE_EDUCATION_HISTORY_FAILURE,
  error,
});
///////////////////////////////////////////////////////////////////////////////////
// profile Work History /////////////////////////////////////////////////////////
// export const fetchCompanyWorkHistory = (payload) => ({
//   type: types.GET_COMPANY_WORK_HISTORY_INIT,
//   payload,
// });
// export const fetchCompanyWorkHistorySuccess = (payload) => ({
//   type: types.GET_COMPANY_WORK_HISTORY_SUCCESS,
//   payload,
// });
// export const fetchCompanyWorkHistoryFailure = (payload) => ({
//   type: types.GET_COMPANY_WORK_HISTORY_FAILURE,
//   payload,
// });
export const fetchJobTitleWorkHistory = (payload) => ({
  type: types.GET_JOBTITLE_WORK_HISTORY_INIT,
  payload,
});
export const fetchJobTitleWorkHistorySuccess = (payload) => ({
  type: types.GET_JOBTITLE_WORK_HISTORY_SUCCESS,
  payload,
});
export const fetchJobTitleWorkHistoryFailure = (payload) => ({
  type: types.GET_JOBTITLE_WORK_HISTORY_FAILURE,
  payload,
});
export const fetchIndustryWorkHistory = (payload) => ({
  type: types.GET_INDUSTRY_WORK_HISTORY_INIT,
  payload,
});
export const fetchIndustryWorkHistorySuccess = (payload) => ({
  type: types.GET_INDUSTRY_WORK_HISTORY_SUCCESS,
  payload,
});
export const fetchIndustryWorkHistoryFailure = (payload) => ({
  type: types.GET_INDUSTRY_WORK_HISTORY_FAILURE,
  payload,
});
export const fetchLocationWorkHistory = (payload) => ({
  type: types.GET_LOCATION_WORK_HISTORY_INIT,
  payload,
});
export const fetchLocationWorkHistorySuccess = (payload) => ({
  type: types.GET_LOCATION_WORK_HISTORY_SUCCESS,
  payload,
});
export const fetchLocationWorkHistoryFailure = (payload) => ({
  type: types.GET_LOCATION_WORK_HISTORY_FAILURE,
  payload,
});
export const fetchSkillsWorkHistory = (params) => ({
  type: types.GET_SKILLS_WORK_HISTORY_INIT,
  params,
});
export const fetchSkillsWorkHistorySuccess = (payload) => ({
  type: types.GET_SKILLS_WORK_HISTORY_SUCCESS,
  payload,
});
export const fetchSkillsWorkHistoryFailure = (payload) => ({
  type: types.GET_SKILLS_WORK_HISTORY_FAILURE,
  payload,
});
export const postWorkHistory = (payload) => ({
  type: types.POST_WORK_HISTORY_INIT,
  payload,
});
export const postWorkHistorySuccess = (payload) => ({
  type: types.POST_WORK_HISTORY_SUCCESS,
  payload,
});
export const postWorkHistoryFailure = (error) => ({
  type: types.POST_WORK_HISTORY_FAILURE,
  error,
});
export const updateWorkHistory = (...payload) => ({
  type: types.UPDATE_WORK_HISTORY_INIT,
  payload,
});
export const updateWorkHistorySuccess = (payload) => ({
  type: types.UPDATE_WORK_HISTORY_SUCCESS,
  payload,
});
export const updateWorkHistoryFailure = (error) => ({
  type: types.UPDATE_WORK_HISTORY_FAILURE,
  error,
});
/////////////////////////////////////////////////////////////////////////
//// Certificates and Awards Profile ////////////////////////////////////
export const postCertificatesAndAwards = (payload) => ({
  type: types.POST_CERTIFICATES_AND_AWARDS_INIT,
  payload,
});
export const postCertificatesAndAwardsSuccess = (payload) => ({
  type: types.POST_CERTIFICATES_AND_AWARDS_SUCCESS,
  payload,
});
export const postCertificatesAndAwardsFailure = (error) => ({
  type: types.POST_CERTIFICATES_AND_AWARDS_FAILURE,
  error,
});
export const updateCertificatesAndAwards = (payload, id) => ({
  type: types.UPDATE_CERTIFICATES_AND_AWARDS_INIT,
  payload,
  id,
});
export const updateCertificatesAndAwardsSuccess = (payload) => ({
  type: types.UPDATE_CERTIFICATES_AND_AWARDS_SUCCESS,
  payload,
});
export const updateCertificatesAndAwardsFailure = (error) => ({
  type: types.UPDATE_CERTIFICATES_AND_AWARDS_FAILURE,
  error,
});
/////////////////////////////////////////////////////////////////////////
// export const fetchRetractedBidsStages = (params) => {
//   return {
//     type: types.FETCH_RETRACTEDBIDS_STATUS_REQUESTED,
//     payload: params,
//   };
// };
export const fetchRetractedBids = (
  perPage,
  page,
  sort,
  longitude,
  latitude,
  maxDistance
) => {
  return {
    type: types.FETCH_RETRACTEDBIDS_REQUESTED,
    payload: {
      perPage,
      page,
      sort,
      longitude,
      latitude,
      maxDistance,
    },
  };
};
export const resetStore = () => ({
  type: types.USER_LOGOUT,
});
// retracted Bid Action Start
export const retractedBidFetch = (sort, lat, long, page, perPage) => ({
  type: types.FETCH_RETRACTED_BID_REQUESTED,
  payload: {
    sort,
    lat,
    long,
    page,
    perPage,
  },
});
export const retractedBidFetchSuccess = (payload) => ({
  type: types.FETCH_RETRACTED_BID_SUCCESS,
  payload,
});
export const retractedBidFetchFailure = () => ({
  type: types.FETCH_RETRACTED_BID_FAILURE,
});
export const retractedBidUpdate = (payload) => ({
  type: types.RETRACTED_BID_UPDATE,
  payload,
});
// Place Bid Action Start
export const placeBidFetch = (
  sort,
  lat,
  long,
  page,
  perPage,
  BidStatusName
) => ({
  type: types.FETCH_PLACE_BID_REQUESTED,
  payload: {
    sort,
    lat,
    long,
    page,
    perPage,
    BidStatusName,
  },
});
export const placeBidFetchSuccess = (payload) => ({
  type: types.FETCH_PLACE_BID_SUCCESS,
  payload,
});
export const placeBidFetchFailure = () => ({
  type: types.FETCH_PLACE_BID_FAILURE,
});
export const fetchDetailPlaceBid = (payload) => ({
  type: types.FETCH_DETAIL_PLACE_BID_INIT,
  payload,
});
export const fetchDetailPlaceBidSuccess = (payload) => ({
  type: types.FETCH_DETAIL_PLACE_BID_SUCCESS,
  payload,
});
export const fetchDetailPlaceBidFailure = (error) => ({
  type: types.FETCH_DETAIL_PLACE_BID_FAILURE,
  error,
});
// Place Bid Action End ////////////////////////////////////////////////////////
// Received Bid Action start ////////////////////////////////////////////////////////
export const fetchReceivedBidsStages = (params) => {
  return {
    type: types.FETCH_RECEIVEDBIDS_STATUS_REQUESTED,
    payload: params,
  };
};

export const fetchReceivedBidCount = (params) => {
  return {
    type: types.FETCH_RECEIVEDBID_COUNT_REQUESTED,
    payload: params,
  };
};

export const fetchReceivedBids = (
  BidStatusName,
  SkillListingId,
  sortBy,
  lat,
  long,
  page,
  perPage
) => {
  return {
    type: types.FETCH_RECEIVEDBIDS_REQUESTED,
    payload: {
      BidStatusName,
      SkillListingId,
      sortBy,
      lat,
      long,
      page,
      perPage,
    },
  };
};
export const fetchDetailReceivedBid = (payload) => ({
  type: types.FETCH_DETAIL_RECEIVED_BID_INIT,
  payload,
});
export const fetchDetailReceivedSuccess = (payload) => ({
  type: types.FETCH_DETAIL_RECEIVED_BID_SUCCESS,
  payload,
});
export const fetchDetailReceivedFailure = (error) => ({
  type: types.FETCH_DETAIL_RECEIVED_BID_FAILURE,
  error,
});
export const fetchDeclinedReasonsBids = (payload) => ({
  type: types.FETCH_DECLINED_REASONS_BIDS_INIT,
  payload,
});
export const fetchDeclinedReasonsBidsSuccess = (payload) => ({
  type: types.FETCH_DECLINED_REASONS_BIDS_SUCCESS,
  payload,
});
export const fetchDeclinedReasonsBidsFailure = (error) => ({
  type: types.FETCH_DECLINED_REASONS_BIDS_FAILURE,
  error,
});
export const updateDeclinedReasonsReceivedBids = (payload) => ({
  type: types.UPDATE_DECLINED_REASONS_BIDS_INIT,
  payload,
});
export const updateDeclinedReasonsReceivedBidsSuccess = (payload) => ({
  type: types.UPDATE_DECLINED_REASONS_BIDS_SUCCESS,
  payload,
});
export const updateDeclinedReasonsReceivedBidsFailure = (error) => ({
  type: types.UPDATE_DECLINED_REASONS_BIDS_FAILURE,
  error,
});
// Received Bid Action end ////////////////////////////////////////////////////////
export const getInterviewCard = (params) => ({
  type: types.GET_INTERVIEW_CARD_REQUEST,
  payload: params,
});
export const getInterviewCardSuccess = (response) => ({
  type: types.GET_INTERVIEW_CARD_SUCCESS,
  payload: response,
});
export const getInterviewCardFailure = (error) => ({
  type: types.GET_INTERVIEW_CARD_FAILURE,
  payload: {},
  error: error,
});
/********** Interview Action Start **********/
export const fetchInterview = () => ({
  type: types.FETCH_INTERVIEW_INIT,
});
export const fetchInterviewSuccess = (payload) => ({
  type: types.FETCH_INTERVIEW_SUCCESS,
  payload,
});
export const fetchInterviewFailure = (error) => ({
  type: types.FETCH_INTERVIEW_FAILURE,
  error,
});
/********** Interview Action End **********/
/********** Resume Action start **********/
// GET FOLLOWERS //
export const fetchResumeGetfollowersInit = (page, perPage) => {
  // 
  return {
    type: types.FETCH_RESUME_GET_FOLLOWERS_INIT,
    payload: { page, perPage },
  };
};
export const fetchResumeGetfollowersSuccess = (payload) => ({
  type: types.FETCH_RESUME_GET_FOLLOWERS_SUCCESS,
  payload,
});
export const fetchResumeGetfollowersFailure = (error) => ({
  type: types.FETCH_RESUME_GET_FOLLOWERS_FAILURE,
  error,
});
// GET VIEWS //
export const fetchResumeGetviewInit = (page, perPage) => {
  // 
  return {
    type: types.FETCH_RESUME_GET_VIEWS_INIT,
    payload: { page, perPage },
  };
};
export const fetchResumeGetviewSuccess = (payload) => ({
  type: types.FETCH_RESUME_GET_VIEWS_SUCCESS,
  payload,
});
export const fetchResumeGetviewFailure = (error) => ({
  type: types.FETCH_RESUME_GET_VIEWS_FAILURE,
  error,
});
// RESUME DETAIL //
export const fetchResumeDetailInit = () => ({
  type: types.FETCH_RESUME_DETAIL_INIT,
});
export const fetchResumeDetaiSuccess = (payload) => ({
  type: types.FETCH_RESUME_DETAIL_SUCCESS,
  payload,
});
export const fetchResumeDetaiFailure = (error) => ({
  type: types.FETCH_RESUME_DETAIL_FAILURE,
  error,
});
/********** Resume Action end **********/
/********** showPublicView Action End **********/
export const setShowPublicView = () => ({
  type: types.SHOW_PUBLIC_VIEW,
});
/********** showPublicView Action End **********/
// Home page action
export const fetchHomeDataInit = (
  continueBrowsing_perPage,
  Favorite_perPage,
  Suggested_perPage,
  Editor_perPage,
  Most_PopularMob,
  EditorPicks,
  Suggested,
  ContinueBrowsing,
  Favorites,
  Most_Popular,
  gigsBPage,
  filters={}
) => {
  return {
    type: types.FETCH_HOME_PAGE_DATA_INIT,
    payload: {
      continueBrowsing_perPage,
      Favorite_perPage,
      Suggested_perPage,
      Editor_perPage,
      Most_PopularMob,
      EditorPicks,
      Suggested,
      ContinueBrowsing,
      Favorites,
      Most_Popular,
      gigsBPage,filters
    },
  };
};
export const fetchHomeDataFilterInit = (
 {continueBrowsing_perPage,
  Favorite_perPage,
  Suggested_perPage,
  Editor_perPage,
  Most_PopularMob,
  EditorPicks,
  Suggested,
  ContinueBrowsing,
  Favorites,
  Most_Popular,
  gigsBPage,
  continueBPage,
mostPopularPage,
editorPage,
suggestPage,
  filters={}}
) => {
  return {
    type: types.FETCH_HOME_PAGE_DATA_FILTER_INIT,
    payload: {
      continueBrowsing_perPage,
      Favorite_perPage,
      Suggested_perPage,
      Editor_perPage,
      Most_PopularMob,
      EditorPicks,
      Suggested,
      ContinueBrowsing,
      Favorites,
      Most_Popular,
      gigsBPage,filters,
      continueBPage,
mostPopularPage,
editorPage,
suggestPage,
    },
  };
};
export const fetchHomeDataSuccess = (payload) => ({
  type: types.FETCH_HOME_PAGE_DATA_SUCCESS,
  payload,
});
export const fetchHomeDataFailure = () => ({
  type: types.FETCH_HOME_PAGE_DATA_FAILURE,
});
export const HomepageFavoriteRemove = (payload) => ({
  type: types.HOME_PAGE_FAVORITE_REMOVE,
  payload,
});
export const HomepageFavorite = (payload) => ({
  type: types.HOME_PAGE_FAVORITE,
  payload,
});
export const HomepageFavoriteUpdate = (payload) => ({
  type: types.HOME_PAGE_FAVORITE_UPDATE,
  payload,
});
// Notification Action //
export const UpdateNotificationInit = (payload) => ({
  type: types.UPDATE_NOTIFICATION_INIT,
  payload,
});
export const UpdateNotificationSuccess = (payload) => ({
  type: types.UPDATE_NOTIFICATION_SUCCESS,
  payload,
});
export const UpdateNotificationFailure = (error) => ({
  type: types.UPDATE_NOTIFICATION_FAILURE,
  error,
});
// Interview Accepted//
export const InterviewScheduleAcceptedInit = (payload) => {
  return {
    type: types.INTERVIEW_ACCEPTED_INIT,
    payload,
  };
};
export const InterviewScheduleAcceptedSuccess = (payload) => ({
  type: types.INTERVIEW_ACCEPTED_SUCCESS,
  payload,
});
export const InterviewScheduleAcceptedfail = (error) => ({
  type: types.INTERVIEW_ACCEPTED_FAILURE,
});

export const RetractBidUpdateStageInit = (payload) => {
  // 
  return {
    type: types.RETRACTED_BID_UPDATE_STAGES_INIT,
    payload,
  };
};
export const RetractBidUpdateStageSuccess = (payload) => ({
  type: types.RETRACTED_BID_UPDATE_STAGES_SUCCESS,
  payload,
});
export const RetractBidUpdateStagefail = (error) => ({
  type: types.RETRACTED_BID_UPDATE_STAGES_FAILURE,
});

export const onClearFilterInit = (payload) => {
  // 
  return {
    type: types.CLEAR_FILTER_INIT,
    payload,
  };
};

export const onClearSearchInit = (payload) => {
  // 
  return {
    type: types.CLEAR_SEARCH_INIT,
    payload,
  };
};

export const onClearSearchValueInit = (payload) => {
  // 
  return {
    type: types.CLEAR_SEARCH_VALUE_INIT,
    payload,
  };
};

export const onClearSearchIconInit = (payload) => {
  // 
  return {
    type: types.CLEAR_SEARCH_ICON_INIT,
    payload,
  };
};

export const searchSuggestionInput = (payload) => {
  // 
  return {
    type: types.SEARCH_SUGGESTION_INPUT,
    payload,
  };
};
export const fecthIsLocation = (payload) => {
  return {
    type: types.IS_LOCATION_DISABLE,
    payload,
  };
};

export const CreateRatingInit = (payload) => {
  // 
  return {
    type: types.CREATE_RATING_INIT,
    payload,
  };
};
export const CreateRatingSuccess = (payload) => ({
  type: types.CREATE_RATING_SUCCESS,
  payload,
});
export const CreateRatingfailure = (error) => ({
  type: types.CREATE_RATING_FAILURE,
});

export const UpdateRatingInit = (payload) => ({
  type: types.UPDATE_RATING_INIT,
  payload,
});
export const UpdateRatingSuccess = (payload) => ({
  type: types.UPDATE_RATING_SUCCESS,
  payload,
});
export const UpdateRatingfailure = (error) => ({
  type: types.UPDATE_RATING_FAILURE,
});

export const deleteRatingreviews = (payload) => ({
  type: types.DELETE_RATING_INIT,
  payload,
});
export const DeleteRatingSuccess = (payload) => ({
  type: types.DELETE_RATING_SUCCESS,
  payload,
});
export const DeleteRatingfailure = (error) => ({
  type: types.DELETE_RATING_FAILURE,
});

export const downloadResumeInit = (payload) => ({
  type: types.DOWNLOAD_RESUME_INIT,
  payload,
});
export const downloadResumeLinkSuccessful = (payload) => ({
  type: types.DOWNLOAD_RESUME_LINK_SUCCESSFUL,
  payload,
});

// export const updateImpersonationIdInit = (payload) => ({
//   type: types.UPDATE_IMPERSONATION_ID_INIT,
//   payload,
// });
// export const updateImpersonationIdSuccess = (payload) => ({
//   type: types.UPDATE_IMPERSONATION_ID_SUCCESS,
//   payload,
// });
// export const updateImpersonationIdFailure = (error) => ({
//   type: types.UPDATE_IMPERSONATION_ID_FAILURE,
// });

export const updateImpersonationIdId = (payload) => ({
  type: types.UPDATE_IMPERSONATION_ID,
  payload,
});

//conversations*******************>>>
export const fetchInboxMessages = (
  conversationType,
  bidType,
  bidStatus,
  page,
  perPage,
  filter,
  fetchMore,
  search
) => {
  return {
    type: types.GET_MESSAGES_INBOX_REQUESTED,
    payload: {
      conversationType,
      bidType,
      bidStatus,
      page,
      perPage,
      filter,
      fetchMore,
      search,
    },
  };
};

//Dasboard Message Inbox*******************>>>
export const fetchDashboardInboxMessages = (
  conversationType,
  bidType,
  bidStatus,
  page,
  perPage,
  filter,
  fetchMore
) => {
  return {
    type: types.DASHBOARD_MESSAGES_INBOX_REQUESTED,
    payload: {
      conversationType,
      bidType,
      bidStatus,
      page,
      perPage,
      filter,
      fetchMore,
    },
  };
};

export const fetchInboxMessagesHeader = (
  conversationType,
  bidType,
  bidStatus,
  page,
  perPage,
  filter,
  fetchMore
) => {
  return {
    type: types.GET_MESSAGES_INBOX_REQUESTED_HEADER,
    payload: {
      conversationType,
      bidType,
      bidStatus,
      page,
      perPage,
      filter,
      fetchMore,
    },
  };
};

export const fetchMessagesCounts = (params) => {
  return {
    type: types.GET_MESSAGES_COUNTS_INIT,
    payload: params,
  };
};

export const messagesJobDetails = (params) => {
  return {
    type: types.GET_INBOX_JOB_DETAILS,
    payload: params,
  };
};

export const sendMessage_Attachment = (response) => {
  // 
  return {
    type: types.SEND_MESSAGE_ATTACHMENT_REQUESTED,
    payload: response,
  };
};

export const rescheduleFromChat = (params) => {
  return {
    type: types.SEND_RESCHEDULE_INTERVIEW,
    payload: params,
  };
};
export const getNotifications = (params) => {
  return {
    type: types.GET_NOTIFICATION_REQUESTED,
    payload: params,
  };
};
export const getDashboardBidsCounts = (params) => {
  return {
    type: types.DASHBOARD_BIDS_COUNTS,
  };
};
export const getDashboardBidsCountsSuccess = (params) => {
  return {
    type: types.DASHBOARD_BIDS_COUNTS_SUCEESS,
    payload: params
  };
};
export const getDashboardBidsCountsFailure = (params) => {
  return {
    type: types.DASHBOARD_BIDS_COUNTS_FAILURE,
    payload: params
  };
};

export const readNotification = (id) => {
  return {
    type: types.READ_NOTIFICATION,
    payload: {
      id,
    },
  };
};

export const getNotificationsSuccess = (params) => {
  return {
    type: types.GET_NOTIFICATION_SUCCESS,
    payload: params,
  };
};

export const talentStagesFilterRetain = (payload) => ({
  type: types.TALENT_STAGES_FILTER_RETAIN,
  payload,
});

export const checkUsernameUnique = (UserName) => ({
  type: types.CHECK_USERNAME_UNIQUE,
  payload: { UserName },
});

export const checkUsernameUniqueSuccess = (valid) => ({
  type: types.CHECK_USERNAME_UNIQUE_SUCCESS,
  payload: { valid },
});

export const checkUsernameUniqueFail = (error) => ({
  type: types.CHECK_USERNAME_UNIQUE_FAIL,
  payload: { error },
});

// EMAIL VERIFICATION

export const sendEmailVerification = (params) => {
  return {
    type: types.SEND_EMAIL_VERIFICATION_INIT,
    payload: params,
  };
};
export const sendEmailVerificationSuccess = (params) => {
  return {
    type: types.SEND_EMAIL_VERIFICATION_SUCCESS,
    payload: params,
  };
};

export const sendEmailVerificationFailure = (payload) => ({
  type: types.SEND_EMAIL_VERIFICATION_FAILURE,
  payload,
});

/********** ViewListing Action Start **********/
export const fetchViewListing = (params) => ({
  type: types.FETCH_VIEWLISTING_INIT,
  payload: params,
});
export const fetchViewListingSuccess = (payload) => ({
  type: types.FETCH_VIEWLISTING_SUCCESS,
  payload,
});
export const fetchViewListingFailure = (error) => ({
  type: types.FETCH_VIEWLISTING_FAILURE,
  error,
});
/********** CompanyProfile Action End **********/

/********** CompanyProfile Action Start **********/
export const fetchCompanyProfile = (params) => ({
  type: types.FETCH_COMPANYPROFILE_INIT,
  payload: params,
});
export const fetchCompanyProfileSuccess = (payload) => ({
  type: types.FETCH_COMPANYPROFILE_SUCCESS,
  payload,
});
export const fetchCompanyProfileFailure = (error) => ({
  type: types.FETCH_COMPANYPROFILE_FAILURE,
  error,
});
export const fetchCompanyProfileGigs = (params) => ({
  type: types.FETCH_COMPANYPROFILE_GIGS_INIT,
  payload: params,
});
/********** CompanyProfile Action End **********/

export const fetchmanageBidSuccess = (payload) => {
  // 
  return {
    type: types.MANAGE_BID_COUNT_FETCH,
    payload,
  };
};

export const addRemoveEmployerFollowInt = (payload) => {
  return {
    type: types.ADD_REMOVE_EMPLOYER_FOLLOW_INIT,
    payload,
  };
};

export const addRemoveEmployerFollowSuccess = (payload) => {
  return {
    type: types.ADD_REMOVE_EMPLOYER_FOLLOW_SUCCESS,
    payload,
  };
};

export const addRemoveEmployerFollowFailure = (payload) => {
  return {
    type: types.ADD_REMOVE_EMPLOYER_FOLLOW_SUCCESS,
    payload,
  };
};

// catch error
export const catchError = (error) => {
  return {
    type: types.CATCH_ERROR,
    error,
  };
};
export const catchErrorFix = (error) => {
  return {
    type: types.CATCH_ERROR_FIX,
    error,
  };
};

export const fetchResumeView = (params) => {
  return {
    type: types.VIEW_RESUME_PAGES_INIT,
    payload: params,
  }
};
export const fetchResumeViewSuccess = (payload) => ({
  type: types.VIEW_RESUME_SUCCESS,
  payload,
});
export const fetchResumeViewFailure = (error) => ({
  type: types.VIEW_RESUME_FAILURE,
  error,
});

export const fetchAdminInit = (params) => {
  return{
    type: types.ADMIN_MESSAGING_DASHBOARD_INIT,
    payload: params,
  }
}
export const fetchAdminSuccess = (payload) => ({
  type: types.ADMIN_MESSAGING_DASHBOARD_SUCCESS,
  payload,
})
export const fetchAdminFailure = (error) => ({
  type: types.ADMIN_MESSAGING_DASHBOARD_FAILURE,
  error,
})

export const fetchPvtCompanyInit = (params) => {
  return {
    type: types.PRIVATE_COMPANY_LIST_INIT,
    payload: params
  }
}

export const fetchPvtCompanySuccess = (params) => {
  return {
    type: types.PRIVATE_COMPANY_LIST_SUCCESS,
    payload: params
  }
}

export const fetchPvtCompanyFailure = (error) => {
  return {
    type: types.PRIVATE_COMPANY_LIST_FAILURE,
    payload: error
  }
}
export const setLandingPageDetails = (payload) => ({
  type: types.SET_EMPLOYER_DETAILS,
  payload,
});

export const fetchMobileOTPInit = (params) => {
  return {
    type: types.MOBILE_OTP_SEND_INIT,
    payload: params,
  }
}
export const fetchMobileOTPSuccess = (payload) => ({
  type: types.MOBILE_OTP_SEND_SUCCESS,
  payload,
})
export const fetchMobileOTPFailure = (error) => ({
  type: types.MOBILE_OTP_SEND_FAILURE,
  error,
})

export const resetOtpStatus = (params) => {
  return {
    type: types.RESET_OTP_STATUS,
    payload: params,
  }
}

export const fetchVerifictionOTPInit = (params) => {
  return {
    type: types.MOBILE_OTP_VERIFICATION_INIT,
    payload: params,
  }
}
export const fetchVerifictionOTPSuccess = (payload) => {
  return ({
    type: types.MOBILE_OTP_VERIFICATION_SUCCESS,
    payload,
  })
}
export const fetchVerifictionOTPFailure = (error) => {
  return ({
    type: types.MOBILE_OTP_VERIFICATION_FAILURE,
    payload: error,
  })
}

export const userLanded = (payload) => {
  return ({
    type: types.USER_LANDED,
    payload,
  })
}
export const userClicked = (payload) => {
  return ({
    type: types.USER_CLICK,
    payload,
  })
}