import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import './TalentEmployer.css';

import Logo from '../../assets/icons/LogoBlack.svg';
import { updateProfile, updateSignupProfile } from '../../redux/actions';
import Loader from '../../components/Loader/Loader';
import youngDocOneMob from '../../assets/img/Healthcare/YoungDocOneMob.jpg';
import youngDocTwoMob from '../../assets/img/Healthcare/YoungDocTwoMob.jpg';
const TalentEmployer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  
  const user = useSelector((state) => state.profileReducer.user);
  const accountType = user.profile.AccountTypeCode;
  const [TalentEmployerValue, setTalentEmployerValue] = useState(accountType);
  const { loading } = useSelector((state) => state.profileReducer);

  const talentEmpUpdate = (code, role) => {
    setTalentEmployerValue(code);
    const profile = {
      AccountTypeCode: code,
      Role: role,
      WizardFlow: 1,
    };
    dispatch(
      updateProfile({
        userId: user.id,
        AccountTypeCode: code,
        Role: role,
        WizardFlow: 1,
        LastLoginDate: new Date(),
        LastSuccessfulLoginDate: new Date(),
      })
    );
    dispatch(
      updateSignupProfile({
        userId: user.id,
        AccountTypeCode: code,
        Role: role,
        WizardFlow: 1,
        LastLoginDate: new Date(),
        LastSuccessfulLoginDate: new Date(),
      })
    );
  };

  useEffect(() => {
    if (TalentEmployerValue === 'TAL' && accountType === 'TAL') {
      history.push('/profession');
    } else if (TalentEmployerValue === 'EMP' && accountType === 'EMP') {
      window.location.replace('/employer');
    }
  }, [accountType, TalentEmployerValue]);

  const removeUTM = () => {
    localStorage.removeItem("utm_source");
    localStorage.removeItem("utm_campaign");
    localStorage.removeItem("utm_medium");
    localStorage.removeItem("utm_term");
  };

  // utm code
  useEffect(() => {
    const utm_source = localStorage.getItem("utm_source");
    const utm_campaign = localStorage.getItem("utm_campaign");
    const utm_medium = localStorage.getItem("utm_medium");
    const utm_term = localStorage.getItem("utm_term");
    if (utm_source || utm_campaign || utm_medium || utm_term)
      dispatch(
        updateProfile({
          MCSource: utm_source,
          MCCampaign: utm_campaign,
          MCMedium: utm_medium,
          MCTerm: utm_term,
        })
      );
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {loading || TalentEmployerValue === 'EMP' ? (
        <Loader scrolling />
      ) : (
        <div className="TalentEmployerPage">
          <div className="TalentEmployer">
            <div className="sectionTL">
              <div className="headerTL">
                <div className="subHeaderTL">
                  <span>
                    <img src={Logo} alt="skillgigs_black_logo" className="logo-class" />
                  </span>
                  <div className="subHeaderTLBold">What's your role?</div>
                  <div className="subHeaderTLBold-heading">
                    To start creating your profile, tell us your role end in a period.
                  </div>
                </div>
              </div>
              <div className="chooseTalentorNot">
                <div>
                  <div
                    className="becomeACandidateBOX becomeACandidateBOX-H"
                    onClick={(e) => {
                      e.stopPropagation();
                      talentEmpUpdate('TAL', ['CONS']);
                    }}
                  >
                    <img
                      src={youngDocOneMob}
                      alt="skillgigs_black_logo"
                      className="imgYoungDocsTwo"
                      style={{width: "100%" }}
                    />
                    <div className="card-description">
                      <span className="title"> Candidate </span>
                      <span className="sub_title"> Find your next dream gig </span>
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    className="findACandidateBOX becomeACandidateBOX-H"
                    onClick={(e) => {
                      e.stopPropagation();
                      talentEmpUpdate('EMP', ['CLIE']);
                    }}
                  >
                    <img
                      src={youngDocTwoMob}
                      alt="skillgigs_black_logo"
                      className="imgYoungDocsTwo"
                      style={{width: "100%" }}
                    />
                    <div className="card-description">
                      <span className="title"> Employer </span>
                      <span className="sub_title"> Find the best candidates </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TalentEmployer;