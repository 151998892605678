import { Divider } from "antd";
import React, { useEffect } from "react";
import "./AboutJobExtraSection.css";

const AboutJobExtraSection = (props) => {
  const { WorkplacePolicy, WorkAuthorization } = props?.gig;
  const ProfessionCode = props?.gig?.Professions?.ProfessionCode;
  return (
    <>
      <Divider />
      <div className="AboutJobExtraSection-Cantainer">
        <div className="AboutJobExtraSection-title">
          <div className="AboutJobExtraSection-SubTitle">
            Work authorization
          </div>
          <div className="AboutJobExtraSection-value">
            {WorkAuthorization ? WorkAuthorization?.join(", ") : "Not disclosed"}
          </div>
        </div>
        {ProfessionCode === "IT" && (
          <div className="AboutJobExtraSection-title">
            <div className="AboutJobExtraSection-SubTitle">
              Workplace policy
            </div>
            <div className="AboutJobExtraSection-value">
              {WorkplacePolicy ? WorkplacePolicy : "Not disclosed"}
            </div>
          </div>
        )}
        {/* tax term */}
        {
          ProfessionCode === "IT" && props?.gig?.IdealTerms !== null? 
          <div style={{display:"flex", justifyContent:"space-between", paddingTop:"10px"}}>
                      <div style={{fontSize:"14px", fontWeight:"400", color:"#3E3E3E"}}>Tax Term</div>
                      <div>
                        {
                           props?.gig?.IdealTerms?.map((val,id)=>{
                            return(
                              <span
                              style={{fontSize:"14px", fontWeight:"400", color:"#1E1E1E"}}>
                              {val?.IdealTermCode}
                              {id !== props?.gig?.IdealTerms?.length - 1 && ', '}
                             </span>
                            )
                          })
                        }
                      </div>
                    </div>
                    : ""
        }
        {/* <div className='AboutJobExtraSection-title'>
                    <div className='AboutJobExtraSection-SubTitle'>Certifications</div>
                    <div className='AboutJobExtraSection-value'>MSFT PowerBI</div>
                </div> */}
      </div>
    </>
  );
};

export default AboutJobExtraSection;
