import React, { Fragment, useState } from "react";
import {
  DownOutlined,
  InstagramOutlined,
  TwitterOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Col, Divider, Row } from "antd";
import { BsFacebook, BsLinkedin, BsSpotify, BsYoutube } from "react-icons/bs";
import FooterLogo from "../assets/img/FooterLogo.png";
import { useHistory } from "react-router-dom";
import Logo2 from "../assets/icons/Logo2.svg";

import "./Footer.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
export const BaseUrl = "https://skillgigs.com/";

const Footer = () => {
  const history = useHistory();
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [ListOfAbout, SetListOfAbout] = useState(false);
  const [ListOfSupport, SetListOfSupport] = useState(false);
  const [ListOfCommunity, SetListOfCommunity] = useState(false);
  const [ListOfMoreFromUs, SetListOfMoreFromUs] = useState(false);

  const About = (
    <>
      <span
        className="footer-first-span"
        onClick={() => window.location.replace(`${BaseUrl}/about-us`)}
      >
        About SG
      </span>
      <span onClick={() => window.location.replace(`${BaseUrl}/how-it-works/`)}>
        How it works
      </span>
      {/* <span
        onClick={() => {
          window.location.replace("/employers/");
        }}
      >
        Employers
      </span> */}
      <span
        onClick={() => {
          window.location.replace(`${BaseUrl}/careers/`);
        }}
      >
        Corp careers
      </span>
      {/* <span
        onClick={() => {
          history.push("/dummy");
        }}
      >
        Investor relations
      </span> */}
      <span
        onClick={() => {
          window.location.replace(`${BaseUrl}/category/`);
        }}
        href="/category"
        target="_blank"
        className="footer-bottom-span"
      >
        {/* <a > */}
        {/*relative URL */}
        Sitemap
        {/* </a> */}
      </span>
      <span
        onClick={() => {
          window.location.replace(`${BaseUrl}/contact-us/`);
        }}
        className="footer-bottom-span"
      >
        Contact Us
      </span>
    </>
  );

  const Support = (
    <>
      <span
        onClick={() => {
          window.location.replace(`${BaseUrl}/terms-of-service`);
        }}
        className="footer-first-span"
      >
        Terms of service
      </span>
      <span
        onClick={() => {
          window.location.replace(`${BaseUrl}/privacy`);
        }}
      >
        Privacy policy
      </span>
      {/* <span
        onClick={() => {
          window.location.replace("/security/");
        }}
      >
        Security
      </span> */}
      <span
        onClick={() => {
          window.location.replace(`${BaseUrl}/help/`);
        }}
      >
        Help and support
      </span>
      {/* <span
        onClick={() => {
          window.location.replace("/talent/contact-us/");
        }}
        className="footer-bottom-span"
      >
        Contact us
      </span> */}
      {/* <span
        onClick={() => {
          window.location.replace("/signup/");
        }}
      >
        Sign Up
      </span> */}
    </>
  );

  const Community = (
    <>
      {/* <span
        onClick={() => {
          history.push("/dummy");
        }}
        className="footer-first-span"
      >
        Our expectations
      </span> */}
      {/* <span
        onClick={() => {
          history.push("/dummy");
        }}
      >
        Career advice
      </span>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
      >
        Business insights
      </span> */}
      <span
        className="footer-first-span"
        onClick={() => {
          window.location.replace(`${BaseUrl}/blog/`);
        }}
      >
        Blog
      </span>
      <span
        onClick={() => {
          window.location.replace(`${BaseUrl}/employers/`);
        }}
      >
        Employers
      </span>
      <span
        onClick={() => {
          window.location.replace(`${BaseUrl}/guides/`);
        }}
      >
        Guides
      </span>
      <span
        onClick={() => {
          window.location.replace(`${BaseUrl}/gig-search/`);
        }}
      >
        Gig search
      </span>
      {/* <span
        onClick={() => {
          history.push("/dummy");
        }}
        className="footer-bottom-span"
      >
        Invite a friend
      </span> */}
    </>
  );

  const MoreFromUs = (
    <>
      <span
        onClick={() => {
          window.location.replace("https://swipefox.com/");
        }}
        className="footer-first-span"
      >
        Swipefox
      </span>
      <span
        onClick={() => {
          window.location.replace(`${BaseUrl}/products/`);
        }}
      >
        Our products
      </span>
      <span
        onClick={() => {
          window.location.replace(`${BaseUrl}/employers/`);
        }}
        className="footer-bottom-span"
      >
        Employer demo
      </span>
    </>
  );
  const initialPath = useLocation().pathname.split("/")[1];

  const isEmployerLandingPage = () => initialPath === "employer";
  if (isEmployerLandingPage())
    return (
      <Fragment>
        <Divider />
        <div
          className="Footer-logo-Skillgig"
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "20px",
          }}
        >
          <img src={Logo2} />
          <span className="skillogo">
            © SkillGigs {new Date().getFullYear()}
          </span>
        </div>
      </Fragment>
    );
  if (["joinus",'signin','login','signup',"forgot"].includes(initialPath) ) {
    return <></>;
  }
  return (
    <>
      <Divider className="footer-divider-top" />
      <div className="footer-container">
        {dimensions < 768 ? (
          <>
            <div className="footer-section">
              <div className="footer-About-section">
                <div
                  onClick={() => SetListOfAbout(!ListOfAbout)}
                  className="footer-heading"
                >
                  <span>About</span>
                  <span>
                    {!ListOfAbout ? <DownOutlined /> : <UpOutlined />}
                  </span>
                </div>
                {ListOfAbout === true ? <>{About}</> : null}
              </div>
              <div className="footer-Support-section">
                <div
                  onClick={() => SetListOfSupport(!ListOfSupport)}
                  className="footer-heading"
                >
                  <span>Support</span>
                  <span>
                    {!ListOfSupport ? <DownOutlined /> : <UpOutlined />}
                  </span>
                </div>
                {ListOfSupport === true ? <>{Support}</> : null}
              </div>
              <div className="footer-Community-section">
                <div
                  onClick={() => SetListOfCommunity(!ListOfCommunity)}
                  className="footer-heading"
                >
                  <span>Community</span>
                  <span>
                    {!ListOfCommunity ? <DownOutlined /> : <UpOutlined />}
                  </span>
                </div>
                {ListOfCommunity === true ? <>{Community}</> : null}
              </div>
              <div className="footer-Community-section">
                <div
                  onClick={() => SetListOfMoreFromUs(!ListOfMoreFromUs)}
                  className="footer-heading"
                >
                  <span>More from us</span>
                  <span>
                    {!ListOfMoreFromUs ? <DownOutlined /> : <UpOutlined />}
                  </span>
                </div>
                {ListOfMoreFromUs === true ? <>{MoreFromUs}</> : null}
              </div>
            </div>
          </>
        ) : (
          <div className="footer-section">
            <div className="footer-About-section">
              <div className="footer-heading">About</div>
              {About}
            </div>
            <div className="footer-Support-section">
              <div className="footer-heading">Support</div>
              {Support}
            </div>
            <div className="footer-Community-section">
              <div className="footer-heading">Community</div>
              {Community}
            </div>
            <div className="footer-Community-section">
              <div className="footer-heading">More from us</div>
              {MoreFromUs}
            </div>
          </div>
        )}
        <Divider />
        <div className="footer-logo-section">
          <div className="Footer-logo-Skillgig">
            <img src={Logo2} />
            <span className="skillogo">
              © SkillGigs {new Date().getFullYear()}
            </span>
          </div>
          <div className="footer-social-link">
            <a href="https://www.facebook.com/skillgigsonline/" target="_blank">
              <span>
                <BsFacebook className="footer-icon-modifiction" />
              </span>
            </a>
            <a href="https://www.instagram.com/skillgigs" target="_blank">
              <span>
                <InstagramOutlined className="footer-icon-modifiction footer-22" />
              </span>
            </a>
            <a
              href="https://www.linkedin.com/company/skillgigs"
              target="_blank"
            >
              <span>
                <BsLinkedin className="footer-icon-modifiction" />
              </span>
            </a>
            <a
              href="https://twitter.com/skillgigsonline?lang=en"
              target="_blank"
            >
              <span>
                <TwitterOutlined className="footer-icon-modifiction  footer-22" />
              </span>
            </a>
            <a
              href="https://open.spotify.com/show/0Hv4UfacP60hX8JM7a69k0"
              target="_blank"
            >
              <span>
                <BsSpotify className="footer-icon-modifiction" />
              </span>
            </a>
            <a
              href="https://www.youtube.com/channel/UC3e1p8j1onjj7sDH2Z_1JAA"
              target="_blank"
            >
              <span>
                <BsYoutube className="footer-icon-modifiction" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
