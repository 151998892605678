import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ProfileCompletion from "./Components/ProfileCompletion/ProfileCompletion";
import "./SignUp.css";
import BottomButtons from "./Components/BottomBottons/BottomButtons";
import RadioInput from "./Components/RadioInput/RadioInput";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { updateSignupProfile } from "../../redux/actions";
import { Alert } from "antd";
const exp = ["Yes", "No"];

function VeteranScreen() {
  const windowHeight = window.innerHeight;
  const history = useHistory();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(null);
  const [flag, setFlag] = useState(false);
  const profiledata = useSelector((state) => state.profileReducer.user);
  const loading = useSelector((state) => state.profileReducer.signUpLoading);
  const apiError = useSelector((state) => state.profileReducer.error);
  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );
  useEffect(() => {
    if (apiSuccess && flag) {
      setFlag(false);
      // history.push("/speciality");
    }
  }, [apiSuccess]);
  useEffect(() => {
    if (profiledata?.profile?.veteran != null) {
      setSelected(profiledata?.profile?.veteran ? "Yes" : "No");
    }
  }, [profiledata]);
  const nextHandler = () => {
    if (!selected) {
      setError("Please select the option to continue");
    } else {
      setFlag(true);
      setError(null);
      dispatch(
        updateSignupProfile({
          "Profiles.veteran": selected === "Yes",
          WizardFlow: 200,
        })
      );
      history.push("/resume-upload-v2");
    }
  };
  return (
    <div className="NameScreenContainer" 
    // style={{ minHeight: windowHeight }}
    >
      {typeof apiError === "string" && (
        <Alert
          message={apiError}
          type="error"
          className="alert-error"
          showIcon
          closable
        />
      )}
      <ProfileCompletion currenPage={10} totalPage={10} />
      <RadioInput
        title={"Are you a Veteran?"}
        type={"Mandatory"}
        onChange={(e) => {
          setSelected(e.target.value);
          error && setError(null);
        }}
        options={exp}
        keyVal={null}
        value={selected}
        error={error}
      />
      <BottomButtons
        nextHandler={nextHandler}
        nextBtnLoading={loading}
        backHandler={() => history.push("/license")}
      />
    </div>
  );
}

export default VeteranScreen;
