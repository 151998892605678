import React from "react";
import "./SkillListingTips.css";

const TipToGettingStart = (props) => {
  const { pageType, ProfessionCode } = props;
  // let gigTypePoints = [
  //   "Employers will place bids on your Skill Listings to be thorough",
  //   "You can create more than one Skill Listing",
  //   "Give the title of the job you want",
  //   "When it comes to salary requirements, be fair but know your worth and ask for it",
  // ];
  // let skillListingTitleHCPoints = [
  //   "The system provides discipline- and specialty-based suggestions.",
  //   "The system also enables the input of a customized title.",
  //   "You may modify your discipline and specialty preferences at any time through your profile settings.",
  // ];
  // let skillListingTitleITPoints = [
  //   "Employers will place bid on your skill listing to be thorough",
  //   "You can create more than one skill listing",
  //   "Give the the title of the gig you want",
  //   "When it comes to salary requirements, be fair but know your worth and ask for it.",
  // ];
 

  return (
    <>
      <div className="ant-tooltip-inner">
        <div>
          <div className="gigType-getstart-crafttitle">
          Tips : Getting Started
          </div>
          <div ><p style={{ fontWeight: '80'}}>Selecting your gig type - Contract/ Travel, Permanent or Per diem.</p></div>
          <div>
            <ul style={{ marginLeft: '14px', fontWeight:'400' }}>
               <li>Opens up tailored opportunities</li>
               <li>This way, you can bid on gigs that perfectly match your skills and preferences</li>             
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TipToGettingStart;
