import React, { Fragment, useEffect, useState } from "react";
import { Button, Dropdown, Menu, Radio, Space, Tag, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./AllGigsFilter.css";
import downArrow from "../../assets/icons/downArrow.png";
import SkillDropDown from "./SkillDropDown/SkillDropDown";
import PrivateTag from "../../assets/icons/PrivateTag.svg";
// import SearchDropDown from "../LocationFilter/SearchDropDown";
// import {
//   fetchMyGigsKeySearchSkills,
//   GetFilters,
//   getJobCounts,
//   GetMyGigFilters,
//   keySearchMyGigsLocation,
// } from "../../../../redux/actions";
import MobileViewFilter, {
  SORT_OPTIONS,
} from "./MobileViewFilter/MobileViewFilter";
import debounce from "lodash/debounce";
import {
  fetchKeyExperinceLevels,
  fetchKeySearchCity,
  fetchKeySearchCountry,
  fetchKeySearchRegion,
  fetchPvtCompanyInit,
  fetchSearchSkill,
} from "../../redux/actions";
import ExperienceFilter from "./ExperienceFilter/ExperienceFilter";
import SearchDropDown from "./SearchDropDown/SearchDropDown";
export const DEFAULT_FILTER_VALUES = {
  location: [],
  CityId: [],
  RegionId: [],
  CountryId: [],
  city: null,
  region: [],
  state: [],
  all: "",
  skillListing_type: null,
  budgetType: null,
  ExperienceLevel: [],
  budgetMin: null,
  budgetMax: null,
  NearBy: null,
  hidePrevious: null,
  ProfessionCode: null,
  isPrivateGig: false,
  visa: [],
  visaName: [],
  company: null,
  industry: null,
  skill: [],
  SkillsId: [],
};
const AllGigsFilter = ({
  handleApplyFilter,
  searchText,
  windowDimensions,
  searchFilters,
  setSearchFilters,
  webPadding = "0px 0px 0px 15px",
  tagPadding = "0px 0px 0px 15px",
  mobileButtonsPadding = undefined,
  defaultParams = {},
  padding,
}) => {
  const [GigType, setGigType] = useState(false);
  const [gigStatus, setGigStatus] = useState(false);
  const [sortType, setSortType] = useState("MOST_RECENT");
  const dispatch = useDispatch();
  // const isPrivateTalent =false
  const isPrivateTalent = useSelector(
    (state) => state.profileReducer?.talentApiData?.talentPrivate || false
  );
  // const Count = useSelector((state) => state.manageJobsReducer.counts);
  // const filters = useSelector(
  //   (state) => state.searchSkillListingReducer.filters
  // );
  const [CloseGigTypeModal, setCloseGigTypeModal] = useState(false);
  const [closeGigStatusModal, setCloseGigStatusModal] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedEmployers, setSelectedEmployers] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [selectedGigType, setSelectedGigType] = useState(null);

  const [filterTags, setFilterTags] = useState([]);

  const jobTypeClass = {
    width: "240px",
    padding: "5px 20px 20px 20px",
    borderRadius: "4px",
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.25)",
    backgroundColor: "#fff",
  };

  const AllGigMenu = (
    <Menu className={`skill-type-menu skill-type-menu2`}>
      <div style={{ width: "100%", marginBottom: "20px" }}>
        <Radio.Group
          value={GigType}
          onChange={(e) => {
            handleGigType(e.target.value);
            setCloseGigTypeModal(false);
          }}
          style={{ width: "inherit" }}
          className="space radio-color "
        >
          <Space
            style={{ width: "inherit", rowGap: "16px",   padding:"10px 10px 5px 16px" }}
            direction="vertical"
          >
            <Radio style={{ width: "inherit" }} value={false}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "inherit",
                }}
              >
                <div>
                  All Gigs{" "}
                  <span style={{ marginLeft: "7.375rem" }}>
                    {/* (
                    {Count?.totalCount
                      ? Count?.totalCount?.[0]?.allGigs
                        ? Count?.totalCount?.[0]?.allGigs
                        : 0
                      : 0}
                    ) */}
                  </span>
                </div>
              </div>
            </Radio>
            <Radio style={{ width: "inherit" }} value={true}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "inherit",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "95%",
                  }}
                >
                  <span style={{width:"250px"}}>
                    Private Gigs <img src={PrivateTag} alt="private" />
                  </span>
                  <span>
                    {/* (
                    {Count?.totalCount
                      ? Count?.totalCount[0]?.isPrivate
                        ? Count?.totalCount[0]?.isPrivate
                        : 0
                      : 0}
                    ) */}
                  </span>
                </div>{" "}
              </div>
            </Radio>
          </Space>
        </Radio.Group>
      </div>
    </Menu>
  );

  const clearFilter = () => {
    applyFilters({});
    updateFilters({});
    setGigType(false);
    setSelectedGigType(null);
    setSelectedCities([]);
    setSelectedCountries([]);
    setSelectedStates([]);
    setSelectedExperience(null);
    setSelectedSkill(null);
    setSelectedExperience(null);
    setFilterTags([]);
    setSearchFilters({
      ...DEFAULT_FILTER_VALUES,
    });
  };

  const handleApplyDropDown = (value, key, tags = []) => {
    const _searchFilters = { ...searchFilters };
    _searchFilters[key] = value;
    if (
      [FILTER_KEYS.ExperienceLevel, FILTER_KEYS.JobOrderTypeName].includes(key)
    )
      _searchFilters[getParamKeyforGqlApi(key)] = tags?.[0]?.title;
    else _searchFilters[getParamKeyforGqlApi(key)] = tags.map((el) => el.title);
    setSearchFilters({
      ..._searchFilters,
    });
    setFilterTags([
      ...filterTags.filter((el) => el.filterKey !== key),
      ...tags,
    ]);
    if (!isMobileView) applyFilters(_searchFilters);
    updateFilters(_searchFilters);
  };

  const handleGigType = (value) => {
    setGigType(value);
    setSearchFilters({ ...searchFilters, isPrivateGig: value });
    applyFilters({ ...searchFilters, isPrivateGig: value });
    updateFilters({ ...searchFilters, isPrivateGig: value });
  };

  const handleGigStatus = (value) => {
    setGigStatus(value);
    setSearchFilters({ ...searchFilters, JobOrderStatusName: value });
    applyFilters({ ...searchFilters, JobOrderStatusName: value });
    updateFilters({ ...searchFilters, JobOrderStatusName: value });
  };

  const getParamKeyforGqlApi = (key) => {
    switch (key) {
      case FILTER_KEYS.JobOrderTypeName:
        return FILTER_KEYS.JobOrderTypeName;
      case FILTER_KEYS.SkillsId:
        return "Skills";
      case FILTER_KEYS.CityId:
        return "cities";
      case FILTER_KEYS.CountryId:
        return "countries";
      case FILTER_KEYS.RegionId:
        return "regions";
      case FILTER_KEYS.ExperienceLevel:
        return "explevel";
      case FILTER_KEYS.PvtEmpIds:
        return "PvtEmpNames";
      default:
        return;
    }
  };

  const updateFilters = (data) => {
    const params = {
      value: "",
      body: {
        isPrivateGig: GigType,
        ...data,
        all: searchText || "",
        ...defaultParams,
      },
    };
    dispatch(fetchSearchSkill(params));
    dispatch(fetchKeyExperinceLevels());
  };

  const applyFilters = (filters = {}) => {
    const { JobOrderStatusName, ..._filters } = filters;
    handleApplyFilter({
      all: searchText || "",
      ..._filters,
      ...defaultParams,
    });
  };

  const handleRemoveTag = ({ id, title, filterKey }) => {
    setFilterTags((prev) => [
      ...prev.filter((el) => !(el.id === id && el.filterKey === filterKey)),
    ]);
    const _searchFiltersCopy = {
      ...searchFilters,
      [filterKey]: [
        FILTER_KEYS.ExperienceLevel,
        FILTER_KEYS.JobOrderTypeName,
      ].includes(filterKey)
        ? null
        : searchFilters?.[filterKey].filter((el) => el !== id),
      [getParamKeyforGqlApi(filterKey)]: [
        FILTER_KEYS.ExperienceLevel,
        FILTER_KEYS.JobOrderTypeName,
      ].includes(filterKey)
        ? null
        : searchFilters?.[getParamKeyforGqlApi(filterKey)].filter(
            (el) => el !== title
          ),
    };

    setSearchFilters(() => ({
      ..._searchFiltersCopy,
    }));
    applyFilters({
      isPrivateGig: GigType,
      ..._searchFiltersCopy,
      search: searchText || "",
      all: searchText || "",
    });
    updateFilters({
      isPrivateGig: GigType,
      ..._searchFiltersCopy,
      search: searchText || "",
      all: searchText || "",
    });

    removeSelectedValues(filterKey, title);
  };

  const removeSelectedValues = (filterKey, title) => {
    switch (filterKey) {
      case FILTER_KEYS.CityId:
        removeSelectedOption({
          title,
          items: selectedCities,
          setItems: setSelectedCities,
          filterKey,
        });
        return;
      case FILTER_KEYS.CountryId:
        removeSelectedOption({
          title,
          items: selectedCountries,
          setItems: setSelectedCountries,
          filterKey,
        });
        return;
      case FILTER_KEYS.PvtEmpIds:
        removeSelectedOption({
          title,
          items: selectedEmployers,
          setItems: setSelectedEmployers,
          filterKey,
        });
        return;
      case FILTER_KEYS.RegionId:
        removeSelectedOption({
          title,
          items: selectedStates,
          setItems: setSelectedStates,
          filterKey,
        });
        return;
      case FILTER_KEYS.ExperienceLevel:
        setSelectedExperience(null);
        return;
      case FILTER_KEYS.JobOrderTypeName:
        setSelectedGigType(null);
        return;
      case FILTER_KEYS.SkillsId:
        setSelectedSkill(null);
        return;
      default:
        return;
    }
  };

  const removeSelectedOption = ({ title, items, setItems, filterKey }) => {
    setItems(items.filter((el) => getTitle(el, filterKey) !== title));
  };

  const isMobileView = windowDimensions.width < 768;

  const handleSort = (e) => {
    setSortType(e?.key);

    // Setting only publised gigs for upcoming expiry
    if (e?.key == "ENDING") handleGigStatus("Published");
    applyFilters({
      ...getGigStatusForSort(e?.key, searchFilters),
      _sort: e?.key,
    });
  };

  const getGigStatusForSort = (value, filters = {}) => {
    if (value == "ENDING") {
      return { ...filters, JobOrderStatusName: "Published" };
    } else return { ...filters, JobOrderStatusName: "All" };
  };

  const sortMenu = (
    <Menu onClick={handleSort}>
      {SORT_OPTIONS.map((el) => (
        <Menu.Item
          key={el.value}
          className={
            sortType == el?.value ? "sort-values-style-select" : "Most Relevant"
          }
        >
          {el.title}
        </Menu.Item>
      ))}
    </Menu>
  );
  const [gigFilter, setGigFilter] = useState(null);
  const [skillFilter, setSkillFilter] = useState(null);
  const [countryFilter, setCountryFilter] = useState(null);
  const [stateFilter, setStateFilter] = useState(null);
  const [pvtEmpFilter, setPvtEmpFilter] = useState(null);
  const [cityFilter, setCityFilter] = useState(null);
  const [experienceFilter, setExperienceFilter] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleFilter = (filterType) => {
    setGigFilter(filterType === "gig");
    setSkillFilter(filterType === "skill");
    setCountryFilter(filterType === "country");
    setStateFilter(filterType === "state");
    setCityFilter(filterType === "city");
    setExperienceFilter(filterType === "experience");
    setPvtEmpFilter(filterType==='PvtEmpIds')
    setCloseGigTypeModal(false);
  };

  useEffect(() => {
    const params = {
      value: "",

      body: {
        ...searchFilters,
        all: searchText || "",
        isPrivateGig: false,
        ...defaultParams,
      },
    };
    // applyFilters(searchFilters);
    dispatch(fetchSearchSkill(params));
    dispatch(fetchKeyExperinceLevels());
    dispatch(fetchKeySearchCity("a"));
    dispatch(fetchKeySearchRegion("a"));
    dispatch(fetchKeySearchCountry("a"));
    dispatch(
      fetchPvtCompanyInit({
        obj: {
          all: " ",
          _sort: false,
          isPrivateGig: false,
          _page: null,
          _perPage: null,
          isAbsGigs: true,
        },
      })
    );
  }, []);
  const handleDocumentClick = (e) => {
    if (!e.target.closest('.DropdownSearchResult1')) {
      setCloseGigTypeModal(false);
    }
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    setCloseGigTypeModal(prev => {
      const newOpenState = !prev;
      if (newOpenState) {
        document.addEventListener('click', handleDocumentClick);
      } else {
        document.removeEventListener('click', handleDocumentClick);
      }
      return newOpenState;
    });
  };

  return (
    <Fragment>
      <MobileViewFilter
        setGigStatus={handleGigStatus}
        gigStatus={gigStatus}
        setGigType={handleGigType}
        GigType={GigType}
        isMobileView={isMobileView}
        handleApplyDropDown={handleApplyDropDown}
        searchFilters={{ ...(searchFilters || {}) }}
        applyMobileFilters={applyFilters}
        clearAll={clearFilter}
        searchText={searchText}
        defaultParams={defaultParams}
        isPrivateTalent={isPrivateTalent}
        {...{
          selectedCities,
          setSelectedCities,
          selectedStates,
          setSelectedStates,
          selectedSkill,
          setSelectedSkill,
          selectedExperience,
          setSelectedExperience,
          selectedCountries,
          setSelectedCountries,
          selectedGigType,
          setSelectedGigType,
          sortType,
          setSortType,
          mobileButtonsPadding,
          selectedEmployers,
          setSelectedEmployers
        }}
      />

      {!isMobileView && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // paddingLeft: "15px",
            flexWrap: "no-wrap",
            rowGap: "15px",
            padding: webPadding,
          }}
        >
          <Space wrap className="space" sx={{ paddingBottom: 4 }} size={22}>
    
            <ExperienceFilter
              filterKey={FILTER_KEYS.JobOrderTypeName}
              label="Gig Type"
              isSelected={searchFilters?.JobOrderTypeName}
              handleApply={handleApplyDropDown}
              value={searchFilters?.JobOrderTypeName}
              selectedOptions={selectedGigType}
              setSelectedOptions={setSelectedGigType}
              defaultParams={defaultParams}
              toggleFilter={() => toggleFilter("gig")}
              isActive={gigFilter}
            />

            <SkillDropDown
              filterKey={"SkillsId"}
              isSelected={searchFilters?.SkillsId}
              label={"Skill"}
              paramKey="skill"
              handleApply={handleApplyDropDown}
              searchFilters={searchFilters}
              selectedOptions={selectedSkill}
              setSelectedOptions={setSelectedSkill}
              searchText={searchText}
              defaultParams={defaultParams}
              toggleFilter={() => toggleFilter("skill")}
              isActive={skillFilter}
            />

            <SearchDropDown
              filterKey={"CountryId"}
              isSelected={searchFilters?.CountryId?.length}
              label={"Country"}
              paramKey="country"
              handleApply={handleApplyDropDown}
              searchFilters={searchFilters}
              selectedOptions={selectedCountries}
              searchText={searchText}
              setSelectedOptions={setSelectedCountries}
              defaultParams={defaultParams}
              toggleFilter={() => toggleFilter("country")}
              isActive={countryFilter}
            />

            <SearchDropDown
              filterKey={"RegionId"}
              isSelected={searchFilters?.RegionId?.length}
              label={"State"}
              paramKey="region"
              handleApply={handleApplyDropDown}
              searchFilters={searchFilters}
              selectedOptions={selectedStates}
              searchText={searchText}
              setSelectedOptions={setSelectedStates}
              defaultParams={defaultParams}
              toggleFilter={() => toggleFilter("state")}
              isActive={stateFilter}
            />

            <SearchDropDown
              filterKey={"CityId"}
              isSelected={searchFilters?.CityId?.length}
              label={"City"}
              handleApply={handleApplyDropDown}
              searchFilters={searchFilters}
              selectedOptions={selectedCities}
              searchText={searchText}
              setSelectedOptions={setSelectedCities}
              defaultParams={defaultParams}
              toggleFilter={() => toggleFilter("city")}
              isActive={cityFilter}
            />

            <ExperienceFilter
              filterKey={FILTER_KEYS.ExperienceLevel}
              label="Experience Level"
              isSelected={searchFilters?.explevel}
              handleApply={handleApplyDropDown}
              value={searchFilters?.explevel}
              selectedOptions={selectedExperience}
              setSelectedOptions={setSelectedExperience}
              defaultParams={defaultParams}
              className={jobTypeClass}
              toggleFilter={() => toggleFilter("experience")}
              isActive={experienceFilter}
            />
                  {isPrivateTalent ? (
              <Dropdown
                destroyPopupOnHide={true}
                overlayClassName={"DropdownSearchResult1"}
                // className={true ? "dropdown-job" : "dropdown"}
                overlay={AllGigMenu}
                trigger="click"
                visible={CloseGigTypeModal}
                onClick={(e) => {
                  e.stopPropagation();
                  setCloseGigTypeModal(!CloseGigTypeModal);
                }}
              >
                <Button className="skill-list-type-dropdown All_gigs_filter visaStatus-dropdown-select"
                style={{
                  backgroundColor: 'transparent',
                  borderColor: '#d9d9d9',
                  color: 'initial',
                  height: "auto",
                  borderRadius:"3px",
                }}
                onClick={handleOpen}
                >
                  All gigs
                  <img
                    alt=""
                    src={downArrow}
                    style={{
                      width: "8px",
                      marginLeft:"13px"
                    }}
                  />
                </Button>
              </Dropdown>
            ) : null}

        { isPrivateTalent ?    <SearchDropDown
              filterKey={"PvtEmpIds"}
              isSelected={searchFilters?.PvtEmpIds?.length}
              label={"Private Employers"}
              paramKey="PvtEmpIds"
              handleApply={handleApplyDropDown}
              searchFilters={searchFilters}
              selectedOptions={selectedEmployers}
              searchText={searchText}
              setSelectedOptions={setSelectedEmployers}
              defaultParams={defaultParams}
              toggleFilter={() => toggleFilter("PvtEmpIds")}
              isActive={pvtEmpFilter}
            /> : ""}
          </Space>
        </div>
      )}

      {window.innerWidth > 786 ? (
        <Row style={{ paddingLeft: padding }}>
          {GigType ? (
            <Tag
              closable={true}
              onClose={() => handleGigType(false)}
              className="tag2"
            >
              Private Gigs <img src={PrivateTag} alt="Private" />
            </Tag>
          ) : null}

          {filterTags.map((el) => (
            <Tag
              key={el.id + el.title}
              closable={true}
              onClose={() => handleRemoveTag(el)}
              className="tag2"
            >
              {el.title}
            </Tag>
          ))}
        </Row>
      ) : (
        ""
      )}

      {window.innerWidth > 786 ? (
        <div className="div2-searchResults">
          <div className="sort-by">Sort by</div>
          <Dropdown
            overlayClassName="sortByFilterDropdown"
            overlay={sortMenu}
            trigger={["click"]}
            className="dropdown1"
          >
            <Space style={{ border: "none", paddingBottom: "11px" }}>
              <a style={{ border: "none" }} onClick={(e) => e.preventDefault()}>
                <div className="most-recent">
                  {SORT_OPTIONS.find((el) => el.value == sortType)?.title ||
                    "Most Recent"}
                </div>
              </a>
              <img
                alt="down arrow"
                src={downArrow}
                style={{
                  width: "8px",
                  cursor: "pointer",
                  marginBottom: "2px",
                  paddingTop: "3px",
                }}
              />
            </Space>
          </Dropdown>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export const FILTER_KEYS = {
  JobOrderTypeName: "JobOrderTypeName",
  SkillsId: "SkillsId",
  CountryId: "CountryId",
  RegionId: "RegionId",
  CityId: "CityId",
  ExperienceLevel: "ExperienceLevel",
  PvtEmpIds: "PvtEmpIds",
};

const getTitle = (item, filterKey) => {
  switch (filterKey) {
    case FILTER_KEYS.CityId:
      return item?.LocationWithCountry;
    case FILTER_KEYS.CountryId:
      return item?.CountryName;
    case FILTER_KEYS.RegionId:
      return item?.RegionName;
    case FILTER_KEYS.PvtEmpIds:
      return item?._id?.EmpName;
    default:
      return "";
  }
};

export default AllGigsFilter;
