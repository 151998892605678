import React from 'react'
import LicenseNumber from '../license/LicenseNumber'

const LicenseNumberPage = () => {
  return (
    <><LicenseNumber
         heading = "Let’s verify your license"
         label="Your license number"
         licenseText="Enter your license number & earn +0.5 rating to your profile"
         StateLicense="Your State License is secure with SkillGigs."
         inputText="Valid till June 10, 2025, for the states of Arizona, New York, and Texas"
    /></>
  )
}

export default LicenseNumberPage;