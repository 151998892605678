import React, { useEffect, useState, useRef } from "react";
import { IoLocationSharp, IoBriefcase } from "react-icons/io5";
import { BsCurrencyDollar, BsStarFill } from "react-icons/bs";
import { FaDollarSign } from "react-icons/fa";
import { HiClock } from "react-icons/hi";
import { HeartFilled, StarOutlined } from "@ant-design/icons";
import { Popover, Rate } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { alertNotification } from "../../components/notifications/alertNotification";

import "./GuestGigCard.css";
import {
  createBidReset,
  // favoritefunctiontrigger,
  // favoriteGigCard,
  isModalVisibleAuth,
  postFavoriteGigCard,
  removeFavoriteGigCard,
  removeFavoriteGigCardReducer,
  removeFavoriteGigCardSuccess,
  ResetBidData,
  retractBidReset,
  retractedBidUpdate,
  updateBidReset,
  UpdateSearchFilter,
} from "../../redux/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { getImageFromCloudinary } from "../../shared/helpers";
import moment from "moment";

import messageIcon from "../../assets/icons/messageIcon.svg";
import StageBar from "../../components/StageBar/StageBar";
import { state } from "@antv/g2plot/lib/adaptor/common";
import { useUpdateLogger } from "../../shared/useUpdateLogger";

const bidsData = [
  {
    bidReceived: "4100/wk",
    title: "RN - ICU Nurse",
    facility: "Kindred Hospital, Central Tampa, ...",
    gigTitle: "Del Sol Medical Center El Paso",
    location: "Pittsburgh, PA",
    gigRate: "4000/wk",
  },
  {
    bidReceived: "4200/wk",
    title: "RN - ICU Nurse",
    facility: "Kindred Hospital, Central Tampa, ...",
    gigTitle: "Del Sol Medical Center El Paso",
    location: "Pittsburgh, PA",
    gigRate: "4000/wk",
  },
  {
    bidReceived: "4300/wk",
    title: "RN - ICU Nurse",
    facility: "Kindred Hospital, Central Tampa, ...",
    gigTitle: "Del Sol Medical Center El Paso",
    location: "Pittsburgh, PA",
    gigRate: "4000/wk",
  },
];

// const screenWidth = window.screen.width;

const GigsCard = (props) => {
  const gig = props.data;
  const jobid = gig?.JobOrderId;
  // 
  const bid = props.bid;
  const budgetType = props.budgetType;
  const history = useHistory();
  const dispatch = useDispatch();
  const locationURL = useLocation().pathname;
  const [budgetHourly, setBudgetHourly] = useState(false);
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const { isModalVisibleAuth, setIsModalVisibleAuth } = props;
  const [IsFavoriteStatus, setIsFavoriteStatus] = useState("0");
  const [nextCounter, setNextCounter] = useState(0);
  const [truncatedStr, settruncatedStr] = useState(dimensions > 720 ? 42 : 33);

  const authTokenValue = useSelector(
    (state) => state.authReducer.user.authToken
  );
  const IsFavoritesValuetrigger = useSelector(
    (state) => state.searchGigsReducer.IsFavoritesValue
  );
  const ProfessionCode = gig?.Professions?.ProfessionCode;
  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };
  // useSelector(
  //   (state) => state.profileReducer?.user?.profile?.ProfessionCode
  // ) || "HC";
  const [stageBids, setStageBids] = useState(0);
  const [budgetMin, setBudgetMin] = useState(0);
  const PlacedBid = () => {
    dispatch(createBidReset());
    dispatch(updateBidReset());
    dispatch(ResetBidData());

    history.push({
      pathname: `/create-bid`,
      state: {
        title: gig.Title,
        FacilityName: gig.FacilityName,
        RegionName: gig.Location.Location,
        budget: gig.BudgetMin,
        id: gig._id,
        AccountRole: gig?.AccountRole,
      },
    });
  };
  const detailsPage = () => {
    dispatch(retractedBidUpdate(false));
    history.push({
      pathname: `/details/${gig.JobOrderId}`,
      state: {
        IsFavoritesdata: gig?.IsFavorite,
        id: gig?.JobOrderId,
      },
    });
  };

  const location =
    gig?.Location && gig?.Location["Location"]
      ? gig?.Location["Location"]
      : gig?.Cities && gig?.Cities["CityName"]
      ? `${gig?.Cities["CityName"]}${
          gig?.Regions && gig?.Regions["RegionName"]
            ? `, ${gig?.Regions["RegionName"]}`
            : ""
        }`
      : "Not disclosed";

  // useUpdateLogger("gigGigData:", gig);
  // const [dimensions, setDimensions] = useState(window.innerWidth);

  // const checkDimensions = () => {
  //   setDimensions(window.innerWidth);
  // };

  const handleApply = (gig) => {
    let location = {
      CityName: gig?.Cities?.CityName,
      Location: gig?.Cities?.CityName + ", " + gig?.Regions?.Code,
      CountryName: gig?.Countries?.CountryName,
      RegionCode: gig?.Regions?.RegionName,
      Latitude: gig?.Cities?.Latitude,
      Longitude: gig?.Cities?.Longitude,
      LocationLong: gig?.Cities?.CityName + ", " + gig?.Regions?.RegionName,
    };

    localStorage.setItem(
      "image",
      getImageFromCloudinary(gig?.Regions?.Code, gig?.Cities?.CityName, nextCounter)
    );
    if (authTokenValue === null) {
      // Logic to redirect Guest user after login/signup (jobDetail page)
      localStorage.setItem("JobDetailpageUrl", `/details/${gig?.JobOrderId}`);
      localStorage.setItem("ProfessionsCode", gig?.Professions?.ProfessionCode);
      history.push("/login");
      // if (dimensions < 769) {
      //   history.push("/login");
      // } else {
      //   setIsModalVisibleAuth(true);
      // }
    } else {
      if (gig?.NewProposals?.[0]?.JobOrderId == jobid && gig?.NewProposals?.[0]?.BidStatusId == 1) {
        let newProposalBidId = gig.NewProposals[0]?.ProposalId;
        let id = gig?.NewProposals[0]._id;
        history.push({
          pathname: `/edit-bid/${newProposalBidId}`,
          state: {
            id: id,
            jobOrder: newProposalBidId,
            JobType: gig?.JobOrderTypes?.JobOrderTypeName,
            Remote: gig?.WorkplacePolicy == 'fully-remote',
          },
        });
      }
      else {
        dispatch(ResetBidData())
        history.push({
          pathname: `/create-bid`,
          state: {
            title: gig?.Title,
            FacilityName: gig?.FacilityName,
            RegionName: gig?.WorkplacePolicy == 'fully-remote' ? null : location,
            Remote: gig?.WorkplacePolicy == 'fully-remote',
            budget: gig.BudgetMin,
            id: gig._id,
            JobOrderId: gig?.JobOrderId,
            professionGig: gig?.Professions?.ProfessionCode,
            JobType: gig?.JobOrderTypes?.JobOrderTypeName,
            AccountRole: gig?.AccountRole
          },
        });
      }
    }
  };

  const onHeartClick = (e) => {
    // 
    history.push("/login");
    e.stopPropagation();
    // if (dimensions < 769) {
    // } else {
    //   e.stopPropagation();
    //   setIsModalVisibleAuth(true);
    // }
    localStorage.setItem(
      "Favorite",
      JSON.stringify({ JobOrderId: gig?.JobOrderId, gig })
    );
  };

  const onPlaceABidClick = (e) => {
    e.stopPropagation();
    if(authTokenValue === null){
      localStorage.setItem("JobDetailpageUrl", `/details/${gig.JobOrderId}`);
      localStorage.setItem("ProfessionsCode", gig?.Professions?.ProfessionCode);
      history.push("/login");
      // if (dimensions < 769) {
      //   e.stopPropagation();
      // } else {
      //   e.stopPropagation();
      //   setIsModalVisibleAuth(true);
      // }
    } else{
      handleApply(gig)
    }

  };

  let time = new Date(gig?.Created?.toString());
  let timeInSerach = new Date(Number(gig?.Created));

  // // 
  let year = time.getFullYear();
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let month = months[time.getMonth()];
  let date = time.getDate();
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let day = days[time.getDay()];
  const dates = { day, date, month, year }.toString();

  // Gig Expires on
  let expmsec = Number(gig?.ExpiryDate);
  let exptime = new Date(expmsec);
  let expyear = exptime.getFullYear();
  let expmonth = months[exptime.getMonth()];
  let expdate = exptime.getDate();
  let expday = days[exptime.getDay()];
  const expdates = { expday, expdate, expmonth, expyear }.toString();

  useEffect(() => {
    // 
  }, [locationURL]);

  useEffect(() => {
    // 
    if (budgetType === "Hourly") {
      setBudgetHourly(true);
    } else {
      setBudgetHourly(false);
    }
  }, [gig, budgetType, onHeartClick]);
  useEffect(() => {
    const BudgetMin = gig?.BudgetMin?.$numberDecimal
      ? gig?.BudgetMin?.$numberDecimal
      : gig?.BudgetMin
      ? gig?.BudgetMin
      : 0;
    setBudgetMin(BudgetMin);
    // // 
  }, [gig?.BudgetMin]);
  // const budget = () => {
  //   return(
  //     {budgetType ? (<><span>${gig?.BudgetMin ? Number(gig?.BudgetMin) * 40 : 0}/WK</span></>) : (<><span>${gig?.BudgetMin ? gig?.BudgetMin : 0}/HR</span></>)}

  //     )
  // };

  const AfterLoginonHeartClick = (e) => {
    if (!gig?.IsFavorite) {
      // 
      alertNotification("success", "gig added to favorites");
      dispatch(postFavoriteGigCard(gig?.JobOrderId, "Gig", gig));
      if (locationURL.includes("/search/")) {
        props.setFavorite(gig?.JobOrderId, true);
      }
    } else if (gig?.IsFavorite) {
      dispatch(removeFavoriteGigCard(gig?.JobOrderId));
      if (locationURL.includes("/search/")) {
        props.setFavorite(gig?.JobOrderId, false);
      }
      // 
      alertNotification("success", "gig removed from favorites");
      if (props?.handleTotalCardFav) {
        props?.handleTotalCardFav();
      }
    }
    e.stopPropagation();
  };
  return (
    <React.Fragment>
      <div
        className="sgigsguest-thecard"
        onClick={() => {
          detailsPage();
        }}
      >
        <img
          src={getImageFromCloudinary(
            gig?.Regions?.Code,
            gig?.Cities?.CityName,
            nextCounter
          )}
          className="cand-sgigsguest-image"
          onError={(e) => setNextCounter(nextCounter + 1)}
        />
        <div className="sgigsguest-upAbove">
          <div className="sgigsguest-theTitle">
            {gig?.Title ? gig?.Title : "NA"}
          </div>
          <div className="sgigsguest-information GigCard-CompanyName-FacilityName">
            <div className="sgigsguest-review GigCard-CompanyName-FacilityName-wrap">
              <Popover
                content={gig?.FacilityName}
                trigger={
                  gig?.FacilityName?.length > truncatedStr ? "hover" : "click"
                }
              >
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="GigCard-CompanyNameIT-FacilityNameHCguest"
                >
                  {ProfessionCode === "HC"
                    ? gig?.FacilityName
                      ? gig?.FacilityName?.substring(0, truncatedStr)
                      : "NA"
                    : gig?.CompanyName
                    ? gig?.CompanyName
                    : null}
                </div>
              </Popover>
            </div>
            <div
              className={`information-left-outer ${
                gig?.CompanyName ||
                (gig?.FacilityName && ProfessionCode === "HC")
                  ? "GigCard-Rating-section-job-g"
                  : null
              }`}
            >
              <div className="information-left">
                <BsStarFill className="sgigsguest-star" />
              </div>
              <div className="information-right">
                <div className="sgigsguest-rating">
                  {gig?.facilityRating?.rating
                    ? gig?.facilityRating?.rating
                    : 3.5}
                </div>
                <div className="review">
                  {`(${gig?.facilityRating?.reviews || 0})`}
                </div>
              </div>
            </div>
          </div>
          <div className="sgigsguest-information">
            <div className="information-left-outer">
              <div className="sgigsguest-IT-Experience">{location}</div>
            </div>

            <div className="sgigsguest-review">
              <div className="posted-on">
                {props?.Type
                  ? moment(moment(timeInSerach)?.toISOString())?.fromNow()
                  : moment(time).fromNow()}
              </div>
            </div>
          </div>
        </div>
        <div className="dividerGigCard">
          <div className="sgigsguest-gigcard-interestedBtn">
            {/* <div className="sgigsguest-Budgets"></div> */}
            <div
              onClick={
                authTokenValue === null
                  ? (e) => onHeartClick(e)
                  : (e) => AfterLoginonHeartClick(e)
              }
              style={{ cursor: "pointer" }}
            >
              {locationURL === "/myfavorites" ||
              props.Types === "Favorites" ||
              gig?.IsFavorite ? (
                <Rate
                  character={
                    <HeartFilled
                      style={{ color: "#ff4343", cursor: "pointer" }}
                    />
                  }
                  count={1}
                  allowClear
                  value={gig?.IsFavorite === true ? 1 : 0}
                  disabled={true}
                />
              ) : (
                <Rate
                  character={<HeartFilled style={{ cursor: "pointer" }} />}
                  count={1}
                  allowClear
                  value={gig?.IsFavorite === true ? 1 : 0}
                  disabled={authTokenValue === null ? true : false}
                />
              )}
            </div>
            <div><button
            onClick={ (e) => onPlaceABidClick(e)} 
             className="place-Bid-btn" >{gig?.NewProposals?.[0]?.JobOrderId == jobid && gig?.NewProposals?.[0]?.BidStatusId == 1 ? "Edit bid" : "Place a bid"}</button></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setIsModalVisibleAuth: (params) => {
      dispatch(isModalVisibleAuth(params));
    },
  };
};

export default connect(null, mapDispatchToProps)(GigsCard);
