import React, { useEffect, useState, useRef } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchChatsHistory, updateBid } from "../../../../../../redux/actions";
import { RiCheckDoubleFill } from "react-icons/ri";
import MediaQuery from "react-responsive";
import RejectedIcon from "../../../../../../assets/icons/Group 19971.png";
import acceptedIcon from "../../../../../../assets/icons/Group 19971.svg";
import "./bidAccepted.css";

const BidAcceptedCard = (props) => {
  const [ranking, setRanking] = useState(null);
  const [isPlaceBid, setIsPlaceBid] = useState(null);
  const [reason, setReason] = useState([]);
  const messagesEndRef = useRef(null);
  const dispatch = useDispatch();
  const bidStatus = useSelector(
    (state) => state.getChatHistoryReducer.bidStatus
  );
  const userProfile = useSelector((state) => state.profileReducer.user);
  const loading = useSelector(
    (state) => state.getChatHistoryReducer.historyLoading
  );
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const profile = Detail && Detail?.user && Detail?.user?.profile;
  const progress = Math.floor(profile?.ResumeCompletion)
    ? Math.floor(profile?.ResumeCompletion)
    : 0;

  const declineReasons = [
    {
      id: 1,
      Reason: "Accepted another candidate",
    },
    {
      id: 2,
      Reason: "Not interested in the candidate",
    },
    {
      id: 3,
      Reason: "Not interested in this Gig",
    },
    {
      id: 4,
      Reason: "Asking payrate is too high",
    },
  ];

  const declineReasons2 = [
    {
      id: 5,
      Reason: "Skills not a match",
    },
    {
      id: 2,
      Reason: "On Assignment",
    },
    {
      id: 3,
      Reason: "Not interested in the location",
    },
    {
      id: 4,
      Reason: "Estimated pay rate is too low",
    },
  ];

  useEffect(() => {
    setIsPlaceBid(props?.data?.SourceTypeId === 1 ? true : false);
  }, [props?.data]);

  const submitProfile = () => {
    dispatch(
      updateBid({
        id: props.bid_id,
        BidStatusName: "Profile Submitted",
        SkillListingId: props?.SkillListing_id ?? null,
      })
    );
    // fetchChat(props.data.ConversationId);
  };

  useEffect(() => {
    let rejectReason;
    let id =
      // props?.data?.SenderUser?.AccountId === userProfile?.profile?.AccountId
      // ?
      props?.data?.BidCard?.DeclineReasonId
        ? props?.data?.BidCard?.DeclineReasonId
        : props?.data?.ProposalCard?.DeclineReasonId
        ? props?.data?.ProposalCard?.DeclineReasonId
        : props?.data?.OfferCard?.DeclineReasonId;
    if (props?.data?.ProposalCard?.DeclineReason) {
      rejectReason = props?.data?.ProposalCard?.DeclineReason;
    } else if (props?.data?.BidCard?.DeclineReason) {
      rejectReason = props?.data?.BidCard?.DeclineReason;
    } else {
      rejectReason =
        declineReasons?.filter((d) => d?.id == id)?.map((res) => res.Reason) ||
        "";
    }
    if (
      props?.data?.SenderUser?.AccountId === userProfile?.profile?.AccountId
    ) {
      const _rejectReason =
        declineReasons2?.find((d) => d?.id == id)?.Reason || "";
      if (_rejectReason) {
        rejectReason = _rejectReason;
      }
    }
    setReason(rejectReason);
  
  }, [props?.data]);

  function fetchChat(params) {
    setTimeout(() => {
      dispatch(fetchChatsHistory(params));
    }, 800);
  }

  return (
    <div>
      {props?.status === "Accepted Bid" && (
        <>
          <div
            className={`chat-container1 mt15px ${isPlaceBid && "chat-container-receivedbid"
              }`}
            ref={messagesEndRef}
          >
            <div className="sender-threat"></div>
            <div className="user-threat">
              {/* <MediaQuery maxWidth={768}> */}
              <div>

                <div className="Accepted_Icon">
                  <img src={acceptedIcon} alt="" />
                </div>
                <div className="Accepted">Interested</div>
                {/* </MediaQuery> */}
                <div className="congratulation">
                  {userProfile?.profile?.ProfessionCode != "HC" && isPlaceBid
                    ? "The employer is notified of your interest on this gig."
                    : userProfile?.profile?.ProfessionCode != "HC" && !isPlaceBid
                      ? "Great News! The employer is interested."
                      : null}
                </div>
              </div>
              <div className="acceptContainer">
                {userProfile?.profile?.ProfessionCode == "HC"
                  ? isPlaceBid
                    ? "The employer is notified of your interest on this gig and will get back to you shortly."
                    : "Congrats! The employer accepted your bid. We’ll notify you once we submit your profile to the facility."
                  : isPlaceBid
                    ? <div>Your 3D Resume is only <span style={{ fontWeight: 'bold' }}>{progress}% complete</span>. While we wait to hear from them, lets get it to 100%. Don't miss because of an incomplete profile.</div>
                    : <div>Your 3D Resume is only <span style={{ fontWeight: 'bold' }}>{progress}% complete</span>. Don't miss because of an incomplete profile.</div>}
                {/* {isPlaceBid
                  ? "You accepted the bid."
                  : "Congrats! The employer accepted your bid. We’ll notify you once we submit your profile to the facility."} */}
              </div>
              <div className="time-stamp">
                {/* <div className="time-text-employer">1:48 pm</div> */}
                {/* <div className="tick-icon">
                  <RiCheckDoubleFill />
                </div> */}
              </div>
            </div>
          </div>
        </>
      )}

      {props?.status === "Declined Bid" && (
        <>
          <div className="chat-profile-submit" ref={messagesEndRef}>
            <div className="user-threat">
              {/* <MediaQuery maxWidth={768}> */}
              <div className="Accepted_Icon">
                <img src={RejectedIcon} alt="" />
              </div>
              {/* <div className="Accepted Terminated">
                  {isPlaceBid ? "Offer Declined" : "Bid Declined"}
                </div> */}
              {/* </MediaQuery> */}
              <div className="congratulation" style={{ marginBottom: '15px', color: "#E16974", marginTop: '5px' }} >
                Not Interested.
              </div>
              <div className="accept"  >
                {bidStatus.includes("Accepted Bid") ||
                  bidStatus.includes("Profile Submitted")
                  ? "Unfortunately, the employer declined your bid."
                  : props?.data?.SenderUser?.AccountId ===
                    userProfile?.profile?.AccountId
                  ? "Need further clarification on this gig? Message the employer here."
                  : "Your bid has been declined by the Employer"}
              </div>
              <div className="interview-accepted-date">
                <div className="date">Reason</div>
                <div className="time-reason">
                  {reason}
                </div>
              </div>              
            </div>
          </div>
        </>
      )}

      {props.status == "Terminated" && (
        <div>
          <div>
            <strong>Sorry!</strong> <br />
            {props.MessageText}
          </div>
          <div
            style={{
              background: "#fff",
              padding: "2.5%",
              marginTop: "2%",
              borderRadius: "5px",
            }}
          >
            <Row>
              <div>Start date</div>
              <div>MM/DD/YYYY</div>
            </Row>
            <Row>
              <div>End date</div>
              <div>MM/DD/YYYY</div>
            </Row>
            <Row>
              <div>Estimated pay package</div>
              <div>USD 73/hr</div>
            </Row>
            <Row>
              <div>Contract duration</div>
              <div>13 months</div>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};
export default BidAcceptedCard;
