const FACEBOOK_SIGNUP_CLICK ='FACEBOOK_SIGNUP_CLICK' 
const GOOGLE_SIGNUP_CLICK ='GOOGLE_SIGNUP_CLICK' 
const LINKEDIN_SIGNUP_CLICK ='LINKEDIN_SIGNUP_CLICK' 
const APPLE_SIGNUP_CLICK ='APPLE_SIGNUP_CLICK' 
const FACEBOOK_SIGNIN_CLICK ='FACEBOOK_SIGNIN_CLICK' 
const GOOGLE_SIGNIN_CLICK ='GOOGLE_SIGNIN_CLICK' 
const LINKEDIN_SIGNIN_CLICK ='LINKEDIN_SIGNIN_CLICK' 
const APPLE_SIGNIN_CLICK ='APPLE_SIGNIN_CLICK'
const SIGN_UP_BUTTON_CLICK ='SIGN_UP_BUTTON_CLICK' 
const SIGN_IN_BUTTON_CLICK ='SIGN_IN_BUTTON_CLICK' 
const SIGN_UP_TEXT_CLICK ='SIGN_UP_TEXT_CLICK'
const SIGN_IN_TEXT_CLICK ='SIGN_IN_TEXT_CLICK'
const TERMS_AND_SERVICES_CLICK ='TERMS_AND_SERVICES_CLICK'


export const EVENTS={
    FACEBOOK_SIGNUP_CLICK,
    GOOGLE_SIGNUP_CLICK,
    LINKEDIN_SIGNUP_CLICK,
    APPLE_SIGNUP_CLICK,
    FACEBOOK_SIGNIN_CLICK,
    GOOGLE_SIGNIN_CLICK,
    LINKEDIN_SIGNIN_CLICK,
    APPLE_SIGNIN_CLICK,
    SIGN_UP_BUTTON_CLICK,
    SIGN_IN_BUTTON_CLICK,
    SIGN_IN_TEXT_CLICK,
    SIGN_UP_TEXT_CLICK,
    TERMS_AND_SERVICES_CLICK
}

export const PAGE_TYPE= {
    SIGNUP:'SIGNUP',
    SIGNIN:'SIGNIN',
}

export const APP_NAME = {
    EMPLOYER:'EMPLOYER',
    TALENT:'TALENT'
}

export const PAGE={
    MODAL:'MODAL',
    STATIC:'STATIC'
}