import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Menu,
  Select,
  Input,
  Space,
  Drawer,
  Dropdown,
  Button,
  Radio,
  Tag,
  Row,
  Switch,
  Pagination,
  Col,
  Typography,
  AutoComplete,
  Modal,
  Form,
  Checkbox,
} from "antd";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchKeySearchDynamicLocation,
//   fetchKeySearchLocation,
//   keySearchMyGigsLocation,
// } from "../../../../redux/actions";
import _ from "lodash";
import {
  fetchKeySearchCity,
  fetchKeySearchCountry,
  fetchKeySearchRegion,
} from "../../../redux/actions";

const PARAM_KEYS = {
  city: "location",
  state: "region",
  country: "country",
  PvtEmpIds: "PvtEmpIds",
};

const SearchDropDown = ({
  isSelected,
  label,
  paramKey = "location",
  searchFilters = {},
  filterKey,
  handleApply,
  isMobileView = false,
  selectedOptions,
  setSelectedOptions,
  drawerTitle = "",
  searchText = "",
  defaultParams = {},
  isActive,
  toggleFilter,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const { searchCountry, searchedRegion, searchedCity } = useSelector(
    (state) => state.credentialingReducer
  );
  const PrivateEmployerList = useSelector(
    (state) => state.searchSuggestionReducer.setPvtEmpList
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isError, setIsError] = useState(true);
  const [list, setList] = useState([]);

  const handleDocumentClick = (e) => {
    if (!e.target.closest(".searchResultsFilterDropdown")) {
      setIsOpen(false);
      document.removeEventListener("click", handleDocumentClick);
    }
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    setIsOpen((prev) => !prev);

    if (!isOpen) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }
    setSearchValue("");
    setIsOpen(!isOpen);
    // setSelectedOptions((prev) =>
    //   prev.filter((el) => searchFilters?.[filterKey].includes(getItemId(el)))
    // );
    handleSearch({ target: { value: "" } });
  };

  const handleDebounceSearch = _.debounce((params) => {
    switch (paramKey) {
      case PARAM_KEYS.city:
        dispatch(fetchKeySearchCity(params));
        break;
      case PARAM_KEYS.state:
        dispatch(fetchKeySearchRegion(params));
        break;
      case PARAM_KEYS.country:
        dispatch(fetchKeySearchCountry(params));
        break;
      case PARAM_KEYS.PvtEmpIds:
        const empList = PrivateEmployerList?.totalCount?.[0]?.EmpNamesCounts||[]
        setList(empList.filter((el) => getTitle(el)?.includes(params?.toUpperCase())))
        // dispatch(fetchKeySearchCountry(params));
        break;
      default:
        dispatch(fetchKeySearchRegion(params));
        break;
    }
  }, 500);

  const handleSearch = ({ target: { value } }) => {
    setSearchValue(value);
    // const query = `${paramKey}=${value || ""}`;
    // const params = {
    //   value: value ? query : null,
    //   body: { ...searchFilters, all: searchText || "",...defaultParams },
    // };
    handleDebounceSearch(value);
  };

  const onCheckBoxChange = (e) => {
    const item = JSON.parse(e.target.value) || {};
    const itemId = getItemId(item);
    if (e.target.checked)
      setSelectedOptions([...selectedOptions, { ...item, id: itemId }]);
    else setSelectedOptions(selectedOptions.filter((el) => el.id !== itemId));
  };

  const getItemId = (item) => {
    if(PARAM_KEYS.PvtEmpIds === paramKey) return item?._id?.AccountId
    return item?.RegionId || item?.CityId || item?.CountryId;
  };
  const getTitle = (item) => {
    switch (paramKey) {
      case PARAM_KEYS.city:
        return item?.LocationWithCountry;
      case PARAM_KEYS.country:
        return item?.CountryName;
      case PARAM_KEYS.state:
        return item?.RegionName;
      case PARAM_KEYS.PvtEmpIds:
        return item?._id?.EmpName;
      default:
        return "";
    }
  };

  const onClickApply = () => {
    setIsOpen(false);
    handleApply([...selectedOptions.map((el) => el.id)], filterKey, [
      ...selectedOptions.map((el) => ({
        title: getTitle(el),
        filterKey,
        id: getItemId(el),
      })),
    ]);
  };

  const onClickClear = () => {
    setIsOpen(false);
    setSelectedOptions([]);
    handleApply([], filterKey, []);
    setSearchValue("");
  };

  const getUpdatedCounts = (option) => {
    return list.find((el) => getTitle(el) == getTitle(option))?.count || 0;
  };
  const locationMenu = (
    <div
      className="location-suggestion-view"
      onClick={(e) => e?.stopPropagation()}
    >
      <Input
        onChange={handleSearch}
        className="filter-search-input"
        placeholder={`Find ${label}`}
        value={searchValue}
        prefix={<SearchOutlined />}
      />
      <Space
        direction="vertical"
        className={"infinite-scroller mobile-no-scroller"}
        style={{ overflow: "auto", width: "103%", paddingRight: "8px" }}
      >
        <div
          className={`location-lists-container ${
            list?.length > 5 || selectedOptions.length > 3
              ? "show-scrollbar"
              : ""
          }`}
        >
          {selectedOptions.map((items, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  padding: "5px",
                  height: "40px",
                  boxSizing: "border-box",
                  alignItems: "center",
                }}
                key={index}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Checkbox
                    key={index}
                    value={JSON.stringify(items)}
                    onChange={onCheckBoxChange}
                    checked={true}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>{getTitle(items)}</div>
                    </div>
                  </Checkbox>
                </div>
              </div>
            );
          })}
          {list?.length ? (
            <>
              {list
                .filter(
                  (item) =>
                    selectedOptions.findIndex(
                      (el) => el.id === getItemId(item)
                    ) < 0
                )
                .map((items, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        padding: "5px",
                        height: "40px",
                        boxSizing: "border-box",
                        alignItems: "center",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Checkbox
                          type={"radio"}
                          key={index}
                          value={JSON.stringify(items)}
                          className="location-radio"
                          onChange={onCheckBoxChange}
                          checked={false}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div>{getTitle(items)}</div>
                          </div>
                        </Checkbox>
                      </div>
                    </div>
                  );
                })}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#7e7e7e",
              }}
            >
              No results found
            </div>
          )}
        </div>
      </Space>
    </div>
  );

  useEffect(() => {
    setList(
      paramKey == PARAM_KEYS.PvtEmpIds
        ? PrivateEmployerList?.totalCount?.[0]?.EmpNamesCounts||[]
        : paramKey == PARAM_KEYS.city
        ? searchedCity
        : paramKey == PARAM_KEYS.country
        ? searchCountry
        : searchedRegion||[]
    );
  }, [searchCountry, searchedRegion, searchedCity]);

  // Mobile Filter

  const [isShowDrawer, setIsShowDrawer] = useState(false);

  const handleDeselect = (item) => {
    const filteredData = selectedOptions.filter((el) => getTitle(el) !== item);
    setSelectedOptions(filteredData);
    handleApply(
      filteredData.map((el) => el.id),
      filterKey,
      [
        ...filteredData.map((el) => ({
          title: getTitle(el),
          filterKey,
          id: getItemId(el),
        })),
      ]
    );
  };
  const onCloseDrawer = () => {
    setIsShowDrawer(false);
    onClickApply();
  };

  const containerStyle = {
    position: "relative",
    height: "100vh !important",
    overflow: "hidden",
    // textAlign: 'center',
  };
  if (isMobileView) {
    return (
      <Fragment>
        <Drawer
          className="location-drawer"
          onClose={onCloseDrawer}
          visible={isShowDrawer}
          open={isShowDrawer}
          closable={true}
          placement={"top"}
          contentWrapperStyle={containerStyle}
          title={drawerTitle}
        >
          {locationMenu}
        </Drawer>
        <Form>
          <Form.Item>
            <Select
              value={selectedOptions.map((el) => getTitle(el))}
              placeholder={"Search " + label}
              className="input"
              mode="multiple"
              bordered={false}
              onDeselect={handleDeselect}
              onClick={() => {
                setSearchValue("");
                setIsShowDrawer(true);
                handleSearch({ target: { value: "" } });
              }}
              showSearch
              listHeight={160}
              open={false}
            ></Select>
          </Form.Item>
        </Form>
      </Fragment>
    );
  }

  const getWebMenu = () => {
    return (
      <Menu
        className={`location-filter-menu ${
          isError === "city" && "filterError"
        }`}
        style={{ padding: "12px" }}
      >
        <div className="location-filter-menu-inner">
          {locationMenu}
          <ActionButtons
            onClickApply={onClickApply}
            onClickClear={onClickClear}
          />
        </div>
      </Menu>
    );
  };

  return (
    // <div ref={dropdownRef}>
    <Dropdown
      overlayClassName="searchResultsFilterDropdown"
      overlay={getWebMenu()}
      visible={isOpen && isActive}
      trigger="click"
      onOpenChange={handleOpen}
      onClick={handleOpen}
      onVisibleChange={(open) => setIsOpen(open)}
    >
      <Button
        onClick={toggleFilter}
        className={
          isSelected > 0 ? "visaStatus-dropdown-select" : "visaStatus-dropdown"
        }
        style={{
          backgroundColor: "transparent",
          borderColor: "#d9d9d9",
          color: "initial",
          height: "auto",
          borderRadius: "3px",
        }}
      >
        {label} <DownOutlined />
      </Button>
    </Dropdown>
    // </div>
  );
};

const ActionButtons = ({ onClickClear, onClickApply }) => {
  return (
    <div style={{ paddingRight: "5px" }}>
      <div className="clearAll-div">
        <div className="cancelfilter-btn" onClick={onClickClear}>
          Clear all
        </div>
        <button className="applyfilter-btn" onClick={onClickApply}>
          Apply
        </button>
      </div>
    </div>
  );
};

export default SearchDropDown;
