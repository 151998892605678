import axios from "axios";
import https from "https";
import store from "../redux/store";
import { getCookie } from "../shared/helpers";
import { Auth } from "../config/aws-exports";
import { responsesAreSame } from "workbox-broadcast-update";
import { useDispatch, useSelector } from "react-redux";
import { receivedMessage } from "../redux/actions";

const BackendApiBaseUrl = process.env.REACT_APP_API_URL;
const BackendApiBaseUrlAdmin = process.env.REACT_APP_ADMIN_WEB_SOCKET_URL;
const BackendHubspotAPI = process.env.REACT_APP_SKILLGIGSSUBSCRIBER_URL;
const BackendKlaviyoAPI = process.env.REACT_APP_SKILLGIGSSUBSCRIBER_KLAVIYO_URL;

const fetchDataUrl = `${BackendApiBaseUrl}/`; //sample
const sendDataUrl = `${BackendApiBaseUrl}/`; //sample
const updateProfileUrl = `${BackendApiBaseUrl}/profile`; // need to append /profileId later
const fetchRoleDiscipline = `${BackendApiBaseUrl}/getDiscipline`;
const uploadFileUrl = `${BackendApiBaseUrl}/upload`; // need to append /profileId later
const uploadDPUrl = `${BackendApiBaseUrl}/upload`; // need to append /profileId later
const uploadResumeUrl = `${BackendApiBaseUrl}/uploadResume`; // need to append /profileId later
const fetchDocUrl = `${BackendApiBaseUrl}/credentialing/userdoc`;
const uploadDocUrl = `${BackendApiBaseUrl}/document/upload`;
const fetchProfileDetails = `${BackendApiBaseUrl}/profile/me`;
const fetchExistUserUrl = `${BackendApiBaseUrl}/auth/checkUser`;
const getSkillsUrl = `${BackendApiBaseUrl}/skill/getSkills`;
const skillListingUrl = `${BackendApiBaseUrl}/skill/listing`;
const fetchProfileExperience = `${BackendApiBaseUrl}/profile/experienceLevels`;
const addToFavouriteUrl = `${BackendApiBaseUrl}/empprofile/favorites/add`;
const removeFromFavouriteUrl = `${BackendApiBaseUrl}/empprofile/favorites/remove`;
const updateSocialProfileLinkUrl = `${BackendApiBaseUrl}/profile/socialAccountLink`;

const fetchProposalsUrl = `${BackendApiBaseUrl}/proposal/placedbids`;
const acceptOfferUrl = `${BackendApiBaseUrl}/interview/offer/update`;
// const acceptOfferUrl = `http://192.168.18.101:5000/production/interview/offer/update`;

const messagesCountUrl = `${BackendApiBaseUrl}/empprofile/messages/conversationsCounts`;
// const acceptOfferUrl = `http://192.168.18.149:5000/development/interview/offer/update`;
const createBidUrl = `${BackendApiBaseUrl}/proposal`;
// const createBidUrl = `http://localhost:3000/staging/proposal`;
const updateBidUrl = `${BackendApiBaseUrl}/proposal/placedbids/update`;
const fetchMessagesUrl = `${BackendApiBaseUrl}/message`;
const fetchBidDataUrl = `${BackendApiBaseUrl}/proposal`;

const getChatHistoryUrl = `${BackendApiBaseUrl}/empprofile/messages/get`;
// const getChatHistoryUrl = `http://192.168.18.149:3000/production/empprofile/messages/get`;
const sendMessageUrl = `${BackendApiBaseUrl}/message/send`;
const sendAttachments = `${BackendApiBaseUrl}/message/attachment`;
const receivedBidStatusUrl = `${BackendApiBaseUrl}/proposal/bidstatus`;
const fetchReceivedBidsUrl = `${BackendApiBaseUrl}/bids/receivedbids/`;
const fetchReceivedBidCountUrl = `${BackendApiBaseUrl}/bids/receivedbidcounts`;
const fetchReceivedBidsDetailUrl = `${BackendApiBaseUrl}/bids`;
const updateReceivedBidsUrl = `${BackendApiBaseUrl}/proposal/receivedbids/update`;

const getGraphDataUrl = `${BackendApiBaseUrl}/timesheet/getgraph`;
const fetchTimeListUrl = `${BackendApiBaseUrl}/timesheet/get`;
const createTimesheetItemsUrl = `${BackendApiBaseUrl}/timesheet`;
const upateTimeSheetUrl = `${BackendApiBaseUrl}/timesheet`;
const createTimeSheetCorrectionUrl = `${BackendApiBaseUrl}/timesheet/corrections`;
const userAssignmentUrl = `${BackendApiBaseUrl}/timesheet/userassignment`;
const getExistingListItem = `${BackendApiBaseUrl}/timesheet/get/exists`;

const fetchDashboardStats = `${BackendApiBaseUrl}/profile/dashboardStats`;

const getUserJobDetailsURL = `${BackendApiBaseUrl}/profile/userJobAssigned`;

const addGigCardFavorite = `${BackendApiBaseUrl}/favorite`;
const removeGigCardFavorites = `${BackendApiBaseUrl}/favorite`;

// const getGraphDataUrl=`http://192.168.18.49:3000/development/timesheet/getgraph`;
// const fetchTimeListUrl = `http://192.168.18.40:3000/development/timesheet/get`;
// const createTimesheetItemsUrl=`http://192.168.18.49:3000/development/timesheet`;
// const upateTimeSheetUrl =`http://192.168.18.49:3000/development/timesheet`;
// const createTimeSheetCorrectionUrl='http://192.168.18.49:3000/development/timesheet/corrections';
// const userAssignmentUrl=`http://192.168.18.49:3000/development/timesheet/userassignment`;
// const getExistingListItem=`http://192.168.18.49:3000/development/timesheet/get/exists`;

const deleteResumeURL = `${BackendApiBaseUrl}/profile/removeResume`;
const gigSearchURL = `${BackendApiBaseUrl}/query`;
const getMyFavoritesURL = `${BackendApiBaseUrl}/favorites`;
const getNewMyFavoritesURL = `${BackendApiBaseUrl}/home/jobs`;

// const retractedBidStatusUrl = `${BackendApiBaseUrl}/types/sort`;
const fetchRetractedBidsUrl = `${BackendApiBaseUrl}/types/sort/`;

const fetchRetractedBidData = `${BackendApiBaseUrl}/proposal/all/retractedbids`;

const fetchPlaceBidData = `${BackendApiBaseUrl}/proposal/all/placedbids`;
const fetchInterviewCardUrl = `${BackendApiBaseUrl}/interview/get-interviews`;

const fetchInterviewURL = `${BackendApiBaseUrl}/interview/get-interviews`;
const updateInterviewURL = `${BackendApiBaseUrl}/interview/update`;
// const updateInterviewURL = `http://192.168.18.149:5000/production/interview/update`;
// const updateInterviewURL = `${BackendApiBaseUrl}/interview/offer/update`;
const updateReceivedBidURL = `${BackendApiBaseUrl}/bids/receivedbids/update`;
// const updateReceivedBidURL = `http://192.168.18.149:4000/production/bids/receivedbids/update`;

//*****  3D Resume APIs  start  *****//
const fetchResumeGetfollowersURL = `${BackendApiBaseUrl}/profile/getfollowers`;
const fetchResumeGetviewsURL = `${BackendApiBaseUrl}/profile/getviews`;
const fetchResumeDetailURL = `${BackendApiBaseUrl}/profile/detail`;
//*****  3D Resume APIs  end  *****//

//****Message Decline Reasons API ******/
const fetchDeclineReasonsURL = `${BackendApiBaseUrl}/bids/declinedstatus`;

const fetchHomePageDataURL = `${BackendApiBaseUrl}/home/jobs`;

// Notification API //
const fetchNotificationURL = `${BackendApiBaseUrl}/profile`;
const retractBidUpdate = `${BackendApiBaseUrl}/proposal/retractedbid/update`;
// const retractBidUpdate = `http://192.168.100.171:3000/staging/proposal/retractedbid/update`;
// const retractBidUpdate = `http://192.168.18.25:5000/production/proposal/retractedbid/update`;

// Rating API //
// const createRatingUrl = `http://192.168.18.149:3000/production/empprofile/ratings/create`;
const createRatingUrl = `${BackendApiBaseUrl}/empprofile/ratings/create`;
const updateRatingUrl = `${BackendApiBaseUrl}/empprofile/ratings/update`;
// const updateRatingUrl = `http://192.168.18.149:3000/production/empprofile/ratings/update`;
const deleteRatingUrl = `${BackendApiBaseUrl}/empprofile/rating/delete`;
// const deleteRatingUrl = `http://192.168.18.149:3000/production/empprofile/rating/delete`;
// download resume API
const downloadResumeURL = `${BackendApiBaseUrl}/message/getattachment`;
const getJobCountsUrl = `${BackendApiBaseUrl}/home/jobs`;

//Chats APIs//
const getInboxUrl = `${BackendApiBaseUrl}/empprofile/messages/all`;
// const getInboxUrl = `http://192.168.18.16:3000/production/empprofile/messages/all`;

//Notifications*********
const notifications = `${BackendApiBaseUrl}/empprofile/notifications/get`;
// const notifications = `http://192.168.18.101:4000/production/empprofile/notifications/get`;
const readNotificationUrl = `${BackendApiBaseUrl}/empprofile/notification/update`;
const checkUserName = `${BackendApiBaseUrl}/auth/check-username`;

const sendEmailVerifyStatusURL = `${BackendApiBaseUrl}/profile/send-verification-link`;

const getASkillListingURL = `${BackendApiBaseUrl}/skill/detail`;
const addUpdateUserDeviceDeatilsURL = `${BackendApiBaseUrl}/profile/user-device`;

//ViewListing API
const fetchViewListingURL = `${BackendApiBaseUrl}/skill/listing/viewers`;
//CompanyProfile API

// const fetchCompanyProfileURL = `${BackendApiBaseUrl}/empprofile/getmastercompanyprofile`;
const fetchCompanyProfileURL = `${BackendApiBaseUrl}/guest/empprofile/getcompanyprofiledetail`;

const fetchCompanyProfileGigsURL = `${BackendApiBaseUrl}/empgigs/getmygigs/$`;
const fetchCompanyProfileGigsCountURL = `${BackendApiBaseUrl}/empgigs/getmygigcounts/$`;
// const fetchCompanyProfilejobcountsURL = `${BackendApiBaseUrl}/empprofile/getjobcounts`;
const fetchCompanyProfilejobcountsURL = `${BackendApiBaseUrl}/guest/getJobCounter`;

//addRemoveEmployerFollow API
const addRemoveEmployerFollowURL = `${BackendApiBaseUrl}/empprofile/follow`;
const fetchManageCountURL = `${BackendApiBaseUrl}/profile/manage-bids-count`;
const getRatingUrl = `${BackendApiBaseUrl}/empprofile/ratings/get`;
const signupUpdate = `${BackendApiBaseUrl}/signup/profileUpdate`;
export const sendBidStatusURL = `${BackendApiBaseUrl}/bids/receivedbid/update/`;
const fetchViewResumeURL = `${BackendApiBaseUrl}/profile`;
const NodificationReadMsg = `${BackendApiBaseUrl}/empprofile/conversation/update`;
const AdminMessageInboxUrl = `${BackendApiBaseUrlAdmin}/message/conversations`;
const HubspotAPI = `${BackendHubspotAPI}/subscribe/dispatch`;
const SignUpKlaviyoAPI = `${BackendKlaviyoAPI}/Subscribe/Dispatch`;

const MobileNumberOTPSendURL = `${BackendApiBaseUrl}/signup/sendOtp`;
const MobileNumberOTPVerifyURL = `${BackendApiBaseUrl}/signup/verifyOtp`;
const UserTrackingURL = `${BackendApiBaseUrl}/user-tracking`;

let talentToken = null;
let headers = null;
let ImpersonationId = null;

const fetchHeader = (talentToken, ImpersonationId) => {
  const GetEmail = localStorage.getItem("Email");

  if (ImpersonationId) {
    headers = {
      "Content-Type": "application/json",
      Authorization: talentToken,
      ImpersonationId,
      // params: "61264b4304ff062ef86cec27",
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: talentToken,
      // email: GetEmail,
    };
  }

  return headers;
};

export const fetchToken = async () => {
  talentToken = await store.getState().authReducer.user.authToken;
  ImpersonationId = await store.getState().authReducer.impersonationId;

  return fetchHeader(talentToken, ImpersonationId);
};

export const getToken = () => {
  // const session = await Auth.currentSession();
  // if (session) {
  //   talentToken = session.getIdToken().getJwtToken();
  //   headers = { "Content-Type": "application/json", Authorization: talentToken };
  //   return headers;
  // }
  talentToken = store.getState().authReducer.user.authToken;
  headers = { "Content-Type": "application/json", Authorization: talentToken };
  return headers;
};

// email verification API
export const sendEmailVerificationStatusAPI = async (params) => {
  const headers = await fetchToken();

  const response = await axios.post(`${sendEmailVerifyStatusURL}`, params, {
    headers,
  });
  return response;
};

// fetch Profile Experience
export const fetchProfileExperienceAPI = async () => {
  const response = await axios.get(fetchProfileExperience);
  return response;
};

// Fetch Location API
export const fetchSearchLocationAPI = async (value) => {
  const fetchLocationAPI = `${BackendApiBaseUrl}/skill/locations?location=${value.payload}`;
  const response = await axios.get(fetchLocationAPI);
  return response;
};

export const fetchDisciplineSpeciality = async (value) => {
  const fetchDisciplineSpecialityAPI = `${BackendApiBaseUrl}/profile/speciality/discipline/${value.search}?type=${value.type}`;
  try {
    const response = await axios.get(fetchDisciplineSpecialityAPI);
    return response;
  } catch (e) {
    return e;
  }
};

// Fetch Country API
export const fetchSearchCountryAPI = async (value) => {
  const fetchCountryAPI = `${BackendApiBaseUrl}/skill/getcountries?country=${value.payload}`;
  const response = await axios.get(fetchCountryAPI);
  return response;
};

// Fetch Region API
export const fetchSearchRegionAPI = async (value) => {
  const fetchRegionAPI = `${BackendApiBaseUrl}/skill/getregions?region=${value.payload}`;
  const response = await axios.get(fetchRegionAPI);
  return response;
};

// Fetch City API
export const fetchSearchCityAPI = async (value) => {
  const fetchCityAPI = `${BackendApiBaseUrl}/skill/locations?location=${value.payload}`;
  const response = await axios.get(fetchCityAPI);
  return response;
};

// Fetch Experince Level API
export const fetchExperincLevelsAPI = async () => {
  const fetchExperinceLevelAPI = `${BackendApiBaseUrl}/skill/getexperincelevels`;
  const response = await axios.get(fetchExperinceLevelAPI);
  return response;
};

// Fetch User Skill Listings
export const fetchUserSkillListingAPI = async (params) => {
  const [type, page, perPage] = params;
  const headers = await fetchToken();

  const response = await axios.get(
    `${skillListingUrl}?type=${type}&page=${page}&perPage=${perPage}`,
    { headers }
  );
  return response;
};

// fetch one skill Listing
export const fetchOneSkillListingAPI = async (id) => {
  const headers = await fetchToken();

  const response = await axios.get(`${getASkillListingURL}/${id}`, { headers });
  return response;
};

// Fetch Discipline API
export const fetchSearchDisciplineAPI = async (value) => {
  const categoryId = value.payload.profCode === "HC" ? 3 : 1;
  const fetchDisciplineAPI = `${BackendApiBaseUrl}/skill/discipline?discipline=${value.payload.val}&categoryId=${categoryId}`;
  const response = await axios.get(fetchDisciplineAPI);
  return response;
};

// LinkedIN User
const fetchLinkedInToken = `${BackendApiBaseUrl}/auth/linkedInSignin`;

export const approveLinkedInUserAPI = async (talentToken) => {
  // try {
  const response = await axios.post(
    fetchLinkedInToken,
    {
      code: talentToken,
      redirectURL: `${process.env.REACT_APP_MY_URL}/linkcheck`,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
  // } catch (err) {
  //   return err.response;
  // }
};

export const fetchDocsList = async () => {
  const headers = await fetchToken();
  const response = await axios.get(fetchDocUrl, { headers });
  return response;
};

export const uploadFileApi = async (request, userToken) => {
  const headers = await fetchToken();
  // const headers = {
  //   "Content-Type": "application/json",
  //   Authorization: userToken,
  // };
  const { file } = request.params;
  const contentType = file.type;
  // const payload = {contentType, type  }
  request.params["contentType"] = contentType;
  const response = await axios.post(uploadFileUrl, request.params, {
    headers,
  });
  const { uploadURL, Key } = response.data;
  const uploadResponse = await axios.put(uploadURL, file);
  if (uploadResponse.status == 200) {
    return { data: { status: "success", payload: Key } };
  } else {
    return {
      data: {
        status: "error",
        error: { message: "File upload failed", code: uploadResponse.status },
      },
    };
  }
};

// deleting Resume from Profile
export const resumeDeleteAPI = async () => {
  const headers = await fetchToken();

  const response = await axios.delete(deleteResumeURL, { headers });
  return response;
};

export const uploadDocApi = async (request) => {
  const headers = await fetchToken();

  const response = await axios.post(uploadDocUrl, request.params, {
    headers,
    // headers: { "Content-Type": "multipart/form-data", Authorization: talentToken },
  });
  return response;
};

export const removeDocApi = async (request) => {
  const headers = await fetchToken();

  const response = await axios.delete(`${fetchDocUrl}/${request}`, {
    // headers: { Authorization: talentToken },
    headers,
  });
  return response;
};

// export const fetchProfileURL = async ({ talentToken, impersonationId = null }) => {
export const fetchProfileURL = async (params) => {
  if (params?.impersonationId) {
    headers = fetchHeader(params.talentToken, params.impersonationId);
  } else {
    headers = await fetchToken();
  }

  const response = await axios.get(fetchProfileDetails, {
    // headers: { Authorization: talentToken ,impersonationId},
    headers,
  });

  return response;
};

export const fetchRoleDisciplineAPI = async (params) => {
  const key = params.payload.key;
  const talentToken = params.payload.authToken;
  const profession = params.payload.pro;

  const headers = await fetchToken();
  // const headers = {
  //   "Content-Type": "application/json",
  //   Authorization: talentToken,
  // };

  const response = await axios.get(
    `${fetchRoleDiscipline}?search=${key ? key : ""}&profession=${profession}`,
    { headers }
  );

  return response;
};
// on HC and IT
export const updateProfileApi = async (request) => {
  const headers = await fetchToken();
  const response = await axios.put(`${updateProfileUrl}`, request.params, {
    headers,
  });
  const HC_LIST_ID = process.env.HC_LIST_ID
  const IT_LIST_ID = process.env.IT_LIST_ID
  var body;
  var bodyEdit;
  if (
    request?.params?.Professions?.ProfessionCode == "HC" ||
    response?.data?.Professions?.ProfessionCode == "HC"
  ) {
    body = { EventType: "Event_HC_Signup", ListId: HC_LIST_ID||"VJd5id" };
    bodyEdit = { EventType: "Event_HC_Edit_Profile" };
  } else {
    body = {
      EventType: "Event_IT_Signup",
      ListId: request?.params?.listId ||IT_LIST_ID|| "Yaec3q",
    };
    bodyEdit = { EventType: "Event_IT_Edit_Profile" };
  }
  if (request?.params?.WizardFlow == 2) {
    const responseData = axios.post(
      `${SignUpKlaviyoAPI}?id=${response?.data?.AccountId}`,
      body
    );
  }
  return response;
};

// rest signup
export const updateSignUpProfileApi = async (request) => {
  const headers = await fetchToken();
  const response = await axios.put(signupUpdate, request.params, {
    headers,
  });
  var bodyEdit;
  var body;
  let arr = [
    101, 102, 103, 104, 105, 106, 107, 108, 110, 113, 112, 114, 115, 116, 201,
    202, 203, 204, 205, 206, 207, 1000,
  ];
  let arrSignUp = [2];
  const HC_LIST_ID = process.env.HC_LIST_ID
  const IT_LIST_ID = process.env.IT_LIST_ID
  if (response?.data?.Professions?.ProfessionCode == "HC") {
    body = { EventType: "Event_HC_Signup", ListId: HC_LIST_ID || "VJd5id" };
    bodyEdit = { EventType: "Event_HC_Edit_Profile" };
  } else if (response?.data?.Professions?.ProfessionCode == "IT") {
    body = {
      EventType: "Event_IT_Signup",
      ListId: request?.params?.listId || IT_LIST_ID || "Yaec3q",
    };
    bodyEdit = { EventType: "Event_IT_Edit_Profile" };
  }
  if (arr?.includes(request?.params?.WizardFlow)) {
    const responseData = axios.post(
      `${SignUpKlaviyoAPI}?id=${response?.data?.AccountId}`,
      bodyEdit
    );
  } else if (arrSignUp?.includes(request?.params?.WizardFlow)) {
    const responseData = axios.post(
      `${SignUpKlaviyoAPI}?id=${response?.data?.AccountId}`,
      body
    );
  }

  return response;
};

export const updateSocialProfileLinkApi = async (request) => {
  const headers = await fetchToken();
  let { params } = request;
  let requestParam = {
    code: params.code,
    redirectURI: params.redirectURI,
    socialAccountType: params.socialAccountType,
  };
  const response = await axios.post(
    `${updateSocialProfileLinkUrl}`,
    requestParam,
    {
      headers,
    }
  );
  return response;
};

export const uploadProfilePicApi = async ({ params }) => {
  await fetchToken();

  const response = await axios.put(`${uploadDPUrl}`, params, {
    // headers,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: talentToken,
    },
  });
  return response;
};

export const uploadResumeApi = async ({ params }) => {
  await fetchToken();
  // const headers = await fetchToken();

  const response = await axios.put(`${uploadResumeUrl}`, params, {
    // headers,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: talentToken,
    },
  });
  return response;
};

export const fetchSkillsApi = async (request) => {
  let categoryId;
  if (request?.params?.profCode) {
    categoryId = request.params.profCode === "IT" ? 1 : 3;
  }
  const response = await axios.get(
    categoryId
      ? `${getSkillsUrl}?skillName=${
          request.params.val ? request.params.val : ""
        }&SkillCategoryId=${categoryId}`
      : `${getSkillsUrl}?skillName=${
          request.params.val ? request.params.val : "A"
        }`
  );
  return response;
};

export const createSkillListingApi = async (request) => {
  const headers = await fetchToken();

  const response = await axios.post(`${skillListingUrl}`, request.params, {
    headers,
  });
  return response;
};

export const updateSkillListingApi = async (request) => {
  const headers = await fetchToken();

  const response = await axios.put(
    `${skillListingUrl}/${request.params._id}`,
    request.params,
    {
      headers,
    }
  );
  return response;
};

export const deleteSkillListingApi = async (request) => {
  const id = request.params;

  const headers = await fetchToken();

  const response = await axios.delete(`${skillListingUrl}/${id}`, {
    headers,
  });
  return response;
};

export const fetchDataApi = async (params) => {
  return await axios.get(fetchDataUrl);
};

export const sendDataApi = async (params) => {
  const data = JSON.stringify(params);
  return await axios.post(sendDataUrl, data, { headers });
};

export const checkUserApi = async (request) => {
  // const data = JSON.stringify(params);
  const headers = await fetchToken();

  const response = await axios.post(
    fetchExistUserUrl,
    { email: request.params },
    { headers }
  );
  return response;
};

// Bids
// Proposal
export const fetchProposalsApi = async (id, limit, skip, status) => {
  const headers = await fetchToken();
  return axios.get(`${fetchProposalsUrl}/${limit}/${skip}/${status}`, {
    headers,
  });
};

export const acceptOfferApi = async (request) => {
  const headers = await fetchToken();
  const data = JSON.stringify(request.payload);
  return await axios.put(`${acceptOfferUrl}/${request?.payload?.id}`, data, {
    headers,
  });
};

export const createBidApi = async (request) => {
  const data = JSON.stringify(request.payload);
  const headers = await fetchToken();
  const results = await axios.post(createBidUrl, data, { headers });
  const HubSpotTrigger = {
    EventType: "Event_Proposal_Submitted",
    GigID: request?.payload?.GigId,
    ProfileID: results?.data?.ProfileId,
    SkillListingID: null,
    EmployerID: 123194,
    Status: "Proposal Submitted",
  };
  const HubSpotResults = axios.post(
    `${HubspotAPI}?id=${results?.data?.ProposalId}`,
    HubSpotTrigger,
    { headers }
  );
  return results;
};

export const updateBidApi = async (request) => {
  const data = JSON.stringify(request.payload);
  const headers = await fetchToken();
  return await axios.put(`${updateBidUrl}/${request.payload.id}`, data, {
    headers,
  });
};
//from chat
export const updateGigApi = async (request) => {
  // const data = JSON.stringify(request.payload);
  const headers = await fetchToken();
  const results = await axios.put(
    `${updateReceivedBidURL}/${request?.payload?.BidUpdatePayload?.id}`,
    request?.payload?.BidUpdatePayload,
    {
      headers,
    }
  );
  const HubSpotResults = axios.post(
    `${HubspotAPI}?id=${request?.payload?.BidUpdatePayload?.id}`,
    request?.payload?.HubSpotTrigger,
    { headers }
  );
  return results;
};

export const retractBidApi = async (request) => {
  const data = JSON.stringify(request.payload);
  const headers = await fetchToken();
  return await axios.put(`${updateBidUrl}/${request.payload.id}`, data, {
    headers,
  });
};

export const fetchMessagesAPI = async (messageType, limit, skip) => {
  const headers = await fetchToken();
  return await axios.get(
    `${fetchMessagesUrl}/${messageType}/${limit}/${skip}`,
    { headers }
  );
};

export const fetchInboxDataAPI = async (
  limit,
  skip,
  type = null,
  bidType = null,
  bidStatus
) => {
  const headers = await fetchToken(); // authentication

  return await axios.get(
    `${fetchMessagesUrl}/all/${limit}/${skip}?type=${type}&bidType=${bidType}&bidStatus=${bidStatus}`, //data fetch
    { headers }
  );
};

export const fetchBidDataApi = async (id) => {
  const headers = await fetchToken();
  return axios.get(`${fetchBidDataUrl}/${id}`, { headers });
};

export const SendMessageAPI = async (
  conversationId,
  name,
  recipientId,
  message,
  profPic,
  fileUploadResponse
) => {
  const headers = await fetchToken();
  headers["Content-Type"] = "multipart/form-data";
  const data = new FormData();

  data.append("ConversationId", conversationId);
  data.append("MessageText", message);
  for (let i = 0; i < fileUploadResponse.length; i++)
    data.append("Attachments[]", fileUploadResponse[i]);

  const req = await axios.post(`${sendMessageUrl}`, data, { headers });
};

export const fetchChatHistoryApi = async (id, bid) => {
  const headers = await fetchToken();
  if (bid) {
    return axios.get(`${getChatHistoryUrl}/${id}?bid=${bid}`, { headers });
  } else {
    return axios.get(`${getChatHistoryUrl}/${id}`, { headers });
  }
};

export const fetchNodificationUpdateApi = async (id) => {
  const headers = await fetchToken();
  return axios.post(`${NodificationReadMsg}/${id}`, "", { headers });
};

export const fetchReceivedBidsStatusAPI = async () => {
  const headers = await fetchToken();

  const response = await axios.get(receivedBidStatusUrl, { headers });

  return response;
};

export const fetchReceivedBidsApi = async (
  BidStatusName,
  skillListingId,
  sortBy,
  lat,
  long,
  page,
  perPage
) => {
  const location = `latitude=${lat}&longitude=${long}&maxDistance=50&`;
  const headers = await fetchToken();
  const countQueryParams = [];
  if (skillListingId) {
    countQueryParams.push(`skillListingId=${skillListingId}`);
  }
  if (BidStatusName !== "All") {
    countQueryParams.push(`BidStatusId=${BidStatusName}`);
  }
  const [response, ManageBidCountRes] = await Promise.all([
    axios.get(
      `${fetchReceivedBidsUrl}${
        skillListingId ? skillListingId : null
      }/${sortBy}?${
        lat && long ? location : ""
      }page=${page}&perPage=${perPage}&status=${BidStatusName}
    `,
      {
        headers,
      }
    ),
    axios.get(
      `${fetchManageCountURL}${
        countQueryParams.length > 0 ? "?" : ""
      }${countQueryParams.join("&")}`,
      { headers }
    ),
  ]);
  return [response, ManageBidCountRes];
};
// export const downloadChatFilesApi=(FilePath)=>{
//   const headers=await fetchToken();
//   return axios.get(`${downloadFilesfromChat}/${FilePath}`,{headers});
// }

export const fetchDashboardBidsCounts = async () => {
  const headers = await fetchToken();
  return await axios.get(`${fetchManageCountURL}`, { headers });
};
export const fetchReceivedBidCountApi = async (request) => {
  const headers = await fetchToken();
  return await axios.get(
    `${fetchReceivedBidCountUrl}/${request.skillListingId}`,
    { headers }
  );
};

export const updateReceivedBidsApi = async (request) => {
  const data = JSON.stringify(request.payload);
  const headers = await fetchToken();
  return await axios.put(
    `${updateReceivedBidsUrl}/${request.payload.id}`,
    data,
    { headers }
  );
};

export const SearchGigsApi = async (request) => {
  // const data = JSON.stringify(params);
  const response = await axios.post(gigSearchURL, request.params, { headers });
  return response;
};
export const getGraphDataApi = async (type, startdate, enddate) => {
  // const headers = await fetchToken();
  // return axios.get(`${getGraphDataUrl}/${type}/${startdate}/${enddate}`, {
  //   headers,
  // });
};

// export const fetchTimeSheetListApi=(limit, skip)=>{
//   const headers=await fetchToken();
//   return axios.get(`${fetchTimeListUrl}/${limit}/${skip}`,{headers});
// }

export const fetchTimeSheetListApi = async (limit, skip, type, start, end) => {
  const headers = await fetchToken();
  return axios.get(
    `${fetchTimeListUrl}/${type}/${limit}/${skip}/${start}/${end}`,
    { headers }
  );
};

//TimeSheet Correction
//create
export const createTimeSheetCorrectionApi = async (request) => {
  const data = JSON.stringify(request.payload);
  const headers = await fetchToken();
  return await axios.post(createTimeSheetCorrectionUrl, data, { headers });
};
export const createTimeSheetItemsApi = async (request) => {
  const data = JSON.stringify(request.payload);
  const headers = await fetchToken();
  return await axios.post(createTimesheetItemsUrl, data, { headers });
};
export const updateTimeSheetApi = async (request) => {
  const headers = await fetchToken();
  return await axios.put(
    `${upateTimeSheetUrl}/${window.timesheetId}`,
    request.payload,
    { headers }
  );
};

// userAssignmentUrl
export const userAssignmentApi = async () => {
  const headers = await fetchToken();
  return await axios.get(`${userAssignmentUrl}`, { headers });
};

export const getExistingListemItemAPi = async () => {
  const headers = await fetchToken();
  return await axios.get(`${getExistingListItem}`, { headers });
};

// SEARCHSUGGESTION
export const fetchSearchSuggestionsAPI = async (value) => {
  const headers = await fetchToken();
  const response = await axios.get(
    `${BackendApiBaseUrl}/search?search=${value.payload}`,
    { headers }
  );
  //const fetchSuggestionAPI = `${BackendApiBaseUrl}/search?search=${value.payload}` ;
  //const response = await axios.get(fetchSuggestionAPI );
  return response;
};

// talent info API
export const talentInfoAPI = async ({ talentToken, empName }) => {
  // const headers = { "Content-Type": "application/json", Authorization: talentToken };
  const headers = await fetchToken();

  return await axios.get(
    `${updateProfileUrl}/detail${empName ? `?empName=${empName}` : ""}`,
    { headers }
  );
};

// Analytic info API
export const AnalyticInfoAPI = async (talentToken) => {
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  const response = await axios.get(`${fetchDashboardStats}`, { headers });
  return response;
};

export const fetchUserJobDetailsAPI = async (value) => {
  const payloadvalue = value.payload.slice(0, -9);
  let updatepayloadvalue;
  if (payloadvalue === "On Billing") {
    updatepayloadvalue = "Hired";
  } else if (payloadvalue === "Completed") {
    updatepayloadvalue = "Off Billing";
  } else {
    updatepayloadvalue = payloadvalue;
  }
  const headers = await fetchToken(payloadvalue, "payloadvalue");
  const response = await axios.get(
    `${getUserJobDetailsURL}?page=1&perPage=2&status=${updatepayloadvalue}`,
    { headers }
  );
  return response;
};

export const fetchUserJobDetailsCompletedAPI = async (value) => {
  const headers = await fetchToken();
  const response = await axios.get(
    `${getUserJobDetailsURL}?page=1&perPage=2&status=Off Billing`,
    { headers }
  );
  return response;
};

export const fetchUserJobDetailsCanceledAPI = async (value) => {
  const headers = await fetchToken();
  const response = await axios.get(
    `${getUserJobDetailsURL}?page=1&perPage=2&status=Cancelled`,
    { headers }
  );
  return response;
};

export const fetchUserJobDetailsTerminatedAPI = async (value) => {
  const headers = await fetchToken();
  const response = await axios.get(
    `${getUserJobDetailsURL}?page=1&perPage=2&status=Terminated`,
    { headers }
  );
  return response;
};

export const fetchUserJobDetailsActiveAPI = async (value) => {
  const headers = await fetchToken();
  const response = await axios.get(
    `${getUserJobDetailsURL}?page=1&perPage=2&status=Hired`,
    { headers }
  );
  return response;
};

// myFavorites info API
export const myFavoritesInfoAPI = async ({ page, perPage, ...payload }) => {
  try {
    const headers = await fetchToken();
    const response = await axios.post(
      `${getMyFavoritesURL}?&page=${page}&_perPage=${perPage}`,
      { ...payload, _perPage: 2, page: 1 },
      { headers }
    );
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const myNewFavoritesInfoAPI = async (payload) => {
  console.log("payload", payload);
  const { gigsBPage = 1, favoritePage = 1, ...rest } = payload || {};
  const headers = await fetchToken();
  const response = await axios.put(
    `${getNewMyFavoritesURL}?editorType=EditorPicks&editorPage=1&editorPerPage=20&suggestType=Suggested&suggestPerPage=10&suggestPage=1&continueBType=Continue%20Browsing&continueBPage=1&continueBPerPage=24&favoriteType=Favorites&favoritePage=${favoritePage}&favoritePerPage=24&mostPopularType=Most%20Popular&mostPopularPerPage=20&mostPopularPage=1&gigsBPagePerPage=10&gigsBPage=${gigsBPage}`,
    rest,
    { headers }
  );
  return response;
};

export const addGigCardFavoriteAPI = async (value) => {
  const data = value.payload;
  const headers = await fetchToken();
  return await axios.post(
    `${addGigCardFavorite}/${data[0]}`,
    { SourceName: data[1] },
    {
      headers,
    }
  );
};

export const removefavoriteGigCardAPI = async (value) => {
  const data = value.payload;
  const headers = await fetchToken();
  return await axios.delete(`${removeGigCardFavorites}/${data[0]}`, {
    headers,
  });
};

// profile Education History
export const getCollegeEducationHistoryAPI = async (key) => {
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  const response = await axios.get(
    `${updateProfileUrl}/getCollegeMaster?search=${key}`,
    {
      headers,
    }
  );
  return response;
};

export const getCourseEducationHistoryAPI = async (key) => {
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  const response = await axios.get(
    `${updateProfileUrl}/getCourseMaster?search=${key}`,
    {
      headers,
    }
  );
  return response;
};

export const getCourseTypeEducationHistoryAPI = async (key) => {
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  const response = await axios.get(
    `${updateProfileUrl}/getCourseType?search=${key}`,
    {
      headers,
    }
  );
  return response;
};

export const postEducationHistoryAPI = async (data) => {
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  // debugger;

  const response = await axios.post(
    `${updateProfileUrl}/educationHistory`,
    data,
    {
      headers,
    }
  );
  return response;
};

export const addToFavouriteApi = async (data) => {
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  // debugger;

  const response = await axios.post(`${addToFavouriteUrl}`, data.payload, {
    headers,
  });
  return response;
};

export const removeFromFavouriteApi = async (data) => {
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  // debugger;

  const response = await axios.put(
    `${removeFromFavouriteUrl}/${data?.payload?.SourceId}`,
    data?.payload,
    {
      headers,
    }
  );
  return response;
};

export const updateEducationHistoryAPI = async (params) => {
  const headers = await fetchToken();

  const data = JSON.stringify(params[0]);

  const response = await axios.put(
    `${updateProfileUrl}/educationHistory/${params[1]}`,
    data,
    {
      headers,
    }
  );
  return response;
};

// export const fetchCompanyWorkHistoryAPI = async (key) => {
//   //const headers = {"Content-Type": "application/json", Authorization: talentToken};
//   const headers = await fetchToken();
//   // debugger;

//   const response = await axios.post(
//     `${updateProfileUrl}/companyMaster?search=${key}`,
//     {
//       headers,
//     }
//   );
//   return response;
// };

export const fetchJobTitleWorkHistoryAPI = async (key) => {
  const headers = await fetchToken();

  const response = await axios.get(
    `${updateProfileUrl}/jobTitleMaster?search=${key}`,
    {
      headers,
    }
  );
  return response;
};

export const fetchIndustryWorkHistoryAPI = async (key) => {
  const headers = await fetchToken();

  const response = await axios.get(
    `${updateProfileUrl}/industriesMaster?search=${key}`,
    {
      headers,
    }
  );
  return response;
};

export const fetchLocationWorkHistoryAPI = async (key) => {
  // const headers = await fetchToken();

  const fetchLocationAPI = `${BackendApiBaseUrl}/skill/locations?location=${key}`;
  const response = await axios.get(fetchLocationAPI);
  return response;
};

export const postWorkHistoryAPI = async (data) => {
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  // debugger;

  const response = await axios.post(`${updateProfileUrl}/workHistory`, data, {
    headers,
  });
  return response;
};

export const postCertificatesAndAwardsAPI = async (data) => {
  const headers = await fetchToken();

  const response = await axios.post(`${updateProfileUrl}/certificate`, data, {
    headers,
  });
  return response;
};

export const updateCertificatesAndAwardsAPI = async (data, id) => {
  const headers = await fetchToken();

  const response = await axios.put(
    `${updateProfileUrl}/certificate/${id}`,
    data,
    {
      headers,
    }
  );
  return response;
};

export const updateWorkHistoryAPI = async (params) => {
  const headers = await fetchToken();

  const data = JSON.stringify(params[0]);

  const response = await axios.put(
    `${updateProfileUrl}/workHistory/${params[1]}`,
    data,
    {
      headers,
    }
  );
  return response;
};

// export const fetchRetractedBidsStatusAPI = async () => {

//   const headers = await fetchToken();

//   const response = await axios.get(retractedBidStatusUrl, { headers });

//   return response;
// };

export const fetchRetractedBidsApi = async (
  perPage,
  page,
  sort,
  longitude,
  latitude,
  maxDistance
) => {
  const location = `latitude=${latitude}&longitude=${longitude}&maxDistance=50`;
  //   "RB URL:",
  //   `${fetchReceivedBidsUrl}${limit}/${skip}/${BidStatusName}/${
  //     skillListingId ? skillListingId : null
  //   }/${sortBy}/${lat && long && location}`
  // );
  const headers = await fetchToken();
  return axios.get(
    `${fetchRetractedBidsUrl}

    /${sort}?latitude=${latitude}&longitude=${longitude}&maxDistance=${maxDistance}
      latitude && longitude ? 50 : null
    }&page=${page}&perPage=${perPage}`,
    {
      headers,
    }
  );
};

export const fetchRetracteBidDataAPI = async (
  sort,
  lat,
  long,
  page,
  perPage
) => {
  const headers = await fetchToken();
  const response = await axios.get(
    `${fetchRetractedBidData}/${sort}?perPage=${perPage}&page=${page}&longitude=${long}&latitude=${lat}&maxDistance=${
      lat && long ? 50 : null
    }`,
    { headers }
  );
  return response;
};

export const fetchPlaceBidDataAPI = async (
  sort,
  lat,
  long,
  page,
  perPage,
  BidStatusName
) => {
  //   "placebid api hit",
  //   sort,
  //   lat,
  //   long,
  //   page,
  //   perPage,
  //   BidStatusName
  // );
  let urlplaceLat = "";
  let urlplace = "";
  if (lat) {
    urlplaceLat =
      urlplaceLat +
      `latitude=${lat}&longitude=${long}&maxDistance=${
        lat && long ? 50 : null
      }`;
  } else if (BidStatusName) {
    urlplace = urlplace + `&status=${BidStatusName}`;
  }
  const headers = await fetchToken();
  const [response, ManageBidCountRes] = await Promise.all([
    axios.get(
      `${fetchPlaceBidData}/${sort}?latitude=${lat}&longitude=${long}&maxDistance=${
        lat && long ? 50 : null
      }&perPage=${perPage}&page=${page}&status=${BidStatusName}`,
      { headers }
    ),
    axios.get(
      `${fetchManageCountURL}${
        BidStatusName !== undefined ? `?BidStatusId=${BidStatusName}` : ""
      }`,
      { headers }
    ),
  ]);
  return [response, ManageBidCountRes];
};

export const fetchDetailPlaceBidDataAPI = async (id) => {
  const headers = await fetchToken();
  const response = await axios.get(`${fetchBidDataUrl}/${id}`, { headers });
  return response;
};

export const fetchDetailReceivedBidDataAPI = async (id) => {
  const headers = await fetchToken();
  const response = await axios.get(`${fetchReceivedBidsDetailUrl}/${id}`, {
    headers,
  });
  return response;
};

// InterviewCard info API
export const InterviewCardAPI = async (talentToken) => {
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  const response = await axios.get(`${fetchInterviewCardUrl}`, { headers });
  return response;
};

/********** Interview API Start **********/

export const fetchInterviewAPI = async () => {
  const headers = await fetchToken();
  const response = await axios.get(`${fetchInterviewURL}`, {
    headers,
  });
  return response;
};

/********** Interview API End **********/

export const fetchDeclinedReasonsBidsAPI = async (payload) => {
  const headers = await fetchToken();
  const response = await axios.get(
    `${fetchDeclineReasonsURL}/${payload}`,
    // `${fetchReceivedBidsDetailUrl}/declinedstatus/${payload}`,
    {
      headers,
    }
  );
  return response;
};

export const updateDeclinedReasonsReceivedBidsAPI = async (payload) => {
  const data = JSON.stringify(payload.body);

  const headers = await fetchToken();
  const response = await axios.put(
    `${fetchReceivedBidsDetailUrl}/receivedbids/update/${payload.id}`,
    data,
    {
      headers,
    }
  );
  return response;
};

/********** Resume API End **********/

// Get Followers //
export const fetchResumeGetfollowersAPI = async (page, perPage) => {
  const headers = await fetchToken();
  const response = await axios.get(
    `${fetchResumeGetfollowersURL}?page=${page}&perPage=${perPage}`,
    { headers }
  );
  return response;
};

// Get Views //
export const fetchResumeGetviewsAPI = async (page, perPage) => {
  const headers = await fetchToken();
  const response = await axios.get(
    `${fetchResumeGetviewsURL}?page=${page}&perPage=${perPage}`,
    { headers }
  );
  return response;
};

//Get Detail //
export const fetchResumeDetailAPI = async () => {
  const headers = await fetchToken();
  const response = await axios.get(`${fetchResumeDetailURL}`, { headers });
  return response;
};

/********** Resume API End **********/

export const fetchHomePageInfoAPI = async (params) => {
  const {
    continueBrowsing_perPage,
    Favorite_perPage,
    Suggested_perPage,
    Editor_perPage,
    Most_PopularMob,
    EditorPicks,
    Suggested,
    ContinueBrowsing,
    Favorites,
    Most_Popular,
    gigsBPage,
    filters = {},
  } = params.payload;
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const queryParams = {
    editorType: EditorPicks,
    editorPage: 1,
    editorPerPage: Editor_perPage,
    suggestType: Suggested,
    suggestPerPage: Suggested_perPage,
    suggestPage: 1,
    continueBType: ContinueBrowsing,
    continueBPage: 1,
    continueBPerPage: continueBrowsing_perPage,
    favoriteType: Favorites,
    favoritePage: 1,
    favoritePerPage: Favorite_perPage,
    mostPopularType: Most_Popular,
    mostPopularPerPage: Most_PopularMob,
    mostPopularPage: 1,
    gigsBPagePerPage: 10,
    gigsBPage: 1,
    ...filters,
  };
  const headers = await fetchToken();
  const response = await axios.get(fetchHomePageDataURL, {
    headers,
    params: queryParams,
  });
  return response;
};

export const fetchHomePageInfoFilterAPI = async (params) => {
  const {
    continueBrowsing_perPage,
    Favorite_perPage,
    Suggested_perPage,
    Editor_perPage,
    Most_PopularMob,
    EditorPicks,
    Suggested,
    ContinueBrowsing,
    Favorites,
    Most_Popular,
    filters = {},
    continueBPage,
    mostPopularPage,
    editorPage,
    suggestPage,
  } = params.payload;
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const queryParams = {
    editorType: EditorPicks,
    editorPage,
    editorPerPage: Editor_perPage,
    suggestType: Suggested,
    suggestPerPage: Suggested_perPage,
    suggestPage,
    continueBType: ContinueBrowsing,
    continueBPage,
    continueBPerPage: continueBrowsing_perPage,
    favoriteType: Favorites,
    favoritePerPage: Favorite_perPage,
    mostPopularType: Most_Popular,
    mostPopularPerPage: Most_PopularMob,
    mostPopularPage,
  };

  const headers = await fetchToken();
  const response = await axios.put(fetchHomePageDataURL, filters, {
    headers,
    params: queryParams,
  });
  return response;
};

// Notification API //
export const UpdateNotificationAPI = async (currentToken) => {
  const data = {
    profile: {
      notificationToken: currentToken,
    },
  };
  // const data = JSON.stringify(payload.body);

  const headers = await fetchToken();
  const response = await axios.put(`${fetchNotificationURL}`, data, {
    headers,
  });
  return response;
};
// export const getToken = () => {

/********* Interview Update Start *********/
export const updateInterviewAPI = async (payload) => {
  // const data = JSON.stringify(payload.body);

  const headers = await fetchToken();

  if (payload.payload.reschedule) {
    return await axios.put(
      `${updateInterviewURL}/${payload.payload.id}`,
      payload.payload.body,
      { headers }
    );
  } else {
    return await axios.put(
      `${updateInterviewURL}/${payload.payload.id}`,
      payload.payload,
      { headers }
    );
  }
  // const response = await axios.put(
  //   `${updateInterviewURL}/${payload.payload.id}`,
  //   payload.payload,
  //   {
  //     headers,
  //   }
  // );
  // return response;/
};

/********* Talent Bid Update Start *********/
export const updateReceivedBidTalentAPI = async (payload) => {
  const headers = await fetchToken();
  const response = await axios.put(`${updateReceivedBidURL}/${payload.id}`, {
    headers,
  });
  return response;
};

export const retractBidUpdateStatus = async (request) => {
  const data = JSON.stringify(request.payload);
  const headers = await fetchToken();
  return await axios.put(`${retractBidUpdate}/${request.payload}`, data, {
    headers,
  });
};

export const createRatingApi = async (request) => {
  const data = JSON.stringify(request.payload);
  const headers = await fetchToken();
  return await axios.post(createRatingUrl, data, { headers });
};

export const getRatingApi = async (request) => {
  const headers = await fetchToken();
  return await axios.get(`${getRatingUrl}/${request.id}?type=${request.type}`, {
    headers,
  });
};

export const updateRatingApi = async (request) => {
  const headers = await fetchToken();

  const response = await axios.put(
    `${updateRatingUrl}/${request.payload?.BidId}`,
    request.payload,
    {
      headers,
    }
  );
  return response;
};

export const deleteRatingAPI = async (request) => {
  const headers = await fetchToken();
  const response = await axios.put(
    `${deleteRatingUrl}/${request.payload.Ratings_id}`,
    request.payload,
    { headers }
  );
  return response;
};
export const downloadResumeAPI = async (payload) => {
  const headers = await fetchToken();
  const response = await axios.get(`${downloadResumeURL}/${payload}`, {
    headers,
  });
  return response;
};
export const MessageAttachmentAPi = async (payload) => {
  const headers = await fetchToken();
  return await axios.post(`${sendAttachments}`, payload, { headers });
};

//********Chats APIs**********//
export const getInboxMessagesAPi = async (payload) => {
  const headers = await fetchToken();
  if (payload?.search) {
    return await axios.get(
      `${getInboxUrl}/${payload.conversationType}/${payload.bidType}/${
        payload.bidStatus
      }?type=${"tal"}&page=${payload.page ?? 1}/&perPage=${
        payload.perPage ?? 5
      }&search=${payload?.search ?? "null"}`,
      { headers }
    );
  }
  return await axios.get(
    `${getInboxUrl}/${payload.conversationType}/${payload.bidType}/${
      payload.bidStatus
    }?type=${"tal"}&page=${payload.page ?? 1}/&perPage=${payload.perPage ?? 5}`,
    { headers }
  );
};
export const getDashboardInboxMessagesAPi = async (payload) => {
  const headers = await fetchToken();
  return await axios.get(
    `${getInboxUrl}/${payload.conversationType}/${payload.bidType}/${
      payload.bidStatus
    }?type=${"tal"}&page=${payload.page}/&perPage=${payload.perPage}`,
    { headers }
  );
};

export const getMsgsCountApi = async (params) => {
  const headers = await fetchToken();
  const response = await axios.get(
    messagesCountUrl,
    // `${messagesCountUrl}/${params ?? "all_bids"}?type=tal`,
    { headers }
  );
  // // const response = {};
  return response;
};

//*******Notifications*******//
// export const getNotificationsAPi = async () => {
//   const headers = await fetchToken();
//   return await axios.get(`${notifications}`, { headers });
// };
export const getNotificationsAPi = async (request) => {
  const headers = await fetchToken();
  return await axios.get(
    `${notifications}/?page=${
      request.payload == undefined ? 1 : request.payload.currentPage
    }&perPage=${request?.payload?.getAll ? request?.payload?.pageSize : 10}`,
    {
      headers,
    }
  );
};

export const getReadNotificationApi = async (id) => {
  const headers = await fetchToken();
  return await axios.put(`${readNotificationUrl}/${id}`, {}, { headers });
};

export const checkUserNameUnique = async (payload) => {
  const response = await axios.post(`${checkUserName}`, payload);
  return response;
};

/********** ViewListing API Start **********/

export const fetchViewListingAPI = async ({ id, page, perPage }) => {
  try {
    const headers = await fetchToken();
    const response = await axios.get(
      `${fetchViewListingURL}${id ? `/${id}` : ""}?page=${page || 1}&perPage=${
        perPage || 24
      }`,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    console.warn(error);
  }
};

/********** ViewListing API End **********/

/********** CompanyProfile API Start **********/

export const fetchCompanyProfileAPI = async (params) => {
  let { impersonationId } = params;
  const headers = fetchHeader(params.talentToken, params.impersonationId);
  const response = await axios.get(
    `${fetchCompanyProfileURL}/${impersonationId}`,
    {
      headers,
    }
  );
  const { MasterAccountId, AccountId } = response.data;
  const accountID = MasterAccountId == null ? AccountId : MasterAccountId;

  const resp = await axios.get(
    `${fetchCompanyProfilejobcountsURL}/${accountID}`,
    {
      headers,
    }
  );
  if (resp && response) {
    response.data.userGigs = resp?.data?.PublishedCounts;
  }
  return response;
};

export const addRemoveEmployerFollowAPI = async (payload) => {
  const { FollowerId, type, talentToken, impersonationId } = payload;
  const headers = fetchHeader(talentToken, impersonationId);
  if (type === "followed")
    return await axios.post(
      `${addRemoveEmployerFollowURL}/add`,
      { FollowerId },
      {
        headers,
      }
    );
  else if (type === "unfollowed")
    return await axios.delete(
      `${addRemoveEmployerFollowURL}/remove/${FollowerId}`,
      {
        headers,
      }
    );
};

export const getJobsGuest = async (data) => {
  const headers = await fetchToken();
  const response = await axios.get(
    `${getJobCountsUrl}?suggestType=${"Suggested"}&suggestPage=${"1"}&suggestPerPage=${"3"}&userId=${data}&email=1`,
    { headers }
  );
  return response;
};

/********** CompanyProfile API End **********/

/// add Update User Device Deatils
export const addUpdateUserDeviceDeatils = async (data) => {
  const headers = await fetchToken();
  await axios.put(`${addUpdateUserDeviceDeatilsURL}`, data, { headers });
};

//company profile gigs
export const fetchCompanyProfileGigsAPI = async (params) => {
  const headers = await fetchToken();
  let { page, pageSize, userId } = params;
  const gigs = axios.get(
    `${fetchCompanyProfileGigsURL}/${page}/${pageSize}?sorttype=undefined&gigtype=Published&userId=${userId}`,
    { headers }
  );
  const gigsCount = axios.get(
    `${fetchCompanyProfileGigsCountURL}?userId=${userId}`,
    { headers }
  );
  const response = await Promise.allSettled([gigs, gigsCount]);
  return response;
};
export const ViewResumeAPI = async (data) => {
  return await axios.get(`${fetchViewResumeURL}/getResumedata/${data}`);
};

export const AdminMessageInboxAPI = async (params) => {
  const headers = await fetchToken();
  return await axios.get(`${AdminMessageInboxUrl}/${params}`, { headers });
};

export const PrivateEmpSuggestionAPI = async (params) => {
  const headers = await fetchToken();
  return await axios.put(
    `${fetchCompanyProfileGigsCountURL}`,
    params?.payload,
    { headers }
  );
};

export const MobileOTPSendAPI = async (params) => {
  const headers = await fetchToken();
  return await axios.put(
    `${MobileNumberOTPSendURL}`,
    { "Profiles.MobilePhone": params?.MobilePhone },
    { headers }
  );
};

export const MobileOTPVerifictionAPI = async (params) => {
  const headers = await fetchToken();
  const data = await axios.put(
    `${MobileNumberOTPVerifyURL}`,
    {
      "Profiles.MobilePhone": params?.MobilePhone,
      otp: params?.Otp,
      WizardFlow: params?.WizardFlow,
    },
    { headers }
  );
  return data;
};

export const userTrackingClickAPI = async (request) => {
  await axios.post(UserTrackingURL, request?.payload);
};
