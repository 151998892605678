import React from "react";
import AlertIcon from "../../../../assets/icons/AlertIcon.svg";
import {formatDateByTimeStamp} from '../../../../shared/dateHelper'
const PlaceBidDisclaimer = ({
  dimensions,
  PlacedBidDate = "",
  CompanyName = "Company Name",
  SkillListingName = "Skill Listing",
}) => {
  return (
    <div
      className="bid-place-div"
      style={{
        margin: dimensions < 769 ? "-24px 0px 5px 0px" : "",
        padding: dimensions < 769 ? "10px 15px 10px 15px" : "",
      }}
    >
      <div>
        <img className="alert-icon" src={AlertIcon} alt="Alert Icon" />
      </div>
      <div
        className="bid-place-text"
        style={{ fontSize: dimensions < 769 ? "12px" : "" }}
      >
        You are not allowed to bid this Gig as you already received a bid from
        this employer! Bid details -{" "}
        <span style={{ fontWeight: "600" }}>{CompanyName}</span>  on {formatDateByTimeStamp(PlacedBidDate) } on your <span style={{ fontWeight: "600", wordBreak: "break-all" }}>
          {" "}
          { SkillListingName}
        </span>
      </div>
    </div>
  );
};

export default PlaceBidDisclaimer;
