import React from "react";
import "./CreateBidButtonIT.css";

const CreateBidButtonIT = (props) => {
  const { handleApply, gig, isExpiredGigs,isEdit,alreadyPlacedBid } = props;
  return (
    <div className="CreateBidButtonIT-button">
      <div className="bottom-details IT-bottom-details">
        <button
          className={`bid-details-apply IT-bid-details-apply ${
            isExpiredGigs ? "is-expired-gigs" : alreadyPlacedBid ? "place-Bid-btn-disabled": ""
          }`}
          onClick={() => {
              handleApply(gig);
          }}
          disabled={alreadyPlacedBid||isExpiredGigs}
        >
          <div>{isEdit && !isExpiredGigs ? "Edit bid" : <h1 className={alreadyPlacedBid ? "text-color-disable" : 
            "text-color-enable"}>Place a bid</h1>}</div>
        </button>
      </div>
    </div>
  );
};

export default CreateBidButtonIT;
