import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { useHistory, useLocation } from "react-router-dom";

import {
  formSubmitSkillListingOne,
  createSkillListing,
  fetchKeySearchDiscipline,
  saveSearchDiscipline,
  updateSkillListing,
  fetchKeySearchLocation,
  saveSearchLocation,
  fetchSearchSkill,
  saveSearchSkill,
  updateProfile,
  fetchOneSkillListing,
  removeOneSkillListingId,
  saveDisplayTitle,
} from "../../redux/actions";

import "./SkillListing.css";

import {
  Form,
  Drawer,
  Input,
  Select,
  Button,
  Spin,
  Divider,
  DatePicker,
  Radio,
  notification,
  Checkbox,
  AutoComplete,
} from "antd";

// icons
import { FaPencilAlt } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa";
import { debounce } from "../../shared/helpers";

import { fetchUserSkillListing } from "../../redux/actions";

import { PlusOutlined } from "@ant-design/icons";

import CalendarIcon from "../../assets/icons/CalendarIcon.svg";

import { alertNotification } from "../../components/notifications/alertNotification";
import _ from "lodash";
import { GoAlert } from "react-icons/go";
import { useUpdateLogger } from "../../shared/useUpdateLogger";
const SkillListingForm = (props) => {
  const dispatch = useDispatch();

  const setUpdateActive = (val) => {
    "setUpdateActive" in props && props.setUpdateActive(val);
  };
  const history = useHistory();
  const { pathname: path, state: redirect } = useLocation();

  const { Option } = Select;
  const [sgTransparency, setSgTransparency] = useState(false);
  const [clientRate, setClientRate] = useState(0);

  const [location, setLocation] = useState([]);

  const [skillFiller, setSkillFiller] = useState({});

  // drop down view
  const [noDataViewDiscipline, setNoDataViewDiscipline] = useState("");
  const [formData, setFormData] = useState({});
  const [noDataViewLocation, setNoDataViewLocation] = useState("");
  const [noDataView, setNoDataView] = useState("");

  const [discipline, setDiscipline] = useState("");

  // Location Drawer for mobile view
  const [locationDrawer, setLocationDrawer] = useState(false);

  const [date, setDate] = useState(null);

  const [selectBefore, setSelectBefore] = useState("Select");

  const [redirection, setRedirection] = useState(false);

  // const [selectedSkills, setSelectedSkills] = useState([]);

  const singleSkillListing = useSelector(
    (state) => state.skillListingReducer?.skillListings
  );
  const { loading } = useSelector((state) => state.profileReducer) || "";
  const oneSkillId = useSelector(
    (state) => state.skillListingReducer.oneSkillId
  );
  const closeLocationDrawer = () => {
    setLocationDrawer(false);
  };
  const profCode = props?.profile?.ProfessionCode;

  const dateFormat = "MMMM DD, YYYY";
  const dateFormatList = ["MMMM DD, YYYY", "MMMM DD, YYYY"];

  const [isUpdateFormData, setIsUpdateFormData] = useState({
    jobType: false,
    DisplayTitle: false,
    roleDiscipline: false,
    desiredLocation: false,
    addYourSkill: false,
    salary: false,
    availableToStart: false,
  });

  const checkIsUpdateForm = () => {
    for (let val in isUpdateFormData) {
      if (isUpdateFormData[val]) {
        return setUpdateActive(isUpdateFormData[val]);
      } else {
        setUpdateActive(isUpdateFormData[val]);
      }
    }
  };

  const updateFormDataFun = (obj) => {
    setIsUpdateFormData((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  useEffect(() => {
    props.saveDisplayTitle(null);
  }, []);

  useEffect(() => {
    checkIsUpdateForm();
  }, [isUpdateFormData]);

  // Form Input
  const dispatcher = (e) => {
    // // 
    // // 
    // debugger;
    if (e.target.name == "jobType" && e.target.value == "Permanent") {
      setSgTransparency(false);
      setSelectBefore("Yearly");
    }
    if (
      e.target.name == "salary" &&
      props.dataForm.jobType !== "Permanent" &&
      (props.dataForm.jobType === "Contract" ||
        props.dataForm.jobType === "Per Diem" ||
        skillFiller?.JobOrderTypes?.JobOrderTypeName === "Contract" ||
        skillFiller?.JobOrderTypes?.JobOrderTypeName === "Per Diem")
    ) {
      // 
      let sal = 0;
      if (e.target.value !== "" && e.target.value >= 0)
        sal = parseInt(e.target.value);

      // const newClientRate = sal / ((100 - 29) / 100);
      const newClientRate = sal * 1.49339;
      setClientRate(newClientRate);
      setSgTransparency(true);
      setSelectBefore("Hourly");
    }
    if (
      e.target.name == "jobType" &&
      (e.target.value == "Contract" || e.target.value == "Per Diem")
    ) {
      // 
      // 
      if (props.dataForm.salary && props.dataForm.salary.length) {
        const sal = parseInt(props.dataForm.salary);
        // const newClientRate = sal / ((100 - 29) / 100);
        const newClientRate = sal * 1.49339;
        setClientRate(newClientRate);
      }
      setSgTransparency(true);
      setSelectBefore("Hourly");
    }
    props.sendFormData(e);
  };

  const submitRef = useRef(null);
  const triggerSubmit = () => submitRef.current.click();

  // redirectionskill;
  useEffect(() => {
    if (oneSkillId && redirection) {
      // history.push(`/skill-listing-detail-page/${oneSkillId}`);
      history.push({
        pathname: `/skill-listing-detail-page/${oneSkillId}`,
        state: { SkillListing: null },
      });
      dispatch(removeOneSkillListingId());
    }
  }, [redirection, oneSkillId]);

  const { step, dataForm, dataForm4 } = props;

  const onFinish = (values) => {
    // 

    // 

    // final number of locations
    
    let locations = [];
    
    if (dataForm4.desiredLocation.length > 0) {
      dataForm4.desiredLocation.map((val) => {
        // // 
        if (val.startsWith("{")) {
          // locations.push({ CityId: JSON.parse(val).CityId });
          locations.push({ Cities: JSON.parse(val) });
        }
        
        skillFiller?.SkillListings_Cities?.filter((loc) => {
          // // 
          if (val === loc?.Cities?.Location) locations.push(loc);
        });
      });
    }

    // final number of skills
    // 
    
    let idSkillArray = [];
    if (dataForm4?.addYourSkill?.length > 0) {
      dataForm4?.addYourSkill?.map((val) => {
        if (val?.SkillId?.startsWith("{")) {
          const theObj = JSON.parse(val?.SkillId);
          idSkillArray.push({
            SkillId: theObj?.SkillId,
            SkillName: theObj?.SkillName,
          });
        } else {
          idSkillArray.push({
            SkillId: null,
            SkillName: val?.SkillId,
          });
          // skillFiller?.SkillListings_Skills?.map((obj) => {
          //   if (obj?.skill?.SkillName === val?.SkillId) {
          //     idSkillArray.push({
          //       SkillId: obj?.skill?.SkillId ? obj?.skill?.SkillId : null,
          //       SkillName: obj?.skill?.SkillName,
          //     });
          //   }
          // });
        }
      });
    } else {
      skillFiller?.SkillListings_Skills?.map((obj) => {
        idSkillArray.push({
          SkillId: obj?.SkillId,
          SkillName: obj?.SkillName,
        });
      });
    }

    // 
    // 
    // 
    // return;

    const GigType =
      dataForm.jobType || skillFiller?.JobOrderTypes?.JobOrderTypeName;
    let MinimumBidType = "";
    let ClientRate = dataForm.salary;
    if (GigType == "Permanent") {
      MinimumBidType = "Year";
    } else if (GigType == "Contract" || GigType == "Per Diem") {
      MinimumBidType = "Hour";
      ClientRate = dataForm.salary * 1.49339;
    }

    if (props.updateId) {
      // // 
      //   Title: dataForm.roleDiscipline || skillFiller.Title,
      //   MinimumBid: dataForm.salary || skillFiller.MinimumBid,
      //   MinimumBidType,
      //   GigType,
      //   ClientRate,
      //   StartDate: new Date(dataForm.availableToStart || skillFiller.StartDate),
      //   SkillListings_Skills:
      //     (idSkillArray.length > 0 ? idSkillArray : null) ||
      //     skillFiller.addYourSkill,
      //   SkillListings_Cities:
      //     (locations.length > 0 ? locations : null) || skillFiller.Location,
      //   _id: props.updateId,
      // });
      props.updateSkillListing({
        DisplayTitle: dataForm.DisplayTitle || skillFiller.DisplayTitle,
        Title: dataForm.roleDiscipline || skillFiller.Title,
        MinimumBid: dataForm.salary || skillFiller.MinimumBid,
        MinimumBidType,
        GigType,
        ClientRate,
        StartDate: new Date(dataForm.availableToStart || skillFiller.StartDate),
        SkillListings_Skills: idSkillArray.length > 0 ? idSkillArray : null,
        SkillListings_Cities:
          (locations.length > 0 ? locations : null) ||
          skillFiller.SkillListings_Cities,
        _id: props.updateId,
      });
      if (path === "/create-skilllisting-signup") {
        props.updateProfileWizard({
          WizardFlow: 4,
        });
        history.push("/resume-upload");
      }
      alertNotification("success", "Skill listing updated successfully");
    } else {
      const idSkillArrayPost = dataForm4?.addYourSkill?.map((val) => {
        if (val?.SkillId.startsWith("{")) {
          const theObj = JSON.parse(val?.SkillId);
          return { SkillId: theObj?.SkillId, SkillName: theObj?.SkillName };
        } else {
          return { SkillId: null, SkillName: val?.SkillId };
        }
      });

      props.postSkillListing({
        DisplayTitle: dataForm.DisplayTitle,
        Title: dataForm.roleDiscipline,
        MinimumBid: dataForm.salary,
        MinimumBidType,
        GigType,
        ClientRate,
        StartDate: new Date(dataForm.availableToStart),
        SkillListings_Skills: idSkillArrayPost,
        SkillListings_Cities: locations,
        fromSignUp: path === "/create-skilllisting-signup",
      });
      alertNotification("success", "Skill listing created successfully");
      if (path === "/create-skilllisting-signup") {
        props.updateProfileWizard({
          WizardFlow: 4,
        });
        history.push("/resume-upload");
      } else {
        // redirectionskill
        setRedirection(true);
      }
      // else if (redirect) history.push(redirect);
      // else history.push("/manageskilllisting");
    }
    props.handleUpdateSkillListing(false);
  };

  // useUpdateLogger("dataForm.DisplayTitle", dataForm);

  const onSkillSearch = (val) => {
    const profCode = props?.profile?.ProfessionCode;
    let result = val
      .replaceAll("%", "%25")
      .replaceAll("#", "%23")
      .replaceAll("*", "%2A")
      .replaceAll("+", "%2B")
      .replaceAll("/", "%2F")
      .replaceAll(",", "%2C")
      .replaceAll(".", "%2E")
      .replaceAll("-", "%2D")
      .replaceAll("&", "%26")
      .replaceAll("'", "%27")
      .replaceAll("?", "%3F")
      .replaceAll("!", "%21")
      .replaceAll('"', "%22")
      .replaceAll("$", "%24")
      .replaceAll("(", "%28")
      .replaceAll(")", "%29")
      .replaceAll(":", "%3A")
      .replaceAll(";", "%3B")
      .replaceAll("=", "%3D")
      .replaceAll("@", "%40")
      .replaceAll("[", "%5B")
      .replaceAll("]", "%5D")
      .replaceAll("newline", "%0A")
      .replaceAll("space", "%20")
      .replaceAll("<", "%3C")
      .replaceAll(">", "%3E")
      .replaceAll("^", "%5E")
      .replaceAll("_", "%5F")
      .replaceAll("\\", "%5C")
      .replaceAll("`", "%60")
      .replaceAll("{", "%7B")
      .replaceAll("}", "%7D")
      .replaceAll("~", "%7E")
      .replaceAll("|", "%7C")
      .replaceAll("£", "%C2%A3");
    setNoDataView(val);
    props.searchSkills({ val: result, profCode });
  };

  const getSearchSkill = (value) => {
    // 
    const truncatedValues = value.map((val) =>
      val?.startsWith("{") ? val : val.slice(0, 50)
    );
    // setSelectedSkills(truncatedValues);

    props.editModalState &&
    truncatedValues &&
    _.isEmpty(_.xor(truncatedValues, formData.addYourSkill))
      ? updateFormDataFun({ addYourSkill: false })
      : updateFormDataFun({ addYourSkill: true });
    if (truncatedValues) {
      props.saveSearchSkill(truncatedValues.map((val) => ({ SkillId: val })));
    }
  };

  function onSearch(val) {
    const profCode = props?.profile?.ProfessionCode;
    // 
    // 

    setDiscipline(val);
    setNoDataViewDiscipline(val);
    props.sendKeyDiscipline({ val: val, profCode });
  }

  const saveDisplayTitle = (e) => {
    const value = e.target.value;
    // 
    props.editModalState && value && value === formData.DisplayTitle
      ? updateFormDataFun({ DisplayTitle: false })
      : updateFormDataFun({ DisplayTitle: true });
    if (value) {
      props.saveDisplayTitle(value);
    }
  };

  const saveSearchDiscipline = (value) => {
    props.editModalState && value && value === formData.roleDiscipline
      ? updateFormDataFun({ roleDiscipline: false })
      : updateFormDataFun({ roleDiscipline: true });
    // 
    if (value) {
      props.saveSearchDiscipline(value);
    }
  };

  function onSearchLocation(val) {
    // 
    // 

    setNoDataViewLocation(val);
    props.sendKeyLocation(val);
  }

  const getSearchLocation = (value) => {
    // 
    props.editModalState &&
    value &&
    _.isEmpty(_.xor(value, formData.desiredLocation))
      ? updateFormDataFun({ desiredLocation: false })
      : updateFormDataFun({ desiredLocation: true });
    if (value) {
      setLocation(value);
      props.saveSearchLocation(value);
    }
  };

  // ADD ITEM DISCIPLINE LOGIC
  const onNameChange = (e) => {
    setDiscipline(e.target.value);
  };

  const addItem = () => {
    props.searchedDiscipline.push({ Name: discipline });
    setDiscipline(null);
  };

  useEffect(() => {
    // 
  }, [props.profile]);

  const [form] = Form.useForm();

  // Edit form for particular skill Listing data
  useEffect(() => {
    // debugger;
    const skills = props?.formSkills?.skillListings?.data;
    if (skills && skills.length > 0) {
      const skill = skills?.filter((el) => el._id === props.updateId)[0];
      // 
      setSkillFiller(skill);
    }
    // 
    // if (props.idSkill) {
    //   dispatch(fetchOneSkillListing(props.idSkill));
    // }
  }, [props.editModalState, props.updateId]);


  useEffect(() => {
    if (singleSkillListing?.data?.[0]?.Created && props?.idSkill) {
      setSkillFiller(singleSkillListing?.data?.[0]);

      return () => {
        setSkillFiller({});
        setFormData({});
      };
    }

    // 
    // 
  }, [singleSkillListing]);

  let defaultValues;
  const Locations = [];
  const nameSkillArr = [];
  // this is to update form default values to the latest skillisting values defined
  useEffect(() => {
    if (props.updateId) {
      skillFiller?.SkillListings_Cities?.map((loc) => {
        loc?.Cities?.Location && Locations.push(`${loc?.Cities?.Location}`);
      });

      // 
      //   "skillFiller?.SkillListings_Skills",
      //   skillFiller?.SkillListings_Skills
      // );

      skillFiller?.SkillListings_Skills?.map((obj) => {
        // // 
        obj?.SkillName && nameSkillArr.push(obj?.SkillName);
      });
      // // 
      defaultValues = {
        DisplayTitle: skillFiller?.DisplayTitle
          ? skillFiller?.DisplayTitle
          : null,
        roleDiscipline: skillFiller?.Title ? skillFiller?.Title : null,
        jobType: skillFiller?.JobOrderTypes?.JobOrderTypeName,
        desiredLocation: Locations,
        addYourSkill: nameSkillArr,
        salary: skillFiller?.MinimumBid,
        billedClient: skillFiller?.ClientRate?.$numberDecimal
          ? skillFiller?.ClientRate?.$numberDecimal
          : skillFiller?.ClientRate
          ? skillFiller?.ClientRate.toFixed(2)
          : 0,
        availableToStart: skillFiller?.StartDate
          ? moment(skillFiller?.StartDate)
          : undefined,
      };
      setFormData(defaultValues);
    }
    form.setFieldsValue(defaultValues);

    // 

    dispatcher({
      target: {
        name: "salary",
        value: skillFiller?.MinimumBid,
      },
    });

    // on reload it activates the onChange and able to convert Desired salary to client rate
    if (skillFiller?.JobOrderTypes?.JobOrderTypeName === "Contract") {
      dispatcher({
        target: {
          name: "jobType",
          value: "Contract",
        },
      });
    }
    if (skillFiller?.JobOrderTypes?.JobOrderTypeName === "Permanent") {
      dispatcher({
        target: {
          name: "jobType",
          value: "Permanent",
        },
      });
    }
    if (skillFiller?.JobOrderTypes?.JobOrderTypeName === "Per Diem") {
      dispatcher({
        target: {
          name: "jobType",
          value: "Per Diem",
        },
      });
    }

    // set Addon and Billed to Client view
    // if (
    //   skillFiller?.JobOrderTypes?.JobOrderTypeName === "Contract" ||
    //   skillFiller?.JobOrderTypes?.JobOrderTypeName === "Per Diem"
    // ) {
    //   setSgTransparency(true);
    //   setSelectBefore("Hourly");
    //   // return;
    // } else if (skillFiller?.JobOrderTypes?.JobOrderTypeName === "Permanent") {
    //   setSgTransparency(false);
    //   setSelectBefore("Yearly");
    //   // return;
    // }

    // 
  }, [skillFiller]);

  useEffect(() => {
    defaultValues = {
      billedClient: clientRate?.$numberDecimal
        ? clientRate?.$numberDecimal
        : clientRate
        ? clientRate.toFixed(2)
        : 0,
    };
    form.setFieldsValue(defaultValues);
  }, [clientRate]);

  useEffect(() => {
    if (props?.getSubmitUpdate) {
      props.getSubmitUpdate(submitRef);
    }
    // not letting Update skill Listing hinder loader on manage skillisting page by fetching user skill Listing
    if (props.updateId) return;
  }, []);

  // useEffect(() => {
  //   // 
  //   // 
  //   // 
  // }, [props.searchLoading, props.skillsLoader, noDataViewLocation]);

  // useEffect(() => {
  //   setSearchedSkills([...searchedSkill, ...props.skills]);
  // }, [props.skills]);

  // useUpdateLogger("skillFiller:dataform", dataForm);

  // const trackingID = process.env.REACT_APP_ADWORDS_ID;
  // const gtag_report_conversion_Skill_listing_page = async () => {
  //   try {
  //     ReactGA.event({
  //       category: "conversion",
  //       action: "send_to",
  //       label: `${trackingID}/Q5qGCOPZvYcYENu60M8D`,
  //     });
  //     return false;
  //   } catch (e) {
  //     
  //   }
  // };

  return (
    <div
      className={`skillListingOne ${
        path === "/create-skilllisting" ||
        path === "/create-skilllisting-signup"
          ? "skillListingOnePadding"
          : "skillListingModalPadding"
      }`}
    >
      {path === "/create-skilllisting" ||
      path === "/create-skilllisting-signup" ? (
        <>
          <div className="headSkillisting">Create your skill listing</div>
          <div className="Sub-headSkillisting">
            For more interaction with employers
          </div>
        </>
      ) : null}
      <Form
        name="normal_login"
        className="login-form skillListing-form"
        form={form}
        initialValues={defaultValues}
        onFinish={onFinish}
        preserve={false}
      >
        <Form.Item
          className="inputLabel"
          name="jobType"
          label={
            <div className="inputTitleSkillisting">
              Looking for - gig type?<span className="reqSkillisting">*</span>
            </div>
          }
          rules={[
            {
              required: true,
              message: "choose a type of gig you're looking for",
            },
          ]}
        >
          <Radio.Group
            style={{ marginTop: 7 }}
            name="jobType"
            onChange={(e) => {
              props.editModalState && e.target.value === formData.jobType
                ? updateFormDataFun({ jobType: false })
                : updateFormDataFun({ jobType: true });
              dispatcher({
                target: {
                  name: "jobType",
                  value: e.target.value,
                },
              });
            }}
          >
            <Radio.Button value="Permanent" className="permanent">
              <div className="fontJobType">Permanent</div>
            </Radio.Button>
            <Radio.Button value="Contract" className="Contract">
              <div className="fontJobType">Contract</div>
            </Radio.Button>
            {profCode === "HC" && (
              <Radio.Button value="Per Diem" className="PerDiem">
                <div className="fontJobType">Per diem</div>
              </Radio.Button>
            )}
          </Radio.Group>
        </Form.Item>
        {/* <Form.Item
          name="DisplayTitle"
          label={
            <div className="inputTitleSkillisting">
              Add your title
              <span className="reqSkillisting">*</span>
            </div>
          }
          className="inputLabel"
          rules={[
            {
              required: true,
              message: () => (
                <div>
                  <span>Add a skill listing title of the role you want</span>
                  <GoAlert className="ErrorIcon" />
                </div>
              ),
            },
            {
              pattern: /^(?!\s)[a-zA-Z\s()-]+$/,
              message: "The input is not a valid!",
            },
            {
              pattern: /^.{1,50}$/,
              message: "The input is not a valid!",
            },
          ]}
        >
          <Input
            placeholder="Add your title here..."
            className="display-title-input"
            onChange={saveDisplayTitle}
          />
        </Form.Item> */}
        <Form.Item
          name="roleDiscipline"
          label={
            <div className="inputTitleSkillisting">
              Title
              <span className="reqSkillisting">*</span>
            </div>
          }
          className="inputLabel"
          rules={[
            {
              required: true,
              message: () => (
                <div>
                  <span>Add a skill listing title of the role you want</span>
                  <GoAlert className="ErrorIcon" />
                </div>
              ),
            },
          ]}
        >
          {/*<Select
            notFoundContent={
              noDataViewDiscipline?.length > 0 ? (
                <div>
                  {props.disciplineLoader ||
                  props.searchedDiscipline.length > 0 ? (
                    <Spin size="small" />
                  ) : (
                    "No data found!"
                  )}
                </div>
              ) : null
            }
            name="roleDiscipline"
            className="dropCss"
            bordered={false}
            placeholder={
              <div className="placeholderSkillListingFormOne">
                Add your Role...
                {/* Add your title here... 
              </div>
            }
            onSearch={debounce((val) => onSearch(val))}
            onChange={saveSearchDiscipline}
            showSearch
            dropdownRender={(menu) => (
              <div>
                {menu}
                {!props.searchedDiscipline?.length > 0 ? (
                  <>
                    <Divider style={{ margin: "4px 0" }} />
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        padding: 8,
                      }}
                    >
                      <Input
                        style={{ flex: "auto" }}
                        value={discipline}
                        onChange={onNameChange}
                      />
                      <a
                        style={{
                          flex: "none",
                          padding: "8px",
                          display: "block",
                          cursor: "pointer",
                        }}
                        onClick={addItem}
                      >
                        <PlusOutlined /> Add item
                      </a>
                    </div>
                  </>
                ) : null}
              </div>
            )}
          >
            {props.searchedDiscipline &&
              props?.searchedDiscipline
                ?.filter((discipline) => discipline?.Name?.length > 0)
                ?.map((discipline) => (
                  <Option value={discipline?.Name} key={discipline?._id}>
                    {discipline?.Name}
                  </Option>
                ))}
          </Select>*/}
          <AutoComplete
            bordered={false}
            className="dropCss"
            name="roleDiscipline"
            onChange={debounce((val) => saveSearchDiscipline(val))}
            onSearch={debounce((val) => onSearch(val))}
            placeholder={
              <div className="placeholderSkillListingFormOne">
                Add your title here...
                {/* Add your title here... */}
              </div>
            }
            notFoundContent={
              noDataViewDiscipline?.length > 0 &&
              (props.disciplineLoader ||
                props.searchedDiscipline.length > 0) ? (
                <div>
                  <Spin size="small" />
                </div>
              ) : null
            }
            showSearch
          >
            {props.searchedDiscipline &&
              props?.searchedDiscipline
                ?.filter((discipline) => discipline?.Name?.length > 0)
                ?.map((discipline) => (
                  <Option value={discipline?.Name} key={discipline?._id}>
                    {discipline?.Name}
                  </Option>
                ))}
          </AutoComplete>
        </Form.Item>
        <Form.Item
          label={
            <div className="inputTitleSkillisting">
              Where are you open to work?
              <span className="reqSkillisting">*</span>
            </div>
          }
          name="desiredLocation"
          className="inputLabel"
          rules={[
            {
              required: true,
              message: () => (
                <div>
                  <span>Please select one or more Locations!</span>
                  <GoAlert className="ErrorIcon" />
                </div>
              ),
            },
          ]}
        >
          <Select
            notFoundContent={
              noDataViewLocation?.length > 0 ? (
                <div>
                  {props.searchLoading || props.searchedLocation.length > 0 ? (
                    <Spin size="small" />
                  ) : (
                    "No data found!"
                  )}
                </div>
              ) : null
            }
            name="desiredLocation"
            placeholder={
              <div className="placeholderSkillListingForm">
                For e.g. Houston, TX
              </div>
            }
            className="dropCss"
            mode="multiple"
            bordered={false}
            onSearch={debounce((val) => onSearchLocation(val))}
            onChange={getSearchLocation}
            onClick={() => {
              if (window.innerWidth < 768) {
                setLocationDrawer(true);
              }
            }}
            showSearch
            listHeight={160}
          >
            {props.searchedLocation?.length > 0 &&
              props.searchedLocation?.map((city) => (
                <Option
                  value={JSON.stringify(city)}
                  key={city.LocationWithCountry}
                >
                  {city.LocationWithCountry}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          className="inputLabel"
          name="addYourSkill"
          label={
            <div className="inputTitleSkillisting">
              Add your top skill(s)<span className="reqSkillisting">*</span>
              <div className="helpAddSkill">
                List your top skills that’ll reflect how you’ll succeed in the
                gig you want.
              </div>
            </div>
          }
          rules={[
            {
              required: true,
              message: () => (
                <div>
                  <span>
                    Add some of your top skill(s) (e.g. Critical Care...)
                  </span>
                  <GoAlert className="ErrorIcon" />
                </div>
              ),
            },
          ]}
        >
          <Select
            notFoundContent={
              noDataView?.length > 0 ? (
                <div>
                  {props.skillsLoader || props.skills.length > 0 ? (
                    <Spin size="small" />
                  ) : (
                    "No data found!"
                  )}
                </div>
              ) : null
            }
            name="addYourSkill"
            placeholder={
              <div className="placeholderSkillListingForm">
                Add one or more skills
              </div>
            }
            className="dropCss"
            // mode="multiple"
            mode="tags"
            bordered={false}
            style={{ marginTop: 16 }}
            onSearch={debounce((val) => onSkillSearch(val))}
            onChange={getSearchSkill}
            // value={selectedSkills}
            showSearch
          >
            {props.skills?.length > 0 &&
              props.skills?.map((skill) => (
                <Option
                  // value={`${skill.SkillName}`}
                  key={`${skill?.SkillName}`}
                  value={JSON.stringify(skill)}

                  // key={skill.SkillId}
                >
                  {skill?.SkillName}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item className="wrapSalaryDate">
          {/* {props.profile.ProfessionCode !== "IT" && ( */}
          <Form.Item
            name="salary"
            label={
              <div className="inputTitleSkillisting">
                {props.profile.ProfessionCode === "IT" &&
                selectBefore === "Yearly"
                  ? "Desired Annual Salary?"
                  : props.profile.ProfessionCode === "IT" &&
                    selectBefore === "Hourly"
                  ? "Desired Pay Rate"
                  : "Desired salary"}
                <span className="reqSkillisting">*</span>
              </div>
            }
            className="inputLabel"
            rules={[
              {
                required: true,
                message:
                  props.profile.ProfessionCode === "IT" &&
                  selectBefore === "Yearly"
                    ? "Tell employers your desired annual salary"
                    : props.profile.ProfessionCode === "IT" &&
                      selectBefore === "Hourly"
                    ? "Tell employers your desired pay"
                    : "Tell employers your desired pay",
              },
              {
                pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                message: "The input is not a valid!",
              },
            ]}
          >
            <Input
              addonBefore={<div>{selectBefore}</div>}
              prefix="$"
              // placeholder={`0`}
              placeholder={
                props.profile.ProfessionCode === "IT" &&
                selectBefore === "Yearly"
                  ? "Enter your desired annual salary"
                  : props.profile.ProfessionCode === "IT" &&
                    selectBefore === "Hourly"
                  ? "Enter your desired Pay rate"
                  : `0`
              }
              type="number"
              className="site-form-item-icon inputSalaryCss"
              name="salary"
              min={1}
              onKeyPress={(e) => {
                if (!(Number(e.target.value) < 1000000)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                props.editModalState &&
                Number(e.target.value) === Number(formData.salary)
                  ? updateFormDataFun({ salary: false })
                  : updateFormDataFun({ salary: true });
                dispatcher(e);
              }}
              bordered={false}
            />
          </Form.Item>
          {/* )} */}
          <Form.Item
            name="availableToStart"
            className="inputLabel"
            label={
              <div className="inputTitleSkillisting">
                When are you available to start?
                <span className="reqSkillisting">*</span>
              </div>
            }
            rules={[
              {
                required: true,
                message: "Let employers know when you’re available",
              },
            ]}
          >
            <DatePicker
              disabledDate={(current) => {
                return current && current.valueOf() < Date.now();
              }}
              placeholder="MM DD, YYYY"
              format={dateFormatList}
              // className={
              //   props.profile.ProfessionCode !== "IT"
              //     ? "sl-datepicker"
              //     : "sl-datepicker-it"
              // }
              className={"sl-datepicker"}
              name="availableToStart"
              inputReadOnly={true}
              onChange={(date, dateString) => {
                props.editModalState &&
                skillFiller?.StartDate &&
                moment(dateString).format() ===
                  moment(formData.availableToStart._i).format()
                  ? updateFormDataFun({ availableToStart: false })
                  : updateFormDataFun({ availableToStart: true });
                dispatcher({
                  target: {
                    name: "availableToStart",
                    value: dateString,
                  },
                });
              }}
              suffixIcon={<img src={CalendarIcon} alt="Calender" />}
            />
          </Form.Item>
          {/* {sgTransparency && (
            <Form.Item
              name="billedClient"
              label={
                <div className="inputBilltoClientLabel">Billed to Client</div>
              }
              className="inputLabel"
            >
              <Input
                prefix="$"
                placeholder={`0`}
                type="number"
                className="site-form-item-icon inputSalaryCss"
                name="billedClient"
                bordered={false}
                addonBefore={selectBefore}
                disabled
              />
            </Form.Item>
          )} */}
        </Form.Item>
        <Form.Item style={{ display: "none" }}>
          <Button ref={submitRef} type="submit" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
      {path === "/create-skilllisting" && (
        <section className="finishSkillisting">
          <div onClick={triggerSubmit}>Create skill listing</div>
        </section>
      )}
      {path === "/create-skilllisting-signup" && (
        <div>
          <Divider className="skilllisting-signup-divider" />
          <div className="roleExperienceNew-Button-section skilllisting-signup-margin">
            <div
              onClick={() => {
                if (loading) return;
                history.push("/role-experience");
              }}
              // className="roleExperienceNew-Button-Back"
              className={`roleExperienceNew-Button-Back ${
                loading ? "back-click-disable click-disable" : "clickable"
              } `}
            >
              Back
            </div>
            <Button
              type="primary"
              htmlType="submit"
              onClick={(e) => {
                // gtag_report_conversion_Skill_listing_page();
                triggerSubmit();
              }}
              className="roleExperienceNew-Button-Back-Next"
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.credentialingReducer,
    dataForm: state.credentialingReducer.step3,
    formSkills: state.skillListingReducer,
    searchedDiscipline: state.skillListingReducer.searchedDiscipline,
    disciplineLoader: state.skillListingReducer.disciplineLoader,
    dataForm4: state.credentialingReducer.step4,
    skills: state.skillListingReducer.skills,
    currentSkillListing: state.skillListingReducer.currentSkillListing,
    skillsLoader: state.skillListingReducer.skillLoader,
    searchedLocation: state.credentialingReducer.searchedLocation,
    searchLoading: state.credentialingReducer.searchLoading,
    profile: state.profileReducer.user.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendFormData: (e) => {
      dispatch(formSubmitSkillListingOne(e));
    },
    postSkillListing: (params) => {
      dispatch(createSkillListing(params));
    },
    updateSkillListing: (params) => {
      dispatch(updateSkillListing(params));
    },
    sendKeyDiscipline: (e) => {
      dispatch(fetchKeySearchDiscipline(e));
    },
    sendKeyLocation: (e) => {
      dispatch(fetchKeySearchLocation(e));
    },
    searchSkills: (params) => {
      dispatch(fetchSearchSkill(params));
    },
    saveSearchDiscipline: (e) => {
      dispatch(saveSearchDiscipline(e));
    },
    saveDisplayTitle: (e) => {
      dispatch(saveDisplayTitle(e));
    },
    saveSearchLocation: (value) => {
      dispatch(saveSearchLocation(value));
    },
    saveSearchSkill: (value) => {
      dispatch(saveSearchSkill(value));
    },
    getSkillListing: () => {
      dispatch(fetchUserSkillListing());
    },
    updateProfileWizard: (value) => {
      dispatch(updateProfile(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillListingForm);
