import React, { useEffect, useState } from "react";
import moment from "moment";
import "./GigExpiresIT.css";

const GigExpiresIT = (props) => {
  const { gig, isExpiredGigs = false } = props;
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (gig?.ExpiryDate) {
      const now = moment.utc();
      const then = moment.utc(
        new Date(Number(gig?.ExpiryDate)),
        "MMMM DD, YYYY hh:mm"
      );
      const countdown = moment.utc(then - now);
      setDays(moment(then).diff(now, "days"));
      setHours(countdown.format("HH"));
      setMinutes(countdown.format("mm") % 60);
    }
  }, [gig?.ExpiryDate, seconds]);

  useEffect(() => {
    setTimeout(() => {
      setSeconds(seconds + 1);
    }, 10000);
  }, [seconds]);

  return (
    <div className="GigExpiresIT-Cantainer">
      <div className="Skill-PieChart-Component">
        <div>Gig expires in</div>
        <div className="GigExpiresIT-Timer">
          <div>
            <span className="GigExpiresIT-Timer-Left">
              {isExpiredGigs ? "-" : Math.abs(days)}
            </span>
            <span className="GigExpiresIT-Timer-Unit">Days</span>
          </div>
          <div>
            <span className="GigExpiresIT-Timer-Left">
              {isExpiredGigs ? "-" : Math.abs(hours)}
            </span>
            <span className="GigExpiresIT-Timer-Unit">Hours</span>
          </div>
          <div>
            <span className="GigExpiresIT-Timer-Left">
              {isExpiredGigs ? "-" : Math.abs(minutes)}
            </span>
            <span className="GigExpiresIT-Timer-Unit">Mins</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GigExpiresIT;
