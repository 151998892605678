import React from "react";
import UploadDocV2 from "../../UploadDoc";

const BLSDocv2 = () => {
  const docInfo = [
    {
      "BLS - Basic life support - front": 2,
    },
    {
      "BLS - Basic life support - back": 81,
    },
  ];
  const styleContainer = {
    width :"337px"
  }

  return (
    <div className="acls-doc-container">
      <UploadDocV2
        docInfo={docInfo}
        backUrl="state-lisence-doc"
        nextUrl="acls-doc"
        currentPage={2}
        WizardFlow={113}
        IsMandatory={true}
        skipUrl={"acls-doc"}
        style={styleContainer}
      />
    </div>
  );
};

export default BLSDocv2;
