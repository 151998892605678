import React from 'react'
import LicenseNumber from '../license/LicenseNumber'
const DriversLicense = () => {
  return (
    <><LicenseNumber
    heading = "Verify your identity, Facility required"
    label="Driver’s license"
    licenseText="Enter your license number & earn + 0.5 rating to your profile"
    StateLicense="Your Driver’s License is secure with SkillGigs"
     inputText="Your driver’s license verified."
/></>
  )
}

export default DriversLicense;