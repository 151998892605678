import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import {
  MobileOTPSendAPI,
  MobileOTPVerifictionAPI,
  approveLinkedInUserAPI,
  fetchToken,
  sendEmailVerificationStatusAPI,
} from "../../API/API";
import { getCookie, setCookie } from "../../shared/helpers";
import { fetchVerifictionOTPFailure, linkedInTokenFail, updateImpersonationIdSuccess, updateSignupProfileSuccess } from "../actions";
import { ProfileAPIResponseStructure } from "../../assets/helpers";

function* authSaga(action) {
  // // 
  yield put({ type: types.UPDATE_USER_INFO_SUCCESS, payload: action.payload });
}

// catches the action before going to the reducer and run the saga attached to it
export function* watchAuthSaga() {
  yield takeEvery(types.UPDATE_USER_INFO_INIT, authSaga);
}

// Linked In Saga

function* linkedInSaga(action) {
  // 
  try {
    const response = yield call(() => approveLinkedInUserAPI(action.payload)); // API Backend
    // 
    setCookie("talentToken", response.data.token, true);
    yield put({
      type: types.UPDATE_USER_INFO_SUCCESS,
      payload: response.data.token,
    });
  } catch (error) {
    // 
    yield put(linkedInTokenFail(error.response.data));
  }
  // yield put({ type: types.LINKEDIN_TOKEN_FETCH, payload: action.payload });
}
export function* watchLinkedInSaga() {
  yield takeEvery(types.LINKEDIN_TOKEN_INIT, linkedInSaga);
}

// email verification saga
function* sendEmailVerificationSaga(action) {
  // 
  try {
    const response = yield call(() =>
      sendEmailVerificationStatusAPI(action.payload)
    ); // API Backend
    // 
    // yield put(getEmailVerificationStatusSuccess(response?.data?.data));
  } catch (error) {
    // 
    // yield put(getEmailVerificationStatusFailure(response?.data?.data));
  }
  // yield put({ type: types.LINKEDIN_TOKEN_FETCH, payload: action.payload });
}

export function* watchSendEmailVerificationSaga() {
  yield takeEvery(
    types.SEND_EMAIL_VERIFICATION_INIT,
    sendEmailVerificationSaga
  );
}

// IMPERSONATION SAGA
// function* impersonationSaga(action) {
//   // 

//   yield put(updateImpersonationIdSuccess(error.response.data));

//   // yield put({ type: types.LINKEDIN_TOKEN_FETCH, payload: action.payload });
// }

// export function* watchImpersonationSaga() {
//   yield takeEvery(types.UPDATE_IMPERSONATION_ID_INIT, impersonationSaga);
// }

function* mobileOTPSendInSaga(action) {
  try {
    // const response = yield call(() => MobileOTPSendAPI(action.payload)); // API Backend
    // yield put({
    //   type: types.MOBILE_OTP_SEND_SUCCESS,
    //   payload: response,
    // });
  } catch (error) {
    yield put({
      type: types.MOBILE_OTP_SEND_FAILURE,
      error
    });
  }
}

export function* watchSendMobileOTPVerificationSaga() {
  yield takeEvery(
    types.MOBILE_OTP_SEND_INIT,
    mobileOTPSendInSaga
  );
}

function* mobileOTPVerificationInSaga(action) {
  try {
    const response = yield call(() => MobileOTPVerifictionAPI(action.payload)); // API Backend
    yield put({
      type: types.MOBILE_OTP_VERIFICATION_SUCCESS,
      payload: true,
    });
    yield put(
      updateSignupProfileSuccess(
        ProfileAPIResponseStructure(response?.data)?.data?.body?.user
    ))
  } catch (error) {
    yield put(fetchVerifictionOTPFailure(false));
  }
}

export function* watchSendMobileOTPCheckSaga() {
  yield takeEvery(
    types.MOBILE_OTP_VERIFICATION_INIT,
    mobileOTPVerificationInSaga
  );
}
