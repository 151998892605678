import React from "react";
// import Assessment from "./AssessmentVideo/Assessment";
import AssesmentFaqExam from "./AssessmentVideo/AssesmentExam";
// import TutorialVideo from "./AssessmentVideo/TutorialVideo";
import { useSelector } from "react-redux";
import DashBoardProfile from "./ProfileSectionV2.2/DashBoardProfileV2.2";

const RightSectionDashboard = () => {
  const user = useSelector((state) => state.profileReducer.user);
  const mobileView = window.innerWidth < 1000;
  return (
    <div className="rightSectionDashboard">
      <div>
        <DashBoardProfile />
      </div>
      {/* <div className="relias-assesment-exam-section"> */}
      {user?.profile?.ProfessionCode == "HC" ? (
        <AssesmentFaqExam
          url={"https://app.healthcarestaffinghire.com/?redirectUrl=/admin/users"}
          labelName={"Required clinical assessment exam"}
          bgColor={"#f6eed1"}
          hoverBgColor="#faf1d2"
        />
      ) : (
        <></>
      )}
      {!mobileView ? (
        <AssesmentFaqExam
          url={"https://skillgigs.com/frequently-asked-questions/#job-seeker"}
          labelName={"FAQ's"}
          bgColor={"#fff"}
          hoverBgColor="#f6f6f6"
        />
      ) : (
        <></>
      )}
      {/* </div> */}
      {/* <div className="tutorial-video-section"> */}
      {/* {mobileView && <TutorialVideo /> } */}
      {/* </div> */}
    </div>
  );
};

export default RightSectionDashboard;
