export const getFullStateName = name => {
    const data = {
        Bangladesh: 'BD',
        Belgium: 'BE',
        'Burkina Faso': 'BF',
        Bulgaria: 'BG',
        'Bosnia and Herzegovina': 'BA',
        Barbados: 'BB',
        'Wallis and Futuna': 'WF',
        ' Saint Barthelemy': 'BL',
        Bermuda: 'BM',
        Brunei: 'BN',
        Bolivia: 'BO',
        Bahrain: 'BH',
        Burundi: 'BI',
        Benin: 'BJ',
        Bhutan: 'BT',
        Jamaica: 'JM',
        'Bouvet Island': 'BV',
        Botswana: 'BW',
        Samoa: 'WS',
        'Bonaire, Saint Eustatius and Saba': 'BQ',
        Brazil: 'BR',
        Bahamas: 'BS',
        Jersey: 'JE',
        Belarus: 'BY',
        Belize: 'BZ',
        Russia: 'RU',
        Rwanda: 'RW',
        Serbia: 'RS',
        'East Timor': 'TL',
        Reunion: 'RE',
        Turkmenistan: 'TM',
        Tajikistan: 'TJ',
        Romania: 'RO',
        Tokelau: 'TK',
        'Guinea-Bissau': 'GW',
        Guam: 'GU',
        Guatemala: 'GT',
        'South Georgia and the South Sandwich Islands': 'GS',
        Greece: 'GR',
        'Equatorial Guinea': 'GQ',
        Guadeloupe: 'GP',
        Japan: 'JP',
        Guyana: 'GY',
        Guernsey: 'GG',
        'French Guiana': 'GF',
        Georgia: 'GE',
        Grenada: 'GD',
        'United Kingdom': 'GB',
        Gabon: 'GA',
        'El Salvador': 'SV',
        Guinea: 'GN',
        Gambia: 'GM',
        Greenland: 'GL',
        Gibraltar: 'GI',
        Ghana: 'GH',
        Oman: 'OM',
        Tunisia: 'TN',
        Jordan: 'JO',
        Croatia: 'HR',
        Haiti: 'HT',
        Hungary: 'HU',
        'Hong Kong': 'HK',
        Honduras: 'HN',
        'Heard Island and McDonald Islands': 'HM',
        Venezuela: 'VE',
        'Puerto Rico': 'PR',
        'Palestinian Territory': 'PS',
        Palau: 'PW',
        Portugal: 'PT',
        'Svalbard and Jan Mayen': 'SJ',
        Paraguay: 'PY',
        Iraq: 'IQ',
        Panama: 'PA',
        'French Polynesia': 'PF',
        'Papua New Guinea': 'PG',
        Peru: 'PE',
        Pakistan: 'PK',
        Philippines: 'PH',
        Pitcairn: 'PN',
        Poland: 'PL',
        'Saint Pierre and Miquelon': 'PM',
        Zambia: 'ZM',
        'Western Sahara': 'EH',
        Estonia: 'EE',
        Egypt: 'EG',
        'South Africa': 'ZA',
        Ecuador: 'EC',
        Italy: 'IT',
        Vietnam: 'VN',
        'Solomon Islands': 'SB',
        Ethiopia: 'ET',
        Somalia: 'SO',
        Zimbabwe: 'ZW',
        'Saudi Arabia': 'SA',
        Spain: 'ES',
        Eritrea: 'ER',
        Montenegro: 'ME',
        Moldova: 'MD',
        Madagascar: 'MG',
        'Saint Martin': 'MF',
        Morocco: 'MA',
        Monaco: 'MC',
        Uzbekistan: 'UZ',
        Myanmar: 'MM',
        Mali: 'ML',
        Macao: 'MO',
        Mongolia: 'MN',
        'Marshall Islands': 'MH',
        Macedonia: 'MK',
        Mauritius: 'MU',
        Malta: 'MT',
        Malawi: 'MW',
        Maldives: 'MV',
        Martinique: 'MQ',
        ' Northern Mariana Islands': 'MP',
        Montserrat: 'MS',
        Mauritania: 'MR',
        'Isle of Man': 'IM',
        Uganda: 'UG',
        Tanzania: 'TZ',
        Malaysia: 'MY',
        Mexico: 'MX',
        Israel: 'IL',
        France: 'FR',
        'British Indian Ocean Territory': 'IO',
        'Saint Helena': 'SH',
        Finland: 'FI',
        Fiji: 'FJ',
        ' Falkland Islands': 'FK',
        Micronesia: 'FM',
        'Faroe Islands': 'FO',
        Nicaragua: 'NI',
        Netherlands: 'NL',
        Norway: 'NO',
        Namibia: 'NA',
        Vanuatu: 'VU',
        'New Caledonia': 'NC',
        Niger: 'NE',
        'Norfolk Island': 'NF',
        Nigeria: 'NG',
        'New Zealand': 'NZ',
        Nepal: 'NP',
        Nauru: 'NR',
        Niue: 'NU',
        'Cook Islands': 'CK',
        Kosovo: 'XK',
        'Ivory Coast': 'CI',
        Switzerland: 'CH',
        Colombia: 'CO',
        China: 'CN',
        Cameroon: 'CM',
        Chile: 'CL',
        ' Cocos Islands': 'CC',
        Canada: 'CA',
        ' Republic of the Congo': 'CG',
        'Central African Republic': 'CF',
        'Democratic Republic of the Congo': 'CD',
        'Czech Republic': 'CZ',
        Cyprus: 'CY',
        'Christmas Island': 'CX',
        'Costa Rica': 'CR',
        Curacao: 'CW',
        'Cape Verde': 'CV',
        Cuba: 'CU',
        Swaziland: 'SZ',
        Syria: 'SY',
        'Sint Maarten': 'SX',
        Kyrgyzstan: 'KG',
        Kenya: 'KE',
        'South Sudan': 'SS',
        Suriname: 'SR',
        Kiribati: 'KI',
        Cambodia: 'KH',
        'Saint Kitts and Nevis': 'KN',
        Comoros: 'KM',
        'Sao Tome and Principe': 'ST',
        Slovakia: 'SK',
        'South Korea': 'KR',
        Slovenia: 'SI',
        'North Korea': 'KP',
        Kuwait: 'KW',
        Senegal: 'SN',
        'San Marino': 'SM',
        'Sierra Leone': 'SL',
        Seychelles: 'SC',
        Kazakhstan: 'KZ',
        'Cayman Islands': 'KY',
        Singapore: 'SG',
        Sweden: 'SE',
        Sudan: 'SD',
        'Dominican Republic': 'DO',
        Dominica: 'DM',
        Djibouti: 'DJ',
        Denmark: 'DK',
        'British Virgin Islands': 'VG',
        Germany: 'DE',
        Yemen: 'YE',
        Algeria: 'DZ',
        'United States': 'US',
        Uruguay: 'UY',
        Mayotte: 'YT',
        'United States Minor Outlying Islands': 'UM',
        Lebanon: 'LB',
        'Saint Lucia': 'LC',
        Laos: 'LA',
        Tuvalu: 'TV',
        Taiwan: 'TW',
        'Trinidad and Tobago': 'TT',
        Turkey: 'TR',
        'Sri Lanka': 'LK',
        Liechtenstein: 'LI',
        Latvia: 'LV',
        Tonga: 'TO',
        Lithuania: 'LT',
        Luxembourg: 'LU',
        Liberia: 'LR',
        Lesotho: 'LS',
        Thailand: 'TH',
        'French Southern Territories': 'TF',
        Togo: 'TG',
        Chad: 'TD',
        'Turks and Caicos Islands': 'TC',
        Libya: 'LY',
        Vatican: 'VA',
        'Saint Vincent and the Grenadines': 'VC',
        'United Arab Emirates': 'AE',
        Andorra: 'AD',
        'Antigua and Barbuda': 'AG',
        Afghanistan: 'AF',
        Anguilla: 'AI',
        'U.S.Virgin Islands': 'VI',
        Iceland: 'IS',
        Iran: 'IR',
        Armenia: 'AM',
        Albania: 'AL',
        Angola: 'AO',
        Antarctica: 'AQ',
        'American Samoa': 'AS',
        Argentina: 'AR',
        Australia: 'AU',
        Austria: 'AT',
        Aruba: 'AW',
        India: 'IN',
        'Aland Islands': 'AX',
        Azerbaijan: 'AZ',
        Ireland: 'IE',
        Indonesia: 'ID',
        Ukraine: 'UA',
        Qatar: 'QA',
        Mozambique: 'MZ',
    }
    return data[name];
};

export const getCountryPhoneCode = (CountryName, all = false) => {
    const codes = {
        Afghanistan: '93',
        Albania: '355',
        Algeria: '213',
        'American Samoa': '1',
        Andorra: '376',
        Angola: '244',
        Anguilla: '1',
        Antarctica: '672',
        'Antigua and Barbuda': '1',
        Argentina: '54',
        Armenia: '374',
        Aruba: '297',
        Australia: '61',
        Austria: '43',
        Azerbaijan: '994',
        Bahamas: '1',
        Bahrain: '973',
        Bangladesh: '880',
        Barbados: '1',
        Belarus: '375',
        Belgium: '32',
        Belize: '501',
        Benin: '229',
        Bermuda: '1',
        Bhutan: '975',
        Bolivia: '591',
        'Bosnia and Herzegovina': '387',
        Botswana: '267',
        Brazil: '55',
        'British Indian Ocean Territory': '246',
        'British Virgin Islands': '1',
        Brunei: '673',
        Bulgaria: '359',
        'Burkina Faso': '226',
        Burundi: '257',
        Cambodia: '855',
        Cameroon: '237',
        Canada: '1',
        'Cape Verde': '238',
        'Cayman Islands': '1',
        'Central African Republic': '236',
        Chad: '235',
        Chile: '56',
        China: '86',
        'Christmas Island': '61',
        'Cocos Islands': '61',
        Colombia: '57',
        Comoros: '269',
        'Cook Islands': '682',
        'Costa Rica': '506',
        Croatia: '385',
        Cuba: '53',
        Curacao: '599',
        Cyprus: '357',
        'Czech Republic': '420',
        'Democratic Republic of the Congo': '243',
        Denmark: '45',
        Djibouti: '253',
        Dominica: '1',
        'Dominican Republic': '1',
        'East Timor': '670',
        Ecuador: '593',
        Egypt: '20',
        'El Salvador': '503',
        'Equatorial Guinea': '240',
        Eritrea: '291',
        Estonia: '372',
        Ethiopia: '251',
        'Falkland Islands': '500',
        'Faroe Islands': '298',
        Fiji: '679',
        Finland: '358',
        France: '33',
        'French Polynesia': '689',
        Gabon: '241',
        Gambia: '220',
        Georgia: '995',
        Germany: '49',
        Ghana: '233',
        Gibraltar: '350',
        Greece: '30',
        Greenland: '299',
        Grenada: '1',
        Guam: '1',
        Guatemala: '502',
        Guernsey: '44',
        Guinea: '224',
        'Guinea Bissau': '245',
        Guyana: '592',
        Haiti: '509',
        Honduras: '504',
        'Hong Kong': '852',
        Hungary: '36',
        Iceland: '354',
        India: '91',
        Indonesia: '62',
        Iran: '98',
        Iraq: '964',
        Ireland: '353',
        'Isle of Man': '44',
        Israel: '972',
        Italy: '39',
        'Ivory Coast': '225',
        Jamaica: '1',
        Japan: '81',
        Jersey: '44',
        Jordan: '962',
        Kazakhstan: '7',
        Kenya: '254',
        Kiribati: '686',
        Kosovo: '383',
        Kuwait: '965',
        Kyrgyzstan: '996',
        Laos: '856',
        Latvia: '371',
        Lebanon: '961',
        Lesotho: '266',
        Liberia: '231',
        Libya: '218',
        Liechtenstein: '423',
        Lithuania: '370',
        Luxembourg: '352',
        Macau: '853',
        Macedonia: '389',
        Madagascar: '261',
        Malawi: '265',
        Malaysia: '60',
        Maldives: '960',
        Mali: '223',
        Malta: '356',
        'Marshall Islands': '692',
        Mauritania: '222',
        Mauritius: '230',
        Mayotte: '262',
        Mexico: '52',
        Micronesia: '691',
        Moldova: '373',
        Monaco: '377',
        Mongolia: '976',
        Montenegro: '382',
        Montserrat: '1',
        Morocco: '212',
        Mozambique: '258',
        Myanmar: '95',
        Namibia: '264',
        Nauru: '674',
        Nepal: '977',
        Netherlands: '31',
        'Netherlands Antilles': '599',
        'New Caledonia': '687',
        'New Zealand': '64',
        Nicaragua: '505',
        Niger: '227',
        Nigeria: '234',
        Niue: '683',
        'North Korea': '850',
        'Northern Mariana Islands': '1',
        Norway: '47',
        Oman: '968',
        Pakistan: '92',
        Palau: '680',
        Palestine: '970',
        Panama: '507',
        'Papua New Guinea': '675',
        Paraguay: '595',
        Peru: '51',
        Philippines: '63',
        Pitcairn: '64',
        Poland: '48',
        Portugal: '351',
        'Puerto Rico': '1',
        Qatar: '974',
        'Republic of the Congo': '242',
        Reunion: '262',
        Romania: '40',
        Russia: '7',
        Rwanda: '250',
        'Saint Barthelemy': '590',
        'Saint Helena': '290',
        'Saint Kitts and Nevis': '1',
        'Saint Lucia': '1',
        'Saint Martin': '590',
        'Saint Pierre and Miquelon': '508',
        'Saint Vincent and the Grenadines': '1',
        Samoa: '685',
        'San Marino': '378',
        'Sao Tome and Principe': '239',
        'Saudi Arabia': '966',
        Senegal: '221',
        Serbia: '381',
        Seychelles: '248',
        'Sierra Leone': '232',
        Singapore: '65',
        'Sint Maarten': '1',
        Slovakia: '421',
        Slovenia: '386',
        'Solomon Islands': '677',
        Somalia: '252',
        'South Africa': '27',
        'South Korea': '82',
        'South Sudan': '211',
        Spain: '34',
        'Sri Lanka': '94',
        Sudan: '249',
        Suriname: '597',
        'Svalbard and Jan Mayen': '47',
        Swaziland: '268',
        Sweden: '46',
        Switzerland: '41',
        Syria: '963',
        Taiwan: '886',
        Tajikistan: '992',
        Tanzania: '255',
        Thailand: '66',
        Togo: '228',
        Tokelau: '690',
        Tonga: '676',
        'Trinidad and Tobago': '1',
        Tunisia: '216',
        Turkey: '90',
        Turkmenistan: '993',
        'Turks and Caicos Islands': '1',
        Tuvalu: '688',
        'U.S. Virgin Islands': '1',
        Uganda: '256',
        Ukraine: '380',
        'United Arab Emirates': '971',
        'United Kingdom': '44',
        'United States': '1',
        Uruguay: '598',
        Uzbekistan: '998',
        Vanuatu: '678',
        Vatican: '379',
        Venezuela: '58',
        Vietnam: '84',
        'Wallis and Futuna': '681',
        'Western Sahara': '212',
        Yemen: '967',
        Zambia: '260',
        Zimbabwe: '263',
    };
    if (all) {
        return codes;
    }
    return codes[CountryName];
};

export const DefaultsRating = 0;

export const getRemainingSeconds = (timestamp=Date.now()) => {
    const now = Date.now();
    const timeDifference = now - timestamp;
    if (timeDifference <= 60000) {
      const remaining = Math.max(0, Math.ceil((60000 - timeDifference) / 1000));
      return remaining;
    } else {
      return 0;
    }
  };

  export const isNull = (value) => { 
  return ['null',null,undefined,''].includes(value)
 }