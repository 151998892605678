import React, { useState } from 'react';
import "./LicenseNumber.css"; 
import {Input, Spin } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import socialsecuirty from "../../assets/icons/socialsecurity.svg";
import validating from "../../assets/icons/validating.svg";
import ProgressBar from '../RatingProgressBar/ProgressBar';
import BackNextSignUpButton from '../../pages/SignUp/Components/DocUpload/Signup_gap_screen/BackNextSignUpButton';

const LicenseNumber = ({ heading,label,licenseText,StateLicense,inputText}) => {
  const [isValidating, setIsValidating] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [value, setValue] = useState('');
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setValue(inputValue);
    setIsValidating(true);

    setTimeout(() => {
      setIsValidating(false);
      if (inputValue.length > 0) {
        setIsValidated(true); 
      } else {
        setIsValidated(false); 
      }
    }, 8000); 
  };

  return (
    <div className='License'>
      <div className='license-number'>
        <div className='ProfileText'>{heading}</div>

        {/* <div className="custom-progress-container">
          <Progress percent={20} strokeColor="#118936" format={() => ''} />
          <span className='Rating-head'>
            <img className='Rating' src={license} alt="License Icon"/>  
            <span><p className='Rating-Number'>3.5</p></span>
          </span>
        </div> */}
        <ProgressBar />
       
        <div className='license-name'>
          <span className='license-label'> {label}</span>
          <span className='Optional-license'>(optional)</span>
        </div>
        <div className='license-Text'>{licenseText}</div>
        <div className="TextInput">
          <Input 
            type="text" 
            className="Input"  
            placeholder="000-00-0000"
            value={value}
            onChange={handleChange}
            suffix={isValidating ?   (<div className='validating'>
            Validating...
              <Spin size="small" />
            </div>
            )  : isValidated ?  < img src={validating}/> : null}
          />
        </div>
        {isValidated && (
          <div className="success-message">
          {inputText}</div>
        )}
         <div className='license-text'>
          <img className='Ssn-Icon' alt="SSN Icon" src={socialsecuirty} />
          <p className='sub-text'>
            <span>{StateLicense}</span>
          </p>
        </div> 
      </div>
      <div className='licensebutton'>
      <div className='gap-back-next-signup-buttons'>
      
      <BackNextSignUpButton 
          skip={true}
        />
  
     
    </div>
    </div>
    </div>
  );
}
export default LicenseNumber;
