import React from "react";
import "./PhoneNumberInput.css";
import { Input } from "antd";
import { WarningFilled } from "@ant-design/icons";
import Verification from "../../../../assets/icons/Verification.svg";

function PhoneNumberInput({
  title,
  type,
  placeholder,
  onChange,
  value,
  error,
  maxLength,
  countryCode,
  isVerified = false,
}) {
  return (
    <div className="TextInputParentContainer">
      <div className="textInputContainer">
        <div className="TextInputTitleContainer">
          <div className="TextInputTitle">{title}</div>
          <div className="TextInputType">({type})</div>
        </div>
        <p className="mobileVerificationText">
        Enter your U.S mobile number for verification
        </p>
        <div className="inputContainer" style={{ position: "relative" }}>
          <div
            className={`cotryCodeContainer${error ? "Error" : ""}  ${
              isVerified ? "inputContainerVerified" : ""
            }`}
          >
            <div className={`cotryCodeContainerinner${error ? "Error" : ""}`}>
              <div className="country-code">+{countryCode}</div>
              <div
                className={
                  value?.length < 1 ? "border-class" : "border-class-withnumber"
                }
              />
            </div>
          </div>
          <Input
            defaultValue={null}
            onChange={(e) => onChange(e)}
            className={`MobileInputStyle${error ? "Error" : ""}  ${
              isVerified ? "inputContainerVerified" : ""
            }`}
            placeholder={placeholder}
            value={value}
            status={error ? "error" : ""}
            help={error}
            maxLength={15}
            autoFocus
          />
          {isVerified ? (
            <img src={Verification} className="verificationSVG" alt="check" />
          ) : null}
        </div>
        {isVerified ? (
          <p className="verificationMsg">Your mobile number is now verified</p>
        ) : null}
        {error && (
          <div className="errorMsg">
            <WarningFilled className="errorIcon" />
            {error}
          </div>
        )}
      </div>
    </div>
  );
}
export default PhoneNumberInput;
