import React, { useState, useEffect, useCallback } from "react";
import "./SocialSecuirtyNumber.css"; 
import BackNextSignUpButton from '../DocUpload/Signup_gap_screen/BackNextSignUpButton';
import socialsecuirty from '../../../../assets/icons/socialsecurity.svg';
import { updateSignupProfile } from '../../../../redux/actions';
import { useDispatch,useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ProgressBar from "../../../../components/RatingProgressBar/ProgressBar";
import { debounce } from "../../../../shared/helpers";
const SocialSecurityNumber = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [SSN, setSSN] = useState(""); 
  const profiledata = useSelector((state) => state.profileReducer.user);

  const handleDebouncedUpdate = useCallback(
    debounce((value) => {
      const cleanedSSN = value.replace(/[^0-9]/g, ''); 
      if (cleanedSSN.length === 9) { 
        dispatch(
          updateSignupProfile({
            "Profiles.SocialSecurityNumber": value,
            WizardFlow: 116,
          })
        );
      }
    }, 500), 
    [dispatch]
  );

  useEffect(() => {
    handleDebouncedUpdate(SSN);
  }, [SSN, handleDebouncedUpdate]);

  const formatSSN = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    if (cleaned.length > 9) {
      return SSN; 
    }
    const match = cleaned.match(/^(\d{0,3})(\d{0,2})(\d{0,4})$/);
    if (match) {
      return `${match[1]}${match[2] ? '-' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
    }
    return value;
  };

  const updateValue = (e) => {
    setSSN(formatSSN(e.target.value));
  };

  const nextHandler = () => {
    history.push('/create-skilllisting-gig-type');
  };
  return (
    <div className='ProfileCompletionParent'>
      <div className='ProfileCompletion'>
      
      
      {profiledata?.profile?.ProfessionCode == "HC"?
          <ProgressBar heading={"Verify your identity, Facility required"} />
     : 
          <div className='completion__Percentage'>
            <div className="progress" />
        <div className='ProfileText'>Social Secuirty Number</div>
          </div>
      }
        <div className="InputTitleContainer">
        <div className="TextInput">Your SSN</div>
          <div className="Text-Type">(optional)</div>
        </div>
        <div className="Sub-ssn">Enter your social security number & earn + 0.5 rating to your profile</div>
        <div className="TextInputField">
          <input
            type="text"
            className="InputText"
            value={SSN}
            onChange={updateValue}
            placeholder="000-00-0000"
          />
        </div>
        <div className='text'>
          <img className='Ssn-Icon' src={socialsecuirty} alt="SSN Icon"/>
          <p className='subtext'>
            <span style={{fontWeight: "600"}}>Your SSN is secure with SkillGigs.</span>
            <span style={{fontSize: "13px", fontWeight: "400"}}>We are JCAHO certified and your Social Security Number (SSN) is encrypted and stored securely to protect your privacy.</span>
          </p>
        </div>
      </div>
     <div style={{paddingTop:"60px"}}>
     </div>
      <BackNextSignUpButton
        nextFunction={nextHandler}
        backFunction={() => history.push('/driver-license-doc')}
        skipFunction={() => history.push('/create-skilllisting-gig-type')}
        skip={true}
      />
    
  </div>
  );
};

export default SocialSecurityNumber;

