import { Pagination } from "antd";
import React, { useState } from "react";
import Loader from "../../components/Loader/Loader";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchViewListing } from "../../redux/actions";
import NoViewCard from "./components/NoViewCard";
import ViewCard from "./components/ViewCard";
import "./ViewListing.css";
import { useParams } from "react-router-dom";
const ViewListing = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const PER_PAGE = 24;
  const [page, setPage] = useState(1);
  const {
    loading,
    success: { viewers: viewListing, count },
  } = useSelector((state) => state.viewListingReducer) || [];

  const handlePagination = (page) => {
    setPage(page);
    dispatch(fetchViewListing({ id, page, perPage: PER_PAGE }));
  };

  useEffect(() => {
    dispatch(fetchViewListing({ id, page: page, perPage: PER_PAGE }));
  }, [id, dispatch]);

  return (
    <>
      <div className="viewListingConatainer">
        <div className="viewListingInfo">
          <div className="viewListingTitle">Skill Listing Views</div>
          {!loading && (
            <>
              {count ? (
                <div className="viewListing90Days">Views from Last 90 days</div>
              ) : null}
              <div className="viewListingCount">
                {count ? count : 0} item{count > 1 ? "s" : ""}
              </div>
            </>
          )}
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            {count && viewListing?.length ? (
              <>
                <div className="viewListingBox">
                  {viewListing?.map((data, i) => (
                    <ViewCard data={data} key={i} />
                  ))}
                </div>
                {count > PER_PAGE && (
                  <div className="viewListingPagination">
                    <Pagination
                      current={page}
                      onChange={handlePagination}
                      pageSize={PER_PAGE}
                      defaultCurrent={PER_PAGE}
                      total={100}
                      showSizeChanger={false}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <NoViewCard />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ViewListing;
