import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import { userTrackingClickAPI } from "../../API/API";

function* userTrackingClickSaga(request) {
  try {
    yield call(userTrackingClickAPI, request);
    yield put({ type: types.USER_CLICKED_SUCCESS, success: true });
  } catch (error) {
    console.warn(error);
  }
}

export function* watchUserLandedSaga() {
  yield takeEvery(types.USER_LANDED, userTrackingClickSaga);
}
export function* watchUserClickSaga() {
  yield takeEvery(types.USER_CLICK, userTrackingClickSaga);
}
