import React, { useState, useEffect, useCallback } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import "../../CreateSkillListing.css";
import "./CreateSkillListingScreen.css";
import errorIcon from "../../../../../assets/img/ci_warning.png";
import { AutoComplete, Spin, Select } from "antd";
import { debounce } from "../../../../../shared/helpers";
import { Tooltip  } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import SkillListingTittleToolTip from "../../../Components/SkillListingTips/SkillListingTittleToolTip"
import {
  fetchKeySearchDiscipline,
  updateSignupProfile,
} from "../../../../../redux/actions";
import BottomBottons from "../Footer/BottomButtons";
import SkillListingTips from "../../../Components/SkillListingTips/SkillListingTips";
import { useHistory } from "react-router-dom";

const AddSkillListingTitle = (props) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [showError, setShowError] = useState(false);
  const [flag, setFlag] = useState(false);
  const [noDataViewTitle, setNoDataViewTitle] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { Option } = Select;
  const history = useHistory();
  const { ProfessionCode } = useSelector(
    (state) => state.profileReducer.user.profile
  );
  const profiledata = useSelector((state) => state.profileReducer.user);
  const discipline = profiledata?.profile?.Discipline;
  const speciality = profiledata?.profile?.Speciality;
  const loading = useSelector((state) => state.profileReducer.signUpLoading);
  const apiError = useSelector((state) => state.profileReducer.error);
  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let totalSuggestions = speciality?.map((specs) => {
      return discipline + " - " + specs
    })
    setSuggestions(totalSuggestions?.length ? totalSuggestions : [])
  }, [discipline, speciality]);

  useEffect(() => {
    setTitle(profiledata?.profile?.DraftSkillListing?.Title);
  }, [profiledata?.profile?.DraftSkillListing?.Title]);

  useEffect(() => {
    if (apiSuccess && title && flag) {
      history.push("/create-skilllisting-skill");
    }
  }, [apiSuccess]);

  const saveSearchDiscipline = (value) => {
    setTitle(value);
    setShowError(false);
  };

  const onSearch = (val) => {
    setNoDataViewTitle(val);
    props.sendKeySkillListingTitle({ val: val, profCode: ProfessionCode });
  };

  const debouncedOnTitleSearch = debounce((val, profCode) => {
    setNoDataViewTitle(val);
    props.sendKeySkillListingTitle({ val: val, profCode });
  }, 500);

  const debouceTitleSearchRequest = useCallback(value => debouncedOnTitleSearch(value, ProfessionCode), [ProfessionCode]);

  const handleNext = () => {
    if (title) {
      setFlag(true);
      dispatch(
        updateSignupProfile({
          "DraftSkillListing.Title": title,
          WizardFlow: 203,
        })
      );
    } else {
      setShowError(true);
    }
  };

  const handleBack = () => {
    history.push("/create-skilllisting-gig-type");
  };

  const displaySuggestions = () => {
    return (
      <div className="skill-listing-title-suggestions-container">
        <span>Suggestions based on your discipline and speciality</span>
        {suggestions?.map((suggestion, index) => {
          return (
            <div key={index} className="skill-listing-title-suggestion" onClick={() => {
              setTitle(suggestion);
              setShowError(false);
            }}>
              <span>{suggestion}</span>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="CreateSkillListingHeaderScreen2">
      <div className="create-skill-listing-title create-skill-input">
        Add Skill listing title{" "}
        <span className="Mandatory-Text">(Mandatory)</span>
        {screenWidth<769 &&
            <Tooltip className="gig-type-Tooltip" title={<SkillListingTittleToolTip/>}>
            <InfoCircleOutlined />
             </Tooltip>
          }
      </div>
      {/* {screenWidth < 769 &&
        <div className="CreateSkillListing-tip-cont title">
          <SkillListingTips 
            pageType="skill-listing-title" 
            ProfessionCode={ProfessionCode}
          />
        </div>
      } */}
     
      <div className="skill-listing-title-tip-container">
        <div className="skill-listing-title-suggestion-cont">
          <AutoComplete
           autoFocus 
            bordered={false}
            className={`desired-loc-skill${showError ? " error-block" : ""} title`}
            name="roleDiscipline"
            value={title}
            onChange={saveSearchDiscipline}
            onSearch={(val) => debouceTitleSearchRequest(val)}
            placeholder={
              <div className="placeholderSkillListingFormOne">
                {ProfessionCode === "HC" ? "Add title" : "ex. Java developer"}
              </div>
            }
            notFoundContent={
              noDataViewTitle?.length > 0 ? (
                props.disciplineLoader || props.searchedDiscipline.length > 0 ? (
                  <div>
                    <Spin size="small" />
                  </div>
                ) : (
                  "No data found!"
                )
              ) : null
            }
            showSearch
          >
            {props.searchedDiscipline &&
              props?.searchedDiscipline
                ?.filter((discipline) => discipline?.Name?.length > 0)
                ?.map((discipline) => (
                  <Option value={discipline?.Name} key={discipline?._id}>
                    {discipline?.Name}
                  </Option>
                ))}
          </AutoComplete>
          {showError && (
            <div className="error-msg-content">
              <img src={errorIcon} alt="" />
              <span className="error-text">
                Please enter the skill listing title to continue
              </span>
            </div>
          )}
          {suggestions?.length > 0 && ProfessionCode === "HC" ? displaySuggestions() : null}
        </div>

        {screenWidth > 1000 &&
          <div className="CreateSkillListing-tip-cont">
            <SkillListingTips 
              pageType="skill-listing-title" 
              ProfessionCode={ProfessionCode}
            />
          </div>
        }
      </div>
      <BottomBottons
        handleBack={handleBack}
        handleNext={handleNext}
        loading={loading}
        isTitlePage={true}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    searchedDiscipline: state.skillListingReducer.searchedDiscipline,
    disciplineLoader: state.skillListingReducer.disciplineLoader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendKeySkillListingTitle: (e) => {
      dispatch(fetchKeySearchDiscipline(e));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSkillListingTitle);
