import React from 'react';
import * as Sentry from '@sentry/react';

const ExampleComponent = () => {
  const throwError = () => {
    throw new Error('This is a simulated error!');
  };

  return (
    <div>
      <h1>Hello, Sentry!</h1>
      <button onClick={throwError}>Throw Error</button>
    </div>
  );
};


const ErrorComponent = () => (
  <Sentry.ErrorBoundary fallback={<p>Something went wrong.</p>}>
    <ExampleComponent />
  </Sentry.ErrorBoundary>
);

export default ErrorComponent;
