import React from "react";
import { useSelector } from "react-redux";
import { getPictureUrl } from "../../../../assets/helpers";
import { Avatar, Tooltip } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./EmployerAssociation.css";

const EmployerAssociation = ({ size = 26 }) => {
  const history = useHistory();

  const EmployeeAssociation = useSelector(
    (state) => state.profileReducer?.user?.EmployeeAssociation || []
  );

  const getMarginByIndex = (index) => {
    switch (index) {
      case 0:
        return 0;
      case 1:
        return size * 0.5 + 2 + "px";
      case 2:
        return size * 1.0 + 2 + "px";
      case 3:
        return size * 1.5 + 2 +"px";
      case 4:
        return size * 2.0 + 2 + "px";
      case 5:
        return size * 2.5 + 2 + "px";
      default:
        return size * 2.5 + 2 + "px";
    }
  };
  if (EmployeeAssociation.length > 1)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
        }}
      >
        {EmployeeAssociation.map((emp, ind) =>
          emp?.PictureUrl ? (
            <div
              key={emp?.FirstName + ind}
              className='assoc-emp-logo'
              style={{
                width: size + "px",
                height: size + "px",
                borderRadius: "50%",
                overflow: "hidden",
                marginLeft: "10px",
                position: "absolute",
                left: getMarginByIndex(ind),
                cursor:'pointer',
                backgroundColor:'black'
              }}
              onClick={() =>
                history.push(`/company-profile/${emp?.Employer_id}`)
              }
            >
              <img
                src={getPictureUrl(emp?.PictureUrl)}
                style={{ width: "100%" }}
                alt="emp"
              />
            </div>
          ) : (
            <Avatar size={size} key={emp?.FirstName + ind}>
              {emp?.FirstName?.[0].toUpperCase() +
                emp?.LastName?.[0].toUpperCase()}
            </Avatar>
          )
        )}
      </div>
    );
  else {
    return EmployeeAssociation.length ? (
      <Tooltip
        title={`You’ve signed up with ${EmployeeAssociation?.[0]?.PvtEmpName}`}
      >
        <div
          style={{
            width: size + "px",
            height: size + "px",
            borderRadius: "50%",
            overflow: "hidden",
            cursor: "pointer",
            backgroundColor:'black'
          }}
          onClick={() =>
            history.push(`/company-profile/${EmployeeAssociation?.[0]?.Employer_id}`)
          }
        >
          <img
            src={getPictureUrl(EmployeeAssociation?.[0]?.PictureUrl)}
            style={{ width: "100%" }}
            alt="emp"
          />
        </div>
      </Tooltip>
    ) : (
      <></>
    );
  }
};

export { EmployerAssociation };
