import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Skeleton, Dropdown, Menu, Input, Button } from "antd";
import "./Notifications.css";

import NotificationItem from "./notificationItem";
import FilterConversations from "../../pages/Conversations/components/Filters/Filter";
import {
  SearchOutlined,
  CloseOutlined,
  Loading3QuartersOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  fetchInboxMessages,
  fetchMessagesCounts,
  getNotifications,
} from "../../redux/actions";
import no_notificationIcon from "../../assets/img/No-Notification.png";
import filterIcon from "../../assets/img/filtericon.svg";
import { useDispatch, useSelector } from "react-redux";
import ConversationCard from "../../pages/Conversations/components/Messages/ConversationCard";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../Loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import fetchNotificationReducer from "../../redux/reducers/fetchNotificationReducer";
import no_chatIcon from "../../assets/img/chatIcon.png";
import LoadMore from "../LoadMore/LoadMore";
import { GetQueryParams } from "../../assets/helpers";
import { debounce } from "../../shared/helpers";

export default function Notifications({
  visible = () => {},
  isTabSwitch,
  setIsTabSwitch = () => {},
}) {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const history = useHistory();
  const dummyRow = [1, 2, 3, 4];
  const [conversationsRadioValue, setConversationsRadioValue] =
    useState("all_conversations");
  const [bidTypeRadioValue, setBidTypeRadioValue] = useState("all_bids");
  const [bidStatusRadioValue, setBidStatusRadioValue] = useState("null");
  const [createTime, setCreatedTime] = useState();
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loadChat, setLoadChat] = useState(false);
  const [filterCount,setFilterCount] = useState(2);
  const [appliedFilterCheck, setAppliedFilterCheck] = useState(false);

  //REDUCERS***
  const inbox = useSelector((state) => state.getChatHistoryReducer.inbox);
  const inboxHeader = useSelector((state) => state.getChatHistoryReducer.inboxHeader);
  const counts = useSelector((state) => state.getChatHistoryReducer.counts);
  const IsinboxLoading = useSelector((state) => state.getChatHistoryReducer.inboxLoading);
  const inboxLoading = useSelector(
    (state) => state.messagesReducer.inboxLoading
  );

  const isNotificationCheck = localStorage.getItem('isNotificationCheck');
  const [isNotification, setisNotification] = useState(isNotificationCheck === 'true' ? true : false);
  const mobileCheck = state?.fromMobileIsTabSwitch !== undefined ? state?.fromMobileIsTabSwitch : isNotification;
  const [allNotification, setAllNotification] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [openedChatRecords,setOpenChatsRecord] = useState({})
  const [showHistory, setshowHistory] = useState(false);
  const [inputWidth, setInputWidth] = useState();
  const [infiniteLoader, setInfiniteLoader] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [searchedValue, setSearchedValue] = useState(false);

  const [Recipient, setRecipient] = useState();
  const [title, setTitle] = useState();
  const [conversations, setConversations] = useState([]);
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const location = useLocation();

  const notification = useSelector(
    (state) => state.NotificationReducer.notifications
  );
  const notificationCont = useSelector(
    (state) => state.NotificationReducer.NotifictionCount
  );
  const currentNotificationLength = useSelector(
    (state) => state.NotificationReducer.currentCount
  );
  const updateRequested = useSelector(
    (state) => state.NotificationReducer.updateRequested
  );
  const hasMorechats = useSelector(
    (state) => state.getChatHistoryReducer.hasMorechats
  );
  const page = useSelector((state) => state.getChatHistoryReducer.page);
  const loading = useSelector((state) => state.NotificationReducer.loading);
  const emailVerification = useSelector(
    (state) => state.profileReducer.user.profile.verification
  );


  const addQuery = (key, value) => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    history.push({
      search: searchParams.toString(),
    });
  };
  const removeQuery = (key) => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(key);
    history.push({
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    setisNotification(
      window.innerWidth > 768 ? isTabSwitch : mobileCheck
      );
    }, [
      window.innerWidth > 768 ? isTabSwitch : mobileCheck,
      window.innerWidth > 768 && isNotification
    ]);  
      
  useEffect(() => {
    if (currentNotificationLength == 10) {
      setHasMore(true);
    }else{
      setHasMore(false);
    }
  }, [notification]);

  useEffect(() => {
    if (emailVerification) {
      if (emailVerification.email) {
        setIsEmailVerified(emailVerification.email);
      }
    }
  }, [emailVerification]);

  useEffect(() => {
    if (updateRequested) {
      setTimeout(() => {
        dispatch(
          getNotifications({
            currentPage: 1,
            pageSize: notification?.length,
            fetchMore: true,
            getAll: true,
          })
        );
      }, 2000);
    }
  }, [updateRequested]);

  const fetchMoreData = (value) => {
    setInfiniteLoader(true);
    if(hasMore){
      dispatch(
        getNotifications({
          currentPage: currentPage + 1,
          pageSize: pageSize,
          fetchMore: true,
        })
      );
    }
    if (notificationCont == notification.length) {
      setHasMore(false);
    } else {
      setHasMore(true);
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(()=>{
    removeQuery('bidType');
    removeQuery('bidStatusType');
    removeQuery('ConversationType');
  },[])


  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const fetchMoreMsgs = (value) => {
    if (hasMorechats) {
      dispatch(
        fetchInboxMessages(
          conversationsRadioValue,
          bidTypeRadioValue,
          bidStatusRadioValue,
          page,
          5,
          false,
          true,
          searchedValue
        )
      );
    }
  };

  useEffect(()=>{
    if(appliedFilterCheck){
      setBidStatusRadioValue(GetQueryParams()?.bidStatusType);
      setBidTypeRadioValue(GetQueryParams()?.bidType=="placed_bids"?"received_bids":GetQueryParams()?.bidType == "received_bids" ? "placed_bids" : GetQueryParams()?.bidType);
      setConversationsRadioValue(GetQueryParams()?.ConversationType);
    }
    else{
      setBidStatusRadioValue('null');
      setBidTypeRadioValue('all_bids');
      setConversationsRadioValue('all_conversations');
    }

  },[dropDownVisible])

  const clearFilter = () => {
    setBidStatusRadioValue(() => "null");
    setConversationsRadioValue(() => "all_conversations");
    setBidTypeRadioValue("all_bids");
    setshowHistory(false);
    removeQuery('bidType');
    removeQuery('bidStatusType');
    removeQuery('ConversationType');
    setFilterCount(2);
    setAppliedFilterCheck(false);
    
    dispatch(fetchMessagesCounts("all_bids"));
    dispatch(
      fetchInboxMessages("null", "all_conversations", "null", null, null, true)
    );
    setDropDownVisible(false);
    setTimeout(() => {
      dispatch(fetchInboxMessages("null", "all_conversations", "null", 2, 5));
    }, 1000);
  };
  
  const onChange = (e) => {
    // var message_title;
    // var searchedConversations = inbox?.data?.data?.body?.conversations.filter(
    //   (item) => {
    //     if (item.SourceTypeId == 1) {
    //       message_title = item?.SkillListing?.Title;
    //     } else {
    //       message_title = item?.JobTitle?.toLowerCase();
    //     }

    //     if (
    //       message_title?.toLowerCase().includes(e.target.value.toLowerCase())
    //     ) {
    //       return item;
    //     }
    //   }
    // );

    // if (e.target.value === "") {
    //   setConversations(inbox?.data?.data?.body?.conversations);
    // } else {
    //   setConversations(searchedConversations);
    // }
    dispatch(
      fetchInboxMessages(
        location?.state?.conversationStatus ?? "null",
        conversationsRadioValue,
        bidStatusRadioValue,
        null,
        null,
        true,
        null,
        e.target.value
      )
    );
    setTimeout(() => {
      dispatch(
        fetchInboxMessages(
          location?.state?.conversationStatus ?? "null",
          conversationsRadioValue,
          bidStatusRadioValue,
          2,
          5,
          null,
          null,
          e.target.value
        )
      );
    }, 1000);
    setSearchedValue(e.target.value);
  };


  const applyFilter = () => {
    setshowHistory(false);
    dispatch(fetchMessagesCounts(bidTypeRadioValue));
    setAppliedFilterCheck(true);
    addQuery('bidType',bidTypeRadioValue=="placed_bids"?"received_bids":bidTypeRadioValue == "received_bids" ? "placed_bids" : bidTypeRadioValue);
    addQuery('bidStatusType',bidStatusRadioValue);
    addQuery('ConversationType',conversationsRadioValue)
    if(bidStatusRadioValue!=="null"){
      setFilterCount(3);
    }
    dispatch(
      fetchInboxMessages(
        conversationsRadioValue,
        bidTypeRadioValue,
        bidStatusRadioValue,
        1,
        5,
        true
      )
    );
    setTimeout(() => {
      dispatch(
        fetchInboxMessages(
          conversationsRadioValue,
          bidTypeRadioValue,
          bidStatusRadioValue,
          2,
          5
        )
      );
    }, 1000);
    setDropDownVisible(false);
  };

  const Filters = (
    <Menu className="chat-filter-menu">
      <FilterConversations
        conversationsRadioValue={conversationsRadioValue}
        setConversationsRadioValue={setConversationsRadioValue}
        bidTypeRadioValue={bidTypeRadioValue}
        setBidTypeRadioValue={setBidTypeRadioValue}
        bidStatusRadioValue={bidStatusRadioValue}
        setBidStatusRadioValue={setBidStatusRadioValue}
        counts={counts}
        conversationsCount={inbox?.data?.data?.totalConversations}
      />

      <div className="statusDropDownBtnss">
        <Button className="clearBtnStatus" onClick={clearFilter}>
          Clear
        </Button>

        <Button className="applyBtnStatus" onClick={applyFilter}>
          Apply
        </Button>
      </div>
    </Menu>
  );

  useEffect(()=>{
    dispatch(fetchInboxMessages("null", "all_conversations", "null", 2, 5));
  },[])

  useEffect(() => {
    if (inbox?.data?.data?.body?.conversations) {
      setConversations(inbox?.data?.data?.body?.conversations);
    }
  }, [inbox]);

  return (
    <div className="notification">
      <Row
        className="notificationHeader"
        aria-details={!isEmailVerified && "extra-top"}
      >
        <Row
          className="notificationCont"
          onClick={() => {
            setisNotification(true);
            localStorage.setItem('isNotificationCheck', true);
            window.innerWidth > 768 && setIsTabSwitch(true);
          }}
        >
          <div className="img_cont">
          </div>
          <div className={`titleText ${isNotification ? "titleActive" : ""}`}>
            Notifications
          </div>
        </Row>
        <Row
          className="messagesCont"
          onClick={(e) => {
            e.stopPropagation();
            if (!isNotification && window.innerWidth > 768 ) {
              history.push({
                pathname: "/messages",
                state: {
                  fromNotification: true,
                },
              });
              visible(false);
            }
            setisNotification(false);
            localStorage.setItem('isNotificationCheck', false);
            window.innerWidth > 768 && setIsTabSwitch(false);
          }}
        >
          <div className="img_cont">
          </div>
          <div className={`titleText ${!isNotification ? "titleActive" : ""}`}>
            Inbox
          </div>
        </Row>
      </Row>

      {isNotification ? (
        !infiniteLoader && loading ? (
          <div className="cardsContainer">
            <Loader />
          </div>
        ) : notification.length === 0 ? (
          <div className="notification-items-container">
            <Row className="no_msgsCont">
              <div className="imageContainer">
                <img width={135} src={no_chatIcon} alt="no notification icon" />
                <div>No notifications</div>
              </div>
            </Row>
          </div>
        ) : (
          <div className="notification-scroll-parent" id="infinite-scroll-parent">
            <InfiniteScroll
              dataLength={notification?.length}
              next={fetchMoreData}
              hasMore={hasMore}
              // style={{minheight:'max-content', maxHeight:'65vh'}}
              style={{minheight:'max-content'}}
              height={(windowDimensions.width < 768) ? "100vh" : "65.5vh"}
              loader={hasMore ? <LoadingOutlined /> : null}
              scrollableTarget={"infinite-scroll-parent"}
            >
            <Row className="notificationListCont" style={{ marginTop: "3rem" }}>
                {notification?.map((item, index) => {
                  return (
                    <Col span={24}>
                      <NotificationItem
                        item={item}
                        index={index}
                        setisNotification={setisNotification}
                        message={item?.Message}
                        sourceId={item?.SourceId}
                        type={item?.NotificationTypeName}
                        created={item?.Created}
                        img={item?.senderprofile[0]?.Profiles?.PictureUrl}
                        interviewer={item?.senderprofile[0]?.UserName ?? ""}
                        startTime={
                        item?.interviews?.length && item?.interviews[0]?.Created
                        }
                        scheduleTimings={
                          item.interviews?.length > 0 &&
                          item.interviews[0]?.Interviews_ScheduledTimings[0]
                            ?.StartTime
                        }
                        visible={visible}
                      />
                    </Col>
                  );
                })}
              </Row>
            </InfiniteScroll>
          </div>
        )
      ) : (
        // case where Inbox Tab is set
        <Row className="notificationListCont" style={{ marginTop:windowDimensions.width > 768?"3rem": "6rem" }}>
          <Row className="InboxFiltersCont">
            {inputWidth ? null : (
              <div className="conversationTitleCont">
                <div className="titleConversation">All conversation</div>
              </div>
            )}
            <Row
              style={{
                width: inputWidth ? "100%" : "50%",
                justifyContent: !inputWidth ? "flex-end" : "center",
              }}
            >
              {inputWidth ? null : (
                <Dropdown
                  autoFocus={true}
                  trigger="click"
                  overlay={Filters}
                  visible={dropDownVisible}
                  onVisibleChange={() => setDropDownVisible(!dropDownVisible)}
                >
                  <div
                    onClick={() => setDropDownVisible(!dropDownVisible)}
                    className="conversationfilterCont"
                  >
                    <div className="conversation-header-filter-image">
                      <img src={filterIcon} alt="filterIcon" />
                    </div>
                    <div className="conversation-header-filter-count">
                      {/* {bidStatusRadioValue == "null" ? 2 : 3} */}
                      {filterCount}
                    </div>
                  </div>
                </Dropdown>
              )}
              <div
                className="filterSearchConversationCont"
                onClick={() => setInputWidth(true)}
                style={{
                  width: inputWidth ? "18rem" : "2.5rem",
                  paddingTop: inputWidth ? "15px" : "0px",
                  paddingBottom: inputWidth ? "15px" : "0px",
                  transition: inputWidth && "width 1s",
                }}
              >
                <Input
                  type={"input"}
                  className="conversation-header-inputt"
                  prefix={
                    <SearchOutlined
                      style={{ color: "#7e7e7e", fontSize: "16px" }}
                    />
                  }
                  placeholder="Find skill listing title or gig title "
                  allowClear = {inputWidth}
                  onChange={debounce((e) => onChange(e))}
                />
                {inputWidth && (
                  <div className="cross-icon-div">
                  <CloseOutlined
                    onClick={(event) => {
                      setInputWidth(false);
                      event.stopPropagation();
                    }}
                    style={{ color: "#c4c4c4" }}
                  />
                </div>
                )}
              </div>
            </Row>
          </Row>

          {/* Inbox data */}
          {inboxLoading && conversations?.length == 0 ? (
            <div className="cardsContainer">
              <LoadingOutlined className="inbox-loader" />
            </div>
          ) : (
            <div
              className="notification-cards-container"
              aria-details={conversations?.length > 0 && "extra-top"}
            >
              {conversations?.length > 0 ? (
                <InfiniteScroll
                  dataLength={conversations?.length}
                  next={fetchMoreMsgs}
                  hasMore={hasMorechats}
                  style={{height:'max-content', maxHeight:'65vh'}}
                  className={"msgs-infinite-scroller"}
                  loader={
                    hasMorechats &&
                    conversations?.length > 0 ? null : inboxLoading ? (
                      <>
                        {dummyRow.map((res) => {
                          return (
                            <div className="shimmer-container">
                              {" "}
                              <Skeleton
                                active
                                avatar
                                paragraph={{ rows: 2 }}
                              />{" "}
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <h4>No conversations</h4>
                    )
                  }
                >
                  {conversations?.map((item, index) => {
                    if (windowDimensions.width > 768 && index > 8) {
                      return;
                    } else
                      return (
                        <Col
                          span={24}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (visible) visible(false);
                          }}
                        >
                          <ConversationCard
                            records={item}
                            Index={index}
                            SourceId={item?.BidId}
                            setshowHistory={setshowHistory}
                            setTitle={setTitle}
                            setCreatedTime={setCreatedTime}
                            title={title}
                            setLoadChat={setLoadChat}
                            SourceName={
                              item?.SourceTypeId === 1 //SourceTypeName === "SkillListing"
                                ? "Bid"
                                : "Proposal"
                            }
                            fromNotificationScreen={true}
                            setOpenChatsRecord={setOpenChatsRecord}
                            conversationType={item?.ConversationType}
                          />
                        </Col>
                      );
                    }
                  )}
                </InfiniteScroll>
              )
                : IsinboxLoading ? <Loader/> : (
                <Row className="no_msgsCont">
                  <div className="imageContainer">
                      <img width={135} src={no_chatIcon} alt="no notification icon" />
                    <div>No conversations</div>
                  </div>
                </Row>
              )}

              {windowDimensions.width > 768 && conversations?.length > 0 ? (
                <div className="seeAll-div">
                  <span onClick={(e)=>{e.stopPropagation(); history.push("/messages");visible(false); setisNotification(false); }} className="seeAll-text">See all</span>
                </div>
              ) : null}
            </div>
          )}
        </Row>
      )}
    </div>
  );
}
