import React, { useEffect, useRef } from "react";
import BannerImg from "../../assets/icons/BannerImg.svg";
import { Row, Col, Rate, Input, Carousel, Empty, Button, Modal } from "antd";
import bannerImgMobile from "../../assets/icons/bannerImgMobile.svg";
import Ourcultureimg from "../../assets/img/Ourcultureimg.png";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import "./Styles.css";
import { useState } from "react";
import FacebookGroup from "../../assets/icons/FacebookGroup.png";
import InstaGroup from "../../assets/icons/InstaGroup.png";
import LinkedInGroup from "../../assets/icons/LinkedInGroup.png";
import TwitterGroup from "../../assets/icons/TwitterGroup.png";
import YoutubeGroup from "../../assets/icons/YoutubeGroup.png";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  useLazyQuery,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { HttpLink } from "apollo-link-http";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { LANDING_PAGE_QUERY } from "./LandingPageQuery";
import Loader from "../../components/Loader/Loader";
import useDocumentTitle from "../../components/UseTitleHook";
import {
  UpdateSearchFilter,
  fetchUserProfile,
  setLandingPageDetails,
} from "../../redux/actions";
import GigsCard from "../SearchGigs/GigsCard";
import { useHistory } from "react-router-dom";
import { getPictureUrl } from "../../assets/helpers";
import { RightOutlined } from "@ant-design/icons";

const EmployerLandingPage = (props) => {
  const history = useHistory();
  const subrout = useLocation().pathname.split("/")[2];
  useDocumentTitle(`${(subrout||"").toUpperCase()} Global Talent Marketplace`)
  const carouselRef = useRef();

  const [hitQuery, { loading, error, data }] = useLazyQuery(LANDING_PAGE_QUERY);
  const dispatch = useDispatch();
  const [Dimensions, setDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const SeeAllfunctionPvt = (EmpName, AccountId) => {
    dispatch(UpdateSearchFilter("PrivateEmployerId", [AccountId]));
    localStorage.setItem(
      "PrivateEmployer",
      JSON?.stringify({ EmpName, AccountId })
    );

    history.push({
      pathname: "/search/Pvt",
      state: {
        EmpName: EmpName,
        AccountId: AccountId,
        showLogo:true
      },
    });
  };

  useEffect(() => {
    hitQuery({ variables: { empName: subrout } });
    localStorage.removeItem("socialSignUpWithEmp");
    localStorage.removeItem("employer");
  }, []);

  useEffect(() => {
    dispatch(setLandingPageDetails(data?.privateEmp?.Emp));
    if(data)
    localStorage.setItem('empDet',JSON.stringify(data?.privateEmp?.Emp))
  }, [data]);

  useEffect(() => {
    function handleResize() {
      setDimensions(getWindowDimensions());
    }
    dispatch(fetchUserProfile({ empName: subrout }));

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getCount = () => data?.privateEmp?.gigs?.counts;

  const getGigs = () => data?.privateEmp?.gigs?.data;

  if (loading) return <Loader />;

  const getIconByType = (item) => {
    switch (item?.type) {
      case "facebook":
        return FacebookGroup;
      case "twitter":
        return TwitterGroup;
      case "linkedIn":
        return LinkedInGroup;
      case "instagram":
        return InstaGroup;
      case "youtube":
        return YoutubeGroup;
      default:
        return FacebookGroup;
    }
  };

  const nextSlide = () => {
    carouselRef.current.next();
  };
  const prevSlide = () => {
    carouselRef.current.prev();
  };

  const getHeading = (emp) => {
    switch (emp) {
      case "SHI":
      case "Shi":
      case "shi":
        return (
          <>
             <h3 className="why-do-text" style={{ fontWeight: "600" , color:"#3E3E3E"}}>
              Get to know SHI</h3>
            
          </>
        );
      case "HEB":
      case "Heb":
      case "heb":
        return (
          <>
            <h3 className="why-do-text" style={{ fontWeight: "600" , color:"#3E3E3E"}}>
            Why we do what we do</h3>
          </>
        );
      case "PRESIDIO":
      case "Presidio":
      case "presidio":
        return (
          <>
            <h3 className="why-do-text" style={{ fontWeight: "600 " , color:"#3E3E3E"}}>
            Why we do what we do</h3>
          </>
        );
      default:
        return <></>;
    }
  };
  const getContent = (emp) => {
    switch (emp) {
      case "SHI":
      case "Shi":
      case "shi":
        return (
          <>
            <p className="why-do-text" style={{ fontWeight:"500", color:"#3E3E3E"}}>
            Think of SHI as your personal technology concierge. 
            We connect your team with the IT solutions and services 
            you need to support your organizational growth and employee experience
            <br/><br/>
            </p>
            <p className="why-do-text" style={{ fontWeight:"400", color:"#3E3E3E"}}>
            Whether you’re building a modern hybrid workplace, 
            defending against an evolving threat landscape, 
            making the cloud work harder for you, or searching 
            for ways to optimize your software portfolio, 
            our friendly 6,000-person team is ready to solve what’s
             next for your organization.
             <br/><br/>
            </p>
          </>
        );
      case "HEB":
      case "Heb":
      case "heb":
        return (
          <>
            <p className="why-do-text" >
              We began as a small, family-owned business in Kerrville, dedicated
              to bringing great products to hard-working Texans. Over 115 years
              and more than 400 stores later, we’re still a family business with
              a heart for – and a hand in – every community we serve. We’re in
              the people business. We just happen to sell groceries.
              <br/><br/>
            </p>
            <p className="why-do-text" >
              We're firm believers in Texans helping Texans. That means taking
              great care of our customers and finding new ways to serve them. It
              means giving back to neighborhoods and schools all over this great
              state. And it means doing our part to support our Nation’s
              military, fight hunger and stand by the communities when disasters
              strike.
              <br/><br/>
            </p>
            <p className="why-do-text" >
              Whether Texans are sipping their morning cup of Cafe Olé® by
              H-E-B® or diving into a spoonful of H-E-B® Creamy Creations® Ice
              Cream, our namesake products are born and raised to be Texas
              favorites, guaranteed. Not that we stop there. We search far and
              wide to bring our customers unique finds from all over the world.
            </p>
          </>
        );
      case "PRESIDIO":
      case "Presidio":
      case "presidio":
        return (
          <>
            <p className="why-do-text"s>
              Presidio is here to guide your digital journey and help you
              navigate the fast-changing tech landscape. Our experts reduce risk
              and get things done. Presidio is your trusted ally.
              <br/><br/>
            </p>
            
            <p className="why-do-text">
              We are with our customers for a lifetime. We understand where
              you’ve been and where you’re going. We bridge the gap between
              traditional IT environments and an automated,
              digitally-transformed future. We see the big picture, take on any
              challenge, and help you adapt.
              <br/><br/>
            </p>
            
            <p className="why-do-text">
              Our approach is fearless, fast, flexible, and compassionate. We
              get involved at all levels to earn your trust and deliver
              innovative, high-quality results at the speed your business needs
              to succeed.
            </p>
          </>
        );
      default:
        return <></>;
    }
  };

  const styles = {
    ...(window.innerWidth <= 786 
        ? { borderTop: '1px solid #E4E4E4' }
        : { border: '1px solid #E4E4E4' }
    )
  };

  return (
    <div className="page-container">
      <img
            className="bannerImg-mobile"
            src={getPictureUrl(data?.privateEmp?.Emp?.banner?.mobile)}
            alt="Mobile Banner"
          />
      <div className="mainSec">
        <div className="bannerSec">
          <img
            className="bannerImg"
            src={getPictureUrl(data?.privateEmp?.Emp?.banner?.desktop)}
          />
        </div>

        <div className="job-container" style={{ padding: "0" }}>
          <div className="mobile-view-wrapper">
            <div className="our-gigs-wrapper">
              <Row className="jobsTileCont">
                <Col xs={24} sm={12}>
                  <div className="job-headings-emp">
                    Our open gigs
                    <div className="job-length-emp">
                      {getCount() ? getCount() : 0}
                    </div>
                  </div>
                </Col>
                {Dimensions.width >= 768 && (
                  <Col
                    span={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div
                      className="comp-main comp-heading2"
                      onClick={() =>
                        SeeAllfunctionPvt(
                          data?.privateEmp?.Emp?.empTitle,
                          data?.privateEmp?.Emp?.EmpAccountId
                        )
                      }
                      style={{
                        cursor: getCount() < 3 && "default",
                        display: "flex",
                        textAlign: "center",
                        fontSize: "14px",
                      }}
                    >
                      {getCount() > 3 && "See all"}
                    </div>
                  </Col>
                )}
              </Row>
            </div>
            {getGigs()?.length > 0 ? (
              <div className="gigs-content-landing">
                <Row
                className="Landing-page-gig-container"
                  style={{
                    paddingTop: "10px",
                    paddingLeft: Dimensions.width < 786 ? "0" : "0",
                    width: "100%",
                    marginLeft:"0 !important",
                    marginRight:"0 !important" 
                  }}
                  // justify="space-between"
                  gutter={[
                    { xs: 0, sm: 8, md: 16 },
                    { xs: 0, sm: 6, md: 12, lg: 16 },
                  ]}
                >
                  {getGigs().map((item, index) => {
                    return (
                      <Col
                        key={index}
                        xs={24}
                        sm={12}

                        md={8}
                        lg={8}
                        xl={8}
                        
                        style={{ marginBottom: "16px",
                        paddingLeft:
                            index == 0 ? "0px !important" : undefined,
                          paddingRight:
                            index == 2 ? "0px !important" : undefined,
                         }}
                      >
                        <GigsCard
                          companyTitleClass={"landing-page-card"}
                          containerClass={"emp-gig-page"}
                          data={item}
                          showEmpName={true}
                          empName={subrout}
                          hideDetailPage={true}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            ) : (
              <Col xs={24}>
                <Empty />
              </Col>
            )}
            <div className="see-all-wrapper">
              {Dimensions.width < 768 && (
                <Row
                  style={{
                    justifyContent: "center",
                    paddingBottom: "35px",
                  }}
                >
                  <Col span={18} style={{ textAlign: "center" }}>
                    <div
                      className="comp-main comp-heading2 fw-400"
                      onClick={() =>
                        SeeAllfunctionPvt(
                          data?.privateEmp?.Emp?.empTitle,
                          data?.privateEmp?.Emp?.EmpAccountId
                        )
                      }
                      style={{ cursor: getCount() < 3 && "default" }}
                    >
                      {getCount() > 3 && "See all"}
                    </div>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingRight: "2px",
                    }}
                  >
                    <span className="comp-heading2 fw-600">
                      <RightOutlined />
                    </span>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </div>

        <div className="OurCultureBorder"></div>

        <div className="Slider-Container" style={{ position: "relative" }}>
          <div className="Heading-OurCUlture">
            <p>Our Culture</p>
          </div>
 

          <Carousel
            ref={carouselRef}
            slidesToShow={
              data?.privateEmp?.Emp?.cultureImages?.length >= 3
                ? 3
                : data?.privateEmp?.Emp?.cultureImages?.length
            }
            dots={false}
            className="LandingPageCarausel"
          >
            {data?.privateEmp?.Emp?.cultureImages?.length &&
              data?.privateEmp?.Emp?.cultureImages.map((el, ind) => (
                <div style={{ margin: "0 5px 0 5px" }} key={el + ind}>
                  <img
                    src={getPictureUrl(el)}
                    alt={"culture -" + ind}
                    style={{ width: "95%", backgroundRepeat: "no-repeat" }}
                  />
                </div>
              ))}
            
          </Carousel>
          {data?.privateEmp?.Emp?.cultureImages?.length > 3 && (
            <>
              <Button
                className="Carausel-btn-left"
                type="primary"
                shape="square"
                icon={<BiChevronLeft style={{ fontSize: "1.8rem" }} />}
                onClick={prevSlide}
              />
              {/* Right Arrow button */}
              <Button
                className="carausel-btn-right"
                type="primary"
                shape="square"
                icon={<BiChevronRight style={{ fontSize: "1.8rem" }} />}
                onClick={nextSlide}
              />
            </>
          )}
        </div>
        {/* Left Arrow Button */}

        <div className="pvt-container" style={styles}>
          <div className="heading">
              {getHeading(subrout)}
          </div>
          <div className="content">
            {getContent(subrout)}
          </div>
        </div>
        <Row>
          <Col span={24}>
            <Row className="followUS">
              <Col span={24}>
                <Row justify="center">
                <h3 className="followUsText">Follow us</h3>
                </Row>
              </Col>
              <Col span={24} style={{marginTop:"15px"}}>
                <Row wrap={false} justify="center" gutter={[10, 0]}>
                  {data?.privateEmp?.Emp?.socialMedia?.length &&
                    data?.privateEmp?.Emp?.socialMedia.map((el) => (
                      <Col>
                        <a
                          href={"https://" + el?.link}
                          target="_blank"
                          rel="nonreferrer noreferrer"
                        >
                          <img src={getIconByType(el)} />
                        </a>
                      </Col>
                    ))}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const ApolloWrapper = (props) => {
  const BackendApiBaseUrl = process.env.REACT_APP_API_URL;
  const authTokenValue = useSelector(
    (state) => state.authReducer.user.authToken
  );

  const ImpersonationId = useSelector(
    (state) => state.authReducer.impersonationId
  );

  const httpLink = new HttpLink({
    uri: `${BackendApiBaseUrl}/query`,
  });
  // const user = useSelector((state) => state.profileReducer.user);
  const authLink = setContext((_, { headers }) => {
    const res = {
      headers: {
        ...headers,
        authorization: authTokenValue ? `${authTokenValue}` : "",
        // email: user.profile.EmailAddress,
      },
    };
    if (ImpersonationId) res.headers.impersonationId = ImpersonationId;
    // res.headers.impersonationId = ImpersonationId ? `${ImpersonationId}` : null;
    return res;
  });

  const client = new ApolloClient({
    // uri: `${BackendApiBaseUrl}/query`,
    cache: new InMemoryCache(),
    // link: ApolloLink.from([httpLink]),
    link: authLink.concat(httpLink),
  });

  // ///////////////////////////////////////////////////////////////
  // const userProfile = JSON.parse(localStorage.getItem("userProfile"));

  return (
    <ApolloProvider client={client}>
      <EmployerLandingPage {...props} />
    </ApolloProvider>
  );
};

export default ApolloWrapper;
