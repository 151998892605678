import React, { useState, useEffect } from "react";
import { Cache } from "aws-amplify";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga4";

// aws configuration
import { Auth, Hub } from "../../../config/aws-exports";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { updateUserInfo, userClicked } from "../../../redux/actions";

// react icons
import { FcGoogle } from "react-icons/fc";
import { BsLinkedin } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { GrApple } from "react-icons/gr";

import "./SocialOptions.css";
import {
  APP_NAME,
  EVENTS,
  PAGE,
  PAGE_TYPE,
} from "../../../constants/userTrackingConstants";
import { is } from "express/lib/request";

const SocialOptions = ({ signUpLogin, employerTitle, isStatic = false, isFbButton }) => {
  const dispatch = useDispatch();
  const uuid = useSelector((state) => state.userTrackingReducer.uuid);
  const employerDetails = useSelector((state) => state.employerReducer.emp);

  const storeEmployerDetails = () => {
    try {
      // if popup showing employer title we save employer details to local storage
      if (!employerTitle) return;
      const empDet = JSON.parse(localStorage.getItem("empDet"));
      localStorage.setItem("socialSignUpWithEmp", JSON.stringify(empDet));
    } catch (error) {
      console.warn("Error# 532143 ");
    }
  };

  const storeAsTalent = () => {
    try {
      localStorage.setItem("asTalent", true);
      localStorage.removeItem("asEmployer");
    } catch {}
  };

  return (
    <div>
      <section className="social-buttons-top-section">
      {/*<a href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77vyvft64zvktg&redirect_uri=http://localhost:3000/auth/linkedin/callback&state=foobar&scope=r_liteprofile">*/}
      <a
          onClick={() => {
            storeEmployerDetails();
            storeAsTalent();

            ReactGA.event({
              category: "User Interaction",
              action:
                (isStatic ? PAGE.STATIC : "") + " " + signUpLogin
                  ? EVENTS.LINKEDIN_SIGNUP_CLICK
                  : EVENTS.LINKEDIN_SIGNIN_CLICK,
              label: "Signup Button",
            });

            dispatch(
              userClicked({
                uuid: uuid,
                event: signUpLogin
                  ? EVENTS.LINKEDIN_SIGNUP_CLICK
                  : EVENTS.LINKEDIN_SIGNIN_CLICK,
                pageType: signUpLogin ? PAGE_TYPE.SIGNUP : PAGE_TYPE.SIGNIN,
                page: isStatic ? PAGE.STATIC : PAGE.MODAL,
                appName: APP_NAME.TALENT,
                url: window?.location?.pathname,
              })
            );
          }}
          href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LinkedInClientID}&redirect_uri=${process.env.REACT_APP_MY_URL}/linkcheck&state=foobar&scope=r_liteprofile%20r_emailaddress`}
        >
         
          <div
            className={
              isStatic
                ? "social-button apple-color"
                : "social-button linkedin-color"
            }
          >
            {isStatic ? (
              <FaLinkedin
                color="rgb(15 116 209)"
                size={16}
                style={{
                  position: "relative",
                  top: "4px",
                  marginLeft: "1.875rem",
                  marginRight: "0.15rem",
                }}
              />
            ) : (
              <BsLinkedin
                size={16}
                style={{
                  position: "relative",
                  top: "4px",
                  marginLeft: "1.875rem",
                  marginRight: "0.15rem",
                }}
              />
            )}
            <span
              className={isStatic ? "white-bg-button-font" : "buttonFont"}
              style={{ marginLeft: "1.625rem" }}
            >
              {isStatic ? "Continue" : signUpLogin ? "Sign up" : "Sign in"} with
              LinkedIn
            </span>
          </div>
        </a>
        <div
          className="social-button google-color"
          onClick={() => {
            ReactGA.event({
              category: "User Interaction",
              action:
                (isStatic ? PAGE.STATIC : "") + " " + signUpLogin
                  ? EVENTS.GOOGLE_SIGNUP_CLICK
                  : EVENTS.GOOGLE_SIGNIN_CLICK,
              label: "Signup Button",
            });

            dispatch(
              userClicked({
                uuid: uuid,
                event: signUpLogin
                  ? EVENTS.GOOGLE_SIGNUP_CLICK
                  : EVENTS.GOOGLE_SIGNIN_CLICK,
                pageType: signUpLogin ? PAGE_TYPE.SIGNUP : PAGE_TYPE.SIGNIN,
                page: isStatic ? PAGE.STATIC : PAGE.MODAL,
                appName: APP_NAME.TALENT,
                url: window?.location?.pathname,
              })
            );
            storeEmployerDetails();
            storeAsTalent();
            Auth.federatedSignIn({ provider: "Google" });
          }}
        >
          <FcGoogle
            size={16}
            style={{
              position: "relative",
              top: "4px",
              marginLeft: "1.875rem",
            }}
          />
          <span className="white-bg-button-font">
            {isStatic ? "Continue" : signUpLogin ? "Sign up" : "Sign in"} with
            Google
          </span>
        </div>
        { isFbButton ? <div
          className={
            isStatic
              ? "social-button apple-color"
              : "social-button facebook-color"
          }
          onClick={() => {
            ReactGA.event({
              category: "User Interaction",
              action:
                (isStatic ? PAGE.STATIC : "") + " " + signUpLogin
                  ? EVENTS.FACEBOOK_SIGNUP_CLICK
                  : EVENTS.FACEBOOK_SIGNIN_CLICK,
              label: "Signup Button",
            });
            dispatch(
              userClicked({
                uuid: uuid,
                event: signUpLogin
                  ? EVENTS.FACEBOOK_SIGNUP_CLICK
                  : EVENTS.FACEBOOK_SIGNIN_CLICK,
                pageType: signUpLogin ? PAGE_TYPE.SIGNUP : PAGE_TYPE.SIGNIN,
                page: isStatic ? PAGE.STATIC : PAGE.MODAL,
                appName: APP_NAME.TALENT,
                url: window?.location?.href,
              })
            );
            storeEmployerDetails();
            storeAsTalent();
            Auth.federatedSignIn({ provider: "Facebook" });
          }}
        >
          {isStatic ? (
            <FaFacebook
              color="rgb(15 116 209)"
              size={16}
              style={{
                position: "relative",
                top: "4px",
                marginLeft: "1.875rem",
              }}
            />
          ) : (
            <BsFacebook
              size={16}
              style={{
                position: "relative",
                top: "4px",
                marginLeft: "1.875rem",
              }}
            />
          )}
          <span className={isStatic ? "white-bg-button-font" : "buttonFont"}>
            {isStatic ? "Continue" : signUpLogin ? "Sign up" : "Sign in"} with
            Facebook
          </span>
        </div> : ""}

        <div
          className="social-button apple-color"
          onClick={() => {
            ReactGA.event({
              category: "User Interaction",
              action:
                (isStatic ? PAGE.STATIC : "") + " " + signUpLogin
                  ? EVENTS.APPLE_SIGNUP_CLICK
                  : EVENTS.APPLE_SIGNIN_CLICK,
              label: "Signup Button",
            });

            dispatch(
              userClicked({
                uuid: uuid,
                event: signUpLogin
                  ? EVENTS.APPLE_SIGNUP_CLICK
                  : EVENTS.APPLE_SIGNIN_CLICK,
                pageType: signUpLogin ? PAGE_TYPE.SIGNUP : PAGE_TYPE.SIGNIN,
                page: isStatic ? PAGE.STATIC : PAGE.MODAL,
                appName: APP_NAME.TALENT,
                url: window?.location?.href,
              })
            );
            storeEmployerDetails();
            storeAsTalent();
            Auth.federatedSignIn({ provider: "SignInWithApple" });
          }}
        >
          <GrApple
            size={16}
            style={{
              position: "relative",
              top: "3px",
              color: "black",
              marginLeft: "1.875rem",
            }}
          />
          <span
            className="white-bg-button-font"
            style={{ marginLeft: "2.25rem" }}
          >
            {isStatic ? "Continue" : signUpLogin ? "Sign up" : "Sign in"} with
            Apple
          </span>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    error: state.authReducer.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendAuthToken: (params) => {
      dispatch(updateUserInfo(params)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialOptions);
