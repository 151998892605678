import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import FiveStar from "../../assets/icons/Fivestar.svg";
import SingleStar from "../../assets/icons/Singlestar.svg";
import Tik from "../../assets/icons/Tik.svg";
import "./Ratingmodal.css";

function Ratingmodal() {
  const [isModal, setisModal] = useState(true);
  const hasShownModal =
    JSON.parse(localStorage.getItem("hasShownModal")) === null;
  console.log("has", hasShownModal);

  const documents = [
    ["Discipline", "Name", "Resume"],
    ["Specialty", "Mobile No.", "State License"],
  ];
  return (
    <div className="div-contains-modal">
      {hasShownModal ? (
        <Modal
          visible={isModal}
          className="Modal"
          footer={[
            <button
              className="resume-five-star-button"
              onClick={() => {
                setisModal(false);
                localStorage.setItem("hasShownModal", false);
              }}
            >
              Keep Going
            </button>,
          ]}
          closable={false}
        >
          <div className="main-container-modal">
            <div className="FiveStar">
              <img src={FiveStar} alt="Five Star Rating" />
            </div>
            <div className="Each-text">Earn a 5 star rating!</div>
            <div className="instruction">
              Make sure your profile is complete to earn a 5 star rating. A
              5-star rating means employers will offer you better gigs and more
              money.
            </div>
            <div className="documents">
              {documents.map((list, index) => (
                <ul key={index}>
                  {list.map((item, i) => (
                    <li key={i}>
                      <img src={Tik} alt="Check" className="check-image" />{" "}
                      {item}
                    </li>
                  ))}
                </ul>
              ))}
            </div>
          </div>
          <div className="Circle">
            <div className="Circle-Star">
              <img className="StarRatingModal" src={SingleStar} />
              <h1
                className="Number-rating"
                style={{
                  color: "#F4B400",
                  fontSize: "30px",
                  fontWeight: "600",
                }}
              >
                0
              </h1>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
}

export default Ratingmodal;
