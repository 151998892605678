import React, { useEffect } from "react";
import "./ReadRules.css";
import { useHistory } from "react-router";
import image from ".././../assets/img/image 431.png";
import imageIT from "../../assets/img/image 422.png";
import iconOne from "../../assets/img/icon-1.png";
import icontwo from "../../assets/icons/noun-skill-5643793 1.svg";
import icontwoIT from "../../assets/icons/Group (3).svg";
import iconThired from "../../assets/icons/search.svg";
import iconFour from "../../assets/img/icon-3.png";
import iconFive from "../../assets/icons/help-circle.svg";
import NursePicOne from "../../assets/img/Healthcare/NursePicOne.jpg";
import NursePicOneMob from "../../assets/img/Healthcare/NursePicOneMob.jpg";
import ItPicOne from "../../assets/img/Healthcare/ItPicOne.jpg";
import ItPicOneMob from "../../assets/img/Healthcare/ItPicOneMob.jpg";
import userCardIcon from "../../assets/img/user-identifier-card.svg";
import SearchIcon from "../../assets/img/UserCircle.svg";
import SearchIconbar from "../../assets/img/search.svg";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile, updateSignupProfile } from "../../redux/actions";
import { IdcardOutlined } from "@ant-design/icons";
import ReadRuleDesktop from "./ReadRuleComponent/ReadRuleDesktop";
import { BaseUrl } from "../../components/Footer";
import BottomButtons from "../SignUp/Components/BottomBottons/BottomButtons";
import { isNull } from "./helper";

export const ReadRules = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profileReducer.user);
  const { loading } = useSelector((state) => state.profileReducer);
  const employer = JSON.parse(localStorage.getItem("employer"));
  const signUpLoading = useSelector(
    (state) => state.profileReducer.signUpLoading
  );

  const RuleData = [
    {
      img: SearchIcon,
      Heading: "Set up your profile",
      Text: "Make sure to upload your resume, ALS/BLS, and skill checklist. These are critical for getting an interview from the facility.",
      TextIT:
        "Make sure to upload a resume and add your education and work history so that you get high visibility among the top employers.",
    },
    {
      img: userCardIcon,
      Heading: "Create a skill listing",
      Text: "Choose the gig type - perm, per diem, or contract/travel. This opens up opportunities to bid on gigs with similar skill matches.",
      TextIT:
        "Create a skill listing by choosing the gig type - perm and contract. The skill listing you create opens up the opportunities to bid on gigs with similar skill match.",
    },
    {
      img: SearchIconbar,
      Heading: "Search and bid on gigs",
      Text: "Find gigs based on your expertise and place bids on them. Multiple bids mean multiple chances of securing the assignment or job you want. You'll also receive bids from employers and marketplace representatives. These are hot gigs, so respond quickly to get the best results.",
    },
  ];

  const handleContinue = () => {
    dispatch(
      updateSignupProfile({
        userId: user.id,
        WizardFlow: 8,
      })
    );
    history.push("/effective-profile-info");
  };
  const handleBack = () => {
    dispatch(
      updateSignupProfile({
        userId: user.id,
        WizardFlow: 1,
      })
    );
    history.push("/profession");
  };

  const infoBar = (
    <div className="read-rule-getSupport-main">
      <div className="read-rule-getSupport">Get support</div>
      <div className="read-rule-getSupport-text">
        SkillGigs offers support at every step, from automatic reach outs and
        email reminders to career advice and platform assistance. If you have
        any doubts, submit a help{" "}
        <span
          onClick={() => {
            window.open(`${BaseUrl}/contact-us`);
          }}
        >
          form
        </span>{" "}
        or{" "}
        <span
          onClick={() =>
            (window.location.href = "mailto:hello@alerts-skillgigs.com")
          }
        >
          email
        </span>
        .
      </div>
    </div>
  );
  if (signUpLoading) return <Loader />;

  return (
    <React.Fragment>
      <div className="main-div-read-rules">
        <div className="read-rules-container">
          <div className="read-rules-main">
            <div className="read-rules-left-section">
              <div className="read-rules-left-section-heading">
                How to use SkillGigs?
              </div>
              {window.innerWidth < 768 && (
                <div className="read-rules-img-mobile">
                  {user?.profile?.ProfessionCode == "HC" ? (
                    <img
                      className="mobile-view-img"
                      style={{ borderRadius: "8px" }}
                      src={NursePicOne}
                      alt=""
                    />
                  ) : (
                    <img
                      className="mobile-view-img"
                      style={{ borderRadius: "8px" }}
                      src={ItPicOne}
                      alt=""
                    />
                  )}
                </div>
              )}
              <div className="read-rule-cantiner-section">
                {RuleData?.map((data) => {
                  return (
                    <ReadRuleDesktop
                      code={user?.profile?.ProfessionCode}
                      data={data}
                    />
                  );
                })}
                {infoBar}
                <div className="read-rules-btn-section">
                  {window.innerWidth > 768 ? (
                    <div className="btns-section">
                      {employer == null && (
                        <div onClick={handleBack} className="btn-senctin-back">
                          <span className="back-btn">Back</span>
                        </div>
                      )}
                      <div
                        onClick={handleContinue}
                        className="btn-senctin-continue"
                      >
                        <span className="continue-btn-read-rules">Next</span>
                      </div>
                    </div>
                  ) : (
                    <BottomButtons
                      nextHandler={handleContinue}
                      nextBtnLoading={loading}
                      backHandler={handleBack}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="read-rules-img">
              {user?.profile?.ProfessionCode == "HC" ? (
                <img
                  className="mobile-view-img"
                  style={{ borderRadius: "8px" }}
                  src={NursePicOne}
                  alt=""
                />
              ) : (
                <img
                  className="mobile-view-img"
                  style={{ borderRadius: "8px" }}
                  src={ItPicOne}
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReadRules;
