import React, { useState, useEffect, useRef } from "react";
import { DatePicker } from "antd";
import "../../CreateSkillListing.css";
import "./CreateSkillListingScreen.css";
import errorIcon from "../../../../../assets/img/ci_warning.png";
import { useHistory } from "react-router-dom";
import BottomBottons from "../Footer/BottomButtons";
import { useDispatch, useSelector } from "react-redux";
import {
  createSkillListing,
  sendEmailVerification,
  updateSignupProfile,
} from "../../../../../redux/actions";
import { alertNotification } from "../../../../../components/notifications/alertNotification";
import moment from "moment";

const StartDateSection = (props) => {
  const dispatch = useDispatch();
  const title = "Desired start date";
  const [selectedDate, setSelectedDate] = useState("");
  const [showError, setShowError] = useState(false);
  const [redirectionFlag, setRedirectionFlag] = useState(false);
  const history = useHistory();
  const profiledata = useSelector((state) => state.profileReducer.user);
  const professionGig = localStorage.getItem("ProfessionsCode");

  const { ResumeFilename: resume, WizardFlow } = useSelector(
    (state) => state.profileReducer.user.profile
  );
  const username = useSelector((state) => state.profileReducer.user.profile);

  const skillId = useSelector(
    (state) => state?.skillListingReducer?.currentSkillListing?.SkillListingId
  );

  const successStatus = useSelector(
    (state) => state?.skillListingReducer?.statusCode
  );


  const profession = useSelector(
    (state) => state.profileReducer.user.profile.ProfessionCode
  );

  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );
  const datePickerRef = useRef(null);
  const [open, setOpen] = useState(false);

  const skillListingFunction = () => {
    // localStorage.removeItem("hasShownModal")
    dispatch(
      updateSignupProfile({
        WizardFlow: 1000,
      })
    );
    dispatch(sendEmailVerification());

    if (localStorage.getItem("JobDetailpageUrl")?.slice(0, 7) === "/detail") {
      if (profession === professionGig) {
        history.push(localStorage.getItem("JobDetailpageUrl"));
      } else {
        localStorage.removeItem("JobDetailpageUrl");
        localStorage.removeItem("ProfessionsCode");
        alertNotification(
          "warning",
          `Hi ${username.FirstName} ${username.LastName}, The selected gig is not applicable to your profile, please find relevant gigs`
        );
        history.push("/");
      }
    } else if (localStorage.getItem("JobDetailpageUrl")) {
      history.push(localStorage.getItem("JobDetailpageUrl"));
    } else {
      if (WizardFlow === 1000) {
        console.log("this condition");
        if (isEmployerSignUp()) {
          const employer = JSON.parse(localStorage.getItem("employer"));
          localStorage.setItem("employerDetails", JSON.stringify(employer));
          localStorage.removeItem("employer");
          const redirectUrl = localStorage.getItem('redirectUrl')
          history.push(`${redirectUrl||'/'}?isEmployer=true`);
          // history.push("/?isEmployer=true");
        } else history.push("/");
      }
    }

    history.push(`/skill-listing-detail-page/${skillId}`);
  };
  const isEmployerSignUp = () => {
    const employer = JSON.parse(localStorage.getItem("employer"));
    return employer != null;
  };

  useEffect(() => {
  if (WizardFlow === 1000) {
    console.log("running this condition");
    if (isEmployerSignUp()) {
        const employer = JSON.parse(localStorage.getItem("employer"));
        localStorage.setItem("employerDetails", JSON.stringify(employer));
      localStorage.removeItem("employer");
      localStorage.removeItem("socialSignUpWithEmp");
      const redirectUrl = localStorage.getItem('redirectUrl')
      history.push(`${redirectUrl||'/'}?isEmployer=true`);
      // history.push("/?isEmployer=true");
    } else history.push("/");
  }
  }, [successStatus === 200]);

  useEffect(() => {
  if (window.location.href.indexOf("/talent/create-skilllisting-date") != -1) {
    window.gtag("event", "conversion", {
      send_to: "AW-972299611/SK8lCNT0vpIYENu60M8D",
    });
}
  }, [successStatus === 200]);

  useEffect(() => {
    if (apiSuccess && redirectionFlag && successStatus === 200) {
      if (profession === "HC") {
        skillListingFunction();
      } else {
        history.push('/user-signup-success');
      }
      setRedirectionFlag(false);
    }
  }, [apiSuccess, redirectionFlag, successStatus, profession, history]);


  useEffect(() => {
    if (profiledata?.profile?.DraftSkillListing?.StartDate) {
      setSelectedDate(
        moment(profiledata?.profile?.DraftSkillListing?.StartDate)
      );
    }
  }, [profiledata?.profile?.DraftSkillListing?.StartDate]);

  // useEffect(() => {
  //   dispatch(
  //     updateSignupProfile({
  //       WizardFlow: 206,
  //     })
  //   );
  // }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (datePickerRef.current) {
        setOpen(true);
      }
    }, 100); 
    return () => clearTimeout(timer);
  }, []);

  const handleOpenChange = (open) => {
    setOpen(open);
  };

  const handleNext = () => {
    if (selectedDate) {
      let draftSkillListing = {
        ...profiledata?.profile?.DraftSkillListing,
        StartDate: new Date(selectedDate)?.toISOString(),
        fromSignUp: true,
      };
      if (
        draftSkillListing.GigType == "Contract" ||
        draftSkillListing.GigType == "Per Diem"
      ) {
        draftSkillListing.ClientRate = draftSkillListing.MinimumBid * 1.49339;
      } else {
        draftSkillListing.ClientRate = draftSkillListing.MinimumBid;
      }
      if(profession === "HC")
      dispatch(
        updateSignupProfile({
          "DraftSkillListing.StartDate": new Date(selectedDate)?.toISOString(),
          WizardFlow: 1000,
        })
      );
      else
      dispatch(
        updateSignupProfile({
          "DraftSkillListing.StartDate": new Date(selectedDate)?.toISOString(),
        })
      );
      dispatch(
        createSkillListing({
          ...draftSkillListing,
          "Profiles.Accounts.Discipline":
            profiledata?.profile?.Discipline,
          "Profiles.Accounts.Speciality": [
            ...profiledata?.profile?.Speciality,
          ],
        })
      );
      // alertNotification("success", "Skill Listing created successfully");

      // history.push("/basic-profile-success");
      setRedirectionFlag(true);
  
    } else {
      setShowError(true);
    }
  };
  

  const handleBack = () => {
    history.push("/create-skilllisting-salary");
  };

  const Skiphandle = () => {
    // if (selectedDate || true) {
      let draftSkillListing = {
        ...profiledata?.profile?.DraftSkillListing,
        // StartDate: new Date(selectedDate)?.toISOString(),
        fromSignUp: true,
      };
      if (
        draftSkillListing.GigType == "Contract" ||
        draftSkillListing.GigType == "Per Diem"
      ) {
        draftSkillListing.ClientRate = draftSkillListing.MinimumBid * 1.49339;
      } else {
        draftSkillListing.ClientRate = draftSkillListing.MinimumBid;
      }
      // dispatch(
      //   updateSignupProfile({
      //     "DraftSkillListing.StartDate": new Date(selectedDate)?.toISOString(),
      //   })
      // );
      dispatch(
        createSkillListing({
          ...draftSkillListing,
          "Profiles.Accounts.Discipline":
            profiledata?.profile?.Discipline,
          "Profiles.Accounts.Speciality": [
            ...profiledata?.profile?.Speciality,
          ],
        })
      );
      // alertNotification("success", "Skill Listing created successfully");

      // history.push("/basic-profile-success");
      setRedirectionFlag(true);
    // } 
  };

  return (
    <div className="CreateSkillListingHeaderScreen2">
      <div className="create-skill-listing-title">
        {title} <span className="Mandatory-Text">(Optional)</span>
      </div>
      <div className="create-skill-listing-subheader-other">
        <span>
          When can you start? Let potential employers know your availability.
        </span>
      </div>
      <DatePicker
        disabledDate={(current) => {
          return current && current.valueOf() < Date.now();
        }}
        value={selectedDate ? moment(selectedDate) : null}
        placeholder="MM DD, YYYY"
        format="MMMM DD, YYYY"
        className={`date-container-datepicker${
          showError ? " error-block" : ""
        }`}
        name="availableToStart"
        inputReadOnly={true}
        ref={datePickerRef}
        open={open}
        onOpenChange={handleOpenChange}
        onChange={(date, dateString) => {
          if (dateString) {
            setSelectedDate(new Date(dateString)?.toISOString());
            setShowError(false);
          } else {
            setSelectedDate("");
          }
        }}
        suffixIcon={null}
      />
      {showError && (
        <div className="error-msg-content">
          <img src={errorIcon} alt="" />
          <span className="error-text">
            Please enter your start date to continue
          </span>
        </div>
      )}
      <BottomBottons
        loading={!apiSuccess}
        handleBack={handleBack}
        handleNext={handleNext}
        Skiphandle={Skiphandle}
        Skip={true}
      />
    </div>
  );
};

export default StartDateSection;
