import React, { Fragment, useEffect, useState } from "react";
import {
  Menu,
  Input,
  Space,
  Dropdown,
  Form, Drawer, Select,
  Button,
  Radio,
} from "antd";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

// import {
//   fetchMyGigsKeySearchSkills,
// } from "../../../../redux/actions";
import _ from "lodash";
import { fetchSearchSkill } from "../../../redux/actions";

const PARAM_KEYS = {
  city: "location",
  state: "region",
  country: "country",
  skill: "skill",
};

const OTHER_FILTERS = {
  BudgetMax: null,
  BudgetMin: null,
  BudgetType: null,
  CityId: [],
  CountryId: [],
  Experience: [],
  GigType: null,
  Latitude: null,
  Longitude: null,
  NearBy: null,
  ProfessionCode: null,
  RegionId: [],
  SkillsId: [],
  hide: true,
  maxDistance: "50",
  search: "",
  searchType: "Skills",
  _page: 1,
  _perPage: "24",
  _sort: "MOST_RELEVANT",
};

const SkillDropDown = ({
  isSelected,
  label,
  paramKey = "",
  searchFilters,
  filterKey,
  handleApply,
  isMobileView = false,
  selectedOptions,
  setSelectedOptions,
  drawerTitle = "",
  searchText = "",
  defaultParams = {},
  isActive,
  toggleFilter
  // handleDebounceSearch=()=>{}
}) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const skills = useSelector((state) => state.skillListingReducer.skills);

  const [isOpen, setIsOpen] = useState(false);
  const [isError, setIsError] = useState(true);
  const [list, setList] = useState([]);

  const handleDocumentClick = (e) => {
    if (!e.target.closest('.searchResultsFilterDropdown')) {
      setIsOpen(false);
      document.removeEventListener('click', handleDocumentClick);
    }
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    setIsOpen(prev => !prev);
    if (!isOpen) {
      document.addEventListener('click', handleDocumentClick);
    } else {
      document.removeEventListener('click', handleDocumentClick);
    }
    setSearchValue("");
    setIsOpen(!isOpen);
    if (!searchFilters[filterKey]?.length) {
      setSelectedOptions(null);
    }
    handleSearch({ target: { value: "" } });
  };

  const handleDebounceSearch = _.debounce((params) => {
    // dispatch(fetchMyGigsKeySearchSkills(params));
    dispatch(fetchSearchSkill(params))
  }, 500);

  const handleSearch = ({ target: { value } }) => {
    setSearchValue(value);
    // const query = `${paramKey}=${value || ""}`;
    const params = {
      val: value,
    };
    handleDebounceSearch(params);
  };

  const onChangeRadio = (e) => {
    const item = JSON.parse(e.target.value) || {};
    // const itemId = getItemId(item);
    if (e.target.checked) setSelectedOptions(item);
    onClickApply(item);
  };

  const getItemId = (item) => {
    return item?.SkillId;
  };

  const getTitle = (item) => {
    switch (paramKey) {
      case PARAM_KEYS.skill:
        return item?._id;
      default:
        return "";
    }
  };

  const onClickApply = (_selectedOption) => {
    setIsOpen(false);
    setIsShowDrawer(false);
    if (_selectedOption) {
      handleApply([getItemId(_selectedOption)], filterKey, [
        {
          title: getTitle(_selectedOption),
          filterKey,
          id: getItemId(_selectedOption),
        },
      ]);
    }
  };

  const onClickClear = () => {
    setIsOpen(false);
    setSelectedOptions(null);
    handleApply([], filterKey, []);
  };

  const menu = (
    <div className="location-suggestion-view">
      <Input
        onChange={handleSearch}
        className="filter-search-input"
        placeholder="Search"
        prefix={<SearchOutlined />}
        value={searchValue}
      />
      <Space
        direction="vertical"
        className={"infinite-scroller mobile-no-scroller"}
        style={{ overflow: "auto", width: "103%", paddingRight: "8px" }}
      >
        <div
          className={`location-lists-container ${list?.length > 5 ? "show-scrollbar" : ""
            }`}
        >
          {list?.length ? (
            <>
              {list.map((items, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "10px",
                      padding: "5px",
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "inherit",
                      }}
                    >
                      <Radio
                        type={"radio"}
                        value={JSON.stringify(items)}
                        className="location-radio"
                        onChange={onChangeRadio}
                        checked={searchFilters?.Skills?.[0] == getTitle(items)}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {" "}
                          <div>{getTitle(items)}</div>
                        </div>
                      </Radio>
                      {/* <>
                        {" "}
                        <div className="countDiv">({items?.count})</div>{" "}
                      </> */}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#7e7e7e",
              }}
            >
              {" "}
              No results found{" "}
            </div>
          )}
        </div>
      </Space>
    </div>
  );

  useEffect(() => {
    setList(skills);
  }, [skills]);

  const [isShowDrawer, setIsShowDrawer] = useState(false);

  const handleDeselect = (item) => {
    onClickClear();
  };

  const onCloseDrawer = () => {
    setIsShowDrawer(false);
    if (selectedOptions) onClickApply(selectedOptions);
  };

  const containerStyle = {
    position: "relative",
    height: "100vh !important",
    overflow: "hidden",
    // textAlign: 'center',
  };

  if (isMobileView) {
    return (
      <Fragment>
        <Drawer
          className="location-drawer"
          onClose={onCloseDrawer}
          visible={isShowDrawer}
          closable={true}
          placement={"top"}
          // afterOpenChange={(e) => {
          //   handleCountryChange(e);
          // }}
          contentWrapperStyle={containerStyle}
          title={drawerTitle}
        >
          {menu}
        </Drawer>
        <Form>
          <Form.Item>
            <Select
              value={getTitle(selectedOptions)}
              placeholder={"Search " + label}
              className="input"
              mode="multiple"
              bordered={false}
              onDeselect={handleDeselect}
              onClick={() => {
                setSearchValue("");
                handleSearch({ target: { value: "" } });
                setIsShowDrawer(true);
              }}
              showSearch
              listHeight={160}
              open={false}
            ></Select>
          </Form.Item>
        </Form>
      </Fragment>
    );
  }

  const getWebMenu = () => {
    return (
      <Menu
        style={{ width: "50%" }}
        className={`location-filter-menu ${isError === "city" && "filterError"
          }`}
      >
        <div className="location-filter-menu-inner" style={{ padding: "10px" }}>{menu}</div>
      </Menu>
    );
  };

  return (
    <Dropdown
      overlayClassName="searchResultsFilterDropdown"
      overlay={getWebMenu()}
      trigger="click"
      visible={isOpen && isActive}
      destroyPopupOnHide={true}
      onClick={handleOpen}
      onVisibleChange={(open) => setIsOpen(open)}
    >
      <Button
        className={
          isSelected > 0 ? "visaStatus-dropdown-select" : "visaStatus-dropdown"
        }
        onClick={toggleFilter}
        style={{
          backgroundColor: 'transparent',
          borderColor: '#d9d9d9',
          color: 'initial',
          height: "auto",
          borderRadius: "3px"
        }}
      >
        {label} <DownOutlined />
      </Button>
    </Dropdown>
  );
};


export default SkillDropDown;
