import React, { useEffect, useState, useRef } from "react";
import { IoLocationSharp, IoBriefcase } from "react-icons/io5";
import { BsCurrencyDollar, BsStarFill } from "react-icons/bs";
import { FaDollarSign } from "react-icons/fa";
import { HiClock } from "react-icons/hi";
import { HeartFilled, StarOutlined } from "@ant-design/icons";
import Private from "../../assets/icons/Private.svg"
import { Popover, Rate } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { alertNotification } from "../../components/notifications/alertNotification";


import "./GigsCard.css";
import {
  createBidReset,
  // favoritefunctiontrigger,
  // favoriteGigCard,
  isModalVisibleAuth,
  postFavoriteGigCard,
  removeFavoriteGigCard,
  removeFavoriteGigCardReducer,
  removeFavoriteGigCardSuccess,
  ResetBidData,
  retractBidReset,
  retractedBidUpdate,
  updateBidReset,
  UpdateSearchFilter,
} from "../../redux/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { getImageFromCloudinary, RemoteUrl } from "../../shared/helpers";
import moment from "moment";

import messageIcon from "../../assets/icons/messageIcon.svg";
import StageBar from "../../components/StageBar/StageBar";
import { state } from "@antv/g2plot/lib/adaptor/common";
import { useUpdateLogger } from "../../shared/useUpdateLogger";
import { DefaultsRating } from "../WelcomeWizard/helper";
import PrivateTag from "./components/privateTag";

const bidsData = [
  {
    bidReceived: "4100/wk",
    title: "RN - ICU Nurse",
    facility: "Kindred Hospital, Central Tampa, ...",
    gigTitle: "Del Sol Medical Center El Paso",
    location: "Pittsburgh, PA",
    gigRate: "4000/wk",
  },
  {
    bidReceived: "4200/wk",
    title: "RN - ICU Nurse",
    facility: "Kindred Hospital, Central Tampa, ...",
    gigTitle: "Del Sol Medical Center El Paso",
    location: "Pittsburgh, PA",
    gigRate: "4000/wk",
  },
  {
    bidReceived: "4300/wk",
    title: "RN - ICU Nurse",
    facility: "Kindred Hospital, Central Tampa, ...",
    gigTitle: "Del Sol Medical Center El Paso",
    location: "Pittsburgh, PA",
    gigRate: "4000/wk",
  },
];

// const screenWidth = window.screen.width;

const GigsCard = ({ hideDetailPage = false, employerTitle = null, showEmpName = false, empName = null, containerClass = null, ...props }) => {
  const gig = props.data;

  const bid = props.bid;
  const budgetType = props.budgetType;
  const history = useHistory();
  const dispatch = useDispatch();
  const locationURL = useLocation().pathname;
  const [budgetHourly, setBudgetHourly] = useState(false);
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const { isModalVisibleAuth, setIsModalVisibleAuth } = props;
  const [IsFavoriteStatus, setIsFavoriteStatus] = useState("0");
  const [nextCounter, setNextCounter] = useState(0);
  const [truncatedStr, settruncatedStr] = useState(dimensions > 720 ? 42 : 33);

  const authTokenValue = useSelector(
    (state) => state.authReducer.user.authToken
  );
  const IsFavoritesValuetrigger = useSelector(
    (state) => state.searchGigsReducer.IsFavoritesValue
  );
  const ProfessionCode = gig?.Professions?.ProfessionCode;
  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };
  // useSelector(
  //   (state) => state.profileReducer?.user?.profile?.ProfessionCode
  // ) || "HC";
  const [stageBids, setStageBids] = useState(0);
  const [budgetMin, setBudgetMin] = useState(0);
  const PlacedBid = () => {
    dispatch(createBidReset());
    dispatch(updateBidReset());
    dispatch(ResetBidData());

    history.push({
      pathname: `/create-bid`,
      state: {
        title: gig.Title,
        FacilityName: gig.FacilityName,
        RegionName: gig.Location.Location,
        budget: gig.BudgetMin,
        id: gig._id,
        AccountRole: gig?.AccountRole,
      },
    });
  };
  const detailsPage = () => {

    if(props?.handleBeforeUnload){

      props?.handleBeforeUnload();
    }
    dispatch(retractedBidUpdate(false));
    history.push({
      pathname: `/details/${gig.JobOrderId}`,
      state: {
        IsFavoritesdata: gig?.IsFavorite,
        id: gig?.JobOrderId,
        // EmpName:empName
      },
    });
  };

  const location =
    gig?.Location && gig?.Location["Location"]
      ? gig?.Location["Location"]
      : gig?.Cities && gig?.Cities["CityName"]
      ? `${gig?.Cities["CityName"]}${
          gig?.Regions && gig?.Regions["RegionName"]
            ? `, ${gig?.Regions["RegionName"]}`
            : ""
        }`
      : "Not disclosed";

  // useUpdateLogger("gigGigData:", gig);
  // const [dimensions, setDimensions] = useState(window.innerWidth);

  // const checkDimensions = () => {
  //   setDimensions(window.innerWidth);
  // };
  const addQueryParam = () => {
    if (!employerTitle) return;
    // const employer = JSON.parse(localStorage.getItem("employer"));
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set("empTitle", employerTitle);
    const newUrl = window.location.pathname + "?" + urlSearchParams.toString();
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  const getEmployerQuery = (emp) => {
    return `empTitle=${emp}`;
  };

  const onHeartClick = (e) => {
    e.stopPropagation();
    localStorage.setItem(
      "Favorite",
      JSON.stringify({ JobOrderId: gig?.JobOrderId, gig })
    );
    handleLogin()
  }

  const handleLogin = () => {
    if (dimensions < 769 ||true) {
      if (showEmpName)
        history.push(`/login?${getEmployerQuery(empName)}`);
      else
      history.push("/login");
    } else {
      addQueryParam()
      setIsModalVisibleAuth(true);
    }
  }

  // useEffect(() => {
  //   if (gig?.IsFavorite === false) {
  //     setIsFavoriteStatus("0");
  //     // 
  //   } else {
  //     setIsFavoriteStatus("1");
  //     // 
  //   }
  // }, [gig?.IsFavorite]);
  // // 

  // const dataToBeRendered = () => {
  //   if (dimensions <= 600) {
  //     return bidsData.filter((data, i) => i === 0);
  //   } else return bidsData;
  // };

  // Gig created on
  // let msec = Number(gig?.Created);
  // let time = new Date(msec);
  let time = new Date(gig?.Created?.toString());
  let timeInSerach = new Date(Number(gig?.Created));

  // // 
  let year = time.getFullYear();
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let month = months[time.getMonth()];
  let date = time.getDate();
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let day = days[time.getDay()];
  const dates = { day, date, month, year }.toString();

  // Gig Expires on
  let expmsec = Number(gig?.ExpiryDate);
  let exptime = new Date(expmsec);
  let expyear = exptime.getFullYear();
  let expmonth = months[exptime.getMonth()];
  let expdate = exptime.getDate();
  let expday = days[exptime.getDay()];
  const expdates = { expday, expdate, expmonth, expyear }.toString();

  useEffect(() => {
    // 
  }, [locationURL]);

  useEffect(() => {
    // 
    if (budgetType === "Hourly") {
      setBudgetHourly(true);
    } else {
      setBudgetHourly(false);
    }
  }, [gig, budgetType, onHeartClick]);
  useEffect(() => {
    const BudgetMin = gig?.BudgetMin?.$numberDecimal
      ? gig?.BudgetMin?.$numberDecimal
      : gig?.BudgetMin
      ? gig?.BudgetMin
      : 0;
    setBudgetMin(BudgetMin);
    // // 
  }, [gig?.BudgetMin]);
  // const budget = () => {
  //   return(
  //     {budgetType ? (<><span>${gig?.BudgetMin ? Number(gig?.BudgetMin) * 40 : 0}/WK</span></>) : (<><span>${gig?.BudgetMin ? gig?.BudgetMin : 0}/HR</span></>)}

  //     )
  // };

  const AfterLoginonHeartClick = (e) => {
    if (!gig?.IsFavorite) {
      // 
      alertNotification("success", "gig added to favorites");
      dispatch(postFavoriteGigCard(gig?.JobOrderId, "Gig", gig));
      if (locationURL.includes("/search/") || locationURL.includes("/company-jobs/")) {
        props.setFavorite(gig?.JobOrderId, true);
      }
    } else if (gig?.IsFavorite) {
      dispatch(removeFavoriteGigCard(gig?.JobOrderId));
      if (locationURL.includes("/search/") || locationURL.includes("/company-jobs/")) {
        props.setFavorite(gig?.JobOrderId, false);
      }
      // 
      alertNotification("success", "gig removed from favorites");
      if (props?.handleTotalCardFav) {
        props?.handleTotalCardFav();
      }
    }
    e.stopPropagation();
  };

  const storeRedirectUrlToLocalStorage = () => { 
    if(gig?.JobOrderId){
      localStorage.setItem('redirectUrl',`/details/${gig.JobOrderId}`)
      localStorage.removeItem('JobDetailpageUrl')
   }}
  const budgetTypeCode = gig?.BudgetTypes?.BudgetTypeCode||'hr'; 
  // 
  const isShowBudgetAmount = () => ProfessionCode === "HC" && gig?.BudgetMin && gig?.ExternalGigId>0

  return (
    <React.Fragment>
      <div
        className={`sgigs-thecard ${containerClass}`}
        onClick={() => {
          if (hideDetailPage && authTokenValue === null) {
            storeRedirectUrlToLocalStorage();
            handleLogin();
          } else {
            detailsPage();
          }
        }}
      >
        <img
          src={
            ["fully-remote", "remote"].includes(
              (gig?.WorkplacePolicy || "").toLowerCase()
            )
              ? RemoteUrl
              : getImageFromCloudinary(
                  gig?.Regions?.Code,
                  gig?.Cities?.CityName,
                  nextCounter
                )
          }
          className="cand-sgigs-image"
          onError={(e) => setNextCounter(nextCounter + 1)}
          alt="gig-img"
        />
        <div className="sgigs-upAbove">
          <div className="sgigs-theTitle">{gig?.Title ? gig?.Title : "NA"}</div>
          {/* <div className="sgigs-theFacility">
            {ProfessionCode === "HC"
              ? gig?.FacilityName
                ? gig?.FacilityName
                : "NA"
              : gig?.WorkplacePolicy
              ? gig?.WorkplacePolicy
              : "NA"}
          </div> */}
          <div className="sgigs-information GigCard-CompanyName-FacilityName">
            {/* <Rate count={1} disabled={true} value={1} /> */}
            {/* {ProfessionCode === "HC" ? ( */}
            <div className="sgigs-review GigCard-CompanyName-FacilityName-wrap">
              <Popover
                content={gig?.FacilityName}
                trigger={
                  gig?.FacilityName?.length > truncatedStr ? "hover" : "click"
                }
              >
                <div
                  onClick={(e) => e.stopPropagation()}
                  className={`GigCard-CompanyNameIT-FacilityNameHC ${
                    props?.companyTitleClass || null
                  }`}
                >
                  {ProfessionCode === "HC"
                    ? gig?.FacilityName
                      ? gig?.FacilityName?.substring(0, truncatedStr)
                      : "NA"
                    : gig?.companyName||gig?.CompanyName
                    ? gig?.companyName || gig?.CompanyName
                    : showEmpName
                    ? empName
                    : null}
                </div>
              </Popover>
            </div>
            <div
              className={`information-left-outer ${
                gig?.companyName ||gig?.CompanyName||
                (gig?.FacilityName && ProfessionCode === "HC") ||
                showEmpName
                  ? "GigCard-Rating-section-job"
                  : null
              }`}
            >
              <div className="information-left">
                <BsStarFill className="sgigs-star" />
              </div>
              <div className="information-right">
                <div className="sgigs-rating">
                  {gig?.FacilityName?.toLowerCase()?.includes("skillgigs") ||
                  gig?.companyName?.toLowerCase()?.includes("skillgigs") ||
                  gig?.CompanyName?.toLowerCase()?.includes("skillgigs")
                    ? 4.2
                    : gig?.facilityRating?.rating
                    ? gig?.facilityRating?.rating
                    : DefaultsRating}
                </div>
                <div className="review">
                  {gig?.FacilityName?.toLowerCase()?.includes("skillgigs") ||
                  gig?.companyName?.toLowerCase()?.includes("skillgigs")||
                  gig?.CompanyName?.toLowerCase()?.includes("skillgigs")
                    ? `(${gig?.facilityRating?.reviews || 49})`
                    : `(${gig?.facilityRating?.reviews || 0})`}
                </div>
              </div>
            </div>
          </div>
          <div className="sgigs-information">
            <div className="information-left-outer">
              <div className="sgigs-IT-Experience">
                {gig?.WorkplacePolicy === "fully-remote" ? "Remote" : location}{" "}
              </div>
            </div>

            <div className="sgigs-review">
              <div className="posted-on">
                {props?.Type
                  ? moment(moment(timeInSerach)?.toISOString())?.fromNow()
                  : moment(time).fromNow() == "Invalid Date"
                  ? moment(
                      moment(
                        props?.Types == "pvtGigCard" || props?.Types == "MostPopular" || props?.Types == "Suggested" || 
                          props?.Types == "Favorites" || props?.Types == "ContinueBrowsing"
                          ? time
                          : timeInSerach
                      )?.toISOString()
                    )?.fromNow()
                  : moment(
                      moment(
                        props?.Types == "pvtGigCard" || props?.Types == "MostPopular" || props?.Types == "Suggested" || 
                          props?.Types == "Favorites" || props?.Types == "ContinueBrowsing" 
                          ? time
                          : timeInSerach
                      )?.toISOString()
                    )?.fromNow()}
              </div>
            </div>
          </div>
          {gig?.isPrivate === true && authTokenValue ? (
            <div>
              <img src={Private} alt="Private Tag" />
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="dividerGigCard">
          <div className="sgigs-gigcard-interestedBtn">
            {isShowBudgetAmount() ? (
              <div className="budget-amount">
                <div className="sgigs-Budgets">
                  <div>
                    ${gig?.BudgetMin ? gig?.BudgetMin : 0}/
                    {budgetTypeCode.toUpperCase()}
                  </div>
                </div>
                <div className="sgigs-div1">Pay Package</div>
              </div>
            ) : null}
            <div
              onClick={
                authTokenValue === null
                  ? (e) => onHeartClick(e)
                  : (e) => AfterLoginonHeartClick(e)
              }
              style={{ cursor: "pointer",marginLeft:'auto' }}
            >
              {locationURL === "/myfavorites" ||
              props.Types === "Favorites" ||
              gig?.IsFavorite ? (
                <Rate
                  character={
                    <HeartFilled
                      style={{ color: "#ff4343", cursor: "pointer" }}
                    />
                  }
                  count={1}
                  allowClear
                  value={gig?.IsFavorite === true ? 1 : 0}
                  disabled={true}
                />
              ) : (
                <Rate
                  character={<HeartFilled style={{ cursor: "pointer" }} />}
                  count={1}
                  allowClear
                  value={gig?.IsFavorite === true ? 1 : 0}
                  disabled={authTokenValue === null ? true : false}
                />
              )}
            </div>
          </div>
        </div>
        {/* {ProfessionCode === "HC" ? (
            <div className="sgigs-div1">Pay Package</div>
          ) : null} */}
      </div>
      {/* ); */}
    </React.Fragment>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setIsModalVisibleAuth: (params) => {
      dispatch(isModalVisibleAuth(params));
    },
  };
};

export default connect(null, mapDispatchToProps)(GigsCard);
