import React from "react";
import "./TextInput.css";
import { Input } from "antd";
import { WarningFilled } from "@ant-design/icons";
function TextInput({ title, type, placeholder, onChange, value, error, maxLength }) {
  return (
    <div className="TextInputParentContainer">
      <div className="textInputContainer">
        <div className="TextInputTitleContainer">
          <div className="TextInputTitle-field">{title}</div>
          <div className="TextInputType-field"><span>({type})</span></div>
        </div>
        <Input
          defaultValue={null}
          onChange={(e) => onChange(e)}
          autoFocus
          className={`TextInputStylefield${error ? 'Error' : ''}`}
          placeholder={placeholder}
          value={value}
          status={error ? 'error' : ''}
          help={error}
          maxLength={maxLength ?? 100}
        />
        {error && 
          <div className="errorMsg">
          <WarningFilled className="errorIcon"/><span className="errorMsg-content">{error}</span>
          </div>
        }
      </div>
    </div>
  );
}
export default TextInput;
