import React, { useState, useEffect } from "react";
import "./LostPassword.css";
import { Form, Input, Button, Checkbox, Tooltip, message } from "antd";
import "antd/dist/antd.css";
import {
  UserOutlined,
  MailOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Auth } from "../../../config/aws-exports";
import { FaCheckCircle } from "react-icons/fa";
import Infomessage from "../../../components/Infomessage";
import { fetchExistingtUser } from "../../../redux/actions";
import { connect } from "react-redux";
import backArrow from "../../../assets/icons/backArrow.svg"

const LostPassword = (props) => {
  const [form] = Form.useForm();
  const [state, setState] = useState("sendmail");
  const [emailid, setEmailid] = useState();
  const [count, setCount] = useState(false);
  const [errormessage, setErrorMessage] = useState("");
  const [errorCodemessage, seterrorCodemessage] = useState("");
  const [LengthOTP, setLengthOTP] = useState();
  const [OTPMessageTrue, SetOTPMessageTrue] = useState(false);
  const [messagetype, setMessageType] = useState("container-error");
  const [passwordMisMatch, setpasswordMisMatch] = useState("");
  const [DisableButton, setDisableButton] = useState();
  const history = useHistory();
  const textemail = <span>example: example@mail.com</span>;
  const loginPage = () => {
    history.push("/login");
  };
  const textpass = <span>Minimum 8 characters required</span>;

  useEffect(() => {
    if (LengthOTP < 6) {
      SetOTPMessageTrue(true);
      seterrorCodemessage("");
    } else if (errorCodemessage) {
      SetOTPMessageTrue(true);
    } else {
      SetOTPMessageTrue(false);
    }
  }, [errorCodemessage, LengthOTP]);

  useEffect(() => {
    setCount(false);
    const checkUser = props.user.userExist;
    if (
      checkUser &&
      checkUser.valid &&
      checkUser.cognitoTriggerSource !== "PreSignUp_ExternalProvider"
    ) {
      setState("mailSent");
      onForgot(emailid);
    }
    if (emailid && !checkUser.valid) {
      setDisableButton(false);
      setErrorMessage("It looks like you signed up with a social account. Please reset your password directly through your social account provider.");
      setCount(true);
      setTimeout(() => {
        setCount(false);
      }, 4000);
    }
    if (
      emailid &&
      checkUser.cognitoTriggerSource === "PreSignUp_ExternalProvider"
    ) {
      setMessageType("container-error");
      setErrorMessage("OOPS, can't reset password for social login");
      setCount(true);
      setTimeout(() => {
        setCount(false);
      }, 4000);
    }
    // setState(data);
  }, [props.user.userExist]);
  useEffect(() => {
    setState("sendmail");
  }, [props.backClick]);

  const checkUserEmail = ({ email }) => {
    setDisableButton(true);
    setEmailid(email);
    if (email) {
      props.checkUser(email?.toLowerCase());
    }
  };

  const onForgot = async (email) => {
    //   // Send confirmation code to user's email
    Auth.forgotPassword(email)
      .then((data) => {
      })
      .catch((err) => {});

  };
  const onFinish = async (event) => {
    setCount(false);
    // Collect confirmation code and new password, then
    const username = emailid;
    if (event.confirm_password !== event.new_password) {
      setCount(true);
      setpasswordMisMatch("container-MisMatch");
      setMessageType("container-error");
      setErrorMessage("Please make sure your passwords match");
      setTimeout(() => {
        setpasswordMisMatch(false);
      }, 4000);
    } else {
      Auth.forgotPasswordSubmit(username, event.code, event.new_password)
        .then((data) => {
          setMessageType("container-success");
          setCount(true);
          setErrorMessage("Password changed successfully!");
          setTimeout(() => {
            history.push("/login");
            setState("sendmail");
            form.resetFields();
            setCount(false);
          }, 3000);
          // history.push("/login");
          props.lostPassToLogin("Success");
        })
        .catch((err) => {
          setCount(true);
          // setErrorMessage(err.message);
          if (
            err?.message ===
              "Invalid verification code provided, please try again." ||
            err?.message ===
              "Invalid code provided, please request a code again."
          ) {
            seterrorCodemessage(
              "This verification code didn't work. Try again"
            );
          }
          setTimeout(() => {
            setCount(false);
            form.resetFields();
          }, 3000);
        });
    }
  };

  if (state === "sendmail") {
    return (
   <div className="main-container">
       <div className="main-sec" >
        <div className="sub-sec">
           <div className="head-text">Reset Password</div>
        <div className="box-text">
        Enter your registered email address and we’ll send you instructions to reset your password
        </div>
        </div>
      </div>
      <div className="container-2">
        <Form
          form={form}
          name="normal_login"
          className="login-form-lpass"
          initialValues={{
            remember: false,
          }}
          // onFinish={onForgot}
          onFinish={checkUserEmail}
        >
          {/* <div className="form-label">Email</div> */}
          <Tooltip placement="topRight" title={textemail} trigger="click">
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not a valid email",
                },
                {
                  required: true,
                  message: "Please input your email",
                },
              ]}
            >
              <Input placeholder="Email address" style={{marginTop:"14px"}}/>
            </Form.Item>
            {count && (
              <Infomessage message={errormessage} class={messagetype} />
            )}
          </Tooltip>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button-lpass"
              // onClick={mailSent}
              disabled={DisableButton}
            >
              <span style={{fontSize:"16px", fontWeight:"600"}}>Get Reset Instructions</span>
            </Button>
          </Form.Item>
          <div className="back-button">
          <button className="button-style" onClick={()=>{history.push("/login")}}>
            <span><img src={backArrow}/></span>
            <span className="back-text">Back to login</span>
          </button>
      </div>
        </Form>
      </div>
   </div>
    );
  } else {
    return (
     <div className="main-container">
         <div className="main-sec" >
        <div className="sub-sec">
           <div className="head-text">Reset Password</div>
           <div className="box-text2">
           Code has been sent to <br/>
           <span style={{color:"black"}}><b>{emailid}</b></span> by no-<br/>
           reply@verificationemail.com, check spam if not <br/>
           found.
            </div>
        </div>
      </div>
       <div className="container-1">
        {/* <div className="lpass-box-text">
          Just enter your email address below and we'll send you instructions on
          how to reset your password
        </div> */}
        <Form
          name="normal_login"
          className="login-form-lpass"
          // className="login-form-lpass"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          {/* <div className="lpass2-span forget-password-check-icon"> */}
            {/* <div>
              <FaCheckCircle className="lpass-checkbox forget-password-lpass-checkbox" />
            </div> */}
            {/* <div className="Email_Send_text">
            <p>
              If the email doesn’t show up ,Check your spam folder. We sent it
              from <span>help@skillgigs.com</span>{" "}
            </p>
          </div> */}
          {/* </div> */}
          <Form.Item
            name="code"
            rules={[
              {
                // pattern: /^([0-9]{1,4})*$/,
                pattern: /^\d{6}$/,
                message: "Enter valid code",
              },
              {
                required: true,
                message: "Please input code",
              },
            ]}
          >
            <Input
              // type="number"
              maxLength="6"
              autoComplete="new-password"
              placeholder="Enter code"
              onChange={(e) => {
                setLengthOTP(e.target.value.length);
              }}
              style={{marginTop:"8px"}}
            />
          </Form.Item>
          {OTPMessageTrue && (
            <Infomessage message={errorCodemessage} class={messagetype} />
          )}
          <Tooltip placement="topRight" title={textpass} trigger="click">
            <Form.Item
              name="new_password"
              rules={[
                {
                  // type: "regexp",
                  pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                  message: "The input is not a valid Password",
                },
                {
                  required: true,
                  message: "Please input new password",
                },
              ]}
            >
              <Input.Password
                placeholder="Enter New Password"
                autoComplete="new-password"
                iconRender={(visible) => (visible ? "Hide" : "Show")}
                style={{marginTop:"8px"}}
              />
            </Form.Item>
          </Tooltip>

          <Form.Item
            name="confirm_password"
            rules={[
              {
                required: true,
                message: "Please confirm new password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Re-Enter New Password"
              iconRender={(visible) => (visible ? "Hide" : "Show")}
              style={{marginTop:"8px"}}
            />
          </Form.Item>
          {passwordMisMatch === "container-MisMatch" && (
            <Infomessage message={errormessage} class={messagetype} />
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button-lpass"
              // onClick={mailSent}
            >
              Save new password
            </Button>
          </Form.Item>
          <div className="back-button">
          <button className="button-style" onClick={()=>{history.push("/login")}}>
            <span><img src={backArrow}/></span>
            <span className="back-text">Back to login</span>
          </button>
      </div>
        </Form>
        {/* <div className="email_id_field">
          <input value={emailid} className="input_feild" />
        </div> */}
        {/* <div className="lpass2-span">
          <FaCheckCircle className="lpass-checkbox" />
          <div className="lpass2-span-text">
            <b>Success</b> Password reset instructions have
            <br />
            been sent to {emailid}
          </div>
          <div className="Email_Send_text">
            <p>
              If the email doesn’t show up ,Check your spam folder. We sent it
              from <span>help@skillgigs.com</span>{" "}
            </p>
          </div>
        </div> */}
      </div>
     </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    user: state.chekckUserReducer.user,
    error: state.chekckUserReducer,
    // userProfile: state.profileReducer.user.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkUser: (params) => {
      dispatch(fetchExistingtUser(params)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LostPassword);