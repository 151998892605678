import React from 'react'
import LicenseNumber from '../license/LicenseNumber';


const BasicLifeSupport = () => {
  return (
    <><LicenseNumber
    heading = "Add Your Certifications"
    label="BLS(Basic life support)"
    licenseText="Enter your BLS e-card code"
    StateLicense="Your BLS is secure with SkillGigs."
    inputText="Valid till July 10,2026"
/></>
  )
}
export default BasicLifeSupport;