import React, { useState, Fragment, useEffect } from "react";
import {
  Menu,
  Input,
  Space,
  Dropdown,
  Button,
  Radio,
  Select,
  Form,
  Drawer,
} from "antd";
import { DownOutlined,} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FILTER_KEYS } from "../AllGigsFilter";



const ExperienceFilter = ({
  isSelected,
  label,
  handleApply,
  filterKey = FILTER_KEYS.ExperienceLevel,
  isMobileView = false,
  selectedOptions,
  setSelectedOptions,
  value,
  isActive,
  toggleFilter
}) => {
  const jobs = [{title:"Contract",id:'Contract'}, {title:"Permanent",id:"Permanent"}];

  const experienceLevels = useSelector((state) =>state.credentialingReducer.experinceLevels);
  const credentialingReducer = useSelector((state) =>state.credentialingReducer);
  const [isOpen, setIsOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [options, setoptions] = useState([]);
  useEffect(() => {
    if (filterKey == FILTER_KEYS.ExperienceLevel) {
      setoptions(experienceLevels || []);
    } else {
      setoptions(jobs);
    }
  }, [
    experienceLevels,
  ]);
  const handleDocumentClick = (e) => {
    if (!e.target.closest('.searchResultsFilterDropdown')) {
      setIsOpen(false);
      document.removeEventListener('click', handleDocumentClick);
    }
  };
  const handleOpen = (e) => {
    e.stopPropagation();
    setIsOpen(prev => !prev);

    if (!isOpen) {
      document.addEventListener('click', handleDocumentClick);
    } else {
      document.removeEventListener('click', handleDocumentClick);
    }
    setIsOpen(!isOpen);
    if (!value) setSelectedOptions(null);
  };

  const getTitle = (item) => {
    switch (filterKey) {
      case FILTER_KEYS.ExperienceLevel:
        return item?.ExperienceLevelName || "";
      default:
        return item?.title;
    }
  };
  const getItemId = (item) => {
    switch (filterKey) {
      case FILTER_KEYS.ExperienceLevel:
        return item?.ExperienceLevelCode || "";
      default:
        return item?.title || "";
    }
  };

  const onChangeRadio = (e) => {
    setIsOpen(false);
    const item = JSON.parse(e.target.value) || {};
    const itemId = getItemId(item);
    if (e.target.checked) {
      setSelectedOptions(item);
      handleApply([itemId], filterKey, [
        { title: getTitle(item), filterKey, id: itemId },
      ]);
    }
  };

  const onClickClear = () => {
    setIsOpen(false);
    setSelectedOptions(null);
    handleApply([], filterKey, []);
  };

  const menu = (
    <div className="location-suggestion-view">
      {/* <Radio.Group
        onChange={onChangeRadio}
        className="location-radios-grp radio-color"
      > */}
        <Space
          direction="vertical"
          className={"infinite-scroller mobile-no-scroller"}
          style={{ overflow: "auto", width: "103%", paddingRight: "8px" }}
        >
          <div className={"location-lists-container"} style={{
             width: '240px',
             padding: '5px 20px 20px 20px',
             borderRadius: '4px',
             backgroundColor: '#fff',
          }}>
            {options?.length ? (
              <>
                {options.map((items, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        marginTop: "10px",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "inherit",
                          marginBottom: "4px",
                        }}
                      >
                        <Radio
                          type={"radio"}
                          value={JSON.stringify(items)}
                          className="location-radio"
                          onChange={onChangeRadio}
                          checked={getTitle(selectedOptions) == getTitle(items)}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {" "}
                            <div>{getTitle(items)}</div>
                          </div>
                        </Radio>
                        {/* <>
                          {" "}
                          <div className="countDiv">
                            ({items?.count || 0})
                          </div>{" "}
                        </> */}
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#7e7e7e",
                }}
              >
                {" "}
                No results found{" "}
              </div>
            )}
          </div>
        </Space>
      {/* </Radio.Group> */}
    </div>
  );

  const [isShowDrawer, setIsShowDrawer] = useState(false);
  const getWebMenu = () => {
    return (
      <Menu
        className={`location-filter-menu ${
          isError === "city" && "filterError"
        }`}
      >
        <div className="location-filter-menu-inner">{menu}</div>
      </Menu>
    );
  };

  if (isMobileView) {
    return (
      <Fragment>
        <div className="Radio-grop-pvt-mkt">
          {/* <Radio.Group onChange={onChangeRadio}> */}
            <Space className="Space-radio-pvt-mkt" direction="vertical">
              {options.map((items, ind) => {
                return (
                  <Radio
                    type={"radio"}
                    value={JSON.stringify(items)}
                    checked={getTitle(selectedOptions) == getTitle(items)}
                    onChange={onChangeRadio}
                    key={getTitle(items) + ind}
                  >
                    <span>{getTitle(items)}</span>
                    <span>
                      {/* {items?.count || 0}  */}
                      </span>
                  </Radio>
                );
              })}
            </Space>
          {/* </Radio.Group> */}
        </div>
      </Fragment>
    );
  }

  return (
    <Dropdown
      overlayClassName="searchResultsFilterDropdown"
      overlay={getWebMenu()}
      trigger="click"
      visible={isOpen && isActive}
      onClick={handleOpen}
      className={"dropdown1"}
      onVisibleChange={(open) => setIsOpen(open)}
    >
      <Button
        className={
          isSelected ? "visaStatus-dropdown-select" : "visaStatus-dropdown"
        }
        onClick={toggleFilter}
        style={{
          backgroundColor: 'transparent',
          borderColor: '#d9d9d9',
          color: 'initial',
          height: "auto",
          borderRadius:"3px"
        }}
      >
        {label} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default ExperienceFilter;
