import { all, call, put, takeEvery } from "redux-saga/effects";
import { catchError, fetchHomeDataFailure, fetchHomeDataSuccess } from "../actions";
import {
    fetchHomePageInfoAPI,
    fetchHomePageInfoFilterAPI,
} from "../../API/API";
import { types } from "../action-types";


function* HomepageInfoSaga(params) {
    try {
        const response = yield call(() => fetchHomePageInfoAPI(params)); // API Backend
        if (response.status === 200) {
            yield put(fetchHomeDataSuccess(response.data.data));
        } else {
            yield put(fetchHomeDataFailure(response.data.data.body));
        }
    } catch (error) {
        // 
        yield put(fetchHomeDataFailure(error));
        yield put(catchError(error));
    }
}
function* HomepageInfoFilerSaga(params) {
    try {
        const response = yield call(() => fetchHomePageInfoFilterAPI(params)); // API Backend
        if (response.status === 200) {
            yield put(fetchHomeDataSuccess(response.data.data));
        } else {
            yield put(fetchHomeDataFailure(response.data.data.body));
        }
    } catch (error) {
        yield put(fetchHomeDataFailure(error));
        yield put(catchError(error));
    }
}

export function* watchHomepageInfoSaga() {
    yield takeEvery(types.FETCH_HOME_PAGE_DATA_FILTER_INIT, HomepageInfoFilerSaga);
    yield takeEvery(types.FETCH_HOME_PAGE_DATA_INIT, HomepageInfoSaga);
}