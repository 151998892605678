import { types } from "../action-types";

const initialState = {
  uuid: null,
  loading: false,
  success: [],
  error: {},
};

const userTrackingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_LANDED:
      localStorage.setItem('uuid',action?.payload?.uuid)
      return {
        ...state,
        uuid:action?.payload?.uuid
      };
    default:
      return {
        ...state,
      };
  }
};

export default userTrackingReducer
