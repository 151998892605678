import React from 'react'
import LicenseNumber from '../license/LicenseNumber'
const AdvancedLifeSupport = () => {
  return (
    <><LicenseNumber
    heading = "Add Your Certifications"
    label="ACLS (Advanced cardiovascular life support)"
    licenseText="Enter your ACLS e-card code"
    StateLicense="Your ACLS is secure with SkillGigs"
     inputText="Valid till July 10,2026"
/></>
  )
}

export default AdvancedLifeSupport;

