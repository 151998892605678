import React from 'react'

const SkillListingTittleToolTip = () => {
    return (
        <>
          <div className="ant-tooltip-inner">
            <div>
              <div className="gigType-getstart-crafttitle">
              Tips : Crafting Title
              </div>
              <div ><p style={{ fontWeight: '80'}}>Create a compelling skill listing title to boost your bid potential.</p></div>
              <div>
                <ul style={{ marginLeft: '14px', fontWeight:'400' }}>
                   <li>Use system suggestions for discipline and specialty, or customize your title.</li>
                   <li>You can update your preferences anytime in your profile settings.</li>             
                </ul>
              </div>
            </div>
          </div>
        </>
      );
}

export default SkillListingTittleToolTip