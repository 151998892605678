import React, { useEffect, useState } from "react";

import "./Home.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeDataFilterInit } from "../../redux/actions";
import GigsCard from "../SearchGigs/GigsCard";
import { useLocation } from "react-router-dom";
import { Breadcrumb, Pagination, Col } from "antd";
import { useHistory } from "react-router-dom";
import AllGigsFilter from "../../components/Filter/AllGigsFilter";
import Loader from "../../components/Loader/Loader";
import browsers from "../../assets/img/browsers.svg";

const HomeSeeAllpage = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  const { HomePageData: homedataAll, loading } = useSelector(
    (state) => state.homepageReducer || {}
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();
  const { ContinueBrowsing, Suggested, EditorPicks, MostPopular } = state;
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [Stagevalue, setStageValue] = useState("");
  const perPage = dimensions > 720 ? 24 : 20;
  const stageType = Suggested || EditorPicks || MostPopular || ContinueBrowsing;

  const [gigType, setGigType] = useState({});
  const [searchFilters, setSearchFilters] = useState({});
  const [page, setPage] = useState(1);

  //
  const HomePageRoute = () => {
    history.push("");
  };
  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  const getPayload = (filters = searchFilters) => {
    try {
      return {
        CityNames: filters.cities?.length ? filters.cities : undefined,
        CountriesNames: filters.countries?.length
          ? filters.countries
          : undefined,
        regionsNames: filters.regions?.length ? filters.regions : undefined,
        PvtEmpIds: filters.PvtEmpIds?.length ? filters.PvtEmpIds : undefined,
        SkillName: filters.Skills?.length ? filters.Skills : undefined,
        explevel: filters?.ExperienceLevel?.[0] || undefined,
        JobOrderTypeName: filters?.JobOrderTypeName || undefined,
        _sort: filters?._sort || "MOST_RECENT",
        isPrivateGig: filters?.isPrivateGig || undefined,
      };
    } catch {
      return {};
    }
  };

  const handleApplyFilter = (filters = searchFilters, _page = 1) => {
    setPage(_page);
    setSearchFilters(filters);
    const payload = getPayload(filters);
    dispatch(
      fetchHomeDataFilterInit({
        continueBrowsing_perPage: perPage,
        Favorite_perPage: perPage,
        Suggested_perPage: perPage,
        Editor_perPage: perPage,
        Most_PopularMob: perPage,
        filters: payload,
        ...getByStage(_page).payload
      })
    );
  };

  const getByStage = (page) => {
    switch (stageType) {
      case ContinueBrowsing:
        return {
          count: homedataAll?.continueBrowsingTotalCount,
          payload: {
            ContinueBrowsing: "Continue Browsing",
            continueBPage: page,
          },
        };
      case MostPopular:
        return {
          count: homedataAll?.mostPopularTotalCount,
          payload: { mostPopularPage: page, Most_Popular: "Most Popular" },
        };
      case EditorPicks:
        return {
          count: homedataAll?.editorTotalCount,
          payload: { editorPage: page, EditorPicks: "EditorPicks" },
        };
      case Suggested:
        return {
          count: homedataAll?.suggestedTotalCount,
          payload: { suggestPage: page, Suggested: "Suggested" },
        };
      default:
        return {
          count: 0,
          payload: {
            ContinueBrowsing: "Continue Browsing",
            continueBPage: page,
          },
        };
    }
  };

  const handlePageChange = (e) => {
    setPage(e);
    handleApplyFilter(searchFilters, e);
  };

  useEffect(() => {
    if (ContinueBrowsing === "ContinueBrowsing") {
      setStageValue("Continue Browsing");
    } else if (Suggested === "Suggested") {
      setStageValue("Suggested Jobs");
    } else if (EditorPicks === "EditorPicks") {
      setStageValue("Editor’s Picks");
    } else if (MostPopular === "MostPopular") {
      setStageValue("Most Popular");
    }
    handleApplyFilter(searchFilters, 1);

    window.addEventListener("resize", checkDimensions);
  }, []);

  return (
    <>
      <div className="HomePage-SeeAll-page">
        <div>
          <Breadcrumb separator=">">
            <Breadcrumb.Item
              className="HomePage-Breadcrumb-home"
              onClick={HomePageRoute}
            >
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item className="HomePage-Breadcrumb-CB">
              {Stagevalue}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="HomePage-Heading">{Stagevalue}</div>
        <div className="HomePage-Sub-Heading">
          Check out your recently viewed gigs
        </div>
        <AllGigsFilter
          setGigType={setGigType}
          GigType={gigType}
          searchText={""}
          handleApplyFilter={handleApplyFilter}
          windowDimensions={{ width: dimensions }}
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
          defaultParams={{ JobOrderStatusName: "Published" }}
          showGigStatus={false}
          webPadding="0px 0px 0px 0px"
        />
        {loading ? (
          <Loader />
        ) : ContinueBrowsing === "ContinueBrowsing" ? (
          <div className={homedataAll?.body?.ContinueBrowsing.length > 0 ? "gql-receivedGigs" : 
            ""
          }>
          {homedataAll?.body?.ContinueBrowsing.length > 0 ? (
            homedataAll.body.ContinueBrowsing.map((data) => (
              <GigsCard key={data.id} data={data} Types="ContinueBrowsing" />
            ))
          ) : (
            <Col
            lg={20}
            xs={24}
            sm={22}
            className={"empty-container-favorite"}
          >
            <div className="empty-container-main-favorite">
              <strong className="favorite-empty-container">
                You have no gigs
              </strong>
            </div>
            <span>
              Add gigs that interest you to gigs.
              <br />
              You can review your gigs at anytime and apply easily.
            </span>
            <br />
            {/* <FileSyncOutlined className={'empty'}/> */}
            <img
              className="Empty-my-fav"
              src={browsers}
              alt="Browser Logo"
              height={"199.6"}
              style={{ marginTop: "50px" }}
            ></img>
          </Col>
          )}
        </div>
        ) : Suggested === "Suggested" ? (
          <div className="gql-receivedGigs">
            {homedataAll?.body?.Suggested?.map((data) => {
              return (
                <>
                  <GigsCard data={data} Types="Suggested" />
                </>
              );
            })}
          </div>
        ) : EditorPicks === "EditorPicks" ? (
          <div className="gql-receivedGigs">
            {homedataAll?.body?.EditorPicks?.map((data) => {
              return (
                <>
                  <GigsCard data={data} Types="EditorPicks" />
                </>
              );
            })}
          </div>
        ) : MostPopular === "MostPopular" ? (
          <div className="gql-receivedGigs">
            {homedataAll?.body?.MostPopular?.map((data) => {
              return (
                <>
                  <GigsCard data={data} Types="MostPopular" />
                </>
              );
            })}
          </div>
        ) : (
          "no data to display"
        )}
        {getByStage().count > perPage && (
          <div className="favorite-pagination">
            <Pagination
              defaultCurrent={1}
              hideOnSinglePage={true}
              total={getByStage().count || 0}
              pageSize={perPage}
              onChange={(e) => handlePageChange(e)}
              current={page}
              showSizeChanger={false}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default HomeSeeAllpage;
