import React, { useState, useEffect } from "react";
import { Input, Button, Col, Alert, DatePicker, Row, Spin } from "antd";
import {
  FileSyncOutlined,
  ClockCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GigsCard from "../SearchGigs/GigsCard";
import "./MyFavorites.css";
import browsers from "../../assets/img/browsers.svg";
import { getFavoritesInfo, getNewFavoritesInfo } from "../../redux/actions";
import "antd/dist/antd.css";
import { Pagination } from "antd";
import { state } from "@antv/g2plot/lib/adaptor/common";
import { useUpdateLogger } from "../../shared/useUpdateLogger";
import Loader from "../../components/Loader/Loader";
import ToolTips from "../../components/ToolTip/ToolTips";
import AllGigsFilter from "./../../components/Filter/AllGigsFilter";
import { getWindowDimensions } from "../../shared/helpers";

export function LocationUpdate(func) {
  return {
    Code: func?.RegionCode,
    CountryId: func?.CountryId,
    RegionId: func?.RegionId,
    RegionName: func?.RegionName,
  };
}
export function LocationUpdateCity(func) {
  return {
    CityId: func?.CityId,
    CityName: func?.CityName,
    Latitude: func?.Latitude,
    Longitude: func?.Longitude,
  };
}

const MyFavoritess = (props) => {
  const dispatch = useDispatch();
  const totalFavorites = useSelector(
    (state) => state.myFavoritesReducer.myFavoritesTotal
  );
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [gigType, setGigType] = useState({});
  const [searchFilters, setSearchFilters] = useState({});

  const myfavorites = useSelector(
    (state) => state.myFavoritesReducer.myFavorites
  );

  const ProfessionCode = useSelector(
    (state) => state.profileReducer?.user?.profile?.ProfessionCode
  );

  const Loading = useSelector((state) => state.myFavoritesReducer.Loading);

  const PER_PAGE = window.innerWidth < 768 ? 20 : 24;
  const [page, setPage] = useState(1);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    dispatch(getFavoritesInfo({ page: 1, perPage: PER_PAGE, ...getPayload() }));
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const paginationHandle = (e) => {
    setPage(e);
    handleApplyFilter(searchFilters, e);
  };

  const getPayload = (filters = searchFilters) => {
    try {
      return {
        cities: filters.cities?.length ? filters.cities : undefined,
        countries: filters.countries?.length ? filters.countries : undefined,
        regions: filters.regions?.length ? filters.regions : undefined,
        skills: filters.Skills?.length ? filters.Skills : undefined,
        explevel: filters?.ExperienceLevel?.[0] || undefined,
        jobOrderTypeName: filters?.JobOrderTypeName || undefined,
        _sort: filters?._sort || "MOST_RECENT",
        isPrivateGig: filters?.isPrivateGig || undefined,
        PvtEmpIds: filters?.PvtEmpIds?.length
          ? filters?.PvtEmpIds
          : undefined,
      };
    } catch {
      return {};
    }
  };
  const handleApplyFilter = (filters = searchFilters, _page = page) => {
    const payload = getPayload(filters);
    dispatch(getFavoritesInfo({ page: _page, perPage: PER_PAGE, ...payload }));
  };

  return (
    <>
      <div className="myfavorites-main-container">
        <div className="myfavorites-container">
          <div className="myfavorites-heading">
            My favorites{" "}
            <ToolTips
              placement={window.innerWidth > 768 ? "bottomLeft" : "bottom"}
              title="We’ll gather a collection of your favorite gigs in “My Favorites”. Choose the heart to add the gig to your favorites."
            />{" "}
          </div>
          <div className="myfavorites-subheading">
            {" "}
            {totalFavorites || 0} items
          </div>
        </div>
        <AllGigsFilter
          setGigType={setGigType}
          GigType={gigType}
          searchText={""}
          handleApplyFilter={handleApplyFilter}
          windowDimensions={windowDimensions}
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
          defaultParams={{ JobOrderStatusName: "Published" }}
          showGigStatus={true}
          webPadding="0px 0px 0px 40px"
          padding={"40px"}
        />

        {Loading ? (
          <Loader />
        ) : (
          <>
            <section
              className={`myfavorites-section ${
                myfavorites?.length > 0 && "Favorites-card-width"
              }`}
            >
              {myfavorites && myfavorites?.length > 0 ? (
                myfavorites?.map((data) => {
                  let GigData = data;
                  if (!data?.hasOwnProperty("Regions")) {
                    GigData["Regions"] = LocationUpdate(data?.Location);
                  }
                  if (!data?.hasOwnProperty("Cities")) {
                    GigData["Cities"] = LocationUpdateCity(data?.Location);
                  }
                  if (!data?.hasOwnProperty("Professions")) {
                    GigData["Professions"] = { ProfessionCode: ProfessionCode };
                  }
                  if (
                    ProfessionCode === "IT" &&
                    !data?.hasOwnProperty("companyName")
                  ) {
                    GigData["companyName"] = data?.CompanyName;
                  }
                  return (
                    <GigsCard
                      Types="Favorites"
                      data={GigData}
                    />
                  );
                })
              ) : (
                <Col
                  lg={20}
                  xs={24}
                  sm={22}
                  className={"empty-container-favorite"}
                >
                  <div className="empty-container-main-favorite">
                    <strong className="favorite-empty-container">
                      You have no favorite gigs
                    </strong>
                  </div>
                  <span>
                    Add gigs that interest you to favorites.
                    <br />
                    You can review your favorites at anytime and apply easily.
                  </span>
                  <br />
                  {/* <FileSyncOutlined className={'empty'}/> */}
                  <img
                    className="Empty-my-fav"
                    src={browsers}
                    alt="Browser Logo"
                    height={"199.6"}
                    style={{ marginTop: "50px" }}
                  ></img>
                </Col>
              )}
            </section>
            {totalFavorites > PER_PAGE && (
              <div className="favorite-pagination">
                <Pagination
                  defaultCurrent={1}
                  hideOnSinglePage={true}
                  total={totalFavorites || 0}
                  pageSize={PER_PAGE}
                  onChange={(e) => paginationHandle(e)}
                  current={page}
                  showSizeChanger={false}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default MyFavoritess;
