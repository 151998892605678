import { types } from "../action-types";

const initialState = {
  myFavorites: [],
  Loading: false,
  Error: {},
  removeFavorites: [],
  myFavoritesTotal: [],
};

const myFavoritesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REMOVE_FAVORITE_GIG_CARD:
      return {
        ...state,
        loading: true,
      };
    case types.REMOVE_FAVORITE_GIG_CARD_SUCCESS:
      return {
        ...state,
        myFavorites: state.myFavorites.filter(
          (item, i) => action.payload !== item.JobOrderId
        ),
        myFavoritesTotal: state.myFavoritesTotal-1 > 0 ? state.myFavoritesTotal-1 : 0,
      };
    case types.FAVORITE_GIG_CARD:
      return {
        ...state,
        loading: true,
      };
    case types.FAVORITE_GIG_CARD_SUCCESS:
      const dublicateFavoriteGigs = state.myFavorites.filter(
        (favGig) => favGig.JobOrderId === action.payload.JobOrderId
      );
      return dublicateFavoriteGigs.length
        ? { ...state }
        : {
            ...state,
            myFavorites: [
               { ...action.payload, IsFavorite: true } ,
              ...state.myFavorites,
            ],
          };
    case types.GET_FAVORITES_INFO_REQUEST:
      return {
        ...state,
        Loading: true,
      };
    case types.GET_FAVORITES_INFO_SUCCESS:
      //
      return {
        ...state,
        Loading: false,
        myFavorites: action.payload.data.body.data,
        myFavoritesTotal: action.payload.data.totalFavorites,
        Error: {},
      };
    case types.GET_FAVORITES_INFO_FAILURE:
      return {
        ...state,
        Loading: false,
        Error: action.error,
      };
    case types.GET_NEW_FAVORITES_INFO_REQUEST:
      return {
        ...state,
        Loading: true,
      };
    case types.GET_NEW_FAVORITES_INFO_SUCCESS:
      console.log('action.payload', action.payload)
      return {
        ...state,
        Loading: false,
        myNewFavorites: action.payload.body.Favorites,
        myFavoritesTotal: action.payload.body.favoritesTotalCount,
        Error: {},
      };
    case types.GET_NEW_FAVORITES_INFO_FAILURE:
      return {
        ...state,
        Loading: false,
        Error: action.error,
      };
    default:
      return state;
  }
};

export default myFavoritesReducer;
