import React, { useState, useEffect } from "react";
import { Select, Spin } from "antd";
import { debounce } from "../../../../../shared/helpers";
import { connect, useDispatch, useSelector } from "react-redux";
import { DownOutlined } from "@ant-design/icons";
import { CheckOutlined } from "@ant-design/icons";
import {
  fetchKeySearchLocation,
  updateSignupProfile,
} from "../../../../../redux/actions";
import errorIcon from "../../../../../assets/img/ci_warning.png";
import "../../CreateSkillListing.css";
import "./CreateSkillListingScreen.css";
import { useHistory } from "react-router-dom";
import BottomBottons from "../Footer/BottomButtons";

const DesiredLocation = (props) => {
  const { description, value, setValue } = props;
  const dispatch = useDispatch();
  const [locations, setLocations] = useState([]);
  const [flag,setFlag] = useState(false);
  const [noDataViewLocation, setNoDataViewLocation] = useState("");
  const { Option } = Select;
  const [showError, setShowError] = useState(false);
  const SkillListings_Cities =
    props.profiledata?.profile?.DraftSkillListing?.SkillListings_Cities || [];
  const history = useHistory();

  useEffect(() => {
    if (SkillListings_Cities.length) {
      setLocations(SkillListings_Cities);
    }
  }, [SkillListings_Cities]);

  useEffect(() => {
    if (props.apiSuccess && locations.length && flag) {
      history.push("/create-skilllisting-salary");
    }
  }, [props.apiSuccess]);

  function onSearchLocation(val) {
    setNoDataViewLocation(val);
    props.sendKeyLocation(val);
  }

  const getSearchLocation = (value) => {
    //selected value will come here
    if (value.length) {
      setShowError(false);
    }
    if (value.length > locations.length) {
      let insertedElement = value[value.length - 1];
      insertedElement = JSON.parse(insertedElement);
      let isFoundIndex = locations.findIndex((city) => city?.Cities?.LocationWithCountry === insertedElement?.LocationWithCountry)
      if (isFoundIndex != -1) {
          let selectedLoc = [...locations];
          selectedLoc.splice(isFoundIndex,1);
          setLocations(selectedLoc);
          return;
      }
    }
    let selectedLoc = value.map((item) => {
      if (item.startsWith("{"))
        return {
          Cities: JSON.parse(item),
        };
      else {
        let stringLoc = locations.filter((item1) => {
          if (item1.Cities.LocationWithCountry === item) return item1;
        });
        return stringLoc[0];
      }
    });
    setLocations(selectedLoc);
  };
  const title = "Your desired locations";

  const handleNext = () => {
    if (locations.length) {
      setFlag(true)
      dispatch(
        updateSignupProfile({
          "DraftSkillListing.SkillListings_Cities": locations,
          WizardFlow: 205,
        })
      );
    } else {
      setShowError(true);
    }
  };

  const handleBack = () => {
    history.push("/create-skilllisting-skill");
  };

  const locationPlaceholder = () => {
    return (
      <div className="placeholder-new-signup-loc-skill">
        <div className="example">ex.</div>
        <div className="circle-box">
          <span>Houston, TX</span>
        </div>
        <div className="circle-box">
          <span>Ferry Pass, FL</span>
        </div>
      </div>
    )
  }
  return (
    <div className="CreateSkillListingHeaderScreen2">
      <div className="create-skill-listing-title">
        {title} <span className="Mandatory-Text">(Mandatory)</span>
      </div>
      <div className="create-skill-listing-subheader-other">
          <span>Add your preferred work locations here</span>
      </div>
      <Select
        notFoundContent={
          noDataViewLocation?.length > 0 ? (
            <div>
              {props.searchLoading || props.searchedLocation.length > 0 ? (
                <Spin size="small" />
              ) : (
                "No data found!"
              )}
            </div>
          ) : null
        }
        name="desiredLocation"
        value={locations.map((item) => item?.Cities?.LocationWithCountry)}
        placeholder={locationPlaceholder()}
        className={`desired-loc-skill${showError ? " error-block" : ""}`}
        mode="multiple"
        bordered={false}
        onSearch={debounce((val) => onSearchLocation(val))}
        onChange={getSearchLocation}
        onClick={() => {}}
        showSearch
        listHeight={160}
        autoFocus
      >
        {props.searchedLocation?.length > 0 &&
          props.searchedLocation?.map((city) => {
            let isChecked = locations.some(
              (item) =>
                item?.Cities?.LocationWithCountry === city?.LocationWithCountry
            );
            return (
              <Option
                value={JSON.stringify(city)}
                key={city.LocationWithCountry}
                style={{ backgroundColor: isChecked ? "#F4F4F4" : null }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {city.LocationWithCountry}
                  {isChecked && <CheckOutlined style={{ color: "black" }} />}
                </div>
              </Option>
            );
          })}
      </Select>
      {showError && (
        <div className="error-msg-content">
          <img src={errorIcon} alt="" />
          <span className="error-text">
            Please add your desired location to continue
          </span>
        </div>
      )}
      <BottomBottons
        loading={props.loading}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    searchedLocation: state.credentialingReducer.searchedLocation,
    searchLoading: state.credentialingReducer.searchLoading,
    profiledata: state.profileReducer.user,
    loading: state.profileReducer.signUpLoading,
    apiError: state.profileReducer.error,
    apiSuccess: state.profileReducer.signupUpdateSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendKeyLocation: (e) => {
      dispatch(fetchKeySearchLocation(e));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DesiredLocation);
