import React from "react";
import UploadDocV2 from "../../UploadDoc";

const Driver_license_DocV2 = () => {
  const docInfo = [
    {
      "Driver’s license": 84,
    },
  ];
  return (
    <div>
      <UploadDocV2
        docInfo={docInfo}
        // backUrl="social-security-doc"
        backUrl="acls-doc"
        nextUrl="social-security-num"
        currentPage={7}
        IsMandatory={true}
        WizardFlow={116}
        skipUrl={"social-security-num"}
      />
    </div>
  );
};

export default Driver_license_DocV2;
