import { types } from "../action-types";

const initialState = {
  loading: false,
  signUpLoading: false,
  resumeloading: false,
  user: {
    profile: {},
    name: "",
    email: "",
    workExperience: [],
    id: null,
    created_at: null,
  },
  roleDiscipline: [],
  error: {},

  talentApiData: {},
  talentInfo: {},
  analyticInfo: {},
  interviewInfo: [],

  profiledata: [],
  profiledataCompleted: [],
  profiledataCancelled: [],
  profiledataTerminated: [],
  profiledataOnBilling: [],
  JobProfileSelecter: "",
  Favoritefunctiondata: "",

  Favoritedata: [],
  RemoveFavoritedata: [],

  loadingED: false,
  schoolEducationHistory: [],
  degreeEducationHistory: [],
  courseEducationHistory: [],

  loadingWH: false,
  jobTitleWorkHistory: [],
  industryWorkHistory: [],
  locationWorkHistory: [],
  skillsWorkHistory: [],

  loadingCA: false,
  downloadURL: "",
  loadingPP: false,
  placedBidsCounts:0,
  receivedBidsCounts:0,
  signupUpdateSuccess:false
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.JOB_DETAILS_PROFILE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case types.JOB_DETAILS_PROFILE_SUCCESS:
      return {
        ...state,
        profiledata: action.payload,
        loading: false,
      };
    case types.JOB_DETAILS_PROFILE_COMPLETED_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case types.JOB_DETAILS_PROFILE_COMPLETED_SUCCESS:
      // 
      return {
        ...state,
        profiledataCompleted: action.payload,
        loading: false,
      };
    case types.JOB_DETAILS_PROFILE_CANCELED_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case types.JOB_DETAILS_PROFILE_CANCELED_SUCCESS:
      // 
      return {
        ...state,
        profiledataCancelled: action.payload,
        loading: false,
      };
    case types.JOB_DETAILS_PROFILE_TERMINATED_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case types.JOB_DETAILS_PROFILE_TERMINATED_SUCCESS:
      // 
      return {
        ...state,
        profiledataTerminated: action.payload,
        loading: false,
      };
    case types.JOB_DETAILS_PROFILE_ON_BILLING_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case types.JOB_DETAILS_PROFILE_ON_BILLING_SUCCESS:
      // 
      return {
        ...state,
        profiledataOnBilling: action.payload,
        loading: false,
      };

    case types.JOB_DETAILS_PROFILE_SELECTER:
      // 
      return {
        ...state,
        JobProfileSelecter: action.payload,
      };
    case types.FETCH_PROFILE_INIT:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_PROFILE_REQUEST_SUCCESS:
      const { profile, email, name, _id, created_at ,EmployeeAssociation} = action.payload;
      return {
        ...state,
        loading: false,
        user: { ...state.user, profile, email, name, id: _id, created_at,EmployeeAssociation:EmployeeAssociation },
        error: {},
      };

    case types.FETCH_ROLE_DISCIPLINE_SUCCESS:
      return {
        ...state,
        loading: false,
        roleDiscipline: action.payload,
        error: {},
      };

    case types.FETCH_PROFILE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.UPDATE_PROFILE_INIT:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        // user: { ...state.user, profile: action.payload.profile },
        user: {
          ...state.user,
          name: action.payload.name,
          profile: { ...state.user.profile, ...action.payload.profile },
        },
        error: {},
      };
    case types.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.UPDATE_SIGNUP_PROFILE_INIT:
      return {
        ...state,
        signUpLoading: true,
        signupUpdateSuccess:false,
        error:{}
      };
    case types.UPDATE_SIGNUP_PROFILE_SUCCESS:
      return {
        ...state,
        signUpLoading: false,
        signupUpdateSuccess:true,
        user: {
          ...state.user,
          name: action.payload.name,
          profile: {...action.payload.profile },
        },
        error: {},
      };
    case types.UPDATE_SIGNUP_PROFILE_FAILURE:
      return {
        ...state,
        signUpLoading: false,
        signupUpdateSuccess:false,
        error: "Something went wrong",
      };
    case types.UPLOAD_PROFILE_PIC_INIT:
      return {
        ...state,
        loading: true,
        loadingPP: true,
      };
    case types.UPLOAD_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          profile: { ...state.user.profile, PictureUrl: action.payload },
        },
        loading: false,
        loadingPP: false,
        error: {},
      };
    case types.UPLOAD_PROFILE_PIC_FAILURE:
      return {
        ...state,
        loading: false,
        loadingPP: false,
        error: action.error,
      };
    case types.UPLOAD_RESUME_INIT:
      return {
        ...state,
        resumeloading: true,
      };

    case types.DELETE_RESUME_INIT:
      return {
        ...state,
        resumeloading: true,
      };

    case types.UPLOAD_RESUME_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          profile: { ...state.user.profile, ResumeFilename: action.payload },
        },
        resumeloading: false,
        error: {},
      };

    case types.DELETE_RESUME_SUCCESS:
      const resumeDelete = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          profile: resumeDelete.profile,
        },
        resumeloading: false,
        error: {},
      };

    case types.FETCH_PROFILE_EXPERIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { ...state.user, workExperience: action.payload },
        error: {},
      };

    case types.UPLOAD_RESUME_FAILURE:
      return {
        ...state,
        resumeloading: false,
        error: action.error,
      };

    case types.DELETE_RESUME_FAILURE:
      return {
        ...state,
        resumeloading: false,
        error: action.error,
      };

    case types.GET_TALENT_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_TALENT_INFO_SUCCESS:
      // 
      return {
        ...state,
        loading: false,
        user: { ...state.user, EmployeeAssociation:action.payload?.EmployeeAssociation },
        talentInfo: action.payload,
        error: {},
      };

    case types.GET_TALENT_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.TALENT_PROFILE_API_DATA:
      // 
      return {
        ...state,
        loading: false,
        talentApiData: action.payload,
        error: {},
      };

    case types.GET_ANALYTIC_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ANALYTIC_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        analyticInfo: action.payload,
        error: {},
      };

    case types.GET_ANALYTIC_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.FETCH_COLLEGE_MASTER_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_COLLEGE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        schoolEducationHistory: action.payload,
        error: {},
      };

    case types.FETCH_COLLEGE_MASTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.FETCH_DEGREE_MASTER_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_DEGREE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        degreeEducationHistory: action.payload,
        error: {},
      };

    case types.FETCH_DEGREE_MASTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.FETCH_COURSE_MASTER_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_COURSE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        courseEducationHistory: action.payload,
        error: {},
      };

    case types.FETCH_COURSE_MASTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.POST_EDUCATION_HISTORY_INIT:
      return {
        ...state,
        loadingED: true,
      };

    case types.POST_EDUCATION_HISTORY_SUCCESS:
      return {
        ...state,
        loadingED: false,
        talentInfo: {
          ...state.talentInfo,
          EducationHistory: action.payload,
        },
        error: {},
      };

    case types.POST_EDUCATION_HISTORY_FAILURE:
      return {
        ...state,
        loadingED: false,
        error: action.error,
      };

    case types.UPDATE_EDUCATION_HISTORY_INIT:
      return {
        ...state,
        loadingED: true,
      };

    case types.UPDATE_EDUCATION_HISTORY_SUCCESS:
      // const index = state.talentInfo.EducationHistory.filter((item, i) => {
      //   if (action.payload._id === item._id) return i;
      // });
      return {
        ...state,
        loadingED: false,
        talentInfo: {
          ...state.talentInfo,
          EducationHistory: action.payload,
          // EducationHistory: [
          //   ...state.talentInfo.EducationHistory.filter(
          //     (item, i) => action.payload._id !== item._id
          //   ),
          //   action.payload,
          // ],
        },
        error: {},
      };

    case types.UPDATE_EDUCATION_HISTORY_FAILURE:
      return {
        ...state,
        loadingED: false,
        error: action.error,
      };

    case types.GET_JOBTITLE_WORK_HISTORY_INIT:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case types.GET_JOBTITLE_WORK_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        jobTitleWorkHistory: action.payload,
        error: {},
      };

    case types.GET_JOBTITLE_WORK_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.GET_INDUSTRY_WORK_HISTORY_INIT:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case types.GET_INDUSTRY_WORK_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        industryWorkHistory: action.payload,
        error: {},
      };

    case types.GET_INDUSTRY_WORK_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.GET_LOCATION_WORK_HISTORY_INIT:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case types.GET_LOCATION_WORK_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        locationWorkHistory: action.payload,
        error: {},
      };

    case types.GET_LOCATION_WORK_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.GET_SKILLS_WORK_HISTORY_INIT:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case types.GET_SKILLS_WORK_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        skillsWorkHistory: action.payload,
        error: {},
      };

    case types.GET_SKILLS_WORK_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.POST_WORK_HISTORY_INIT:
      return {
        ...state,
        loadingWH: true,
      };

    case types.POST_WORK_HISTORY_SUCCESS:
      return {
        ...state,
        loadingWH: false,
        talentInfo: {
          ...state.talentInfo,
          WorkHistory: action.payload,
          // WorkHistory: [...state.talentInfo.WorkHistory, action.payload],
        },
        error: {},
      };

    case types.POST_WORK_HISTORY_FAILURE:
      return {
        ...state,
        loadingWH: false,
        error: action.error,
      };

    case types.UPDATE_WORK_HISTORY_INIT:
      return {
        ...state,
        loadingWH: true,
      };

    case types.UPDATE_WORK_HISTORY_SUCCESS:
      // 
      return {
        ...state,
        loadingWH: false,
        talentInfo: {
          ...state.talentInfo,
          WorkHistory: action.payload,
        },
        error: {},
      };

    case types.UPDATE_WORK_HISTORY_FAILURE:
      return {
        ...state,
        loadingWH: false,
        error: action.error,
      };

    case types.POST_CERTIFICATES_AND_AWARDS_INIT:
      return {
        ...state,
        loadingCA: true,
      };

    case types.POST_CERTIFICATES_AND_AWARDS_SUCCESS:
      return {
        ...state,
        loadingCA: false,
        talentInfo: {
          ...state.talentInfo,
          Certificate: action.payload,
          // Certificate: [...state.talentInfo.Certificate, action.payload],
        },
        error: {},
      };

    case types.POST_CERTIFICATES_AND_AWARDS_FAILURE:
      return {
        ...state,
        loadingCA: false,
        error: action.error,
      };

    case types.UPDATE_CERTIFICATES_AND_AWARDS_INIT:
      return {
        ...state,
        loadingCA: true,
      };

    case types.UPDATE_CERTIFICATES_AND_AWARDS_SUCCESS:
      const filterData = state.talentInfo.Certificate.filter(
        (obj) => obj._id !== action.payload.id
      );
      return {
        ...state,
        loadingCA: false,
        talentInfo: {
          ...state.talentInfo,
          Certificate: action.payload,
          // Certificate: [...filterData, action.payload.response],
        },
        error: {},
      };

    case types.UPDATE_CERTIFICATES_AND_AWARDS_FAILURE:
      return {
        ...state,
        loadingCA: false,
        error: action.error,
      };

    case types.GET_INTERVIEW_CARD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_INTERVIEW_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        interviewInfo: action.payload,
        error: {},
      };

    case types.GET_INTERVIEW_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.DOWNLOAD_RESUME_LINK_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        downloadURL: action.payload,
      };
    case types.DASHBOARD_BIDS_COUNTS_SUCEESS:
      return {
        ...state,
        placedBidsCounts: action.payload.totalPlacedBid.count,
        receivedBidsCounts: action.payload.totalRecivedBid.count,
      };
    case types.DASHBOARD_BIDS_COUNTS_FAILURE:
      return {
        ...state,
        placedBidsCounts: 0,
        receivedBidsCounts: 0,
      };
    default:
      return state;
  }
};

export default profileReducer;
