import React from 'react';
import './SkillListingTips.css'

import GettingStart from '../../../../assets/img/GettingStart.png'

const TipToGettingStart = (props) => {
    const {pageType, ProfessionCode} = props;
    let gigTypePoints = [
        "Opens up tailored opportunities",
        "This way, you can bid on gigs that perfectly match your skills and preferences"
    ]
    let skillListingTitleHCPoints = [
        "Use system suggestions for discipline and specialty, or customize your title.",
        "You can update your preferences anytime in your profile settings.",
        // "You may modify your discipline and specialty preferences at any time through your profile settings."
    ]
    let skillListingTitleITPoints = [
        "Employers will place bid on your skill listing to be thorough",
        "You can create more than one skill listing",
        "Give the the title of the gig you want",
        "When it comes to salary requirements, be fair but know your worth and ask for it."
    ]
    let displayPoints = pageType === "gigType" ? gigTypePoints : ProfessionCode === "HC" ? skillListingTitleHCPoints : skillListingTitleITPoints;

    return (
        <>
        
            <div className='Skill-Listing-Tips-Started-Container' >
                <div className='Skill-Listing-Circle-Container'>
                <span className='Skill-Listing-Tips-Started-Cricle' >
                    <img src={GettingStart} />
                </span>
                </div>
                
                <div>
                    <div className='Skill-Listing-Tips-Started-Heading' >
                        {pageType === "gigType" ? "Tips : getting started" : "Tips : Crafting title" }
                    </div>
                    <div className='Skill-Listing-Tips-Started-Sub-Heading-container' >
                        <div className='Skill-Listing-Tips-Started-Sub-Heading' >
                        {pageType === "gigType" ? "Selecting your gig type - Contract/ Travel, Permanent or Per diem." : 
                        "Create a compelling skill listing title to boost your bid potential." }
                        </div>
                    </div>
                    
                    <div className='Skill-Listing-Tips-Started-points-container' >
                        {
                            displayPoints.map((point,index) => (
                                <div className='Skill-Listing-Tips-Started-points' key={index}>
                            {point}
                        </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default TipToGettingStart;