import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { connect, useSelector } from "react-redux";

import { message, Input, Select, Modal, Menu, List } from "antd";

import {
  fetchData,
  uploadDocument,
  removeDocument,
} from "../../../redux/actions";

import { LoadingOutlined } from "@ant-design/icons";
import { AiOutlineUpload } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import { ReactComponent as IconMenu } from "../../../assets/img/viewDoc.svg";

import Loader from "../../../components/Loader/Loader";

import "./StepTwo.css";
import { alertNotification } from "../../../components/notifications/alertNotification";
import CredPDFviewer from "./CredPDFviewer";
import CredValidation from "./CredValidation";
import { useLocation } from "react-router-dom";

const StepTwo = (props) => {
  const showPublicView = useSelector((state) => state.showPublicViewReducer);
  // 
  const userProfile= useSelector(
    (state) => state.profileReducer?.user.profile
  );
  console.log(userProfile,"userprofile")

  const { Option } = Select;
  const fileRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isSelected, setIsSelected] = useState(false);

  const [currentDocId, setCurrentDocId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [docsData, setDocsData] = useState([]);
  const [uploadCount, setUploadcount] = useState(0);
  const [searchSelect, setSearchSelect] = useState("");

  const [docsSearchData, setDocsSearchData] = useState([]);
  const [dropSearchVisible, setDropSearchVisible] = useState(false);
  const [attachement, setAttachment] = useState([]);

  const [docFile, setDocFile] = useState(null);
  const { docFileView, setDocFileView } = props;
  // const [docFileView, setDocFileView] = useState(false);
  const [docfileName, setDocFileName] = useState("");
  const [docFileType, setDocFileType] = useState(null);
  const [docFileSize, setDocFileSize] = useState(null);
  const [load, setLoading] = useState(true);
  const [styleImage, setStyleImage] = useState({ display: "none" });

  const [deleteModalVisible, setDeleteIsModalVisible] = useState(null);

  const [state, setState] = useState({ cursor: 0 });
  const listRef = useRef();
  const locationURL = useLocation().pathname;
  const [PrivateView, setPrivateView] = useState(true);
  const showModal = () => {
    setDeleteIsModalVisible(true);
  };

  const handleCancel = () => {
    setDeleteIsModalVisible(false);
  };

  const handlePDFCancel = () => {
    setDocFile(null);
  };

  const loadFile = (e) => {
    const file = e.target.files[0];
    // // 

    if (
      file.type !== "application/pdf" &&
      file.type !== "image/jpeg" &&
      file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
      file?.type !== "application/msword" &&
      file.type !== "image/png"
    ) {
      alertNotification("warning", " File format not supported. Upload only JPEG, JPG, PNG, & PDF file formats.");
      e.target.value = ""; // reset onchange so it can be trigger over a same file
      return;
    }
    if (file.size >= 10590617) {
      alertNotification("warning", "File size bigger than 10 MB");
      e.target.value = ""; // reset onchange so it can be trigger over a same file
      return;
    }
    setFile(e.target.files[0]);
    setIsSelected(true);
    // setIsLoading(true);

    // const fileName = file.name.split(".");
    // const filtered = fileName.filter((s) => s !== "");
    // const lastIndex = filtered.length - 1;
    // filtered.splice(lastIndex, 0, ".");
    // const FileName = filtered.join("");

    // const payload = {
    //   file,
    //   fileName: FileName,
    //   fileSize: file.size.toString(),
    //   userId: props.user.id,
    //   documentId: currentDocId,
    //   type: "credentialingDoc",
    // };

    // props.uploadDocument(payload);
  };

  const onLoadUpload = () => {
    setIsLoading(true);

    const fileName = file.name.split(".");
    const filtered = fileName.filter((s) => s !== "");
    const lastIndex = filtered.length - 1;
    filtered.splice(lastIndex, 0, ".");
    const FileName = filtered.join("");

    const payload = {
      file,
      fileName: FileName,
      fileSize: file.size.toString(),
      userId: props.user.id,
      documentId: currentDocId,
      type: "credentialingDoc",
    };

    props.uploadDocument(payload);
  };

  const uploadHandler = (documentId) => {
    // // 
    setCurrentDocId(documentId);

    fileRef.current.click();
  };

  const removeHandler = (documentId) => {
    // 
    setDocFileView(false);
    setCurrentDocId(documentId);
    props.removeDocument(documentId);
    setIsLoading(true);
    // props.fetchAPIData();
    fileRef.current.value = "";
  };

  useEffect(() => {
    props.fetchAPIData();
  }, []);

  useEffect(() => {
    if (!showPublicView && locationURL === "/3d_resume") {
      setPrivateView(false);
    } else {
      setPrivateView(true);
    }
  }, [showPublicView]);

  // Arrange the list
  const datafilterSort = (notReset) => {
    if (props.docs.userDocs) {
      const docs = props.docs.userDocs;

      // Upload Count
      const count = docs.filter((data) => data.userDoc);
      const sortCount = count?.sort((a, b) => {
        const firstVal = new Date(a.userDoc.Created);
        const secondVal = new Date(b.userDoc.Created);
        if (firstVal < secondVal) {
          return 1;
        }
        if (firstVal > secondVal) {
          return -1;
        }
        return 0;
      });
      const notUploaded = docs.filter((data) => !data.userDoc);

      if (count.length > 0) {
        setDocsData(sortCount.concat(notUploaded));
      } else setDocsData(docs);
      // 
      setUploadcount(count.length);
    }
  };

  useEffect(() => {
    if (uploadCount > 0) {
      setIsLoading(true);
      props.fetchAPIData();
    }
  }, []);

  useEffect(() => {
    setIsLoading(false);
    // 
    datafilterSort();
    if (searchSelect) {
      getFilterDocs(searchSelect);
    }
  }, [props.docs, props.docs.userDocs]);

  const getFilterSearchDocs = (val) => {
    // // 
    setSearchSelect(val);
    const value = val.toLowerCase();
    if (value === "") datafilterSort();
    // // 

    const filterData = props.docs.userDocs.filter((data) => {
      if (data.CredentialDocumentName) {
        refList.push(React.createRef());
        const dataCheck = data.CredentialDocumentName.toLowerCase();
        return dataCheck.indexOf(value) != -1;
      }
    });

    const sortedData = filterData.filter((data) => {
      if (data.CredentialDocumentName) {
        refList.push(React.createRef());
        const dataCheck = data.CredentialDocumentName.toLowerCase();
        return dataCheck.startsWith(value);
      }
    });

    const allData = sortedData.concat(filterData);
    const finalData = allData.filter(
      (data, index) => allData.indexOf(data) === index
    );

    setDocsSearchData(finalData.filter((Credential) =>  !skipSSNDocument(Credential.CredentialDocumentName)));
    // // 

    if (value.length > 0) {
      return setDropSearchVisible(true);
    }
  };

  const getFilterDocs = (val) => {
    // // 
    const value = val.toLowerCase();
    // // 

    const filterData = props.docs.userDocs.filter((data) => {
      if (data.CredentialDocumentName) {
        const dataCheck = data.CredentialDocumentName.toLowerCase();

        return dataCheck.indexOf(value) != -1;
      }
    });
    // // 
    setDocsData(filterData);
  };

  const findSearchDocument = (docName) => {
    setSearchSelect(docName);
    getFilterDocs(docName);
    setDropSearchVisible(false);
  };

  const resetDocArray = () => {
    datafilterSort();
    setSearchSelect(null);
    setSearchSelect("");
  };

  // To KB
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const skipSSNDocument = (docName) => { 
      return userProfile?.SSN!=null && ["SSN", "Social security code"].includes(docName)
   }

  useEffect(() => {
    // 
    if (docFileView) {
      // 
      document.getElementById("credSection").scrollIntoView();
    }
  }, [docFileView]);

  const viewDocument = (fileUrl, name, size, fileUrl2) => {
    if (fileUrl) {
      const type = name?.split(".")[1];
      // 
      setDocFile(fileUrl);
      setDocFileName(name);
      setDocFileType(type);
      setDocFileSize(formatBytes(size * 1));
      setDocFileView(true);
    } else if (fileUrl2) {
      const tmp = fileUrl2.split("/");
      const name = tmp[tmp.length - 1];
      const type = name.split(".")[1];
      // 
      setDocFile(fileUrl2);
      setDocFileName(name);
      setDocFileType(type);
      setDocFileSize(formatBytes(200 * 1));
      setDocFileView(true);
    }
    // window.scroll("credSection");
    if (fileUrl === docFile) return; // it help not set loading to true for already loaded image
    setStyleImage({ display: "none" });
    setLoading(true);
  };

  const refList = [];

  const handleKeyDown = (e) => {
    if (!dropSearchVisible) return;
    const { cursor } = state;
    if (e.keyCode === 13) {
      findSearchDocument(docsSearchData[cursor].CredentialDocumentName);
    }
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38 && cursor > 0) {
      setState((prevState) => ({
        cursor: prevState.cursor - 1,
      }));
      listRef.current.scrollTo(0, refList[cursor - 1].current.offsetTop);
    } else if (e.keyCode === 40 && cursor < docsSearchData.length - 1) {
      setState((prevState) => ({
        cursor: prevState.cursor + 1,
      }));
      listRef.current.scrollTo(0, refList[cursor].current.offsetTop);
    }
  };

  return (
    <React.Fragment>
      {isSelected ? (
        <CredValidation
          loadFile={[file, fileRef, setIsSelected, onLoadUpload]}
        />
      ) : null}
      <section
        id="credSection"
        // ref={checkRef}
        className={`UserDocuments ${docFileView ? "width100" : null}`}
        onClick={() => {
          setState({ cursor: 0 });
          setDropSearchVisible(false);
        }}
      >
        <div className="credentialing-title">Credentialing</div>
        <h4 className="upload-documents">
          {`${
            uploadCount === 0 ? "Upload credential" : "Uploaded credentials"
          } (${
            uploadCount > 0 ? String(uploadCount).padStart(2, "0") : "00"
          } / ${
            props.docs.userDocs && props.docs.userDocs.length > 0
              ? props.docs.userDocs.length
              : "00"
          })`}
        </h4>
        <div className="header-info-text">
        Upload necessary credentials and documents that employers require
        </div>
        <div className="input-select-dropdown">
          <div className="input-select">
            <span className="search-cred">
              <AiOutlineSearch color="#b2b2b2" size="1.25rem" />
            </span>
            <input
              value={searchSelect}
              placeholder="Search documents"
              onChange={(e) => getFilterSearchDocs(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <span className="close-search">
              {searchSelect.length > 0 && (
                <MdOutlineClose
                  size="1.25rem"
                  color="#b2b2b2"
                  className="clickable"
                  onClick={resetDocArray}
                />
              )}
            </span>
          </div>
          {dropSearchVisible && (
            <section className="selectDropdown" ref={listRef}>
              {props.docs.userDocs && docsSearchData.length > 0 ? (
                <>
                  {docsSearchData.map((Credential, i) => {
                    refList.push(React.createRef());
                    return (
                      <span
                        className={`docSearchName ${
                          state.cursor === i ? "docSearchName-active" : null
                        }`}
                        onClick={(e) => {
                          // 
                          findSearchDocument(Credential.CredentialDocumentName);
                        }}
                        ref={refList[i]}
                        // onKeyDown={(e) => {
                        //   // 
                        //   // if (e.keyCode === 13)
                        //   findSearchDocument(Credential.CredentialDocumentName);
                        // }}
                      >
                        {Credential.CredentialDocumentName}
                      </span>
                    );
                  })}
                </>
              ) : (
                <span className="noDatafoundDocs">No data found!</span>
              )}
            </section>
          )}
        </div>
        <div className="format-validation">
          <span>*</span> You can only upload JPEG, JPG, PNG, PDF & DOC file formats
          up to 10 MB
        </div>
        <div className="document-border">
          {props.docs.userDocs && props.docs.userDocs.length > 0 ? (
            docsData
            .filter((Credential) =>  !skipSSNDocument(Credential.CredentialDocumentName))
            .map((Credential, i) => {
              if (Credential.CredentialDocumentName)
                return (
                  <React.Fragment key={i}>
                    <div className="cred-documents">
                      <div className="docs-name">
                        {Credential.CredentialDocumentName}
                      </div>
                      {PrivateView && (
                        <div className="upload-actions">
                          {isLoading && (Credential.CredentialDocumentId == currentDocId || Credential._id == currentDocId) ? (
                            <LoadingOutlined />
                          ) : (
                            <React.Fragment>
                              <input
                                // ref={fileRef}
                                ref={(input) => (fileRef.current = input)}
                                type="file"
                                onChange={loadFile}
                                name={Credential.CredentialDocumentName}
                                style={{ display: "none" }}
                              />
                              {Credential.userDoc ? (
                                <React.Fragment>
                                  <div
                                    className="clickable"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      viewDocument(
                                        Credential.userDoc.s3FileKey,
                                        Credential.userDoc.FileName,
                                        Credential.userDoc.FileSize,
                                        Credential.userDoc.DocumentUrl
                                      );
                                    }}
                                  >
                                    <IconMenu />
                                  </div>
                                  <div className="clickable">
                                    {setTimeout(() => {
                                      return true;
                                    }, 1000) && (
                                      <AiOutlineDelete
                                        color="#db2828"
                                        size="1.25rem"
                                        onClick={() => {
                                          setDeleteIsModalVisible(
                                            Credential.CredentialDocumentId
                                          );
                                        }}
                                      />
                                    )}
                                  </div>
                                </React.Fragment>
                              ) : (
                                <div
                                  className="uploadDocs-button clickable"
                                  onClick={() => {
                                    // // 
                                    uploadHandler(Credential._id);
                                  }}
                                >
                                  <AiOutlineUpload size="1.25rem" />
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                );
            })
          ) : (
            <Loader height="20vh" scrolling={false} />
          )}
        </div>
      </section>
      <Modal
        visible={deleteModalVisible}
        footer={false}
        closable={false}
        onCancel={handleCancel}
        className="deleteCredDocModal"
        width={window.innerWidth > 1000 ? "21.88rem" : "20.63rem"}
        centered
      >
        <div>
          <div className="title-pop-up-delete">Delete uploaded credential?</div>
          <div className="delete-popup-subheading">
            <div className="aysCDM">
              Do you want to delete this uploaded document?
            </div>
            <div className="yesNoCDM">
              <div
                className="no"
                onClick={() => setDeleteIsModalVisible(false)}
              >
                Go back
              </div>
              <div
                className="yes"
                onClick={() => {
                  removeHandler(deleteModalVisible);
                  setDeleteIsModalVisible(null);
                }}
              >
                Delete
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {docFileView &&
        window.innerWidth > 1000 &&
        !props.skillListingDetailPage && (
          <CredPDFviewer
            docfileName={docfileName}
            docFileSize={docFileSize}
            docFileType={docFileType}
            setDocFileView={setDocFileView}
            docFile={docFile}
            setDocFile={setDocFile}
            load={load}
            setLoading={setLoading}
            styleImage={styleImage}
            setStyleImage={setStyleImage}
          />
        )}
      <Modal
        visible={
          window.innerWidth < 1000 || props.skillListingDetailPage
            ? docFileView
            : false
        }
        footer={false}
        closable={false}
        onCancel={handlePDFCancel}
        className={`pdfCredDocModal ${
          props.skillListingDetailPage && "skillListingDetailPage-pdf"
        }`}
        width="100%"
        centered
      >
        <CredPDFviewer
          docfileName={docfileName}
          docFileSize={docFileSize}
          docFileType={docFileType}
          setDocFileView={setDocFileView}
          docFile={docFile}
          setDocFile={setDocFile}
          load={load}
          setLoading={setLoading}
          styleImage={styleImage}
          setStyleImage={setStyleImage}
        />
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.docReducer.loading,
    docs: state.docReducer.user,
    user: state.profileReducer.user,
    error: state.docReducer.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAPIData: (params) => {
      dispatch(fetchData(params)); // action dispatched
    },
    uploadDocument: (params) => {
      dispatch(uploadDocument(params)); // action dispatched
    },
    removeDocument: (params) => {
      dispatch(removeDocument(params)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
